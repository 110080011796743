// ==== 💪 Action Types
export const actionTypes = {
  GET_NOTIFICATIONS_BY_HOST: '@notifications/get-notifications-by-host',
  SET_NOTIFICATIONS: '@notifications/set-notifications',
  SET_ERROR: '@notifications/error',
  GET_NOTIFICATIONS_BY_TIME: '@notifications/set-notifications-by-time',
};

// ==== 🎬 Actions

export const updateNotificationsByHost = (host, from, to) => ({
  type: actionTypes.GET_NOTIFICATIONS_BY_HOST,
  payload: {host, from, to},
});
export const updateNotificationsByTime = (from, to) => ({
  type: actionTypes.GET_NOTIFICATIONS_BY_HOST,
  payload: {from, to},
});
export const getSetNotificationsByHost = (data) => ({
  type: actionTypes.SET_NOTIFICATIONS,
  payload: data,
});
