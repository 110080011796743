import { actionTypes } from '@/services/actions/analytics/shares';

// ==== 🗄️ Reducer
export const initialState = {
  share_clicks: {},
  share_clicks_loading: false,
  most_shared_articles: [],
  most_shared_articles_loading: false,
  share_clicks_by_time: [],
  share_clicks_by_time_loading: false,
};

export const sharesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_SHARE_CLICKS: {
      return {
        ...state,
        share_clicks: initialState.share_clicks,
        share_clicks_loading: true,
      };
    }
    case actionTypes.SET_SHARE_CLICKS: {
      return {
        ...state,
        share_clicks: action.payload,
        share_clicks_loading: false,
      };
    }

    case actionTypes.LOAD_MOST_SHARED_ARTICLES: {
      return {
        ...state,
        most_shared_articles: initialState.most_shared_articles,
        most_shared_articles_loading: true,
      };
    }
    case actionTypes.SET_MOST_SHARED_ARTICLES: {
      return {
        ...state,
        most_shared_articles: action.payload,
        most_shared_articles_loading: false,
      };
    }

    case actionTypes.LOAD_SHARE_CLICKS_BY_TIME: {
      return {
        ...state,
        share_clicks_by_time: initialState.share_clicks_by_time,
        share_clicks_by_time_loading: true,
      };
    }
    case actionTypes.LOAD_AGGREGATED_SHARE_CLICKS_BY_TIME: {
      return {
        ...state,
        share_clicks_by_time: initialState.share_clicks_by_time,
        share_clicks_by_time_loading: true,
      };
    }
    case actionTypes.SET_SHARE_CLICKS_BY_TIME: {
      return {
        ...state,
        share_clicks_by_time: action.payload,
        share_clicks_by_time_loading: false,
      };
    }

    default:
      return state;
  }
};

export default sharesReducer;
