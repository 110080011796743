import dayjs from 'dayjs';
import mapKeys from 'lodash/mapKeys';
import pick from 'lodash/pick';
import Cookies from 'js-cookie';
import {toaster} from "evergreen-ui";

const cookieOptions = { sameSite: 'Lax' };
export const hashCode = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = ~~((hash << 5) - hash + str.charCodeAt(i));
  }
  return hash;
};

export const randomHashCode = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = ~~((hash << 12) - hash + str.charCodeAt(i));
  }
  return hash;
};

export const blockedQuizPagesApiKeyList = [
  'bc9fd4eb-d7be-4d00-87ef-b570563b015f'
]

export const compare_dates = (d1, d2) => {
  const date1 = dayjs(d1);
  const date2 = dayjs(d2);
  if (date1.isSame(date2)) {
    return 0;
  }
  return date1.isAfter(date2) ? 1 : -1;
};

export const num2percent = (num, decimals = 2) => {
  const exp = Math.pow(10, decimals);
  return Math.round(num * 100 * exp) / exp + '%';
};

export const covers = (val1, val2) => {
  val1 = val1 ?? '';
  val2 = val2 ?? '';
  return val1.toString().toLowerCase().includes(val2.toString().toLowerCase());
};

export const containsFilter = (arr, filter) =>
  Object.values(arr).some((value) => covers(value, filter));

export const containsFilterByAdUnits = (arr, filter, by) => {
  if (typeof arr === 'object') {
    if (by === 'adUnit') {
      return arr.filter((obj) =>
        Object.keys(obj).some((key) => covers(obj.adUnit, filter)),
      );
    } else if (by === 'category') {
      return arr.filter((obj) =>
        Object.keys(obj).some((key) => covers(obj.category, filter)),
      );
    } else if (by === 'device') {
      return arr.filter((obj) =>
        Object.keys(obj).some((key) => covers(obj.device, filter)),
      );
    }
  }
};

export const filterUnknown = (data) =>
  mapKeys(data, (value, key) =>
    key === '<UNKNOWN>' || key === '' ? 'unknown' : key,
  );

export const filterDevices = (devices) =>
  pick(devices, ['Desktop', 'Tablet', 'Mobile', 'AMP']);

export const stripHtml = (html) => {
  var tmp = document.createElement('DIV');
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || '';
};

export const getImageFromString = (str) => {
  const htmlElement = document.createElement('div');
  htmlElement.innerHTML = str;
  const imageElements = htmlElement.getElementsByTagName('img');
  if (imageElements.length > 0) {
    return imageElements[0].src;
  }
  return null;
};

export const calculateTotalStatsFromAPIResponse = (sitesArr) => {
  return sitesArr.reduce(
    (acc, item) => {
      return {
        pageViews: acc.pageViews + item.pageViews,
        comments: acc.comments + item.comments,
        shares: acc.shares + item.shares,
        emotes: acc.emotes + item.emotes,
        // recommendations: acc.recommendations + item.recommends,
        pendingComments: acc.pendingComments + item.pending,
        commentViews: acc.commentViews + item.commentViews,
        quizAnswers: acc.quizAnswers + item.quizAnswers,
        revenue: acc.revenue + item.revenue,
        replies: acc.replies + item.replies,
        users: acc.users + item.users,
        // runChatMessages: acc.runChatMessages + item.runChatMessages,
        // runChatOpened: acc.runChatOpened + item.runChatOpened,
        audio:
          acc.audio +
          (item.audioClicked || 0) +
          (item.audioPaused || 0) +
          (item.audioResumed || 0) +
          (item.audioRestarted || 0),
      };
    },
    {
      pageViews: 0,
      comments: 0,
      shares: 0,
      emotes: 0,
      // recommendations: 0,
      pendingComments: 0,
      // runChatOpened: 0,
      // runChatMessages: 0,
      commentViews: 0,
      quizAnswers: 0,
      revenue: 0,
      replies: 0,
      users: 0,
      audio: 0,
    },
  );
};

export const calculateTotalDifferenceAnalyticsStatsFromAPIResponse = (
  sitesArr,
) => {
  return sitesArr.reduce(
    (acc, elem) => {
      return {
        pageViews: acc.pageViews + elem.pageViews,
        comments: acc.comments + elem.comments,
        shares: acc.shares + elem.shares,
        emotes: acc.emotes + elem.emotes,
        // recommends: acc.recommends + elem.recommends,
        users: acc.users + elem.users,
        // runChatMessages: acc.runChatMessages + elem.runChatMessages,
        // runChatOpened: acc.runChatOpened + elem.runChatOpened,
        replies: acc.replies + elem.replies,
        commentViews: acc.commentViews + elem.commentViews,
        quizAnswers: acc.quizAnswers + elem.quizAnswers,
        revenue: acc.revenue + elem.revenue,
        audioClicks:
          acc?.audioClicks +
          elem?.audioClicked +
          elem?.audioPaused +
          elem?.audioRestarted +
          elem?.audioResumed,
      };
    },
    {
      pageViews: 0,
      comments: 0,
      shares: 0,
      emotes: 0,
      // recommends: 0,
      users: 0,
      // runChatOpened: 0,
      // runChatMessages: 0,
      replies: 0,
      commentViews: 0,
      quizAnswers: 0,
      revenue: 0,
      audioClicks: 0,
    },
  );
};

export const calculateTotalDifferencePercentageFromAPIResponse = (
  lastWeekData,
  yesterdayData,
) => {
  const lastWeekTotal = calculateTotalStatsFromAPIResponse(lastWeekData);
  const yesterdayTotal = calculateTotalStatsFromAPIResponse(yesterdayData);
  const res = {};
  let defaultMax = 100;

  Object.keys(lastWeekTotal).forEach((elem) => {
    if (!yesterdayTotal[elem]) {
      defaultMax = -100;
    }
    let percent = 0;
    if (elem === 'comments') {
      percent =
        Number(lastWeekTotal[elem]) && Number(yesterdayTotal[elem])
          ? (
              100 -
              ((lastWeekTotal[elem] +
                lastWeekTotal.replies -
                (yesterdayTotal[elem] + yesterdayTotal.replies)) *
                100) /
                (lastWeekTotal[elem] + lastWeekTotal.replies)
            ).toFixed(2)
          : defaultMax;
    } else {
      percent =
        Number(lastWeekTotal[elem]) && Number(yesterdayTotal[elem])
          ? (
              100 -
              ((lastWeekTotal[elem] - yesterdayTotal[elem]) * 100) /
                lastWeekTotal[elem]
            ).toFixed(2)
          : defaultMax;
    }

    res[elem] = percent;
  });

  return res;
};

export const calculateAverageOfSiteStatsFromAPIResponse = (siteStats) => {
  const siteStatsTotal = calculateTotalStatsFromAPIResponse(siteStats);
  const res = {};

  Object.keys(siteStatsTotal).forEach((elem) => {
    res[elem] = Math.round(siteStatsTotal[elem] / siteStats.length) || 0;
  });

  return res;
};

export const adjustDateRange = (
  dateRange1,
  dateRange2,
  defaultDiffDays = 30,
) => {
  let from = null,
    to = null;
  if (dayjs(dateRange1[0], 'YYYY-MM-DD', true).isValid()) {
    from = dayjs(dateRange1[0]);
  } else if (dayjs(dateRange2[0], 'YYYY-MM-DD', true).isValid()) {
    from = dayjs(dateRange2[0]);
  }
  if (dayjs(dateRange1[1], 'YYYY-MM-DD', true).isValid()) {
    to = dayjs(dateRange1[1]);
  } else if (dayjs(dateRange2[1], 'YYYY-MM-DD', true).isValid()) {
    to = dayjs(dateRange2[1]);
  }
  if (
    !dayjs(from, 'YYYY-MM-DD', true).isValid() &&
    !dayjs(to, 'YYYY-MM-DD', true).isValid()
  ) {
    from = dayjs().subtract(defaultDiffDays, 'days');
    to = dayjs();
  } else if (!dayjs(from, 'YYYY-MM-DD', true).isValid()) {
    from = dayjs(to).subtract(defaultDiffDays, 'days');
  } else if (!dayjs(to, 'YYYY-MM-DD', true).isValid()) {
    to = dayjs(from).add(defaultDiffDays, 'days');
  }
  return {
    from: dayjs(from).format('YYYY-MM-DD'),
    to: dayjs(to).format('YYYY-MM-DD'),
  };
};

export const adjustHost = (host1, host2, hosts) => {
  let host = '';
  if (hosts.length == 0 || hosts.includes(host1)) {
    host = host1;
  } else if ((host2 == '' && hosts.length >= 2) || hosts.includes(host2)) {
    host = host2;
  } else if (hosts.length > 0) {
    host = hosts[0];
  }
  return host;
};

export const setTokenCookie = (token) => {
  Cookies.set('dash_token', token, cookieOptions);
  Cookies.set('vuukle_token', token, cookieOptions);
};

export const removeTokenCookie = () => {
  Cookies.remove('dash_token');
  Cookies.remove('dash_token', cookieOptions);
  Cookies.remove('vuukle_token', cookieOptions);
};
export const removeAgreedWithTermsCookie = () =>
  Cookies.remove('agreed-with-terms', cookieOptions);

export const revenueNumbToDollars = (numb) =>
  `$${kmFormat.format(numb.toFixed(0))}`;

export const dollarFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const dollarFormat2Decimals = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

export const kmFormat = new Intl.NumberFormat('en-US', {
  notation: 'compact',
  compactDisplay: 'short',
});

export const matchesRoute = (routeToCheck) =>
  global &&
  global.location &&
  global.location.href.indexOf(routeToCheck) !== -1;

/** Checks for global object presence and redirects based on arguments */
export const checkForRouteAndRedirect = ({
  routeToCheck,
  comparison = 'doesNotMatchRoute',
  redirectLink,
}) => {
  if (comparison === 'matchesRoute') {
    if (matchesRoute(routeToCheck)) {
      process.env.NODE_ENV === 'development'
        ? (global.location.href = redirectLink)
        : (global.location.href = `//${global.location.hostname}${redirectLink}`);
    }
  }

  if (comparison === 'doesNotMatchRoute') {
    if (!matchesRoute(routeToCheck)) {
      process.env.NODE_ENV === 'development'
        ? (global.location.href = redirectLink)
        : (global.location.href = `//${global.location.hostname}${redirectLink}`);
    }
  }
};

export const makeArrayFromOffensiveList = (offensiveList) => {
  if (typeof offensiveList !== 'string' || !offensiveList) {
    return [];
  }

  return offensiveList.indexOf('|') > -1
    ? offensiveList.split('|')
    : [offensiveList];
};

export const calculatePermissions = (permissions) => {
  let total = 0;

  if (permissions.all) {
    return 1015;
  } else {
    if (permissions.revenue) {
      total++;
    }

    if (permissions.analytics) {
      total += 2;
    }

    if (permissions.moderation) {
      total += 4;
    }

    if (permissions.integration) {
      total += 16;
    }

    if (permissions.userManagement) {
      total += 32;
    }

    if (permissions.settings) {
      total += 64;
    }

    if (permissions.adManager) {
      total += 128;
    }

    if (permissions.webNotifications) {
      total += 256;
    }

    if (permissions.quizzlyAi) {
      total += 512;
    }
  }

  return total;
};

export const getPermissionsFromPoints = (points) => {
  const permissionsObject = {};

  if (points === 1015) {
    return { all: true };
  } else {
    if (points >= 512) {
      permissionsObject.quizzlyAi = true;
      points -= 512;
    }

    if (points >= 256) {
      permissionsObject.webNotifications = true;
      points -= 256;
    }

    if (points >= 128) {
      permissionsObject.adManager = true;
      points -= 128;
    }

    if (points >= 64) {
      permissionsObject.settings = true;
      points -= 64;
    }

    if (points >= 32) {
      permissionsObject.userManagement = true;
      points -= 32;
    }

    if (points >= 16) {
      permissionsObject.integration = true;
      points -= 16;
    }

    if (points >= 4) {
      permissionsObject.moderation = true;
      points -= 4;
    }

    if (points >= 2) {
      permissionsObject.analytics = true;
      points -= 2;
    }

    if (points >= 1) {
      permissionsObject.revenue = true;
      points--;
    }
  }

  return permissionsObject;
};

export const calculateLoginTypes = (loginTypes) => {
  let total = 0;

  if (loginTypes.password) {
    total++;
  }

  if (loginTypes.facebook) {
    total += 2;
  }

  if (loginTypes.google) {
    total += 4;
  }

  if (loginTypes.twitter) {
    total += 8;
  }

  if (loginTypes.disqus) {
    total += 16;
  }

  if (loginTypes.guest) {
    total += 32;
  }

  if (loginTypes.sso) {
    total += 64;
  }

  return total;
};

export const calculateLoginTypes2 = (loginTypesArr, rawForm = false) => {
  let total = 0;

  if (
    loginTypesArr.includes('password') ||
    (rawForm && loginTypesArr[0].isAllowed)
  ) {
    total++;
  }

  if (
    loginTypesArr.includes('facebook') ||
    (rawForm && loginTypesArr[1].isAllowed)
  ) {
    total += 2;
  }

  if (
    loginTypesArr.includes('google') ||
    (rawForm && loginTypesArr[2].isAllowed)
  ) {
    total += 4;
  }

  if (
    loginTypesArr.includes('twitter') ||
    (rawForm && loginTypesArr[3].isAllowed)
  ) {
    total += 8;
  }

  if (
    loginTypesArr.includes('disqus') ||
    (rawForm && loginTypesArr[4].isAllowed)
  ) {
    total += 16;
  }

  // if (
  //   loginTypesArr.includes("guest") ||
  //   (rawForm && loginTypesArr[5].isAllowed)
  // ) {
  //   total += 32;
  // }

  if (
    loginTypesArr.includes('sso') ||
    (rawForm && loginTypesArr[5].isAllowed)
  ) {
    total += 64;
  }

  return total;
};
export const paginationOptions = [30, 50, 100];

export const colorArray = ['#4FCDF0', '#4F86F0', '#B34FF0', '#F04FB9', '#F0A64F'];

export const isInteger = (num) => {
  return (num ^ 0) === num;
};

export const isArrayEqual = (arr1, arr2) => {
  if (arr1.length === arr2.length) {
    let isEqual = true;
    for (let i = 0; i < arr1.length; i++) {
      if (!arr2.includes(arr1[i])) {
        isEqual = false;
        break;
      }
    }
    return isEqual;
  }
  return false;
};

export const adImpressionsDataApiKeys = [
  'ee5aca27-743e-4c3c-a4e8-c64bdf9cfda0',
];

export const copyTextOrElementContents = (text, element, callback) => {
  if (typeof ClipboardItem !== 'undefined' && text) {
    const type = 'text/plain';
    const blob = new Blob([text], { type });
    const data = [new ClipboardItem({ [type]: blob })];

    navigator.clipboard.write(data).then(() => {
      if (typeof callback === 'function') {
        callback();
      }
    });
  } else {
    if (element !== null) {
      element.select();
      document.execCommand('copy');
      if (typeof callback === 'function') {
        callback();
      }
    }
  }
};

export const copyText = (text) => {
  if (typeof navigator.clipboard === 'object' && typeof navigator.clipboard.writeText === 'function') {
    navigator.clipboard.writeText(text)
    toaster.success('Article ID successfully copied!');
  } else {
    const elem = document.createElement('input');
    elem.textContent = text;
    elem.select();
    document.execCommand('copy');
    elem.remove();
  }
}

export const colors4 = ['#4FCDF0', '#4F86F0', '#B34FF0', '#F04FB9'];
export const colors5 = ['#4FCDF0', '#4F86F0', '#B34FF0', '#F04FB9', '#F0A64F'];

export const monthArray = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const socialOptions = {
  facebook: 'Facebook',
  twitter: 'Twitter',
  whatsapp: 'Whatsapp',
  telegram: 'Telegram',
  linkedin: 'Linkedin',
  reddit: 'Reddit',
  messenger: 'Messenger',
  pinterest: 'Pinterest',
  flipboard: 'Flipboard',
  email: 'Email',
  tumblr: 'Tumblr',
  newsbreak: 'Newsbreak',
  viber: 'Viber',
  parler: 'Parler',
  koo: 'Koo',
};

export const countryCodes = [
  "AD", "AE", "AF", "AG", "AI", "AL", "AM", "AO", "AQ", "AR",
  "AS", "AT", "AU", "AW", "AX", "AZ", "BA", "BB", "BD", "BE",
  "BF", "BG", "BH", "BI", "BJ", "BL", "BM", "BN", "BO", "BQ",
  "BR", "BS", "BT", "BV", "BW", "BY", "BZ", "CA", "CC", "CD",
  "CF", "CG", "CH", "CI", "CK", "CL", "CM", "CN", "CO", "CR",
  "CU", "CV", "CW", "CX", "CY", "CZ", "DE", "DJ", "DK", "DM",
  "DO", "DZ", "EC", "EE", "EG", "EH", "ER", "ES", "ET", "FI",
  "FJ", "FK", "FM", "FO", "FR", "GA", "GB", "GD", "GE", "GF",
  "GG", "GH", "GI", "GL", "GM", "GN", "GP", "GQ", "GR", "GS",
  "GT", "GU", "GW", "GY", "HK", "HM", "HN", "HR", "HT", "HU",
  "ID", "IE", "IL", "IM", "IN", "IO", "IQ", "IR", "IS", "IT",
  "JE", "JM", "JO", "JP", "KE", "KG", "KH", "KI", "KM", "KN",
  "KP", "KR", "KW", "KY", "KZ", "LA", "LB", "LC", "LI", "LK",
  "LR", "LS", "LT", "LU", "LV", "LY", "MA", "MC", "MD", "ME",
  "MF", "MG", "MH", "MK", "ML", "MM", "MN", "MO", "MP", "MQ",
  "MR", "MS", "MT", "MU", "MV", "MW", "MX", "MY", "MZ", "NA",
  "NC", "NE", "NF", "NG", "NI", "NL", "NO", "NP", "NR", "NU",
  "NZ", "OM", "PA", "PE", "PF", "PG", "PH", "PK", "PL", "PM",
  "PN", "PR", "PS", "PT", "PW", "PY", "QA", "RE", "RO", "RS",
  "RU", "RW", "SA", "SB", "SC", "SD", "SE", "SG", "SH", "SI",
  "SJ", "SK", "SL", "SM", "SN", "SO", "SR", "SS", "ST", "SV",
  "SX", "SY", "SZ", "TC", "TD", "TF", "TG", "TH", "TJ", "TK",
  "TL", "TM", "TN", "TO", "TR", "TT", "TV", "TW", "TZ", "UA",
  "UG", "UM", "US", "UY", "UZ", "VA", "VC", "VE", "VG", "VI",
  "VN", "VU", "WF", "WS", "YE", "YT", "ZA", "ZM", "ZW"
];

export const countryNames = [
  "Andorra", "United Arab Emirates", "Afghanistan", "Antigua and Barbuda", "Anguilla", "Albania", "Armenia", "Angola", "Antarctica", "Argentina",
  "American Samoa", "Austria", "Australia", "Aruba", "Åland Islands", "Azerbaijan", "Bosnia and Herzegovina", "Barbados", "Bangladesh", "Belgium",
  "Burkina Faso", "Bulgaria", "Bahrain", "Burundi", "Benin", "Saint Barthélemy", "Bermuda", "Brunei", "Bolivia", "Bonaire, Sint Eustatius and Saba",
  "Brazil", "The Bahamas", "Bhutan", "Bouvet Island", "Botswana", "Belarus", "Belize", "Canada", "Cocos (Keeling) Islands", "The Democratic Republic of the Congo",
  "Central African Republic", "Congo", "Switzerland", "Côte d'Ivoire", "Cook Islands", "Chile", "Cameroon", "China", "Colombia", "Costa Rica",
  "Cuba", "Cape Verde", "Curaçao", "Christmas Island", "Cyprus", "Czech Republic", "Germany", "Djibouti", "Denmark", "Dominica",
  "Dominican Republic", "Algeria", "Ecuador", "Estonia", "Egypt", "Western Sahara", "Eritrea", "Spain", "Ethiopia", "Finland",
  "Fiji", "Falkland Islands", "Federated States of Micronesia", "Faroe Islands", "France", "Gabon", "United Kingdom", "Grenada", "Georgia", "French Guiana",
  "Guernsey", "Ghana", "Gibraltar", "Greenland", "Gambia", "Guinea", "Guadeloupe", "Equatorial Guinea", "Greece", "South Georgia and the South Sandwich Islands",
  "Guatemala", "Guam", "Guinea-Bissau", "Guyana", "Hong Kong", "Heard Island and McDonald Islands", "Honduras", "Croatia", "Haiti", "Hungary",
  "Indonesia", "Ireland", "Israel", "Isle of Man", "India", "British Indian Ocean Territory", "Iraq", "Iran", "Iceland", "Italy",
  "Jersey", "Jamaica", "Jordan", "Japan", "Kenya", "Kyrgyzstan", "Cambodia", "Kiribati", "Comoros", "Saint Kitts and Nevis",
  "North Korea", "South Korea", "Kuwait", "Cayman Islands", "Kazakhstan", "Laos", "Lebanon", "Saint Lucia", "Liechtenstein", "Sri Lanka",
  "Liberia", "Lesotho", "Lithuania", "Luxembourg", "Latvia", "Libya", "Morocco", "Monaco", "Moldova", "Montenegro",
  "Saint Martin", "Madagascar", "Marshall Islands", "Macedonia", "Mali", "Myanmar", "Mongolia", "Macao", "Northern Mariana Islands", "Martinique",
  "Mauritania", "Montserrat", "Malta", "Mauritius", "Maldives", "Malawi", "Mexico", "Malaysia", "Mozambique", "Namibia",
  "New Caledonia", "Niger", "Norfolk Island", "Nigeria", "Nicaragua", "Netherlands", "Norway", "Nepal", "Nauru", "Niue",
  "New Zealand", "Oman", "Panama", "Peru", "French Polynesia", "Papua New Guinea", "Philippines", "Pakistan", "Poland", "Saint Pierre and Miquelon",
  "Pitcairn Islands", "Puerto Rico", "Palestine", "Portugal", "Palau", "Paraguay", "Qatar", "Réunion", "Romania", "Serbia",
  "Russia", "Rwanda", "Saudi Arabia", "Solomon Islands", "Seychelles", "Sudan", "Sweden", "Singapore", "Saint Helena, Ascension and Tristan da Cunha", "Slovenia",
  "Svalbard and Jan Mayen", "Slovakia", "Sierra Leone", "San Marino", "Senegal", "Somalia", "Suriname", "South Sudan", "São Tomé and Príncipe", "El Salvador",
  "Sint Maarten", "Syria", "Swaziland", "Turks and Caicos Islands", "Chad", "French Southern Territories", "Togo", "Thailand", "Tajikistan", "Tokelau",
  "Timor-Leste", "Turkmenistan", "Tunisia", "Tonga", "Turkey", "Trinidad and Tobago", "Tuvalu", "Taiwan", "Tanzania", "Ukraine",
  "Uganda", "United States Minor Outlying Islands", "United States", "Uruguay", "Uzbekistan", "Vatican City", "Saint Vincent and the Grenadines", "Venezuela", "British Virgin Islands", "U.S. Virgin Islands",
  "Vietnam", "Vanuatu", "Wallis and Futuna", "Samoa", "Yemen", "Mayotte", "South Africa", "Zambia", "Zimbabwe"
];

export const stringToHex = (str) => {
  let hex = '';
  for (let i = 0; i < str.length; i++) {
    const charCode = str.charCodeAt(i);
    const hexValue = charCode.toString(16);
    hex += hexValue.padStart(2, '0');
  }
  return hex;
};
export const hexToString = (hex) => {
  let str = '';
  for (let i = 0; i < hex.length; i += 2) {
    const hexValue = hex.substr(i, 2);
    const decimalValue = parseInt(hexValue, 16);
    str += String.fromCharCode(decimalValue);
  }
  return str;
}
