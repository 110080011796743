import { call, put, select, takeLatest } from 'redux-saga/effects';
import dayjs from 'dayjs';
import { toaster } from 'evergreen-ui';
import {
  getShareClicksbyTimebyApiKey,
  getShareClicksbyNetworkbyTimebyHost,
  getShareClicksbyNetworkbyTimebyApikey,
  getShareClicksByTimeByHost,
  getMostSharedArticlesByNetworkbyTimebyHost,
  getMostSharedArticlesByNetworkbyTimebyApiKey,
} from '@/services/api/analytics/shares';
import { actionTypes } from '@/services/actions/analytics/shares';
import { initialState } from '@/services/reducers/analytics/comments';

export function* loadShareClicks(params) {
  try {
    const { token, apiKey } = yield select(({ session }) => session);
    const { dateRange } = yield select(({ filter }) => filter);
    const host = yield select(({ filter }) => filter.host);

    const result = yield call(
      host
        ? getShareClicksbyNetworkbyTimebyHost
        : getShareClicksbyNetworkbyTimebyApikey,
      token,
      host || apiKey,
      params.payload,
      dayjs(dateRange[0]).format('YYYY-MM-DD'),
      dayjs(dateRange[1]).format('YYYY-MM-DD'),
    );

    yield put({
      type: actionTypes.SET_SHARE_CLICKS,
      payload: result || initialState.share_clicks,
    });
  } catch (e) {
    console.log('err', e);
    toaster.danger('Error. Could not fetch list of commenters.', {
      id: 'analytics-top-commenters',
    });
    yield put({
      type: actionTypes.SET_SHARE_CLICKS,
      payload: initialState.share_clicks,
    });
  }
}

export function* loadMostSharedArticles(params) {
  try {
    const { token, apiKey } = yield select(({ session }) => session);
    const { dateRange } = yield select(({ filter }) => filter);
    const host = yield select(({ filter }) => filter.host);

    const result = yield call(
      host
        ? getMostSharedArticlesByNetworkbyTimebyHost
        : getMostSharedArticlesByNetworkbyTimebyApiKey,
      token,
      host || apiKey,
      params.payload.network,
      dayjs(dateRange[0]).format('YYYY-MM-DD'),
      dayjs(dateRange[1]).format('YYYY-MM-DD'),
      params.payload.page,
      params.payload.pageSize,
    );

    yield put({
      type: actionTypes.SET_MOST_SHARED_ARTICLES,
      payload: result || initialState.most_shared_articles,
    });
  } catch (e) {
    console.log('err', e);
    toaster.danger('Error. Could not fetch list of commenters.', {
      id: 'analytics-top-commenters',
    });
    yield put({
      type: actionTypes.SET_MOST_SHARED_ARTICLES,
      payload: initialState.most_shared_articles,
    });
  }
}

// ShareClicksByTime
export function* loadShareClicksByTime() {
  try {
    const { token, apiKey } = yield select(({ session }) => session);
    const { dateRange } = yield select(({ filter }) => filter);
    const host = yield select(({ filter }) => filter.host);

    const result = yield call(
      host ? getShareClicksByTimeByHost : getShareClicksbyTimebyApiKey,
      host || apiKey,
      dayjs(dateRange[0]).format('YYYY-MM-DD'),
      dayjs(dateRange[1]).format('YYYY-MM-DD'),
      token,
    );

    yield put({
      type: actionTypes.SET_SHARE_CLICKS_BY_TIME,
      payload: result || initialState.most_shared_articles,
    });
  } catch (e) {
    console.log('err', e);
    toaster.danger('Error. Could not fetch list of commenters.', {
      id: 'analytics-top-commenters',
    });
    yield put({
      type: actionTypes.SET_SHARE_CLICKS_BY_TIME,
      payload: initialState.most_shared_articles,
    });
  }
}

export function* loadAggregatedShareClicksByTime(params) {
  try {
    const { token, apiKey } = yield select(({ session }) => session);
    const { dateRange } = yield select(({ filter }) => filter);
    const host = yield select(({ filter }) => filter.host);
    const { groupType } = params.payload;

    const result = yield call(
      host ? getShareClicksByTimeByHost : getShareClicksbyTimebyApiKey,
      host || apiKey,
      dayjs(dateRange[0]).format('YYYY-MM-DD'),
      dayjs(dateRange[1]).format('YYYY-MM-DD'),
      token,
      groupType,
    );

    yield put({
      type: actionTypes.SET_SHARE_CLICKS_BY_TIME,
      payload: result || initialState.most_shared_articles,
    });
  } catch (e) {
    console.log('err', e);
    toaster.danger('Error. Could not fetch list of commenters.', {
      id: 'analytics-top-commenters',
    });
    yield put({
      type: actionTypes.SET_SHARE_CLICKS_BY_TIME,
      payload: initialState.most_shared_articles,
    });
  }
}

export default function* sharesSaga() {
  yield takeLatest(actionTypes.LOAD_SHARE_CLICKS, loadShareClicks);
  yield takeLatest(
    actionTypes.LOAD_MOST_SHARED_ARTICLES,
    loadMostSharedArticles,
  );
  yield takeLatest(
    actionTypes.LOAD_SHARE_CLICKS_BY_TIME,
    loadShareClicksByTime,
  );
  yield takeLatest(
    actionTypes.LOAD_AGGREGATED_SHARE_CLICKS_BY_TIME,
    loadAggregatedShareClicksByTime,
  );
}
