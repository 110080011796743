import axios from 'axios';
import { toaster } from 'evergreen-ui';

export const getSurveyStatsByDayAPI = (token, surveyId, from, to) =>
  axios
    .get(
      `${process.env.API_BASE_URL}api/v1/QuizAnalytics/GetSurveyStatsByDay`,
      {
        params: { from, to, surveyId },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;
      if (success) {
        return data;
      } else {
        toaster.danger(errors[0] ? errors[0] : 'Something went wrong');
        return;
      }
      throw new Error('Not valid details');
    });

export const getCitiesAPI = () =>
  axios
    .get(
      `https://apitest.vuukle.com/Cities`,
      {},
    )
    .then((response) => {
      const { data, success, errors } = response.data;
      if (success) {
        return data;
      } else {
        toaster.danger(errors[0] ? errors[0] : 'Something went wrong');
        return;
      }
      throw new Error('Not valid details');
    });

export const getTableDataAPI = (token, surveyId, from, to, share_token) =>
  axios
    .get(
      `${process.env.API_BASE_URL}api/v1/QuizAnalytics/SurveyAnalytics/${surveyId}?domain=true&city=true&country=true&demographicData=true`,
      {
        params: { from, to },
        headers: {
          Authorization: `Bearer ${share_token || token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;
      if (success) {
        return data;
      } else {
        toaster.danger(errors[0] ? errors[0] : 'Something went wrong');
        return;
      }
      throw new Error('Not valid details');
    });

export const getSurveyQuestionsAnswersAPI = (
  token,
  surveyId,
  from,
  to,
  share_token,
) =>
  axios
    .get(
      `${process.env.API_BASE_URL}api/v1/QuizAnalytics/GetSurveyQuestionsAnswers`,
      {
        params: { from, to, surveyId },
        headers: {
          Authorization: `Bearer ${share_token || token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;
      if (success) {
        return data;
      } else {
        toaster.danger(errors[0] ? errors[0] : 'Something went wrong');
        return;
      }
      throw new Error('Not valid details');
    });

export const getSurveyQuestionStatsAPI = (
  token,
  surveyId,
  from,
  to,
  questionId,
  share_token,
) =>
  axios
    .get(
      `${process.env.API_BASE_URL}api/v1/QuizAnalytics/GetSurveyQuestionStats`,
      {
        params: { from, to, surveyId, questionId },
        headers: {
          Authorization: `Bearer ${share_token || token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;
      if (success) {
        return data;
      } else {
        toaster.danger(errors[0] ? errors[0] : 'Something went wrong');
        return;
      }
      throw new Error('Not valid details');
    });

export const getSurveyQuestionStatsDemographicAPI = (
  token,
  surveyId,
  from,
  to,
  questionId,
  option,
  share_token,
) =>
  axios
    .get(
      `${process.env.API_BASE_URL}api/v1/QuizAnalytics/GetSurveyQuestionDemographicData`,
      {
        params: { from, to, surveyId, questionId, option },
        headers: {
          Authorization: `Bearer ${share_token || token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;
      if (success) {
        return data;
      } else {
        toaster.danger(errors[0] ? errors[0] : 'Something went wrong');
        return;
      }
      throw new Error('Not valid details');
    });

export const getSurveyQuestionStatsDemographicGroupAPI = (
  token,
  surveyId,
  from,
  to,
  questionId,
  option,
  share_token,
) =>
  axios
    .get(
      `${process.env.API_BASE_URL}api/v1/QuizAnalytics/GetSurveyStatsByResponseGroup`,
      {
        params: { from, to, surveyId, questionId, option },
        headers: {
          Authorization: `Bearer ${share_token || token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;
      if (success) {
        return data;
      } else {
        toaster.danger(errors[0] ? errors[0] : 'Something went wrong');
        return;
      }
      throw new Error('Not valid details');
    });

export const getSurveyStatsByGeoAPI = (
  token,
  surveyId,
  from,
  to,
  share_token,
  questionId,
  option,
) =>
  axios
    .get(
      `${process.env.API_BASE_URL}api/v1/QuizAnalytics/GetSurveyStatsByGeo`,
      {
        params: { from, to, surveyId, questionId, option },
        headers: {
          Authorization: `Bearer ${share_token || token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;
      if (success) {
        return data;
      } else {
        toaster.danger(errors[0] ? errors[0] : 'Something went wrong');
        return;
      }
      throw new Error('Not valid details');
    });

export const getSurveyStatsByCityAPI = (
  token,
  surveyId,
  from,
  to,
  share_token,
  questionId,
  option,
) =>
  axios
    .get(
      `${process.env.API_BASE_URL}api/v1/QuizAnalytics/GetSurveyStatsByCity`,
      {
        params: { from, to, surveyId, questionId, option },
        headers: {
          Authorization: `Bearer ${share_token || token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;
      if (success) {
        return data;
      } else {
        toaster.danger(errors[0] ? errors[0] : 'Something went wrong');
        return;
      }
      throw new Error('Not valid details');
    });

export const getSurveyStatsByAgeAPI = (
  token,
  surveyId,
  from,
  to,
  share_token,
) =>
  axios
    .get(
      `${process.env.API_BASE_URL}api/v1/QuizAnalytics/GetSurveyStatsByAge`,
      {
        params: { from, to, surveyId },
        headers: {
          Authorization: `Bearer ${share_token || token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;
      if (success) {
        return data;
      } else {
        toaster.danger(errors[0] ? errors[0] : 'Something went wrong');
        return;
      }
      throw new Error('Not valid details');
    });

export const getResponseGroupStatsAPI = (
  token,
  surveyId,
  from,
  to,
  share_token,
) =>
  axios
    .get(
      `${process.env.API_BASE_URL}api/v1/QuizAnalytics/GetResponseGroupStats`,
      {
        params: { from, to, surveyId },
        headers: {
          Authorization: `Bearer ${share_token || token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;
      if (success) {
        return data;
      } else {
        toaster.danger(errors[0] ? errors[0] : 'Something went wrong');
        return;
      }
      throw new Error('Not valid details');
    });

export const getCompletionRateAPI = (token, surveyId, from, to, share_token) =>
  axios
    .get(
      `${process.env.API_BASE_URL}api/v1/QuizAnalytics/GetSurveyCompletionRate`,
      {
        params: { from, to, surveyId },
        headers: {
          Authorization: `Bearer ${share_token || token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;
      if (success) {
        return data;
      } else {
        toaster.danger(errors[0] ? errors[0] : 'Something went wrong');
        return;
      }
      throw new Error('Not valid details');
    });

export const getSurveyStatsByGenderAPI = (
  token,
  surveyId,
  from,
  to,
  share_token,
) =>
  axios
    .get(
      `${process.env.API_BASE_URL}api/v1/QuizAnalytics/GetSurveyStatsByGender`,
      {
        params: { from, to, surveyId },
        headers: {
          Authorization: `Bearer ${share_token || token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;
      if (success) {
        return data;
      } else {
        toaster.danger(errors[0] ? errors[0] : 'Something went wrong');
        return;
      }
      throw new Error('Not valid details');
    });

export const getSurveyQuestionStatsPerDayAPI = (token, surveyId, from, to) =>
  axios
    .get(
      `${process.env.API_BASE_URL}api/v1/QuizAnalytics/GetSurveyStatsByDayByQuestions`,
      {
        params: { from, to, surveyId },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;
      if (success) {
        return data;
      } else {
        toaster.danger(errors[0] ? errors[0] : 'Something went wrong');
        return;
      }
      throw new Error('Not valid details');
    });

export const getSurveyDataApi = (token, host, share_token) =>
  axios
    .get(`${process.env.API_BASE_URL}api/v1/Quiz/GetQuizBrandSurvey`, {
      params: { host },
      headers: {
        Authorization: `Bearer ${share_token || token}`,
      },
    })
    .then((response) => {
      const { data, activeSurveysCount, success, errors } = response.data;
      if (success) {
        return [data, activeSurveysCount];
      } else {
        toaster.danger(errors[0] ? errors[0] : 'Something went wrong');
        return;
      }
      throw new Error('Not valid details');
    });

export const getSurveyDataSingleApi = (token, host, share_token, surveyId) =>
  axios
    .get(
      `${process.env.API_BASE_URL}api/v1/Quiz/GetSingleBrandSurvey?surveyId=${surveyId}`,
      {
        params: { host },
        headers: {
          Authorization: `Bearer ${share_token || token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;
      if (success) {
        return [data];
      } else {
        toaster.danger(errors[0] ? errors[0] : 'Something went wrong');
        return;
      }
      throw new Error('Not valid details');
    });

export const getQuizzlyLeadsClickCountApi = (
  token,
  surveyId,
  from,
  to,
  share_token,
) =>
  axios
    .get(
      `${process.env.API_BASE_URL}api/v1/QuizAnalytics/GetQuizzlyLeadsClickCountByDay?surveyId=${surveyId}&from=${from}&to=${to}`,
      {
        headers: {
          Authorization: `Bearer ${share_token || token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;
      if (success) {
        return [data];
      } else {
        toaster.danger(errors[0] ? errors[0] : 'Something went wrong');
        return;
      }
      throw new Error('Not valid details');
    });

export const getQuestionsByBrandIDApi = (token, brandId) =>
  axios
    .get(`${process.env.API_BASE_URL}api/v1/Quiz/GetBrandQuestionsByBrandId`, {
      params: { brandId },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      const { data, success, errors } = response.data;
      if (success) {
        return data;
      } else {
        toaster.danger(errors[0] ? errors[0] : 'Something went wrong');
        return;
      }
      throw new Error('Not valid details');
    });

export const getSurveysAllApi = (token) =>
  axios
    .get(`${process.env.API_BASE_URL}api/v1/Quiz/GetSurveys`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      const { data, success, errors } = response.data;
      if (success) {
        return data;
      } else {
        toaster.danger(errors[0] ? errors[0] : 'Something went wrong');
        return;
      }
      throw new Error('Not valid details');
    });

export const runSurveyApi = (token, surveyData) =>
  axios
    .post(
      `${process.env.API_BASE_URL}api/v1/Quiz/CreateQuizBrandSurvey`,
      {
        ...surveyData,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      return response.data;
    });

export const getShareAnalyticsApi = (token, surveyId, ExpiresAt, filters) =>
  axios
    .post(
      `${process.env.API_BASE_URL}api/v1/Quiz/CreateAuthToken?SurveyId=${surveyId}&ExpiresAt=${ExpiresAt}`,
      { filters },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      return response.data.data;
    });

export const getEmailsListApi = (token, surveyId, questionId) =>
  axios
    .get(
      `${process.env.API_BASE_URL}api/v1/Quiz/GetQuizzlySubmitedEmails?SurveyId=${surveyId}&questionId=${questionId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      return response.data.data;
    });

export const getShareAnalyticsTokenApi = (code) =>
  axios
    .get(`${process.env.API_BASE_URL}api/v1/Quiz/TicketSignIn?code=${code}`)
    .then((response) => {
      return response.data;
    });

export const getSurveyReportApi = (token, id, from, to) =>
  axios
    .get(
      `${process.env.API_BASE_URL}api/v1/QuizAnalytics/GetSurveyAnalytics/${id}` /** ?from=${from}&to=${to}, lets use default 3 month */,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      return response.data;
    });

export const copyQuizSurveyApi = (token, quizBrandSurveyId, host) =>
  axios
    .post(
      `${process.env.API_BASE_URL}api/v1/Quiz/CopyQuizBrandSurvey`,
      {
        quizBrandSurveyId,
        host,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      return response.data;
    });

export const editSurveyApi = (token, surveyData) =>
  axios
    .put(
      `${process.env.API_BASE_URL}api/v1/Quiz/QuizBrandSurvey/${surveyData.survey_id}`,
      {
        ...surveyData,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      return response.data;
      throw new Error('not valid details');
    });

export const deleteBrandSurveyApi = (token, id) =>
  axios
    .delete(`${process.env.API_BASE_URL}api/v1/Quiz/QuizBrandSurvey/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      const { success, errors } = response.data;
      if (success) {
        return success;
      } else {
        toaster.danger(errors[0] ? errors[0] : 'Something went wrong');
        return;
      }
      throw new Error('not valid details');
    });
