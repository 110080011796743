export const actionTypes = {
  GET_DATA: '@adblock-management/get-data',
  SET_DATA: '@adblock-management/set-data',
  UPDATE_DATA: '@adblock-management/update-data',
  SET_LOADING: '@adblock-management/set-loading',
  UPDATE_ADBLOCK_DATA: '@adblock-managment/update-adblock-data',
};

export const getAdblockData = (host) => ({
  type: actionTypes.GET_DATA,
  payload: host,
});

export const setAdblockData = (data) => ({
  type: actionTypes.SET_DATA,
  payload: data,
});

export const updateAdblockData = (data) => ({
  type: actionTypes.UPDATE_DATA,
  payload: data,
});

export const setAdblockLoading = (loading) => ({
  type: actionTypes.SET_LOADING,
  payload: loading,
});

export const updateDataForAdblock = (data) => ({
  type: actionTypes.UPDATE_ADBLOCK_DATA,
  payload: data,
});
