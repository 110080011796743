// ==== 💪 Action Types
export const actionTypes = {
  SET_LOADING: '@sites/loading',
  LOAD_SITE_STATS: '@sites/load-site-stats',
  SET_SITE_STATS: '@sites/set-site-stats',
  SET_TOTAL_STATS: '@sites/set-total-stats',
  ADD_NEW_SITE: '@sites/add-new-site',
  LOAD_SITE_STATS_COMPARISON: '@sites/load-site-stats-comparison',
  SET_SITE_STATS_COMPARISON: '@sites/set-site-stats-comparison',
  SET_SITE_LAST_WEEK_STATS_COMPARISON: '@sites/set-site-last-week-stats-comparison',
  SET_TOTAL_STATS_COMPARISON: '@sites/set-total-stats-comparison',
  SET_DIFFERENCE_DATA: '@sites/set-difference-data',
};

// ==== 🎬 Actions
export const setLoading = (loading = true) => ({
  type: actionTypes.SET_LOADING,
  payload: loading,
});

export const loadSiteStats = () => ({
  type: actionTypes.LOAD_SITE_STATS_COMPARISON,
});

export const loadSiteStatsComparison = () => ({
  type: actionTypes.LOAD_SITE_STATS,
});

export const addNewSite = (site) => ({
  type: actionTypes.ADD_NEW_SITE,
  payload: site,
});

export const loadDifferenceData = (data) => ({
  type: actionTypes.SET_DIFFERENCE_DATA,
  payload: data,
});
