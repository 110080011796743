export const actionTypes = {
  GET_USER_REQUEST: '@settings/user/get-request',
  GET_USER_SUCCESS: '@settings/user/get-success',
  ADD_USER_REQUEST: '@settings/user/add-request',
  ADD_USER_SUCCESS: '@settings/user/add-success',
  UPDATE_USER_REQUEST: '@settings/user/update-request',
  UPDATE_USER_SUCCESS: '@settings/user/update-success',
  DELETE_USER_REQUEST: '@settings/user/delete-request',
  DELETE_USER_SUCCESS: '@settings/user/delete-success',
  SET_USER_PASSWORD_REQUEST: '@settings/user/set-password-request',
  SET_USER_PASSWORD_SUCCESS: '@settings/user/set-password-success',
  MANAGEMENT_REQUEST_ERROR: '@settings/user/management-request-error',
};

export const addUserRequest = (email, name, permissions, hosts, autoAddForNewHost) => ({
  type: actionTypes.ADD_USER_REQUEST,
  email,
  name,
  permissions,
  hosts,
  autoAddForNewHost
});

export const addUserSuccess = (data) => ({
  type: actionTypes.ADD_USER_SUCCESS,
  payload: data,
});

export const updateUserRequest = (email, permissions, host) => ({
  type: actionTypes.UPDATE_USER_REQUEST,
  email,
  permissions,
  host,
});

export const updateUserSuccess = () => ({
  type: actionTypes.UPDATE_USER_SUCCESS,
});

export const getUserRequest = () => ({
  type: actionTypes.GET_USER_REQUEST,
});

export const getUserSuccess = (data, dataApiKey) => ({
  type: actionTypes.GET_USER_SUCCESS,
  payload: { data, dataApiKey },
});

export const deleteUserRequest = (email, host, allSites = false) => ({
  type: actionTypes.DELETE_USER_REQUEST,
  email,
  host,
  allSites,
});

export const deleteUserSuccess = (email) => ({
  type: actionTypes.DELETE_USER_SUCCESS,
  email,
});

export const setUserPasswordRequest = (email, newPassword) => ({
  type: actionTypes.SET_USER_PASSWORD_REQUEST,
  email,
  newPassword,
});

export const setUserPasswordSuccess = () => ({
  type: actionTypes.SET_USER_PASSWORD_SUCCESS,
});

export const userManagementError = () => ({
  type: actionTypes.MANAGEMENT_REQUEST_ERROR,
});
