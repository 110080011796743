import { actionTypes } from '@/services/actions/report';

// ==== 🗄️ Reducer
export const initialState = {
  schedules: [],
  schedules_loading: false,
  active_surveys: null,
  active_surveys_loading: false,
  timezones: [],
};

export const reportReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_SCHEDULES: {
      return {
        ...state,
        schedules: initialState.schedules,
        schedules_loading: true,
      };
    }
    case actionTypes.SET_SCHEDULES: {
      return { ...state, schedules: action.payload, schedules_loading: false };
    }

    case actionTypes.LOAD_ACTIVE_SURVEYS: {
      return {
        ...state,
        active_surveys_loading: true,
      };
    }
    case actionTypes.SET_ACTIVE_SURVEYS: {
      return {
        ...state,
        active_surveys: action.payload,
        active_surveys_loading: false,
      };
    }

    case actionTypes.SET_TIMEZONES: {
      return { ...state, timezones: action.payload };
    }

    default:
      return state;
  }
};

export default reportReducer;
