export const actionTypes = {
  GET_PAYMENT_DETAILS_REQUEST: '@settings/payment/get-details-request',
  GET_PAYMENT_DETAILS_SUCCESS: '@settings/payment/get-details-success',
  SAVE_OR_UPDATE_PAYMENT_DETAILS_REQUEST:
    '@settings/payment/save-update-details-request',
  PAYMENT_DETAILS_ERROR: '@settings/payment/details-error',
  FINISH_LOADING: '@settings/payment/finish-loading',
};

export const getPaymentDetails = () => ({
  type: actionTypes.GET_PAYMENT_DETAILS_REQUEST,
});

export const getPaymentDetailsSuccess = (payload) => ({
  type: actionTypes.GET_PAYMENT_DETAILS_SUCCESS,
  payload,
});

export const setOrUpdatePaymentDetails = (paymentData) => ({
  type: actionTypes.SAVE_OR_UPDATE_PAYMENT_DETAILS_REQUEST,
  paymentData,
});

export const paymentDetailsError = () => ({
  type: actionTypes.PAYMENT_DETAILS_ERROR,
});
