import { actionTypes } from '@/services/actions/quizModeration';

// ==== 🗄️ Reducer
export const initialState = {
  data_loading: false,
  data: [],

  flagged_loading: false,
  flagged: [],

  data_default_loading: false,
  data_default: [],

  data_brand_loading: false,
  data_brand: [],

  update_loading: false,
  update: [],

  update_brand_loading: false,
  update_brand: [],

  create_loading: false,
  create: [],

  create_default_loading: false,
  create_default: [],

  create_brand_loading: false,
  create_brand: [],

  generate_brand_question_loading: false,
  generate_brand_question: [],

  language_codes: [],
  language_codes_loading: false,

  blocked_quiz_uris: [],
  blocked_quiz_uris_loading: false,
  create_blocked_uri_loading: false,

  delete_quiz_uri_loading: false,

  delete_quiz_question_loading: false,

  delete_quiz_default_question_loading: false,

  delete_quiz_brand_question_loading: false,
};

export const quizModerationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_QUIZ_WITH_QUESTION_REQUEST: {
      return { ...state, data_loading: true };
    }
    case actionTypes.GET_QUIZ_WITH_QUESTION_SUCCESS: {
      return {
        ...state,
        data: action.startFrom0
          ? action.data.items
          : [...state.data, ...action.data.items],
        data_loading: false,
      };
    }

    case actionTypes.GET_QUIZ_FLAGGED_QUESTION_REQUEST: {
      return { ...state, flagged_loading: true };
    }
    case actionTypes.GET_QUIZ_FLAGGED_QUESTION_SUCCESS: {
      return {
        ...state,
        flagged: action.startFrom0
            ? action.data.items
            : [...state.flagged, ...action.data.items],
        flagged_loading: false,
      };
    }

    case actionTypes.GET_QUIZ_WITH_DEFAULT_QUESTION_REQUEST: {
      return { ...state, data_default_loading: true };
    }
    case actionTypes.GET_QUIZ_WITH_DEFAULT_QUESTION_SUCCESS: {
      return {
        ...state,
        data_default: action.data,
        data_default_loading: false,
      };
    }

    case actionTypes.GET_QUIZ_WITH_BRAND_QUESTION_REQUEST: {
      return { ...state, data_brand_loading: true };
    }
    case actionTypes.GET_QUIZ_WITH_BRAND_QUESTION_SUCCESS: {
      return {
        ...state,
        data_brand: action.data,
        data_brand_loading: false,
      };
    }

    case actionTypes.GET_QUIZ_WITH_QUESTION_BY_ID_REQUEST: {
      return { ...state, data_loading: true };
    }
    case actionTypes.GET_QUIZ_WITH_QUESTION_BY_ID_SUCCESS: {
      return { ...state, data: action.data, data_loading: false };
    }

    case actionTypes.LANGUAGE_CODES_REQUEST: {
      return { ...state, language_codes_loading: true };
    }
    case actionTypes.LANGUAGE_CODES_SUCCESS: {
      return {
        ...state,
        language_codes: action.data,
        language_codes_loading: false,
      };
    }

    case actionTypes.GET_BLOCKED_QUIZ_URIS_REQUEST: {
      return { ...state, blocked_quiz_uris_loading: true };
    }

    case actionTypes.GET_BLOCKED_QUIZ_URIS_SUCCESS: {
      return {
        ...state,
        blocked_quiz_uris: action.data,
        blocked_quiz_uris_loading: false,
      };
    }

    case actionTypes.UPDATE_QUIZ_WITH_QUESTION_REQUEST: {
      return { ...state, update_loading: true };
    }
    case actionTypes.UPDATE_QUIZ_WITH_QUESTION_SUCCESS: {
      return { ...state, update_loading: false, update: action.data };
    }

    case actionTypes.UPDATE_QUIZ_BRAND_WITH_QUESTION_REQUEST: {
      return { ...state, update_brand_loading: true };
    }
    case actionTypes.UPDATE_QUIZ_BRAND_WITH_QUESTION_SUCCESS: {
      return { ...state, update_brand_loading: false, data_brand: state.data_brand.map(item => item.id === action.data.id ? action.data : item)};
    }

    case actionTypes.CREATE_QUIZ_WITH_QUESTION_REQUEST: {
      return { ...state, create_loading: true };
    }
    case actionTypes.CREATE_QUIZ_WITH_QUESTION_SUCCESS: {
      return { ...state, create_loading: false, create: action.data };
    }

    case actionTypes.CREATE_QUIZ_DEFAULT_QUESTION_REQUEST: {
      return { ...state, create_default_loading: true };
    }
    case actionTypes.CREATE_QUIZ_DEFAULT_QUESTION_SUCCESS: {
      return {
        ...state,
        create_default_loading: false,
        create_default: action.data,
      };
    }

    case actionTypes.CREATE_QUIZ_BRAND_QUESTION_REQUEST: {
      return { ...state, create_brand_loading: true };
    }
    case actionTypes.CREATE_QUIZ_BRAND_QUESTION_SUCCESS: {
      return {
        ...state,
        create_brand_loading: false,
        create_brand: action.data,
      };
    }
    case actionTypes.GENERATE_QUIZ_BRAND_QUESTION_SUCCESS: {
      return {
        ...state,
        generate_brand_question_loading: false,
        generate_brand_question: action.data,
      };
    }
    case actionTypes.GENERATE_QUIZ_BRAND_QUESTION_REQUEST: {
      return {
        ...state,
        generate_brand_question_loading: true,
      };
    }

    case actionTypes.BLOCK_QUIZ_GENERATION_REQUEST: {
      return { ...state, create_blocked_uri_loading: true };
    }

    case actionTypes.BLOCK_QUIZ_GENERATION_SUCCESS: {
      return {
        ...state,
        create_blocked_uri_loading: false,
        blocked_quiz_uris: [...state.blocked_quiz_uris, action.data],
      };
    }

    case actionTypes.DELETE_QUIZ_WITH_QUESTION_REQUEST: {
      return { ...state, delete_quiz_question_loading: true };
    }
    case actionTypes.DELETE_QUIZ_WITH_QUESTION_SUCCESS: {
      return { ...state, delete_quiz_question_loading: false };
    }

    case actionTypes.DELETE_QUIZ_WITH_DEFAULT_QUESTION_REQUEST: {
      return { ...state, delete_quiz_default_question_loading: true };
    }
    case actionTypes.DELETE_QUIZ_WITH_DEFAULT_QUESTION_SUCCESS: {
      return { ...state, delete_quiz_default_question_loading: false };
    }

    case actionTypes.DELETE_QUIZ_WITH_BRAND_QUESTION_REQUEST: {
      return { ...state, delete_quiz_default_question_loading: true };
    }
    case actionTypes.DELETE_QUIZ_WITH_BRAND_QUESTION_SUCCESS: {
      return { ...state, delete_quiz_default_question_loading: false };
    }

    case actionTypes.DELETE_BLOCKED_QUIZ_GENERATION_REQUEST: {
      return { ...state, delete_quiz_uri_loading: true };
    }
    case actionTypes.DELETE_BLOCKED_QUIZ_GENERATION_SUCCESS: {
      return { ...state, delete_quiz_uri_loading: false };
    }

    default:
      return state;
  }
};

export default quizModerationReducer;
