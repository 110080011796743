import axios from 'axios';

export const getSiteStats = (token, host, from, to, groupType = 1) =>
  axios
    .get(`${process.env.API_BASE_URL}api/v2/DailyStats/getSiteStats`, {
      params: {
        host,
        from,
        to,
        groupType,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      if (response.data.success) {
        return response.data;
      }

      throw new Error('not valid details');
    });

export const getSiteStatsByApiKey = (token, apiKey, from, to, groupType = 1) =>
  axios
    .get(`${process.env.API_BASE_URL}api/v2/DailyStats/getSiteStatsByApiKey`, {
      params: {
        apiKey,
        from,
        to,
        groupType,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      if (response.data.success) {
        return response.data;
      }

      throw new Error('not valid details');
    });

export const getStatsByHostTimeSeries = (token, host) =>
  axios
    .get(
      `${process.env.API_BASE_URL}api/v1/DailyStats/getStatsByHostTimeSeries`,
      {
        params: { host },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success } = response.data;

      if (success) {
        return data;
      }

      throw new Error('not valid details');
    });

export const getStatsByApiKeyTimeSeries = (token, apiKey) =>
  axios
    .get(
      `${process.env.API_BASE_URL}api/v1/DailyStats/getStatsByApiKeyTimeSeries`,
      {
        params: { apiKey },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success } = response.data;

      if (success) {
        return data;
      }

      throw new Error('not valid details');
    });
