// ==== 💪 Action Types
export const actionTypes = {
  POST_SURVEY_DATA_REQUEST: '@brand-survey/post-survey-request',
  POST_SURVEY_DATA_SUCCESS: '@brand-survey/post-survey-success',

  GET_SURVEYS_DATA_REQUEST: '@brand-survey/get-surveys-all-request',
  GET_SURVEYS_DATA_SUCCESS: '@brand-survey/get-surveys-all-success',

  GET_CITIES_REQUEST: '@brand-survey/get-cities-request',
  GET_CITIES_SUCCESS: '@brand-survey/get-cities-success',

  POST_COPY_SURVEY_REQUEST: '@brand-survey/post-copy-survey-request',
  POST_COPY_SURVEY_SUCCESS: '@brand-survey/post-copy-survey-success',

  PUT_SURVEY_DATA_REQUEST: '@brand-survey/put-survey-request',
  PUT_SURVEY_DATA_SUCCESS: '@brand-survey/put-survey-success',

  GET_SURVEY_DATA_REQUEST: '@brand-survey/get-survey-request',
  GET_SURVEY_DATA_SUCCESS: '@brand-survey/get-survey-success',

  GET_SURVEY_SINGLE_DATA_REQUEST: '@brand-survey/get-survey-single-request',
  GET_SURVEY_SINGLE_DATA_SUCCESS: '@brand-survey/get-survey-single-success',

  GET_BRAND_QUESTIONS_BY_BRAND_ID_REQUEST:
    '@brand-survey/get-brand-questions-by-brand-id-request',
  GET_BRAND_QUESTIONS_BY_BRAND_ID_SUCCESS:
    '@brand-survey/get-brand-questions-by-brand-id-success',

  GET_STATS_BY_SURVEY_ID_DATA_REQUEST:
    '@brand-survey/get-stats-by-survey-id-data-request',
  GET_STATS_BY_SURVEY_ID_DATA_SUCCESS:
    '@brand-survey/get-stats-by-survey-id-data-success',

  GET_STATS_BY_GEO_REQUEST: '@brand-survey/get-stats-by-geo-request',
  GET_STATS_BY_GEO_SUCCESS: '@brand-survey/get-stats-by-geo-success',

  GET_LEADS_CLICK_REQUEST: '@brand-survey/get-lead-clicks-request',
  GET_LEADS_CLICK_SUCCESS: '@brand-survey/get-lead-clicks-success',

  GET_STATS_BY_CITY_REQUEST: '@brand-survey/get-stats-by-city-request',
  GET_STATS_BY_CITY_SUCCESS: '@brand-survey/get-stats-by-city-success',

  GET_STATS_BY_AGE_REQUEST: '@brand-survey/get-stats-by-age-request',
  GET_STATS_BY_AGE_SUCCESS: '@brand-survey/get-stats-by-age-success',

  GET_STATS_BY_GENDER_REQUEST: '@brand-survey/get-stats-by-gender-request',
  GET_STATS_BY_GENDER_SUCCESS: '@brand-survey/get-stats-by-gender-success',

  GET_TABLE_STATS_REQUEST: '@brand-survey/get-table-stats-request',
  GET_TABLE_STATS_SUCCESS: '@brand-survey/get-table-stats-success',

  GET_RESPONSE_GROUP_STATS_REQUEST: '@brand-survey/get-response-group-stats-request',
  GET_RESPONSE_GROUP_STATS_SUCCESS: '@brand-survey/get-response-group-stats-success',

  GET_COMPLETION_RATE_REQUEST: '@brand-survey/get-completion-rate-request',
  GET_COMPLETION_RATE_SUCCESS: '@brand-survey/get-completion-rate-success',

  GET_ANALYTICS_ACCESS_CODE_REQUEST: '@brand-survey/get-analytics-access-code-request',
  GET_ANALYTICS_ACCESS_CODE_SUCCESS: '@brand-survey/get-analytics-access-code-success',

  GET_EMAILS_LIST_REQUEST: '@brand-survey/get-emails-list-request',
  GET_EMAILS_LIST_SUCCESS: '@brand-survey/get-emails-list-success',

  GET_REPORT_DATA_REQUEST: '@brand-survey/get-report-data-request',
  GET_REPORT_DATA_SUCCESS: '@brand-survey/get-report-data-success',

  GET_SHARED_ANALYTICS_TOKEN_REQUEST: '@brand-survey/get-shared-analytics-token-request',
  GET_SHARED_ANALYTICS_TOKEN_SUCCESS: '@brand-survey/get-shared-analytics-token-success',

  GET_QUESTIONS_STATS_DATA_REQUEST:
    '@brand-survey/get-stats-by-question-data-request',
  GET_QUESTIONS_STATS_DATA_SUCCESS:
    '@brand-survey/get-stats-by-question-data-success',

  GET_SURVEY_QUESTIONS_STATS_DATA_REQUEST:
    '@brand-survey/get-stats-by-question-survey-request',
  GET_SURVEY_QUESTIONS_STATS_DATA_SUCCESS:
    '@brand-survey/get-stats-by-question-survey-success',

  GET_SURVEY_QUESTIONS_DEMOGRAPHIC_DATA_REQUEST:
    '@brand-survey/get-stats-by-question-option-survey-request',
  GET_SURVEY_QUESTIONS_DEMOGRAPHIC_DATA_SUCCESS:
    '@brand-survey/get-stats-by-question-option-survey-success',

  GET_QUESTIONS_STATS_DATA_PER_DAY_REQUEST:
    '@brand-survey/get-stats-by-question-data-per-day-request',
  GET_QUESTIONS_STATS_DATA_PER_DAY_SUCCESS:
    '@brand-survey/get-stats-by-question-data-per-day-success',

  DELETE_SURVEY_DATA_REQUEST: '@brand-survey/delete-survey-request',
  DELETE_SURVEY_DATA_SUCCESS: '@brand-survey/delete-survey-success',
};

// ==== 🎬 Actions
export const runSurvey = (runSurvey) => ({
  type: actionTypes.POST_SURVEY_DATA_REQUEST,
  payload: { runSurvey },
});

export const getCities = () => ({
  type: actionTypes.GET_CITIES_REQUEST,
});

export const shareAnalytics = (surveyId, ExpiresAt, filters) => ({
  type: actionTypes.GET_ANALYTICS_ACCESS_CODE_REQUEST,
  payload: { surveyId, ExpiresAt, filters },
});


export const getEmailsList = (surveyId, questionId) => ({
  type: actionTypes.GET_EMAILS_LIST_REQUEST,
  payload: { surveyId, questionId },
});

export const shareAnalyticsToken = (code) => ({
  type: actionTypes.GET_SHARED_ANALYTICS_TOKEN_REQUEST,
  payload: { code },
});

export const getReportData = (id, from, to) => ({
  type: actionTypes.GET_REPORT_DATA_REQUEST,
  payload: { id, from, to },
});

export const getSurveys = () => ({
  type: actionTypes.GET_SURVEYS_DATA_REQUEST,
});

export const editSurvey = (surveyData, showStatus, state, deleteAction) => ({
  type: actionTypes.PUT_SURVEY_DATA_REQUEST,
  payload: { surveyData, showStatus, state, deleteAction },
});

export const getSurvey = (host) => ({
  type: actionTypes.GET_SURVEY_DATA_REQUEST,
  payload: { host },
});

export const getSurveySingle = (host, share_token, surveyId) => ({
  type: actionTypes.GET_SURVEY_SINGLE_DATA_REQUEST,
  payload: { host, share_token, surveyId },
});

export const copySurvey = (quizBrandSurveyId, host) => ({
  type: actionTypes.POST_COPY_SURVEY_REQUEST,
  payload: { quizBrandSurveyId, host },
});

export const getBrandQuestionsByID = (brandId) => ({
  type: actionTypes.GET_BRAND_QUESTIONS_BY_BRAND_ID_REQUEST,
  payload: { brandId },
});

export const getStatsDataByDaySurveyId = (surveyId, from, to) => ({
  type: actionTypes.GET_STATS_BY_SURVEY_ID_DATA_REQUEST,
  payload: { surveyId, from, to },
});

export const getTableDataBySurveyId = (surveyId, from, to, share_token) => ({
  type: actionTypes.GET_TABLE_STATS_REQUEST,
  payload: { surveyId, from, to, share_token },
});

export const getResponseGroupStats = (surveyId, from, to, share_token) => ({
  type: actionTypes.GET_RESPONSE_GROUP_STATS_REQUEST,
  payload: { surveyId, from, to, share_token },
});

export const getCompletionRate = (surveyId, from, to, share_token) => ({
  type: actionTypes.GET_COMPLETION_RATE_REQUEST,
  payload: { surveyId, from, to, share_token },
});

export const getStatsDataByQuestionSurveyId = (surveyId, from, to, share_token) => ({
  type: actionTypes.GET_QUESTIONS_STATS_DATA_REQUEST,
  payload: { surveyId, from, to, share_token },
});

export const getStatsDataByQuestionPerDaySurveyId = (surveyId, from, to) => ({
  type: actionTypes.GET_QUESTIONS_STATS_DATA_PER_DAY_REQUEST,
  payload: { surveyId, from, to },
});

export const getStatsSurveyByQuestion = (surveyId, from, to, questionId, share_token) => ({
  type: actionTypes.GET_SURVEY_QUESTIONS_STATS_DATA_REQUEST,
  payload: { surveyId, from, to, questionId, share_token },
});

export const getAnswerSurveyByQuestion = (
  surveyId,
  from,
  to,
  questionId,
  option,
  share_token,
) => ({
  type: actionTypes.GET_SURVEY_QUESTIONS_DEMOGRAPHIC_DATA_REQUEST,
  payload: { surveyId, from, to, questionId, option, share_token },
});

export const getStatsByGeo = (surveyId, from, to, questionId, option, share_token,) => ({
  type: actionTypes.GET_STATS_BY_GEO_REQUEST,
  payload: { surveyId, from, to, share_token, questionId, option },
});

export const getLeadClicks = (surveyId, from, to, share_token) => ({
  type: actionTypes.GET_LEADS_CLICK_REQUEST,
  payload: { surveyId, from, to, share_token },
});

export const getStatsByCity = (surveyId, from, to, questionId, option, share_token) => ({
  type: actionTypes.GET_STATS_BY_CITY_REQUEST,
  payload: { surveyId, from, to, share_token, questionId, option },
});

export const getStatsByAge = (surveyId, from, to, share_token) => ({
  type: actionTypes.GET_STATS_BY_AGE_REQUEST,
  payload: { surveyId, from, to, share_token },
});

export const getStatsByGender = (surveyId, from, to, share_token) => ({
  type: actionTypes.GET_STATS_BY_GENDER_REQUEST,
  payload: { surveyId, from, to, share_token },
});

export const deleteSurvey = (id) => ({
  type: actionTypes.DELETE_SURVEY_DATA_REQUEST,
  payload: { id },
});
