export const actionTypes = {
  GET_COMMENTS_BY_USER_EMAIL_REQUEST:
    '@settings/comments/general/get-comments-by-user-email-request',
  SET_COMMENTS_BY_USER_EMAIL_SUCCESS:
    '@settings/comments/general/get-comments-by-user-email-success',
  SET_MODERATION_FLAG_REQUEST:
    '@settings/comments/general/set-moderation-flag-request',
  SET_MODERATION_FLAG_SUCCESS:
    '@settings/comments/general/set-moderation-flag-success',
  SET_CUSTOM_CHECKBOX_FLAG_REQUEST:
    '@settings/comments/general/set-custom-checkbox-flag-request',
  SET_CUSTOM_CHECKBOX_FLAG_SUCCESS:
    '@settings/comments/general/set-custom-checkbox-flag-success',
  UPDATE_PRIVACY_POLICY_REQUEST:
    '@settings/comments/general/update-privacy-policy-request',
  SET_LINK_MODERATION_FLAG_REQUEST:
    '@settings/comments/general/set-link-moderation-flag-request',
  SET_LINK_MODERATION_FLAG_SUCCESS:
    '@settings/comments/general/set-link-moderation-flag-success',
  SET_IMAGES_MODERATION_FLAG_REQUEST:
    '@settings/comments/general/set-images-moderation-flag-request',
  SET_IMAGES_MODERATION_FLAG_SUCCESS:
    '@settings/comments/general/set-images-moderation-flag-success',
  SET_EMOGISTO_MODERATION_FLAG_REQUEST:
    '@settings/comments/general/set-emojis-to-moderation-flag-request',
  SET_EMOGISTO_MODERATION_FLAG_SUCCESS:
    '@settings/comments/general/set-emojis-to-moderation-flag-success',
  SET_EXACT_BLOCK_WORD_MATCH_REQUEST:
    '@settings/comments/general/set-exact-block-word-match-request',
  SET_EXACT_BLOCK_WORD_MATCH_SUCCESS:
    '@settings/comments/general/set-exact-block-word-match-success',
  SET_DISABLE_OPENAI_REQUEST:
    '@settings/comments/general/set-openai-request',
  SET_DISABLE_OPENAI_SUCCESS:
    '@settings/comments/general/set-openai-success',
  SET_NOTIFICATION_FOR_ADMIN_REQUEST:
    '@settings/comments/general/set-notification-for-admin-request',
  SET_NOTIFICATION_FOR_ADMIN_SUCCESS:
    '@settings/comments/general/set-notification-for-admin-success',
  SET_NOTIFICATION_FOR_MODERATOR_REQUEST:
    '@settings/comments/general/set-notification-for-moderator-request',
  SET_NOTIFICATION_FOR_MODERATOR_SUCCESS:
    '@settings/comments/general/set-notification-for-moderator-success',
  SET_BROWSER_NOTIFICATIONS_REQUEST:
    '@settings/comments/general/set-browser-notifications-request',
  SET_BROWSER_NOTIFICATIONS_SUCCESS:
    '@settings/comments/general/set-browser-notifications-success',
  SET_AUTO_NOTIFICATIONS_REQUEST:
    '@settings/comments/general/set-auto-notifications-request',
  SET_AUTO_NOTIFICATIONS_SUCCESS:
    '@settings/comments/general/set-auto-notifications-success',
  SET_INITIAL_GENERAL_SETTINGS_DATA: '@settings/comments/general/set-data',
  SET_FLAG_ERROR: '@settings/comments/general/set-flag-error',
  SET_PRIVACY_POLICY_DATA: '@settings/comments/general/set-privacy-policy-data',
};

export const setFlagRequest = (type, flagValue, flagName, allSites) => ({
  type,
  flagValue,
  flagName,
  allSites,
});

export const setFlagError = () => ({
  type: actionTypes.SET_FLAG_ERROR,
});

export const setCustomCheckboxFlag = ({ value, allSites }) => ({
  type: actionTypes.SET_CUSTOM_CHECKBOX_FLAG_REQUEST,
  payload: { value, allSites },
});

export const updatePrivacyPolicy = (data) => ({
  type: actionTypes.UPDATE_PRIVACY_POLICY_REQUEST,
  payload: data,
});

export const setInitialGeneralSettingsData = (data) => ({
  type: actionTypes.SET_INITIAL_GENERAL_SETTINGS_DATA,
  payload: data,
});

export const setBrowserNotifications = (data, allSites) => ({
  type: actionTypes.SET_BROWSER_NOTIFICATIONS_REQUEST,
  payload: { data, allSites },
});

export const getCommentsByUserEmail = (userEmail, allSites) => ({
  type: actionTypes.GET_COMMENTS_BY_USER_EMAIL_REQUEST,
  payload: { userEmail, allSites },
});

export const setAutoNotifications = (data, allSites) => ({
  type: actionTypes.SET_AUTO_NOTIFICATIONS_REQUEST,
  payload: { data, allSites },
});

export const setBrowserNotificationsSuccess = (data) => ({
  type: actionTypes.SET_BROWSER_NOTIFICATIONS_SUCCESS,
  payload: data,
});

export const setAutoNotificationsSuccess = (data) => ({
  type: actionTypes.SET_AUTO_NOTIFICATIONS_SUCCESS,
  payload: data,
});
