import axios from 'axios';

export const updateAdminProfile = (token, host, name, pictureUrl) =>
  axios
    .put(
      `${process.env.API_BASE_URL}api/v1/Publishers/updateprofile`,
      {
        host,
        name,
        pictureUrl,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      throw new Error(errors[0]);
    });

export const updateAdminProfileByApiKey = (token, apiKey, name, pictureUrl) =>
  axios
    .put(
      `${process.env.API_BASE_URL}api/v1/Publishers/updateprofilebyapikey`,
      {
        apiKey,
        name,
        pictureUrl,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      throw new Error(errors[0]);
    });

export const updateAdminEmail = (token, email, password) =>
  axios
    .post(
      `${process.env.API_BASE_URL}api/v1/Publishers/updateAdminEmail`,
      {
        email,
        password,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { token, errors } = response.data;

      if (token) {
        return token;
      }

      if (errors) {
        throw new Error(errors[0]);
      }

      throw new Error('not valid response');
    });

export const updateAdminPassword = (token, oldPassword, newPassword) =>
  axios
    .put(
      `${process.env.API_BASE_URL}api/v1/Publishers/updateAdminPassword`,
      {
        oldPassword,
        newPassword,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      if (errors) {
        throw new Error(errors[0]);
      }

      throw new Error('not valid response');
    });
