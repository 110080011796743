import { call, put, select, takeLatest } from 'redux-saga/effects';
import Router from 'next/router';
import {
  notificationGet,
  notificationPost,
  reportsGet,
  ScheduleNotification,
  SendNotification,
  testnotification,
  WebNotificationGetTotalData,
  WebNotificationTopicsDELETE,
  WebNotificationTopicsGETAll,
  WebNotificationTopicsGETHost,
  WebNotificationTopicsPOST,
} from '@/services/api/cloudMessaging';
import { actionTypes } from '@/services/actions/cloudMessaging';
import { toaster } from 'evergreen-ui';

export function* getTopicsRequest(action) {
  try {
    const token = yield select(({ session }) => session.token);
    const { host, v1 } = action.payload;

    let data = null;

    if (!v1) {
      data = yield call(
        host ? WebNotificationTopicsGETHost : WebNotificationTopicsGETAll,
        token,
        host,
      );
    } else {
      data = yield call(WebNotificationTopicsGETHost, token, host);
    }

    yield put({
      type: actionTypes.GET_TOPICS_FINISHED,
      payload: data,
    });
  } catch (e) {
    console.log('err', e);
  }
}

export function* createTopicsRequest(action) {
  try {
    const token = yield select(({ session }) => session.token);
    const { topics } = yield select(({ cloudMessaging }) => cloudMessaging);
    const { host, topic, callback } = action.payload;

    const response = yield call(WebNotificationTopicsPOST, token, host, topic);
    const { success, data, errors } = response;

    if (success && data) {
      if (host) {
        const newData = [...topics, ...data];
        yield put({
          type: actionTypes.GET_TOPICS_FINISHED,
          payload: newData,
        });
      } else {
        const newData = [...topics, { topicName: topic, topics: data }];
        yield put({
          type: actionTypes.GET_TOPICS_FINISHED,
          payload: newData,
        });
      }

      yield put({
        type: actionTypes.CREATE_TOPICS_FINISHED,
      });
      toaster.success('Topic successfully created!', {
        id: 'successful-update-web-push',
      });
      setTimeout(() => callback(), 1000);
    } else {
      yield put({
        type: actionTypes.CREATE_TOPICS_FINISHED,
      });
      toaster.warning(`${errors && errors.length  && errors.length === 1 ?
        errors[0] : 
        `"${errors[0].split(' ')[0]}" topic is already created for some of your hosts`}`);
    }
  } catch (e) {
    console.log('err', e);
  }
}

export function* deleteTopicsRequest(action) {
  try {
    const token = yield select(({ session }) => session.token);
    const host = yield select(({ filter }) => filter.host);
    const { topics } = yield select(({ cloudMessaging }) => cloudMessaging);
    const { id, allSites } = action.payload;

    const data = yield call(WebNotificationTopicsDELETE, token, id, allSites);

    if (data) {
      toaster.success('Successfully removed!');

      yield put({
        type: actionTypes.GET_TOPICS_REQUEST,
        payload: { host: host },
      });
    }

    // const newData = topics.filter((el) => el.id !== id);

    // yield put({
    //   type: actionTypes.GET_TOPICS_FINISHED,
    //   payload: newData,
    // });
  } catch (e) {
    console.log('err', e);
  }
}

export function* getNotificationRequest(action) {
  try {
    const token = yield select(({ session }) => session.token);
    const { host } = action.payload;

    const data = yield call(notificationGet, token, host);

    yield put({
      type: actionTypes.GET_NOTIFICATION_FINISHED,
      payload: data,
    });
  } catch (e) {
    console.log('err', e);
  }
}

export function* createNotificationRequest(action) {
  try {
    const token = yield select(({ session }) => session.token);
    const { notification } = yield select(
      ({ cloudMessaging }) => cloudMessaging,
    );
    const { apiKey } = yield select(({ session }) => session);
    const {
      host,
      topicIds,
      notificationTitle,
      notificationText,
      notificationImage,
      notificationName,
      notificationRedirectURL,
      callback,
    } = action.payload;

    const ids = [];

    topicIds.forEach((el) => el.forEach((el1) => ids.push(el1)));

    const payloadForApi = {
      topicIds: ids,
      notificationTitle,
      notificationText,
      notificationImage,
      notificationName,
      notificationRedirectURL,
    };

    if (host) {
      payloadForApi.host = host;
    } else {
      payloadForApi.apiKey = apiKey;
    }

    const data = yield call(notificationPost, token, payloadForApi);

    if (data) {
      const newData = [...notification, ...data];

      toaster.success('Notification successfully created!');

      yield put({
        type: actionTypes.GET_NOTIFICATION_FINISHED,
        payload: newData,
      });

      yield put({
        type: actionTypes.CREATE_NOTIFICATION_FINISHED,
        payload: data,
      });

      setTimeout(() => callback(), 1000);
    }
  } catch (e) {
    console.log('err', e);
  }
}

export function* createTestNotificationRequest(action) {
  try {
    const token = yield select(({ session }) => session.token);
    const {
      notificationTitle,
      notificationText,
      notificationImage,
      deviceToken,
      notificationRedirectURL,
      callback,
    } = action.payload;

    const payloadForApi = {
      notificationTitle,
      notificationText,
      notificationImage,
      deviceToken,
      notificationRedirectURL,
    };

    const data = yield call(testnotification, token, payloadForApi);

    if (data) {
      toaster.success('Test notification is successfully sent!');
      setTimeout(() => callback(), 1000);
    }
  } catch (e) {
    console.log('err', e);
  }
}

export function* sendNotificationRequest(action) {
  try {
    const token = yield select(({ session }) => session.token);
    const { id } = action.payload;

    const data = yield call(SendNotification, token, id);

    if (data) {
      toaster.success('Notification is successfully sent!');
    } else {
      toaster.danger('Something went wrong!');
    }
  } catch (e) {
    console.log('err', e);
  }
}

export function* scheduleNotificationRequest(action) {
  try {
    const token = yield select(({ session }) => session.token);
    const { id, scheduleTimeUtc } = action.payload;
    const data = yield call(ScheduleNotification, token, id, scheduleTimeUtc);
    yield put({
      type: actionTypes.SCHEDULE_NOTIFICATION_SUCCESS,
      payload: data,
    });

    if (data && data.success) {
      yield put({
        type: actionTypes.GET_NOTIFICATION_REQUEST,
        payload: { host: Router.query.host },
      });
      toaster.success(
        `Notification is successfully ${
          scheduleTimeUtc ? 'scheduled' : 'unscheduled'
        }!`,
      );
    } else {
      toaster.danger(
        `${data.errors[0].charAt(0).toUpperCase() + data.errors[0].slice(1)}!`,
      );
    }
  } catch (e) {
    console.log('err', e);
  }
}

export function* getReportsData(action) {
  try {
    const token = yield select(({ session }) => session.token);
    const { host, from, to } = action.payload;

    const data = yield call(reportsGet, token, from, to, host);

    yield put({
      type: actionTypes.SET_REPORTS_DATA,
      payload: data,
    });
  } catch (e) {
    console.log('err', e);
  }
}

export function* getTotalsData(action) {
  try {
    const token = yield select(({ session }) => session.token);
    const { host } = action.payload;

    const data = yield call(WebNotificationGetTotalData, token, host);

    yield put({
      type: actionTypes.GET_NOTIFICATION_TOTALS_FINISHED,
      payload: data,
    });
  } catch (e) {
    console.log('err', e);
  }
}

export default function* cloudMessagingSaga() {
  yield takeLatest(actionTypes.GET_REPORTS_DATA, getReportsData);
  yield takeLatest(actionTypes.GET_NOTIFICATION_TOTALS_REQUEST, getTotalsData);
  yield takeLatest(
    actionTypes.SEND_TEST_NOTIFICATION,
    createTestNotificationRequest,
  );
  yield takeLatest(actionTypes.GET_TOPICS_REQUEST, getTopicsRequest);
  yield takeLatest(actionTypes.CREATE_TOPICS_REQUEST, createTopicsRequest);
  yield takeLatest(actionTypes.DELETE_TOPIC_REQUEST, deleteTopicsRequest);
  yield takeLatest(
    actionTypes.GET_NOTIFICATION_REQUEST,
    getNotificationRequest,
  );
  yield takeLatest(
    actionTypes.CREATE_NOTIFICATION_REQUEST,
    createNotificationRequest,
  );
  yield takeLatest(actionTypes.SEND_NOTIFICATION, sendNotificationRequest);
  yield takeLatest(
    actionTypes.SCHEDULE_NOTIFICATION_REQUEST,
    scheduleNotificationRequest,
  );
}
