// ==== 💪 Action Types
export const actionTypes = {
  GET_SUBSCRIBED_EMAIL_BY_HOST_REQUEST: '@analytics/getSubscribedEmailsByHostRequest',
  GET_SUBSCRIBED_EMAIL_BY_HOST_SUCCESS: '@analytics/getSubscribedEmailsByHostSuccess',

  GET_SUBSCRIBED_EMAIL_TOP_BY_FILTER_REQUEST: '@analytics/getSubscribedEmailsTopByFilterRequest',
  GET_SUBSCRIBED_EMAIL_TOP_BY_FILTER_SUCCESS: '@analytics/getSubscribedEmailsTopByFilterSuccess',

  GET_TOP_COUNTRIES_REQUEST: '@analytics/newsletters/load-top-countries',
  GET_TOP_COUNTRIES_SUCCESS: '@analytics/newsletters/set-top-countries',

  GET_TOP_CITIES_REQUEST: '@analytics/newsletters/load-top-cities',
  GET_TOP_CITIES_SUCCESS: '@analytics/newsletters/set-top-cities',

  GET_TOP_OSS_REQUEST: '@analytics/newsletters/load-top-oss',
  GET_TOP_OSS_SUCCESS: '@analytics/newsletters/set-top-oss',

  GET_TOP_BROWSERS_REQUEST: '@analytics/newsletters/load-top-browsers',
  GET_TOP_BROWSERS_SUCCESS: '@analytics/newsletters/set-top-browsers',

  GET_TOP_DEVICES_REQUEST: '@analytics/newsletters/load-top-devices',
  GET_TOP_DEVICES_SUCCESS: '@analytics/newsletters/set-top-devices',

};

// ==== 🎬 Actions
export const getSubscribedEmailsByHost = () => ({
  type: actionTypes.GET_SUBSCRIBED_EMAIL_BY_HOST_REQUEST,
  payload: {},
});

export const loadTopCountries = (count = 500) => ({
  type: actionTypes.GET_TOP_COUNTRIES_REQUEST,
  payload: {count},
});

export const loadTopCities = (count = 500) => ({
  type: actionTypes.GET_TOP_CITIES_REQUEST,
  payload: {count},
});

export const loadTopOss = (count = 500) => ({
  type: actionTypes.GET_TOP_OSS_REQUEST,
  payload: {count},
});

export const loadTopBrowsers = (count = 500) => ({
  type: actionTypes.GET_TOP_BROWSERS_REQUEST,
  payload: {count},
});

export const loadTopDevices = (count = 500) => ({
  type: actionTypes.GET_TOP_DEVICES_REQUEST,
  payload: {count},
});

