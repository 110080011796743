import { call, put, select, takeLatest } from 'redux-saga/effects';
import dayjs from 'dayjs';
import { toaster } from 'evergreen-ui';

import {
  createQuizBrandQuestion,
  createQuizDefaultQuestion,
  createQuizQuestion,
  deleteBrandQuizQuestion,
  deleteDefaultQuizQuestion,
  deleteQuizQuestion,
  editQuizQuestion,
  getLanguageCodesApi,
  getQuizBrandQuestions,
  getQuizDefaultQuestions,
  getQuizQuestions,
  getQuizQuestionsById,
  blockQuizGeneration,
  deleteBlockedUrl,
  getBlockedQuizUris,
  editQuizBrandQuestion,
  getGeneratedQuizBrandQuestion,
  getQuizFlaggedQuestions,
} from '@/services/api/quizModeration';
import { actionTypes } from '@/services/actions/quizModeration';

export function* getQuizData(params) {
  try {
    const token = yield select(({ session }) => session.token);
    const dateRange = yield select(({ filter }) => filter.dateRange);
    const { start, pageSize, filters, startFrom0 } = params.payload;
    const host = yield select(({ filter }) => filter.host);
    const data = yield call(
      getQuizQuestions,
      token,
      host,
      dayjs.utc(dayjs(dateRange[0])).startOf('day').unix(),
      dayjs.utc(dayjs(dateRange[1])).endOf('day').unix(),
      start,
      pageSize,
      filters,
    );

    yield put({
      type: actionTypes.GET_QUIZ_WITH_QUESTION_SUCCESS,
      data,
      startFrom0,
    });
  } catch (e) {
    console.log('err', e);
    toaster.danger(`${e}`);
  }
}

export function* getQuizFlaggedData(params) {
  try {
    const token = yield select(({ session }) => session.token);
    const dateRange = yield select(({ filter }) => filter.dateRange);
    const { start, pageSize, startFrom0 } = params.payload;
    const host = yield select(({ filter }) => filter.host);
    const data = yield call(
      getQuizFlaggedQuestions,
      token,
      host,
      dayjs.utc(dayjs(dateRange[0])).startOf('day').unix(),
      dayjs.utc(dayjs(dateRange[1])).endOf('day').unix(),
      start,
      pageSize,
    );

    yield put({
      type: actionTypes.GET_QUIZ_FLAGGED_QUESTION_SUCCESS,
      data,
      startFrom0,
    });
  } catch (e) {
    console.log('err', e);
    toaster.danger(`${e}`);
  }
}

export function* getQuizBrandData(action) {
  try {
    const token = yield select(({ session }) => session.token);
    const { host } = action.payload;
    const data = yield call(getQuizBrandQuestions, token, host);

    yield put({
      type: actionTypes.GET_QUIZ_WITH_BRAND_QUESTION_SUCCESS,
      data,
    });
  } catch (e) {
    console.log('err', e);
    toaster.danger(`${e}`);
  }
}

export function* getQuizBlockedUrls() {
  try {
    const token = yield select(({ session }) => session.token);
    const host = yield select(({ filter }) => filter.host);
    const data = yield call(getBlockedQuizUris, token, host);

    yield put({
      type: actionTypes.GET_BLOCKED_QUIZ_URIS_SUCCESS,
      data,
    });
  } catch (e) {
    console.log('err', e);
    toaster.danger(`${e}`);
  }
}

export function* getQuizDefaultData() {
  try {
    const token = yield select(({ session }) => session.token);
    const host = yield select(({ filter }) => filter.host);
    const data = yield call(getQuizDefaultQuestions, token, host);

    yield put({
      type: actionTypes.GET_QUIZ_WITH_DEFAULT_QUESTION_SUCCESS,
      data,
    });
  } catch (e) {
    console.log('err', e);
    toaster.danger(`${e}`);
  }
}

export function* getQuizDataById(params) {
  try {
    const token = yield select(({ session }) => session.token);
    const { articleId } = params.payload;
    const host = yield select(({ filter }) => filter.host);
    const data = yield call(getQuizQuestionsById, token, host, articleId);

    yield put({
      type: actionTypes.GET_QUIZ_WITH_QUESTION_BY_ID_SUCCESS,
      data,
    });
  } catch (e) {
    console.log('err', e);
    toaster.danger(`${e}`);
  }
}

export function* getLanguageCodes() {
  try {
    const data = yield call(getLanguageCodesApi);
    yield put({
      type: actionTypes.LANGUAGE_CODES_SUCCESS,
      data,
    });
  } catch (e) {
    console.log('err', e);
    toaster.danger(`${e}`);
  }
}

export function* updateQuizData(params) {
  try {
    const token = yield select(({ session }) => session.token);
    const { question } = params.payload;
    const data = yield call(editQuizQuestion, token, question);

    yield put({
      type: actionTypes.UPDATE_QUIZ_WITH_QUESTION_SUCCESS,
      data,
    });

    if (data) {
      toaster.success('Successfully updated');
    }
  } catch (e) {
    console.log('err', e);
    toaster.danger(`${e}`);
  }
}

export function* updateQuizBrandData(params) {
  try {
    const token = yield select(({ session }) => session.token);
    const { question } = params.payload;
    const data = yield call(editQuizBrandQuestion, token, question);

    if (data.success) {
      yield put({
        type: actionTypes.UPDATE_QUIZ_BRAND_WITH_QUESTION_SUCCESS,
        data: data.data,
      });
      toaster.success('Successfully updated');
    } else {
      yield put({
        type: actionTypes.UPDATE_QUIZ_BRAND_WITH_QUESTION_SUCCESS,
        data: [],
      });
      toaster.danger(data.errors[0] ? data.errors[0] : data.errors || "Something went wrong");
    }
  } catch (e) {
    console.log('err', e);
    toaster.danger(e.message);
  }
}

export function* createQuizData(params) {
  try {
    const token = yield select(({ session }) => session.token);
    const { question } = params.payload;
    const data = yield call(createQuizQuestion, token, question);

    yield put({
      type: actionTypes.CREATE_QUIZ_WITH_QUESTION_SUCCESS,
      data,
    });

    if (data) {
      toaster.success('Successfully created');
    }
  } catch (e) {
    console.log('err', e);
    toaster.danger(`${e}`);
  }
}

export function* blockQuizGenerationForUrl(params) {
  try {
    const token = yield select(({ session }) => session.token);
    const host = yield select(({ filter }) => filter.host);
    const { uri, checkQueryString } = params.payload;
    const data = yield call(
      blockQuizGeneration,
      token,
      host,
      uri,
      checkQueryString,
    );

    if (data) {
      yield put({
        type: actionTypes.BLOCK_QUIZ_GENERATION_SUCCESS,
        data,
      });
      toaster.success(`Successfully blocked the URI from generating Quiz`);
    }
  } catch (e) {
    console.log('err', e);
    if (e === `Host Can't Be Empty`) {
      toaster.danger(`Error: Host doesn't exist`);
    } else {
      toaster.danger(`${e}`);
    }
  }
}

export function* createDefaultQuizData(params) {
  try {
    const token = yield select(({ session }) => session.token);
    const { question } = params.payload;
    const data = yield call(createQuizDefaultQuestion, token, question);

    yield put({
      type: actionTypes.CREATE_QUIZ_DEFAULT_QUESTION_SUCCESS,
      data,
    });

    if (data) {
      toaster.success('Successfully created');
    }
  } catch (e) {
    console.log('err', e);
    toaster.danger(`${e}`);
  }
}

export function* generateBrandQuizSaga(params) {
  try {
    const token = yield select(({ session }) => session.token);

    const data = yield call(
      getGeneratedQuizBrandQuestion,
      token,
      params.payload,
    );
    if (data.data && data.data.length) {
      toaster.success('Successfully generated');
      yield put({
        type: actionTypes.GENERATE_QUIZ_BRAND_QUESTION_SUCCESS,
        data: data.data,
      });
    } else {
      toaster.danger(
        +data.status === 200
          ? 'Something went wrong'
          : data.errors && data.errors[0]
          ? errors[0]
          : 'Something went wrong',
      );
      yield put({
        type: actionTypes.GENERATE_QUIZ_BRAND_QUESTION_SUCCESS,
        data: [],
      });
    }
  } catch (e) {
    console.log('err', e);
    toaster.danger(`${e}`);
  }
}

export function* createBrandQuizData(params) {
  try {
    const token = yield select(({ session }) => session.token);
    const { question, brandDetails } = params.payload;
    const data = yield call(
      createQuizBrandQuestion,
      token,
      question,
      brandDetails,
    );

    yield put({
      type: actionTypes.CREATE_QUIZ_BRAND_QUESTION_SUCCESS,
      data,
    });

    if (data) {
      toaster.success('Successfully created');
    }
  } catch (e) {
    console.log('err', e);
    toaster.danger(e.message);
  }
}

export function* deleteQuizData(params) {
  try {
    const token = yield select(({ session }) => session.token);
    const { quizId } = params.payload;
    const success = yield call(deleteQuizQuestion, token, quizId);

    if (success) {
      yield put({
        type: actionTypes.DELETE_QUIZ_WITH_QUESTION_SUCCESS,
      });
      toaster.success('Successfully deleted');
    }
  } catch (e) {
    console.log('err', e);
    toaster.danger(`${e}`);
  }
}

export function* deleteDefaultQuizData(params) {
  try {
    const token = yield select(({ session }) => session.token);
    const { quizId } = params.payload;
    const success = yield call(deleteDefaultQuizQuestion, token, quizId);

    if (success) {
      yield put({
        type: actionTypes.DELETE_QUIZ_WITH_DEFAULT_QUESTION_SUCCESS,
      });
      toaster.success('Successfully deleted');
    }
  } catch (e) {
    console.log('err', e);
    toaster.danger(`${e}`);
  }
}

export function* deleteBrandQuizData(params) {
  try {
    const token = yield select(({ session }) => session.token);
    const { quizId } = params.payload;
    const success = yield call(deleteBrandQuizQuestion, token, quizId);

    if (success) {
      yield put({
        type: actionTypes.DELETE_QUIZ_WITH_BRAND_QUESTION_SUCCESS,
      });
      toaster.success('Successfully deleted');
    }
  } catch (e) {
    console.log('err', e);
    toaster.danger(`${e}`);
  }
}

export function* deleteBlockedUrlSaga(params) {
  try {
    const token = yield select(({ session }) => session.token);
    const { id } = params.payload;
    const success = yield call(deleteBlockedUrl, token, id);

    if (success) {
      yield put({
        type: actionTypes.DELETE_BLOCKED_QUIZ_GENERATION_SUCCESS,
      });
      toaster.success('Successfully deleted');
      yield put({
        type: actionTypes.GET_BLOCKED_QUIZ_URIS_REQUEST,
      });
    }
  } catch (e) {
    console.log('err', e);
    toaster.danger(`${e}`);
  }
}

export default function* quizModerationSaga() {
  yield takeLatest(actionTypes.GET_QUIZ_WITH_QUESTION_REQUEST, getQuizData);
  yield takeLatest(
    actionTypes.GET_QUIZ_FLAGGED_QUESTION_REQUEST,
    getQuizFlaggedData,
  );
  yield takeLatest(
    actionTypes.GET_QUIZ_WITH_DEFAULT_QUESTION_REQUEST,
    getQuizDefaultData,
  );
  yield takeLatest(
    actionTypes.GET_QUIZ_WITH_BRAND_QUESTION_REQUEST,
    getQuizBrandData,
  );
  yield takeLatest(actionTypes.LANGUAGE_CODES_REQUEST, getLanguageCodes);
  yield takeLatest(
    actionTypes.UPDATE_QUIZ_WITH_QUESTION_REQUEST,
    updateQuizData,
  );
  yield takeLatest(
    actionTypes.UPDATE_QUIZ_BRAND_WITH_QUESTION_REQUEST,
    updateQuizBrandData,
  );
  yield takeLatest(
    actionTypes.CREATE_QUIZ_WITH_QUESTION_REQUEST,
    createQuizData,
  );
  yield takeLatest(
    actionTypes.CREATE_QUIZ_DEFAULT_QUESTION_REQUEST,
    createDefaultQuizData,
  );
  yield takeLatest(
    actionTypes.GENERATE_QUIZ_BRAND_QUESTION_REQUEST,
    generateBrandQuizSaga,
  );
  yield takeLatest(
    actionTypes.CREATE_QUIZ_BRAND_QUESTION_REQUEST,
    createBrandQuizData,
  );
  yield takeLatest(
    actionTypes.DELETE_QUIZ_WITH_QUESTION_REQUEST,
    deleteQuizData,
  );
  yield takeLatest(
    actionTypes.DELETE_QUIZ_WITH_DEFAULT_QUESTION_REQUEST,
    deleteDefaultQuizData,
  );
  yield takeLatest(
    actionTypes.DELETE_QUIZ_WITH_BRAND_QUESTION_REQUEST,
    deleteBrandQuizData,
  );
  yield takeLatest(
    actionTypes.GET_QUIZ_WITH_QUESTION_BY_ID_REQUEST,
    getQuizDataById,
  );
  yield takeLatest(
    actionTypes.DELETE_BLOCKED_QUIZ_GENERATION_REQUEST,
    deleteBlockedUrlSaga,
  );
  yield takeLatest(
    actionTypes.GET_BLOCKED_QUIZ_URIS_REQUEST,
    getQuizBlockedUrls,
  );
  yield takeLatest(
    actionTypes.BLOCK_QUIZ_GENERATION_REQUEST,
    blockQuizGenerationForUrl,
  );
}
