// ==== 💪 Action Types
export const actionTypes = {
  GET_TOPICS_REQUEST: '@cloud-messaging/get-topics-request',
  GET_TOPICS_FINISHED: '@cloud-messaging/get-topics-finished',

  CREATE_TOPICS_REQUEST: '@cloud-messaging/create-topics-request',
  CREATE_TOPICS_FINISHED: '@cloud-messaging/create-topics-finished',

  DELETE_TOPIC_REQUEST: '@cloud-messaging/delete-topic-request',

  GET_NOTIFICATION_REQUEST: '@cloud-messaging/get-notification-request',
  GET_NOTIFICATION_FINISHED: '@cloud-messaging/get-notification-finished',

  GET_NOTIFICATION_TOTALS_REQUEST: '@cloud-messaging/get-notification-totals-request',
  GET_NOTIFICATION_TOTALS_FINISHED: '@cloud-messaging/get-notification-totals-finished',

  CREATE_NOTIFICATION_REQUEST: '@cloud-messaging/create-notification-request',
  CREATE_NOTIFICATION_FINISHED: '@cloud-messaging/create-notification-finished',

  SEND_NOTIFICATION: '@cloud-messaging/send-notification',
  SEND_TEST_NOTIFICATION: '@cloud-messaging/send-test-notification',

  SCHEDULE_NOTIFICATION_REQUEST: '@cloud-messaging/schedule-notification',
  SCHEDULE_NOTIFICATION_SUCCESS:
    '@cloud-messaging/schedule-notification-success',

  GET_REPORTS_DATA: '@cloud-messaging/get-reports-data',
  SET_REPORTS_DATA: '@cloud-messaging/set-reports-data',
};

// ==== 🎬 Actions
export const getReportsData = (from, to, host) => ({
  type: actionTypes.GET_REPORTS_DATA,
  payload: { from, to, host },
});

export const getTotalsData = (host) => ({
  type: actionTypes.GET_NOTIFICATION_TOTALS_REQUEST,
  payload: { host },
});

export const getTopicsRequest = (host, v1 = false) => ({
  type: actionTypes.GET_TOPICS_REQUEST,
  payload: { host, v1 },
});

export const createTopicRequest = (host, topic, callback) => ({
  type: actionTypes.CREATE_TOPICS_REQUEST,
  payload: { host, topic, callback },
});

export const deleteTopicRequest = (id, allSites, host = '') => ({
  type: actionTypes.DELETE_TOPIC_REQUEST,
  payload: { id, allSites, host },
});

export const getNotificationRequest = (host) => ({
  type: actionTypes.GET_NOTIFICATION_REQUEST,
  payload: { host },
});

export const createNotificationRequest = (
  host,
  topicIds,
  notificationTitle,
  notificationText,
  notificationImage,
  notificationName,
  notificationRedirectURL,
  callback,
) => ({
  type: actionTypes.CREATE_NOTIFICATION_REQUEST,
  payload: {
    host,
    topicIds,
    notificationTitle,
    notificationText,
    notificationImage,
    notificationName,
    notificationRedirectURL,
    callback,
  },
});

export const scheduleNotificationRequest = (id, scheduleTimeUtc) => ({
  type: actionTypes.SCHEDULE_NOTIFICATION_REQUEST,
  payload: {
    id,
    scheduleTimeUtc,
  },
});

export const createTestNotificationRequest = (
  notificationTitle,
  notificationText,
  notificationImage,
  deviceToken,
  notificationRedirectURL,
  callback,
) => ({
  type: actionTypes.SEND_TEST_NOTIFICATION,
  payload: {
    notificationTitle,
    notificationText,
    notificationImage,
    deviceToken,
    notificationRedirectURL,
    callback,
  },
});

export const sendNotificationRequest = (id) => ({
  type: actionTypes.SEND_NOTIFICATION,
  payload: { id },
});
