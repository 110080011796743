import { combineReducers } from 'redux';
import generalCommentsSettings from './general';
import userListsSettings from './userlists';
import widgetSettings from './widget';

export default combineReducers({
  general: generalCommentsSettings,
  userlists: userListsSettings,
  widget: widgetSettings,
});
