import { all, call } from 'redux-saga/effects';
import generalCommentsSettingsSaga from './general';
import userListsSettingsSaga from './userlists';
import widgetSettingsSaga from './widget';

export default function* coreCommentsSaga() {
  yield all({
    general: call(generalCommentsSettingsSaga),
    userlists: call(userListsSettingsSaga),
    widget: call(widgetSettingsSaga),
  });
}
