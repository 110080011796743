import { call, put, select, takeLatest } from 'redux-saga/effects';
import { toaster } from 'evergreen-ui';
import {
  getAdblockSettings,
  updateAdBlockModalSettingsByApikey,
  updateAdminProfileAdblock,
} from '@/services/api/adblockmanagement';
import { actionTypes } from '@/services/actions/adblockmanagement';

export function* loadAdblockData(params) {
  try {
    const token = yield select(({ session }) => session.token);

    yield put({
      type: actionTypes.SET_LOADING,
      payload: true,
    });

    const result = yield call(getAdblockSettings, token, params.payload);

    yield put({
      type: actionTypes.SET_DATA,
      payload: result,
    });
  } catch (e) {
    console.log('err', e);
    toaster.danger('Error fetching adblock management data');
  } finally {
    yield put({
      type: actionTypes.SET_LOADING,
      payload: false,
    });
  }
}

export function* updateAdblockData(params) {
  try {
    const token = yield select(({ session }) => session.token);
    yield call(
      updateAdminProfileAdblock,
      token,
      params.payload.host,
      params.payload.showAdBlockModal,
      params.payload.noAdBlockFreeArticles,
      params.payload.adBlockModalMessage,
    );
  } catch (e) {
    console.log('err', e);
    toaster.danger('Error updating Adblock Management data');
  }
}

export function* updateDataForAdblock(params) {
  try {
    const token = yield select(({ session }) => session.token);

    yield call(updateAdBlockModalSettingsByApikey, token, params.payload);
  } catch (e) {
    console.log('err', e);
    toaster.danger('Error updating Adblock Management data');
  }
}

export default function* adblockManagementSaga() {
  yield takeLatest(actionTypes.GET_DATA, loadAdblockData);
  yield takeLatest(actionTypes.UPDATE_DATA, updateAdblockData);
  yield takeLatest(actionTypes.UPDATE_ADBLOCK_DATA, updateDataForAdblock);
}
