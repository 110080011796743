import axios from 'axios';

export const getTopArticles = (token, host, from, to) =>
  axios
    .get(`${process.env.API_BASE_URL}api/v1/Audio/getTopArticles`, {
      params: {
        host,
        from,
        to,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      const { data, success } = response.data;

      if (success) {
        return data;
      }

      throw new Error('not valid details');
    });

export const getTopArticlesByApiKey = (token, apiKey, from, to) =>
  axios
    .get(`${process.env.API_BASE_URL}api/v1/Audio/getTopArticlesByApiKey`, {
      params: {
        apiKey,
        from,
        to,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      const { data, success } = response.data;

      if (success) {
        return data;
      }

      throw new Error('not valid details');
    });

export const getEmoteState = (token, from, to, host) =>
  axios
    .get(`${process.env.API_BASE_URL}api/v1/Audio/getAudioState`, {
      params: { from, to, host },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      const { data, success } = response.data;

      if (success) {
        return data;
      }

      throw new Error('not valid details');
    });

export const getEmoteStateByApiKey = (token, from, to, apiKey) =>
  axios
    .get(`${process.env.API_BASE_URL}api/v1/Audio/getAudioStateByApiKey`, {
      params: { from, to, apiKey },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      const { data, success } = response.data;

      if (success) {
        return data;
      }

      throw new Error('not valid details');
    });

export const getTopByFilter = (
  token,
  host,
  from,
  to,
  filter,
  fromCount,
  toCount,
) =>
  axios
    .get(`${process.env.API_BASE_URL}api/v1/Audio/getTopByFilter`, {
      params: {
        host,
        from,
        to,
        filter,
        fromCount,
        toCount,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      const { data, success } = response.data;

      if (success) {
        return data;
      }

      throw new Error('not valid details');
    });

export const getTopByFilterByApiKey = (
  token,
  apiKey,
  from,
  to,
  filter,
  fromCount,
  toCount,
) =>
  axios
    .get(`${process.env.API_BASE_URL}api/v1/Audio/getTopByFilterByApiKey`, {
      params: {
        apiKey,
        from,
        to,
        filter,
        fromCount,
        toCount,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      const { data, success } = response.data;

      if (success) {
        return data;
      }

      throw new Error('not valid details');
    });

export const getTopArticlesByFilter = (
  token,
  host,
  from,
  to,
  filter,
  fromCount,
  toCount,
  filterName,
) =>
  axios
    .get(`${process.env.API_BASE_URL}api/v1/Audio/getTopArticlesByFilter`, {
      params: {
        host,
        from,
        to,
        filter,
        fromCount,
        toCount,
        filterName,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      const { data, success } = response.data;

      if (success) {
        return data;
      }

      throw new Error('not valid details');
    });

export const getTopArticlesByFilterByApiKey = (
  token,
  apiKey,
  from,
  to,
  filter,
  fromCount,
  toCount,
  filterName,
) =>
  axios
    .get(
      `${process.env.API_BASE_URL}api/v1/Audio/getTopArticlesByFilterByApiKey`,
      {
        params: {
          apiKey,
          from,
          to,
          filter,
          fromCount,
          toCount,
          filterName,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success } = response.data;

      if (success) {
        return data;
      }

      throw new Error('not valid details');
    });
