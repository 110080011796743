import axios from 'axios';

export const setFlag = (token, flagName, flagValue, host, adminEmails) =>
  axios
    .post(
      `${process.env.API_BASE_URL}api/v1/Publishers/setFlag`,
      {
        flagName,
        flagValue,
        host,
        adminEmails,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      throw new Error(errors[0]);
    });

export const setFlagByApiKey = (
  token,
  flagName,
  flagValue,
  apiKey,
  adminEmails,
) =>
  axios
    .post(
      `${process.env.API_BASE_URL}api/v1/Publishers/setFlagByApiKey`,
      {
        flagName,
        flagValue,
        apiKey,
        adminEmails,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      throw new Error(errors[0]);
    });

export const setCustomCheckboxFlag = (token, data, host) =>
  axios
    .post(
      `${process.env.API_BASE_URL}api/v1/Publishers/UpdateCustomPrivacyFlag`,
      data.allSites
        ? {
            CustomPrivacy: data.value,
          }
        : {
            Host: host,
            CustomPrivacy: data.value,
          },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      throw new Error(errors[0]);
    });

export const updatePrivacyPolicyAPI = (token, data, host) =>
  axios
    .post(
      `${process.env.API_BASE_URL}api/v1/Publishers/UpdatePrivacyPolicy`,
      data.allSites
        ? {
            CheckboxText: data.customCheckboxText,
            PrivacyPolicyLink: data.customPrivacyPolicyLink,
            PrivacyPolicyLinkText: data.customPolicyLinkText,
          }
        : {
            Host: host,
            CheckboxText: data.customCheckboxText,
            PrivacyPolicyLink: data.customPrivacyPolicyLink,
            PrivacyPolicyLinkText: data.customPolicyLinkText,
          },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      throw new Error(errors[0]);
    });

export const getCommentsByUserEmail = (token, host, userEmail) =>
  axios
    .get(`${process.env.API_BASE_URL}api/v1/Comments/CommentsDataDump`, {
      params: { userEmail, host },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
        return response.data;
    });
