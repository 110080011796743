import { call, put, select, takeLatest, all } from 'redux-saga/effects';
import dayjs from 'dayjs';
import { toaster } from 'evergreen-ui';
import commentsSaga from './comments';
import emotesSaga from './emotes';
import recommendationsSaga from './recommendations';
import sharesSaga from './shares';
import newslettersSaga from './newsletters';
import bookmarksSaga from './bookmarks';
import quizSaga from './quiz';

import { compare_dates } from '@/utils';
import {
  getSiteStats,
  getSiteStatsByApiKey,
  getStatsByHostTimeSeries,
  getStatsByApiKeyTimeSeries,
} from '@/services/api/analytics';

import { actionTypes } from '@/services/actions/analytics';
import { initialState } from '@/services/reducers/analytics';
import audioSaga from './audio';

export function* loadDailySiteStats(action) {
  try {
    const { token, apiKey } = yield select(({ session }) => session);
    const { host, dateRange } = yield select(({ filter }) => filter);

    let resultArr = [];

    const result = yield call(
      host ? getSiteStats : getSiteStatsByApiKey,
      token,
      host || apiKey,
      new Date(dayjs(action.payload.from).startOf('day')).getTime() / 1000,
      (
        (new Date(dayjs(action.payload.to).endOf('day')).getTime() + 1) /
        1000
      ).toFixed(),
    );

    const {data, totalDiffObj} = result;

    if (host && data && data.length) {
      resultArr = data.slice(0, data.length - 1);
    } else if (!host && result && data.length) {
      resultArr = data;
    }

    let newData = [];

    if (host) {
      newData = resultArr.map((el) => ({ ...el, Users: el.users }));
    } else {
      newData = resultArr;
    }

    yield put({
      type: actionTypes.SET_DAILY_SITE_STATS,
      payload: newData || initialState.daily_site_stats,
    });

    yield put({
      type: actionTypes.SET_DIFFERENCE_DATA,
      payload: totalDiffObj,
    });
  } catch (e) {
    console.log('err', e);
    toaster.danger('Error fetching daily site stats', {
      id: 'analytics-daily-site-stats',
    });
    yield put({
      type: actionTypes.SET_DAILY_SITE_STATS,
      payload: initialState.daily_site_stats,
    });
  }
}

export function* loadMonthStats() {
  try {
    const { token, apiKey } = yield select(({ session }) => session);
    const host = yield select(({ filter }) => filter.host);

    const month_stats = yield call(
      host ? getStatsByHostTimeSeries : getStatsByApiKeyTimeSeries,
      token,
      host || apiKey,
    );

    month_stats.sort((m1, m2) => compare_dates(m1.statsDay, m2.statsDay));

    yield put({
      type: actionTypes.SET_MONTH_STATS,
      payload:
        month_stats.slice(month_stats?.length - 30, month_stats?.length) ||
        initialState.month_stats,
    });
  } catch (e) {
    console.log('err', e);
    toaster.danger('Error fetching month stats', {
      id: 'analytics-montn-stats',
    });
    yield put({
      type: actionTypes.SET_MONTH_STATS,
      payload: initialState.month_stats,
    });
  }
}

export function* loadAggregatedDailySiteStats(params) {
  try {
    const { token, apiKey } = yield select(({ session }) => session);
    const { host, dateRange } = yield select(({ filter }) => filter);
    const { groupType } = params.payload;

    const result = yield call(
      host ? getSiteStats : getSiteStatsByApiKey,
      token,
      host || apiKey,
      new Date(dayjs(params.payload.from).startOf('day')).getTime() / 1000,
      (
        (new Date(dayjs(params.payload.to).endOf('day')).getTime() + 1) /
        1000
      ).toFixed(),
      groupType,
    );

    yield put({
      type: actionTypes.SET_DAILY_SITE_STATS,
      payload: result.data || initialState.daily_site_stats,
    });
  } catch (e) {
    console.log('err', e);
    toaster.danger('Error fetching daily site stats', {
      id: 'analytics-daily-site-stats',
    });
    yield put({
      type: actionTypes.SET_DAILY_SITE_STATS,
      payload: initialState.daily_site_stats,
    });
  }
}

export default function* analyticsSaga() {
  yield takeLatest(actionTypes.LOAD_MONTH_STATS, loadMonthStats);
  yield takeLatest(actionTypes.LOAD_DAILY_SITE_STATS, loadDailySiteStats);
  yield takeLatest(
    actionTypes.LOAD_AGGREGATED_DAILY_SITE_STATS,
    loadAggregatedDailySiteStats,
  );

  yield all({
    comments: call(commentsSaga),
    emotes: call(emotesSaga),
    recommendations: call(recommendationsSaga),
    shares: call(sharesSaga),
    audio: call(audioSaga),
    newsletters: call(newslettersSaga),
    bookmarks: call(bookmarksSaga),
    quiz: call(quizSaga),
  });
}
