import { call, put, select, takeLatest } from 'redux-saga/effects';
import { toaster } from 'evergreen-ui';
import {
  getBillingInfo,
  updateBillingInfo,
  setBillingInfo,
} from '@/services/api/settings/payment';
import {
  getPaymentDetailsSuccess,
  actionTypes,
  paymentDetailsError,
} from '@/services/actions/settings/payment';

export function* getBillingData() {
  try {
    const token = yield select(({ session }) => session.token);
    const data = yield call(getBillingInfo, token);
    yield put(getPaymentDetailsSuccess(data));
  } catch (e) {
    yield put(paymentDetailsError());
    toaster.danger('Something went wrong while trying to fetch payment info.', {
      id: 'unsuccessful-fetch-payment-data',
    });
    console.log('Something went wrong', e.message);
  }
}

export function* setOrUpdateBillingData(action) {
  try {
    const token = yield select(({ session }) => session.token);
    const apiKey = yield select(({ session }) => session.apiKey);
    yield call(
      action.paymentData.billingDataExists ? updateBillingInfo : setBillingInfo,
      token,
      apiKey,
      action.paymentData,
    );
    toaster.success('Your payment details were successfully saved', {
      id: 'successful-save-payment-data',
    });
    yield put({
      type: actionTypes.FINISH_LOADING,
    });
  } catch (e) {
    yield put(paymentDetailsError());
    toaster.danger(
      'Something went wrong while trying to save your payment info.',
      {
        id: 'unsuccessful-save-payment-data',
      },
    );
    console.log('Something went wrong', e.message);
  }
}

export default function* paymentSaga() {
  yield takeLatest(actionTypes.GET_PAYMENT_DETAILS_REQUEST, getBillingData);
  yield takeLatest(
    actionTypes.SAVE_OR_UPDATE_PAYMENT_DETAILS_REQUEST,
    setOrUpdateBillingData,
  );
}
