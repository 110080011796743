import {
  call,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import dayjs from 'dayjs';
import {
  CreateCompany,
  AdUnits,
  GetTargetableLocations,
  GetTargetDevices,
  CreateLineItem,
  GetLineItemsByOrderName,
  AddCreativeForLineItem,
  EditLineItemById,
  CreativesForLineItem,
  UpdateCreativeById,
  LineItemReport,
  DeleteCreateiveForLineItem,
  PerformLineItemAction,
  CopyLineItem,
  EditLineItemNameById,
  GetTargetedLocationsForLineItems,
  GetPrivacyIssues,
} from '@/services/api/publisher';
import { actionTypes } from '@/services/actions/publisher';
import { toaster } from 'evergreen-ui';
import { widthAndHeightOptions } from '../globalConfig';

const maxValue = 922337203;

export function* createCompanyRequest(params) {
  try {
    const token = yield select(({ session }) => session.token);
    const { companyName } = params.payload;

    const response = yield call(CreateCompany, token, companyName);

    if (
      response.success ||
      response.errors[0] === 'company_is_already_exist_with_specified_name'
    ) {
      yield put({
        type: actionTypes.SET_ORDER_COMPANY_DATA,
        payload: response.data || {
          advertiser: { companyName: companyName },
          order: { orderName: companyName },
        },
      });
      toaster.success('Company was successfully created!');
    }
  } catch (e) {
    toaster.danger(e.message, {
      id: 'unsuccessful-save-payment-data',
    });
    console.log('Something went wrong', e.message);
  }
}

export function* getExtraInfoForLineItemsRequestAdUnits(params) {
  try {
    const token = yield select(({ session }) => session.token);

    const { name } = params.payload;

    const adUnitIds = yield call(AdUnits, token, name);

    const adUnitIdsArray = adUnitIds || [];

    yield put({
      type: actionTypes.SET_EXTRA_INFO_FOR_LINE_ITEMS_REQUEST_AD_UNITS,
      payload: adUnitIdsArray,
    });
  } catch (e) {
    toaster.danger(e.message, {
      id: 'unsuccessful-save-payment-data',
    });
    console.log('Something went wrong', e.message);
  }
}

export function* getExtraInfoForLineItemsRequestLocations() {
  try {
    const token = yield select(({ session }) => session.token);

    const geoLocations = yield call(GetTargetableLocations, token);

    const geoLocationsArray = geoLocations || [];

    yield put({
      type: actionTypes.SET_EXTRA_INFO_FOR_LINE_ITEMS_REQUEST_LOCATIONS,
      payload: geoLocationsArray,
    });
  } catch (e) {
    toaster.danger(e.message, {
      id: 'unsuccessful-save-payment-data',
    });
    console.log('Something went wrong', e.message);
  }
}

export function* getExtraInfoForLineItemsRequestDevices() {
  try {
    const token = yield select(({ session }) => session.token);

    const targetedDevice = yield call(GetTargetDevices, token);

    const targetedDeviceArray = targetedDevice || [];

    yield put({
      type: actionTypes.SET_EXTRA_INFO_FOR_LINE_ITEMS_REQUEST_DEVICES,
      payload: targetedDeviceArray.filter((device) => device.name),
    });
  } catch (e) {
    toaster.danger(e.message, {
      id: 'unsuccessful-save-payment-data',
    });
    console.log('Something went wrong', e.message);
  }
}

export function* createLineItemRequest(params) {
  try {
    const token = yield select(({ session }) => session.token);

    const {
      lineItemName,
      orderName,
      costPerUnit,
      quantity,
      startTime,
      endTime,
      selectedAdUnitIds,
      selectedLocations,
      selectedDevices,
      widthAndHeight,
      callback,
      primaryGoalType,
      setProgressBarPercent,
    } = params.payload;
    const { adUnitIds, geoLocations, targetedDevice } = yield select(
      ({ admanager }) => admanager.extra_order_company_data,
    );

    const adUnitIdsFromList = selectedAdUnitIds.length
      ? selectedAdUnitIds
      : adUnitIds.map((el) => el.id);
    const geoLocationsFromList =
      selectedLocations.length > 10000
        ? geoLocations
        : geoLocations.filter((el) => selectedLocations.includes(el.id));
    const devicesFromList = selectedDevices.length
      ? targetedDevice
          .filter((el) => selectedDevices.includes(el.id))
          .map((el) => ({ id: Number(el.id) }))
      : targetedDevice.map((el) => ({ id: Number(el.id) }));
    const creativePlaceholderForLineItem = widthAndHeight.map((item) => ({
      width: Number(item.split('x')[0]),
      height: Number(item.split('x')[1]),
      isAspectRatio: false,
      placeholderCreativeSizeType: 0,
    }));

    const fromTime = dayjs
      .utc(dayjs(startTime))
      .add(1, 'minute')
      .format('YYYY-MM-DD HH:mm');
    const toTime = dayjs.utc(dayjs(endTime)).format('YYYY-MM-DD HH:mm');

    const response = yield call(
      CreateLineItem,
      token,
      lineItemName,
      orderName,
      costPerUnit,
      quantity,
      fromTime,
      toTime,
      adUnitIdsFromList,
      geoLocationsFromList.map((el) => ({
        ...el,
        id: Number(el.id),
        canonicalParentId: el.canonicalParentId
          ? Number(el.canonicalParentId)
          : 0,
      })),
      [],
      devicesFromList,
      primaryGoalType,
      creativePlaceholderForLineItem,
      setProgressBarPercent,
    );

    if (response) {
      callback(response);
    }
  } catch (e) {
    if (
      e.message !== 'startTime_must_be_in_the_future' &&
      e.message !== 'UniqueError.NOT_UNIQUE'
    ) {
      toaster.danger(e.message, {
        id: 'unsuccessful-save-payment-data',
      });
      console.log('Something went wrong', e.message);
    } else if (e.message == 'startTime_must_be_in_the_future') {
      toaster.danger('Start time must be in the future!', {
        id: 'unsuccessful-save-payment-data23',
      });
      console.log('Something went wrong', e.message);
    } else if (e.message == 'UniqueError.NOT_UNIQUE') {
      toaster.danger('Line item already exists with the same name!', {
        id: 'unsuccessful-save-payment-data3231',
      });
      console.log('Something went wrong', e.message);
    }
  } finally {
    const { setShowProgresBar, setProgressBarPercent } = params.payload;
    setShowProgresBar(false);
    setProgressBarPercent(0);
  }
}

export function* getLinesItem(params) {
  try {
    const token = yield select(({ session }) => session.token);

    const { orderName } = params.payload;

    const response = yield call(GetLineItemsByOrderName, token, orderName);

    const statuses = [];

    response.data.map((el) => {
      if (!statuses.includes(el.status)) {
        statuses.push(el.status);
      }
    });

    yield put({
      type: actionTypes.SET_LINES_ITEM,
      payload: {
        data: response.data,
        flag: response.advertiserExists,
        status: statuses,
      },
    });

    yield put({
      type: actionTypes.SET_FINISH_LINE_ITEM_LOADING,
      payload: false,
    });
  } catch (e) {
    if (e.message !== 'invalid_order_Name') {
      toaster.danger(e.message, {
        id: 'unsuccessful-save-payment-data',
      });
      console.log('Something went wrong', e.message);
    } else {
      toaster.notify('Advertiser and Order still not created.', {
        id: 'Advertiser-and-Order-still-not-created.',
      });
      yield put({
        type: actionTypes.SET_FINISH_LINE_ITEM_LOADING,
        payload: false,
      });
    }
  }
}

export function* addCreativeDataForLineItem(params) {
  try {
    const token = yield select(({ session }) => session.token);

    const {
      AdvertiserName,
      LineItemName,
      CreativeDestinationUrl,
      formFile,
      callback,
      startTime,
      endTime,
      height,
      width,
      orderId,
      setProgressBarPercent,
    } = params.payload;

    const formData = new FormData();
    formData.append('AdvertiserName', AdvertiserName);
    formData.append('LineItemName', LineItemName);
    formData.append('CreativeDestinationUrl', CreativeDestinationUrl);
    formData.append('formFile', formFile);
    formData.append(
      'StartTime',
      dayjs.utc(dayjs(startTime)).add(1, 'minute').format('YYYY-MM-DD HH:mm'),
    );
    formData.append(
      'endTime',
      dayjs.utc(dayjs(endTime)).format('YYYY-MM-DD HH:mm'),
    );
    formData.append('CreativeHeight', Number(height));
    formData.append('CreativeWidth', Number(width));
    formData.append('OrderId', orderId);

    const response = yield call(
      AddCreativeForLineItem,
      token,
      formData,
      setProgressBarPercent,
    );

    toaster.success('Successfully added creative to line item!');
    callback();
  } catch (e) {
    if (
      e.message !==
        'LineItemCreativeAssociationError.ENDDATE_AFTER_LINEITEM_ENDDATE' &&
      e.message !==
        'LineItemCreativeAssociationError.STARTDATE_BEFORE_LINEITEM_STARTDATE' &&
      e.message !==
        'LineItemCreativeAssociationError.ENDDATE_NOT_AFTER_STARTDATE' &&
      e.message !==
        'LineItemCreativeAssociationError.ENDDATE_NOT_AFTER_LINEITEM_STARTDATE' &&
      e.message !== 'RequiredSizeError.NOT_ALLOWED'
    ) {
      toaster.danger(e.message, {
        id: 'unsuccessful-save-payment-data1',
      });
      console.log('Something went wrong', e.message);
    } else if (
      e.message ==
      'LineItemCreativeAssociationError.ENDDATE_AFTER_LINEITEM_ENDDATE'
    ) {
      toaster.danger("End date can't be after Line Item end date!", {
        id: 'unsuccessful-save-payment-data2',
      });
      console.log('Something went wrong', e.message);
    } else if (
      e.message ==
      'LineItemCreativeAssociationError.STARTDATE_BEFORE_LINEITEM_STARTDATE'
    ) {
      toaster.danger("Start date can't be before Line Item start date!", {
        id: 'unsuccessful-save-payment-data3',
      });
      console.log('Something went wrong', e.message);
    } else if (
      e.message ==
      'LineItemCreativeAssociationError.ENDDATE_NOT_AFTER_STARTDATE'
    ) {
      toaster.danger('End date must be after start date!', {
        id: 'unsuccessful-save-payment-data4',
      });
      console.log('Something went wrong', e.message);
    } else if (
      e.message ==
      'LineItemCreativeAssociationError.ENDDATE_NOT_AFTER_LINEITEM_STARTDATE'
    ) {
      toaster.danger('End date must be after Line Item start date!', {
        id: 'unsuccessful-save-payment-data5',
      });
      console.log('Something went wrong', e.message);
    } else if (e.message == 'RequiredSizeError.NOT_ALLOWED') {
      toaster.danger('Wrong creative size for image!', {
        id: 'unsuccessful-save-payment-data5',
      });
      console.log('Something went wrong', e.message);
    }
  } finally {
    const { setShowProgresBar } = params.payload;
    setShowProgresBar(false);
  }
}

export function* editLineItemData(params) {
  try {
    const token = yield select(({ session }) => session.token);

    const {
      id,
      rate,
      quantity,
      startTime,
      endTime,
      height,
      width,
      adUnitIds,
      geoLocations,
      targetedDevice,
      callback,
      setShowProgresBar,
      setProgressBarPercent,
    } = params.payload;
    const {
      adUnitIds: adUnitIdsRedux,
      geoLocations: geoLocationsRedux,
      targetedDevice: targetedDeviceRedux,
    } = yield select(({ admanager }) => admanager.extra_order_company_data);

    const adUnitIdsFromList = adUnitIds.length
      ? adUnitIds
      : adUnitIdsRedux.map((el) => el.id);
    const geoLocationsFromList = geoLocations.length
      ? geoLocationsRedux
          .filter((el) => geoLocations.includes(el.id))
          .map((el) => ({
            id: Number(el.id),
            canonicalParentId: Number(el.canonicalParentId) || 0,
            name: el.name,
            geoType: el.type,
            parentIds: el.parentIds,
          }))
      : geoLocationsRedux.map((el) => ({
          id: Number(el.id),
          canonicalParentId: Number(el.canonicalParentId) || 0,
          name: el.name,
          geoType: el.type,
          parentIds: el.parentIds,
        }));
    const devicesFromList = targetedDevice.length
      ? targetedDeviceRedux
          .filter((el) => targetedDevice.includes(el.id))
          .map((el) => ({ id: Number(el.id) }))
      : targetedDeviceRedux.map((el) => ({ id: Number(el.id) }));

    const utcStartTime = dayjs
      .utc(dayjs(startTime))
      .format('YYYY-MM-DD HH:mm');
    const utcEndTime = dayjs.utc(dayjs(endTime)).format('YYYY-MM-DD HH:mm');

    const response = yield call(
      EditLineItemById,
      token,
      id,
      Number(rate) > maxValue ? parseInt(maxValue) : Number(rate),
      Number(quantity) > maxValue ? parseInt(maxValue) : Number(quantity),
      utcStartTime,
      utcEndTime,
      Number(height),
      Number(width),
      adUnitIdsFromList,
      geoLocationsFromList,
      [],
      devicesFromList,
      setProgressBarPercent,
    );

    if (response) {
      toaster.success('Successfully updated!');
      callback();
    }
  } catch (e) {
    if (
      e.message !== 'LineItemFlightDateError.START_DATE_TIME_IS_IN_PAST' &&
      e.message !== 'LineItemFlightDateError.END_DATE_TIME_NOT_AFTER_START_TIME'
    ) {
      toaster.danger(e.message, {
        id: 'unsuccessful-save-payment-data',
      });
      console.log('Something went wrong', e.message);
    } else if (
      e.message == 'LineItemFlightDateError.START_DATE_TIME_IS_IN_PAST'
    ) {
      toaster.danger("Start date can't be in the past!", {
        id: 'unsuccessful-save-payment-data323',
      });
      console.log('Something went wrong', e.message);
    } else if (
      e.message == 'LineItemFlightDateError.END_DATE_TIME_NOT_AFTER_START_TIME'
    ) {
      toaster.danger('End date time must be after start date time!', {
        id: 'unsuccessful-save-payment-data324',
      });
      console.log('Something went wrong', e.message);
    }
  } finally {
    const { setShowProgresBar } = params.payload;
    setShowProgresBar(false);
  }
}

export function* getCreativeForLineItem(params) {
  try {
    const token = yield select(({ session }) => session.token);

    const { name } = params.payload;

    const response = yield call(CreativesForLineItem, token, name);

    yield put({
      type: actionTypes.SET_CREATIVE_FOR_LINE_ITEM,
      payload: response,
    });
  } catch (e) {
    if (e.message !== 'creatives_not_found_for_this_line_item') {
      toaster.danger(e.message, {
        id: 'unsuccessful-save-payment-data',
      });
      console.log('Something went wrong', e.message);
    } else {
      yield put({
        type: actionTypes.SET_CREATIVE_FOR_LINE_ITEM,
        payload: [],
      });
    }
  }
}

export function* updateCreativeData(params) {
  try {
    const token = yield select(({ session }) => session.token);

    const {
      formFile,
      Id,
      lineitemName,
      CreativeDestinationUrl,
      callback,
      startTime,
      endTime,
    } = params.payload;

    const formdata = new FormData();
    formdata.append('Id', Id);
    formdata.append('lineitemName', lineitemName);
    formdata.append('CreativeDestinationUrl', CreativeDestinationUrl);
    formdata.append(
      'StartTime',
      dayjs.utc(dayjs(startTime)).format('YYYY-MM-DD HH:mm'),
    );
    formdata.append(
      'EndTime',
      dayjs.utc(dayjs(endTime)).format('YYYY-MM-DD HH:mm'),
    );
    formFile && formdata.append('formFile', formFile);

    const response = yield call(UpdateCreativeById, token, formdata);

    if (response) {
      toaster.success('Successfully updated!');
      callback(response);
    }
  } catch (e) {
    if (
      e.message !==
        'LineItemCreativeAssociationError.ENDDATE_AFTER_LINEITEM_ENDDATE' &&
      e.message !==
        'LineItemCreativeAssociationError.STARTDATE_BEFORE_LINEITEM_STARTDATE' &&
      e.message !==
        'LineItemCreativeAssociationError.ENDDATE_NOT_AFTER_STARTDATE' &&
      e.message !==
        'LineItemCreativeAssociationError.ENDDATE_NOT_AFTER_LINEITEM_STARTDATE' &&
      e.message !== 'RequiredSizeError.NOT_ALLOWED'
    ) {
      toaster.danger(e.message, {
        id: 'unsuccessful-save-payment-data1',
      });
      console.log('Something went wrong', e.message);
    } else if (
      e.message ==
      'LineItemCreativeAssociationError.ENDDATE_AFTER_LINEITEM_ENDDATE'
    ) {
      toaster.danger("End date can't be after Line Item end date!", {
        id: 'unsuccessful-save-payment-data2',
      });
      console.log('Something went wrong', e.message);
    } else if (
      e.message ==
      'LineItemCreativeAssociationError.STARTDATE_BEFORE_LINEITEM_STARTDATE'
    ) {
      toaster.danger("Start date can't be before Line Item start date!", {
        id: 'unsuccessful-save-payment-data3',
      });
      console.log('Something went wrong', e.message);
    } else if (
      e.message ==
      'LineItemCreativeAssociationError.ENDDATE_NOT_AFTER_STARTDATE'
    ) {
      toaster.danger('End date must be after start date!', {
        id: 'unsuccessful-save-payment-data4',
      });
      console.log('Something went wrong', e.message);
    } else if (
      e.message ==
      'LineItemCreativeAssociationError.ENDDATE_NOT_AFTER_LINEITEM_STARTDATE'
    ) {
      toaster.danger('End date must be after Line Item start date!', {
        id: 'unsuccessful-save-payment-data5',
      });
      console.log('Something went wrong', e.message);
    } else if (e.message == 'RequiredSizeError.NOT_ALLOWED') {
      toaster.danger('Wrong creative size for image!', {
        id: 'unsuccessful-save-payment-data5',
      });
      console.log('Something went wrong', e.message);
    }
  }
}

export function* getGamReportData(params) {
  try {
    const {
      itemsList,
      orderName,
      from,
      to,
      reportType,
      adUnitNames,
      country,
      deviceIds,
      selectedCreativeSizes,
      setShowProgresBar,
      setProgressBarPercent,
    } = params.payload;

    const token = yield select(({ session }) => session.token);
    const { adUnitIds, targetedDevice } = yield select(
      ({ admanager }) => admanager.extra_order_company_data,
    );

    const creativeSizesArray = selectedCreativeSizes.length
      ? selectedCreativeSizes.map((item) => ({
          width: Number(item.split('x')[0]),
          height: Number(item.split('x')[1]),
        }))
      : widthAndHeightOptions.map((item) => ({
          width: Number(item.value.split('x')[0]),
          height: Number(item.value.split('x')[1]),
        }));
    const adUnitNamesArray =
      adUnitNames.length !== adUnitIds.length ? adUnitNames : [];
    const countryArray = country.length < 500 ? country : [];
    const deviceIdsArray =
      deviceIds.length !== targetedDevice.length ? deviceIds : [];

    const response = yield call(
      LineItemReport,
      token,
      itemsList,
      orderName,
      from,
      to,
      Number(reportType),
      adUnitNamesArray,
      countryArray,
      deviceIdsArray,
      creativeSizesArray,
      setProgressBarPercent,
    );

    setShowProgresBar(false);
    setProgressBarPercent(0);

    if (!response.length) {
      toaster.warning('There is no data!');
    }

    yield put({
      type: actionTypes.SET_GAM_REPORT_DATA,
      payload: response,
    });
  } catch (e) {
    toaster.danger(e.message, {
      id: 'unsuccessful-save-payment-data',
    });
    console.log('Something went wrong', e.message);
  } finally {
    const { setShowProgresBar, setProgressBarPercent } = params.payload;
    setShowProgresBar(false);
    setProgressBarPercent(0);
  }
}

export function* getPolicyIssues() {
  try {
    const token = yield select(({ session }) => session.token);
    const host = yield select(({ filter }) => filter.host);

    const data = yield call(
      GetPrivacyIssues,
      token,
      host ? host : null
    );

    if (data && data.issues && !data.issues.length) {
      toaster.warning('There is no data!');
    }

    yield put({
      type: actionTypes.SET_HOST_POLICY_ISSUES,
      payload: data,
    });
  } catch (e) {
    toaster.danger(e.message, {
      id: 'unsuccessful-data-response',
    });
    console.log('Something went wrong', e.message);
  }
}

export function* deleteCreativeItem(params) {
  try {
    const token = yield select(({ session }) => session.token);
    const { lineItemId, creativeId } = params.payload;

    const { creative_for_line_item } = yield select(
      ({ admanager }) => admanager,
    );

    const response = yield call(
      DeleteCreateiveForLineItem,
      token,
      lineItemId,
      creativeId,
    );

    if (response) {
      yield put({
        type: actionTypes.SET_CREATIVE_FOR_LINE_ITEM,
        payload: creative_for_line_item.filter((el) => el.id !== creativeId),
      });
      toaster.success('Successfully deleted!');
    }
  } catch (e) {
    toaster.danger(e.message, {
      id: 'unsuccessful-save-payment-data',
    });
    console.log('Something went wrong', e.message);
  }
}

export function* changeLineItemState(params) {
  try {
    const { id, state, callback } = params.payload;
    const token = yield select(({ session }) => session.token);

    const response = yield call(PerformLineItemAction, token, id, state);

    if (response) {
      callback();
    }
  } catch (e) {
    if (e.message !== 'LineItemOperationError.NOT_APPLICABLE') {
      toaster.danger(e.message, {
        id: 'unsuccessful-save-payment-data',
      });
      console.log('Something went wrong', e.message);
    } else if (e.message == 'LineItemOperationError.NOT_APPLICABLE') {
      toaster.danger('LineItemOperationError.NOT_APPLICABLE', {
        id: 'unsuccessful-save-payment-data',
      });
      console.log('Something went wrong', e.message);
    }
  }
}

export function* copyLineItemsRequest(params) {
  try {
    const { lineItemIds, count, withCreative, callback } = params.payload;
    const token = yield select(({ session }) => session.token);

    const response = yield call(
      CopyLineItem,
      token,
      lineItemIds,
      Number(count),
      withCreative,
    );

    if (response) {
      callback(response[0].name, response[0]);
    }
  } catch (e) {
    toaster.danger(e.message, {
      id: 'unsuccessful-save-payment-data',
    });
    console.log('Something went wrong', e.message);
  }
}

export function* updateLineItemName(params) {
  try {
    const { id, name, callback } = params.payload;
    const token = yield select(({ session }) => session.token);
    const { lines_items } = yield select(({ admanager }) => admanager);

    const response = yield call(EditLineItemNameById, token, id, name);

    if (response) {
      callback();
      toaster.success('Line Item was successfully renamed!');
      const newLineItemsArray = lines_items.map((el) => {
        if (el.id == id) {
          return { ...el, name: name };
        }
        return el;
      });
      yield put({
        type: actionTypes.SET_ONLY_LINE_ITEMS,
        payload: newLineItemsArray,
      });
    }
  } catch (e) {
    if (e.message !== 'UniqueError.NOT_UNIQUE') {
      toaster.danger(e.message, {
        id: 'unsuccessful-save-payment-data',
      });
      console.log('Something went wrong', e.message);
    } else if (e.message == 'UniqueError.NOT_UNIQUE') {
      toaster.danger('Line item already exists with the same name!', {
        id: 'unsuccessful-save-payment-data32321',
      });
      console.log('Something went wrong', e.message);
    }
  }
}

export function* getTargetedLocationsForLineItem(params) {
  try {
    const { lineItemId } = params.payload;
    const token = yield select(({ session }) => session.token);

    const response = yield call(
      GetTargetedLocationsForLineItems,
      token,
      lineItemId,
    );

    yield put({
      type: actionTypes.SET_TARGETED_LOCATIONS_FOR_LINE_ITEM,
      payload: response,
    });
  } catch (e) {
    toaster.danger(e.message, {
      id: 'unsuccessful-save-payment-data',
    });
    console.log('Something went wrong', e.message);
  }
}

export default function* publisheraga() {
  yield takeLatest(actionTypes.CREATE_COMPANY_REQUEST, createCompanyRequest);
  yield takeLatest(
    actionTypes.GET_EXTRA_INFO_FOR_LINE_ITEMS_REQUEST_AD_UNITS,
    getExtraInfoForLineItemsRequestAdUnits,
  );
  yield takeLatest(
    actionTypes.GET_EXTRA_INFO_FOR_LINE_ITEMS_REQUEST_LOCATIONS,
    getExtraInfoForLineItemsRequestLocations,
  );
  yield takeLatest(
    actionTypes.GET_EXTRA_INFO_FOR_LINE_ITEMS_REQUEST_DEVICES,
    getExtraInfoForLineItemsRequestDevices,
  );
  yield takeLatest(actionTypes.CREATE_LINE_ITEM_REQUEST, createLineItemRequest);
  yield takeLatest(actionTypes.GET_LINES_ITEM, getLinesItem);
  yield takeLatest(
    actionTypes.ADD_CREATIVE_DATA_FOR_LINE_ITEM,
    addCreativeDataForLineItem,
  );
  yield takeLatest(actionTypes.EDIT_LINE_ITEM_DATA, editLineItemData);
  yield takeLatest(
    actionTypes.GET_CREATIVE_FOR_LINE_ITEM,
    getCreativeForLineItem,
  );
  yield takeLatest(actionTypes.UPDATE_CREATIVE_DATA, updateCreativeData);
  yield takeLatest(actionTypes.GET_GAM_REPORT_DATA, getGamReportData);
  yield takeLatest(actionTypes.GET_HOST_POLICY_ISSUES, getPolicyIssues);
  yield takeLatest(actionTypes.DELETE_CREATIVE_ITEM, deleteCreativeItem);
  yield takeLatest(actionTypes.CHANGE_LINE_ITEM_STATE, changeLineItemState);
  yield takeLatest(actionTypes.COPY_LINE_ITEMS_REQUEST, copyLineItemsRequest);
  yield takeLatest(actionTypes.UPDATE_LINE_ITEM_NAME, updateLineItemName);
  yield takeLatest(
    actionTypes.GET_TARGETED_LOCATIONS_FOR_LINE_ITEM,
    getTargetedLocationsForLineItem,
  );
}
