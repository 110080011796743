import { call, put, select, takeLatest } from 'redux-saga/effects';
import dayjs from 'dayjs';
import { toaster } from 'evergreen-ui';

import {
  getSubscribedEmailsByHost,
  getSubscribedEmailsByApiKey,
  getNewslettersSubscribersTopByFilter,
  getNewslettersSubscribersTopByFilterByApiKey,
} from '@/services/api/analytics/newsletters';

import { actionTypes } from '@/services/actions/analytics/newsletters';
import { initialState } from '@/services/reducers/analytics/newsletters';

export function* loadSubscribedEmailsByHost() {
  try {
    const { token } = yield select(({ session }) => session);
    const apiKey = yield select(({ session }) => session.apiKey);
    const { host, dateRange } = yield select(({ filter }) => filter);

    const result = yield call(
      host ? getSubscribedEmailsByHost : getSubscribedEmailsByApiKey,
      token,
      dayjs.utc(dayjs(dateRange[0])).startOf('day').unix(),
      dayjs.utc(dayjs(dateRange[1])).endOf('day').unix(),
      host || apiKey,
    );
    yield put({
      type: actionTypes.GET_SUBSCRIBED_EMAIL_BY_HOST_SUCCESS,
      payload: result || initialState.data,
    });
  } catch (e) {
    console.log('err', e);
    toaster.danger('Error fetching newsletter subscribers', {
      id: 'analytics-newsletter-subscribers',
    });
  }
}

export function* loadTopCountries(params) {
  try {
    const { token, apiKey } = yield select(({ session }) => session);
    const { host, dateRange } = yield select(({ filter }) => filter);
    const { count } = params.payload;

    const result = yield call(
      host
        ? getNewslettersSubscribersTopByFilter
        : getNewslettersSubscribersTopByFilterByApiKey,
      token,
      dayjs.utc(dayjs(dateRange[0])).startOf('day').unix(),
      dayjs.utc(dayjs(dateRange[1])).endOf('day').unix(),
      host || apiKey,
      'Country',
      0,
      count,
    );

    yield put({
      type: actionTypes.GET_TOP_COUNTRIES_SUCCESS,
      payload: result || initialState.top_countries,
    });
  } catch (e) {
    console.log('err', e);
    toaster.danger('Error fetching top countries', {
      id: 'analytics-top-countries',
    });
  }
}

export function* loadTopCities(params) {
  try {
    const { token, apiKey } = yield select(({ session }) => session);
    const { host, dateRange } = yield select(({ filter }) => filter);
    const { count } = params.payload;

    const result = yield call(
      host
        ? getNewslettersSubscribersTopByFilter
        : getNewslettersSubscribersTopByFilterByApiKey,
      token,
      dayjs.utc(dayjs(dateRange[0])).startOf('day').unix(),
      dayjs.utc(dayjs(dateRange[1])).endOf('day').unix(),
      host || apiKey,
      'City',
      0,
      count,
    );
    yield put({
      type: actionTypes.GET_TOP_CITIES_SUCCESS,
      payload: result || initialState.top_cities,
    });
  } catch (e) {
    console.log('err', e);
    toaster.danger('Error fetching top cities', { id: 'analytics-top-cities' });
  }
}

export function* loadTopOss(params) {
  try {
    const { token, apiKey } = yield select(({ session }) => session);
    const { host, dateRange } = yield select(({ filter }) => filter);
    const { count } = params.payload;

    const result = yield call(
      host
        ? getNewslettersSubscribersTopByFilter
        : getNewslettersSubscribersTopByFilterByApiKey,
      token,
      dayjs.utc(dayjs(dateRange[0])).startOf('day').unix(),
      dayjs.utc(dayjs(dateRange[1])).endOf('day').unix(),
      host || apiKey,
      'Os',
      0,
      count,
    );

    yield put({
      type: actionTypes.GET_TOP_OSS_SUCCESS,
      payload: result || initialState.top_oss,
    });
  } catch (e) {
    console.log('err', e);
    toaster.danger('Error fetching top OS', { id: 'analytics-top-os' });
  }
}

export function* loadTopBrowsers(params) {
  try {
    const { token, apiKey } = yield select(({ session }) => session);
    const { host, dateRange } = yield select(({ filter }) => filter);
    const { count } = params.payload;

    const result = yield call(
      host
        ? getNewslettersSubscribersTopByFilter
        : getNewslettersSubscribersTopByFilterByApiKey,
      token,
      dayjs.utc(dayjs(dateRange[0])).startOf('day').unix(),
      dayjs.utc(dayjs(dateRange[1])).endOf('day').unix(),
      host || apiKey,
      'Browser',
      0,
      count,
    );

    yield put({
      type: actionTypes.GET_TOP_BROWSERS_SUCCESS,
      payload: result || initialState.top_browsers,
    });
  } catch (e) {
    console.log('err', e);
    toaster.danger('Error fetching top browsers', {
      id: 'analytics-top-browsers',
    });
  }
}

export function* loadTopDevices(params) {
  try {
    const { token, apiKey } = yield select(({ session }) => session);
    const { host, dateRange } = yield select(({ filter }) => filter);
    const { count } = params.payload;

    const result = yield call(
      host
        ? getNewslettersSubscribersTopByFilter
        : getNewslettersSubscribersTopByFilterByApiKey,
      token,
      dayjs.utc(dayjs(dateRange[0])).startOf('day').unix(),
      dayjs.utc(dayjs(dateRange[1])).endOf('day').unix(),
      host || apiKey,
      'Device',
      0,
      count,
    );

    yield put({
      type: actionTypes.GET_TOP_DEVICES_SUCCESS,
      payload: result || initialState.top_devices,
    });
  } catch (e) {
    console.log('err', e);
    toaster.danger('Error fetching top devices', {
      id: 'analytics-top-devices',
    });
  }
}

export default function* newslettersSaga() {
  yield takeLatest(
    actionTypes.GET_SUBSCRIBED_EMAIL_BY_HOST_REQUEST,
    loadSubscribedEmailsByHost,
  );

  yield takeLatest(actionTypes.GET_TOP_COUNTRIES_REQUEST, loadTopCountries);
  yield takeLatest(actionTypes.GET_TOP_CITIES_REQUEST, loadTopCities);
  yield takeLatest(actionTypes.GET_TOP_OSS_REQUEST, loadTopOss);
  yield takeLatest(actionTypes.GET_TOP_BROWSERS_REQUEST, loadTopBrowsers);
  yield takeLatest(actionTypes.GET_TOP_DEVICES_REQUEST, loadTopDevices);
}
