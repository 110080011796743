import { call, put, select, takeLatest } from 'redux-saga/effects';

import { getNotificationsByHost } from '@/services/api/notifications';

import { actionTypes } from '@/services/actions/notifications';

export function* getNotifications(params) {
  try {
    const { host, from, to } = params.payload;

    const sessionStore = yield select(({ session }) => session);
    const { token, apiKey } = sessionStore;
    const data = yield call(getNotificationsByHost, token, host, from, to);
    yield put({
      type: actionTypes.SET_NOTIFICATIONS,
      payload: data,
    });
    // }
  } catch (e) {
    console.log('err', e);
    yield put({
      type: actionTypes.SET_ERROR,
      payload: e.message,
    });
  }
}

export default function* notificationsSaga() {
  yield takeLatest(actionTypes.GET_NOTIFICATIONS_BY_HOST, getNotifications);
}
