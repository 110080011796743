import { actionTypes } from '@/services/actions/revenue';

// ==== 🗄️ Reducer
export const initialState = {
  revenue: [],
  revenue_loading: false,

  revenue_reporting_filter: [],
  revenue_reporting_filter_loading: false,

  revenue_reporting: [],
  revenue_reporting_filters: [],
  revenue_reporting_loading: false,

  revenue_by_date_adunits: [],
  revenue_by_date_adunits_loading: false,
  revenue_by_ad_units_loading: false,

  revenue_traffic: [],
  revenue_traffic_totals: {},
  revenue_traffic_domains: [],
  revenue_traffic_difference: {},
  revenue_traffic_loading: false,

  revenue_device: [],
  revenue_device_loading: false,
  revenue_category: [],
  revenue_category_loading: false,

  revenue_ad_units_list: [],

  payments: [],
  payments_loading: false,
  pageSize: 25,

  company_info: {},

  error: '',
};

export const revenueReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_REVENUE_BY_DOMAIN:
    case actionTypes.LOAD_REVENUE_BY_SINGLEDATE:
    case actionTypes.LOAD_REVENUE_BY_DOMAIN_AND_DATE:
    case actionTypes.LOAD_REVENUE_BY_ADUNITS:
    case actionTypes.LOAD_AGGREGATED_REVENUE: {
      return { ...state, revenue: initialState.revenue };
    }
    case actionTypes.SET_REVENUE: {
      return { ...state, revenue: action.payload };
    }
    case actionTypes.SET_REVENUE_LOADING: {
      return { ...state, revenue_loading: action.payload };
    }
    case actionTypes.SET_ERROR: {
      return { ...state, error: action.payload };
    }
    case actionTypes.CLEAR_ERROR: {
      return { ...state, error: initialState.error };
    }
    case actionTypes.LOAD_REVENUE_BY_DEVICE: {
      return { ...state, revenue_device_loading: true };
    }
    case actionTypes.LOAD_REVENUE_BY_CATEGORY: {
      return { ...state, revenue_category_loading: true };
    }
    case actionTypes.SET_REVENUE_BY_DEVICE: {
      return {
        ...state,
        revenue_device: action.payload,
        revenue_device_loading: false,
      };
    }
    case actionTypes.LOAD_FILTERS: {
      return { ...state, revenue_reporting_filter_loading: true };
    }
    case actionTypes.SET_FILTERS: {
      return {
        ...state,
        revenue_reporting_filter_loading: false,
        revenue_reporting_filter: action.payload,
      };
    }
    case actionTypes.LOAD_REPORTS: {
      return { ...state, revenue_reporting_loading: true };
    }
    case actionTypes.SET_REPORTS: {
      return {
        ...state,
        revenue_reporting_loading: false,
        revenue_reporting: action.payload.records,
        revenue_reporting_filters: action.payload.filters,
      };
    }
    case actionTypes.SET_REVENUE_BY_CATEGORY: {
      return {
        ...state,
        revenue_category: action.payload,
        revenue_category_loading: false,
      };
    }
    case actionTypes.LOAD_PAYMENTS: {
      return { ...state, payments: initialState.payments };
    }
    case actionTypes.SET_PAYMENTS: {
      return { ...state, payments: action.payload };
    }
    case actionTypes.SET_PAYMENTS_LOADING: {
      return { ...state, payments_loading: action.payload };
    }
    case actionTypes.SET_COMPANY_INFO: {
      return { ...state, company_info: action.payload };
    }
    case actionTypes.SET_REVENUE_AD_UNIT_LIST: {
      return { ...state, revenue_ad_units_list: action.payload, revenue_by_ad_units_loading: false };
    }
    case actionTypes.LOAD_REVENUE_AD_UNIT_LIST: {
      return { ...state, revenue_by_ad_units_loading: true };
    }
    case actionTypes.LOAD_REVENUE_BY_DATE_ADUNITS: {
      return { ...state, revenue_by_date_adunits_loading: true };
    }
    case actionTypes.SET_REVENUE_BY_DATE_ADUNITS: {
      return {
        ...state,
        revenue_by_date_adunits_loading: false,
        revenue_by_date_adunits: action.payload,
      };
    }
    case actionTypes.LOAD_REVENUE_BY_TRAFFIC_EXIT:
    case actionTypes.LOAD_REVENUE_BY_TRAFFIC: {
      return { ...state, revenue_traffic_loading: true };
    }
    case actionTypes.SET_REVENUE_BY_TRAFFIC_EXIT:
    case actionTypes.SET_REVENUE_BY_TRAFFIC: {
      return {
        ...state,
        revenue_traffic: action.payload.data,
        revenue_traffic_totals: action.payload.total,
        revenue_traffic_domains: action.payload.domains,
        revenue_traffic_difference: action.payload.difference,
        revenue_traffic_loading: false,
      };
    }

    default:
      return state;
  }
};

export default revenueReducer;
