// ==== 💪 Action Types
export const actionTypes = {
  GET_QUIZ_ANSWERED_ARTICLES_REQUEST: '@analytics/getQuizAnsweredArticlesRequest',
  GET_QUIZ_ANSWERED_ARTICLES_SUCCESS: '@analytics/getQuizAnsweredArticlesSuccess',

  GET_QUIZ_MARKETING_REQUEST: '@analytics/getQuiz-marketing-dataRequest',
  GET_QUIZ_MARKETING_QUESTION_REQUEST: '@analytics/getQuiz-marketing-question-dataRequest',
  GET_QUIZ_MARKETING_QUESTION_SUCCESS: '@analytics/getQuiz-marketing-question-dataSuccess',
  GET_QUIZ_MARKETING_TOPIC_SUCCESS: '@analytics/getQuiz-marketing-topic-dataSuccess',

  GET_QUIZ_PER_ARTICLES_REQUEST: '@analytics/getQuizPerArticlesRequest',
  GET_QUIZ_PER_ARTICLES_SUCCESS: '@analytics/getQuizPerArticlesSuccess',

  GET_QUIZ_ANSWERED_QUESTIONS_REQUEST: '@analytics/getQuizAnsweredQuestionsRequest',
  GET_QUIZ_ANSWERED_QUESTIONS_SUCCESS: '@analytics/getQuizAnsweredQuestionsSuccess',

  GET_TOP_QUIZ_ANSWERS_REQUEST: '@analytics/getTopQuizAnswersRequest',
  GET_TOP_QUIZ_ANSWERS_SUCCESS: '@analytics/getTopQuizAnswersSuccess',

  GET_TOP_COUNTRIES_REQUEST: '@analytics/quiz/load-top-countries',
  GET_TOP_COUNTRIES_SUCCESS: '@analytics/quiz/set-top-countries',

  GET_TOP_OSS_REQUEST: '@analytics/quiz/load-top-oss',
  GET_TOP_OSS_SUCCESS: '@analytics/quiz/set-top-oss',

  GET_TOP_BROWSERS_REQUEST: '@analytics/quiz/load-top-browsers',
  GET_TOP_BROWSERS_SUCCESS: '@analytics/quiz/set-top-browsers',

  GET_TOP_DEVICES_REQUEST: '@analytics/quiz/load-top-devices',
  GET_TOP_DEVICES_SUCCESS: '@analytics/quiz/set-top-devices',

};

// ==== 🎬 Actions
export const getQuizAnsweredArticles = () => ({
  type: actionTypes.GET_QUIZ_ANSWERED_ARTICLES_REQUEST,
});

export const getQuizMarketingQuestionData = (topic) => ({
  type: actionTypes.GET_QUIZ_MARKETING_QUESTION_REQUEST,
  payload: { topic},
});

export const getQuizMarketingTopicData = () => ({
  type: actionTypes.GET_QUIZ_MARKETING_REQUEST,
});

export const getQuizAnsweredQuestions = () => ({
  type: actionTypes.GET_QUIZ_ANSWERED_QUESTIONS_REQUEST,
});

export const getQuizPerArticle = () => ({
  type: actionTypes.GET_QUIZ_PER_ARTICLES_REQUEST,
});

export const getTopQuizAnswers = () => ({
  type: actionTypes.GET_TOP_QUIZ_ANSWERS_REQUEST,
  payload: {},
});

export const loadTopCountries = (count = 500) => ({
  type: actionTypes.GET_TOP_COUNTRIES_REQUEST,
  payload: {count},
});

export const loadTopOss = (count = 500) => ({
  type: actionTypes.GET_TOP_OSS_REQUEST,
  payload: {count},
});

export const loadTopBrowsers = (count = 500) => ({
  type: actionTypes.GET_TOP_BROWSERS_REQUEST,
  payload: {count},
});

export const loadTopDevices = (count = 500) => ({
  type: actionTypes.GET_TOP_DEVICES_REQUEST,
  payload: {count},
});

