import { actionTypes } from '@/services/actions/analytics/bookmarks';
import { filterDevices, filterUnknown } from '@/utils';

// ==== 🗄️ Reducer
export const initialState = {
  data: [],
  data_loading: false,

  top_countries: {},
  top_countries_loading: false,

  top_cities: {},
  top_cities_loading: false,

  top_oss: {},
  top_oss_loading: false,

  top_browsers: {},
  top_browsers_loading: false,

  top_devices: {},
  top_devices_loading: false,
};

export const getBookmarksReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_BOOKMARKED_ARTICLES_REQUEST: {
      return { ...state, data: state.data, data_loading: true };
    }
    case actionTypes.GET_BOOKMARKED_ARTICLES_SUCCESS: {
      return { ...state, data: action.payload, data_loading: false };
    }

    case actionTypes.GET_TOP_COUNTRIES_REQUEST: {
      return {
        ...state,
        top_countries: initialState.top_countries,
        top_countries_loading: true,
      };
    }
    case actionTypes.GET_TOP_COUNTRIES_SUCCESS: {
      return {
        ...state,
        top_countries: filterUnknown(action.payload),
        top_countries_loading: false,
      };
    }

    case actionTypes.GET_TOP_CITIES_REQUEST: {
      return {
        ...state,
        top_cities: initialState.top_cities,
        top_cities_loading: true,
      };
    }
    case actionTypes.GET_TOP_CITIES_SUCCESS: {
      return {
        ...state,
        top_cities: filterUnknown(action.payload),
        top_cities_loading: false,
      };
    }

    case actionTypes.GET_TOP_OSS_REQUEST: {
      return { ...state, top_oss: initialState.top_oss, top_oss_loading: true };
    }
    case actionTypes.GET_TOP_OSS_SUCCESS: {
      return {
        ...state,
        top_oss: filterUnknown(action.payload),
        top_oss_loading: false,
      };
    }

    case actionTypes.GET_TOP_BROWSERS_REQUEST: {
      return {
        ...state,
        top_browsers: initialState.top_browsers,
        top_browsers_loading: true,
      };
    }
    case actionTypes.GET_TOP_BROWSERS_SUCCESS: {
      return {
        ...state,
        top_browsers: filterUnknown(action.payload),
        top_browsers_loading: false,
      };
    }

    case actionTypes.GET_TOP_DEVICES_REQUEST: {
      return {
        ...state,
        top_devices: initialState.top_devices,
        top_devices_loading: true,
      };
    }
    case actionTypes.GET_TOP_DEVICES_SUCCESS: {
      return {
        ...state,
        top_devices: filterDevices(action.payload),
        top_devices_loading: false,
      };
    }
    default:
      return state;
  }
};

export default getBookmarksReducer;
