import axios from 'axios';
import { toaster } from 'evergreen-ui';

export const getQuizQuestions = (
  token,
  host,
  from,
  to,
  start,
  pageSize,
  filters,
) =>
  axios
    .post(
      `${process.env.API_BASE_URL}api/v1/Quiz/GetQuizQuestionsByPage`,
      { host, from, to, start, pageSize, filters },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;
      if (success) {
        return data;
      } else {
        toaster.danger(errors[0] ? errors[0] : 'Something went wrong');
        return;
      }
      throw new Error('Not valid details');
    });

export const getQuizFlaggedQuestions = (
  token,
  host,
  from,
  to,
  start,
  pageSize,
) =>
  axios
    .post(
      `${process.env.API_BASE_URL}api/v1/Quiz/GetQuizAiFlaggedQuestions`,
      { host, from, to, start, pageSize },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;
      if (success) {
        return data;
      } else {
        toaster.danger(errors[0] ? errors[0] : 'Something went wrong');
        return;
      }
      throw new Error('Not valid details');
    });

export const getQuizDefaultQuestions = (token, host) =>
  axios
    .get(
      `${process.env.API_BASE_URL}api/v1/Quiz/GetQuizDefaultQuestions?host=${host}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success } = response.data;
      if (success) {
        return data;
      } else {
        toaster.danger(errors[0] ? errors[0] : 'Something went wrong');
        return;
      }
      throw new Error('Not valid details');
    });

export const getQuizBrandQuestions = (token, host) =>
  axios
    .get(
      `${process.env.API_BASE_URL}api/v1/Quiz/GetQuizBrandQuestions?host=${host}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success } = response.data;
      if (success) {
        return data;
      } else {
        toaster.danger(errors[0] ? errors[0] : 'Something went wrong');
        return;
      }
      throw new Error('Not valid details');
    });

export const getQuizQuestionsById = (token, host, articleId) =>
  axios
    .get(
      `${process.env.API_BASE_URL}api/v1/Quiz/GetQuizQuestionsByArticleId?host=${host}&articleId=${articleId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success } = response.data;
      if (success) {
        return data;
      } else {
        toaster.danger(errors[0] ? errors[0] : 'Something went wrong');
        return;
      }
      throw new Error('Not valid details');
    });

export const getLanguageCodesApi = () =>
  axios.get(`${process.env.API_BASE_URL}LanguageCodes`).then((response) => {
    return response.data;
    throw new Error('Not valid details');
  });

export const editQuizQuestion = (token, question) =>
  axios
    .put(
      `${process.env.API_BASE_URL}api/v1/Quiz/EditQuizQuestion`,
      {
        question,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;
      if (success) {
        return data;
      } else {
        toaster.danger(errors[0] ? errors[0] : 'Something went wrong');
        return;
      }
      throw new Error('not valid details');
    });

export const editQuizBrandQuestion = (token, question) =>
  axios
    .put(
      `${process.env.API_BASE_URL}api/v1/Quiz/UpdateQuizBrandQuestion`,
      {
        ...question,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      return response.data;
    });

export const createQuizQuestion = (token, question) =>
  axios
    .put(
      `${process.env.API_BASE_URL}api/v1/Quiz/CreateQuizQuestion`,
      {
        question,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;
      if (success) {
        return data;
      } else {
        toaster.danger(errors[0] ? errors[0] : 'Something went wrong');
        return;
      }
      throw new Error('not valid details');
    });

export const createQuizDefaultQuestion = (token, question) =>
  axios
    .post(
      `${process.env.API_BASE_URL}api/v1/Quiz/CreateQuizDefaultQuestion`,
      {
        question,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;
      if (success) {
        return data;
      } else {
        toaster.danger(errors[0] ? errors[0] : 'Something went wrong');
        return;
      }
      throw new Error('not valid details');
    });

export const createQuizBrandQuestion = (token, question, brandDetails) =>
  axios
    .post(
      `${process.env.API_BASE_URL}api/v1/Quiz/CreateQuizBrandQuestion`,
      {
        question,
        brandDetails,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;
      if (success) {
        return data;
      } else {
        throw new Error(errors[0] ? errors[0] : 'Something went wrong');
      }
    });

export const deleteQuizQuestion = (token, quizId) =>
  axios
    .delete(
      `${process.env.API_BASE_URL}api/v1/Quiz/DeleteQuizQuestion/${quizId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { success, errors } = response.data;
      if (success) {
        return success;
      } else {
        toaster.danger(errors[0] ? errors[0] : 'Something went wrong');
        return;
      }
      throw new Error('not valid details');
    });

export const deleteDefaultQuizQuestion = (token, quizId) =>
  axios
    .delete(
      `${process.env.API_BASE_URL}api/v1/Quiz/DeleteQuizDefaultQuestion/${quizId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { success, errors } = response.data;
      if (success) {
        return success;
      } else {
        toaster.danger(errors[0] ? errors[0] : 'Something went wrong');
        return;
      }
      throw new Error('not valid details');
    });

export const deleteBrandQuizQuestion = (token, quizId) =>
  axios
    .delete(
      `${process.env.API_BASE_URL}api/v1/Quiz/DeleteQuizBrandQuestion/${quizId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { success, errors } = response.data;
      if (success) {
        return success;
      } else {
        toaster.danger(errors[0] ? errors[0] : 'Something went wrong');
        return;
      }
      throw new Error('not valid details');
    });

export const blockQuizGeneration = (token, host, uri, checkQueryString) =>
  axios
    .post(
      `${process.env.API_BASE_URL}api/v1/Quiz/BlockQuizGeneration`,
      {
        host,
        uri,
        checkQueryString,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;
      if (success) {
        return data;
      } else {
        throw new Error(errors[0]);
      }
    });

export const deleteBlockedUrl = (token, id) =>
  axios
    .delete(
      `${process.env.API_BASE_URL}api/v1/Quiz/BlockQuizGeneration/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { success, errors } = response.data;
      if (success) {
        return success;
      } else {
        toaster.danger(errors[0] ? errors[0] : 'Something went wrong');
        return;
      }
    });

export const getBlockedQuizUris = (token, host) =>
  axios
    .get(
      `${process.env.API_BASE_URL}api/v1/Quiz/GetBlockedQuizUris?host=${host}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success } = response.data;
      if (success) {
        return data;
      } else {
        toaster.danger(errors[0] ? errors[0] : 'Something went wrong');
        return;
      }
    });

export const getGeneratedQuizBrandQuestion = (token, brand) =>
  axios
    .get(
      `${process.env.API_BASE_URL}api/v1/Publishers/GenerateBrandSuggestion?brand=${brand}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      return response;
    });
