export const actionTypes = {
  ADMIN_EMAIL_UPDATE_REQUEST: '@settings/profile/admin-email-update-request',
  ADMIN_EMAIL_UPDATE_SUCCESS: '@settings/profile/admin-email-update-success',
  ADMIN_PASSWORD_UPDATE_REQUEST:
    '@settings/profile/admin-password-update-request',
  ADMIN_PASSWORD_UPDATE_SUCCESS:
    '@settings/profile/admin-password-update-success',
  PROFILE_UPDATE_REQUEST: '@settings/profile/update-request',
  PROFILE_UPDATE_SUCCESS: '@settings/profile/update-success',
  PROFILE_SETTINGS_SET_ERROR: '@settings/profile/settings-set-error',
  PROFILE_SETTINGS_SET_LOADING: '@settings/profile/settings-set-loading',
  SET_ADS_TEXT_MISSING_LINES: '@settings/set-ads-text-missing-lines',
  ADS_TEXT_MISSING_LINES_REQUEST: '@settings/ads-text-missing-lines-request',
};

export const profileUpdateRequest = (name, avatar, allSites) => ({
  type: actionTypes.PROFILE_UPDATE_REQUEST,
  name,
  avatar,
  allSites,
});

export const profileUpdateSuccess = (data) => ({
  type: actionTypes.PROFILE_UPDATE_SUCCESS,
  payload: data,
});

export const adminEmailUpdateRequest = (adminEmail, password) => ({
  type: actionTypes.ADMIN_EMAIL_UPDATE_REQUEST,
  adminEmail,
  password,
});

export const adminEmailUpdateSuccess = (data) => ({
  type: actionTypes.ADMIN_EMAIL_UPDATE_SUCCESS,
  payload: data,
});

export const adminPasswordUpdateRequest = (
  oldPassword,
  newPassword,
  resetPasswords,
) => ({
  type: actionTypes.ADMIN_PASSWORD_UPDATE_REQUEST,
  oldPassword,
  newPassword,
  resetPasswords,
});

export const adminPasswordUpdateSuccess = () => ({
  type: actionTypes.ADMIN_PASSWORD_UPDATE_SUCCESS,
});

export const setProfileSettingsError = () => ({
  type: actionTypes.PROFILE_SETTINGS_SET_ERROR,
});

export const setProfileSettingsLoading = () => ({
  type: actionTypes.PROFILE_SETTINGS_SET_LOADING,
});

export const adsTextMissingLinesRequest = (host) => ({
  type: actionTypes.ADS_TEXT_MISSING_LINES_REQUEST,
  payload: { host },
});
