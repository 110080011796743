export const actionTypes = {
  ADMIN_PROFILE_REQUEST: '@settings/admin-profile-request',
  ADMIN_PROFILE_SUCCESS: '@settings/admin-profile-success',
};

export const getAdminProfileRequest = (host) => ({
  type: actionTypes.ADMIN_PROFILE_REQUEST,
  payload: host
});

export const setAdminProfileData = (data) => ({
  type: actionTypes.ADMIN_PROFILE_SUCCESS,
  payload: data,
});
