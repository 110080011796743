import { call, put, select, takeLatest } from 'redux-saga/effects';
import { toaster } from 'evergreen-ui';
import {
  updateAdminProfile,
  updateAdminProfileByApiKey,
  updateAdminEmail,
  updateAdminPassword,
} from '@/services/api/settings/profile';
import {
  setProfileSettingsError,
  profileUpdateSuccess,
  adminEmailUpdateSuccess,
  adminPasswordUpdateSuccess,
  actionTypes,
} from '@/services/actions/settings/profile';
import * as sessionActions from '@/services/actions/session';
import { getHostAdsTxtMissingLines } from '@/services/api/settings';

export function* updateAdminProfileSaga(action) {
  try {
    const token = yield select(({ session }) => session.token);
    const apiKey = yield select(({ session }) => session.apiKey);
    const user = yield select(({ session }) => session.user);
    const host = yield select(({ filter }) => filter.host);

    yield call(
      action.allSites ? updateAdminProfileByApiKey : updateAdminProfile,
      token,
      action.allSites ? apiKey : host,
      action.name,
      action.avatar,
    );

    if (action.allSites) {
      yield put(
        profileUpdateSuccess({
          name: action.name,
          avatar: action.avatar,
        }),
      );
    }

    // if (action.allSites) {
    yield put(
      sessionActions.setUserProfile({
        ...user,
        name: action.name,
        pictureUrl: action.avatar,
      }),
    );
    // }
    toaster.success('Profile info updated', {
      id: 'successful-admin-profile-update',
    });
  } catch (e) {
    yield put(setProfileSettingsError());
    toaster.danger(
      'Something went wrong with your request! Please try again or contact support',
      { id: 'unsuccessful-admin-profile-update' },
    );
    console.log('Something went wrong', e.message);
  }
}

export function* updateAdminEmailSaga(action) {
  try {
    const token = yield select(({ session }) => session.token);
    const data = yield call(
      updateAdminEmail,
      token,
      action.adminEmail,
      action.password,
    );

    yield put(
      adminEmailUpdateSuccess({
        adminEmail: action.adminEmail,
      }),
    );
    yield put(sessionActions.saveToken(data));
    toaster.success('Email successfully updated', {
      id: 'successful-admin-email-update',
    });
  } catch (e) {
    yield put(setProfileSettingsError());
    if (e.message === 'same_email') {
      toaster.danger('The email you typed is exactly the same', {
        id: 'unsuccessful-email-update',
      });
    } else if (e.message === 'wrong_password') {
      toaster.danger('Wrong password', {
        id: 'unsuccessful-email-update',
      });
    } else if (e.message === 'user_exists_choose_different_email') {
      toaster.danger('Email already exists!', {
        id: 'unsuccessful-email-update',
      });
    } else {
      toaster.danger(
        'Something went wrong with your request! Please try again later.',
        { id: 'unsuccessful-email-update' },
      );
    }
    console.log('Something went wrong', e.message);
  }
}

export function* updateAdminPasswordSaga(action) {
  try {
    const token = yield select(({ session }) => session.token);
    yield call(
      updateAdminPassword,
      token,
      action.oldPassword,
      action.newPassword,
    );

    yield put(adminPasswordUpdateSuccess());
    toaster.success('Password successfully changed', {
      id: 'successful-password-update',
    });

    action.resetPasswords();
  } catch (e) {
    yield put(setProfileSettingsError());
    if (e.message === 'wrong_password') {
      toaster.danger(
        'You entered your current password incorrectly! Please try again.',
        { id: 'unsuccessful-password-update' },
      );
    } else {
      toaster.danger(
        'Something went wrong with your request! Please try again later.',
        { id: 'unsuccessful-password-update' },
      );
    }
    console.log('Something went wrong', e.message);
  }
}

export function* adsTextMissingLinesRequest(params) {
  try {
    const { host } = params.payload;

    const token = yield select(({ session }) => session.token);

    const data = yield call(getHostAdsTxtMissingLines, token, host);

    yield put({
      type: actionTypes.SET_ADS_TEXT_MISSING_LINES,
      payload: data,
    });
  } catch (err) {
    console.log('err', err);
  }
}

export default function* profileSaga() {
  yield takeLatest(actionTypes.PROFILE_UPDATE_REQUEST, updateAdminProfileSaga);
  yield takeLatest(
    actionTypes.ADMIN_EMAIL_UPDATE_REQUEST,
    updateAdminEmailSaga,
  );

  yield takeLatest(
    actionTypes.ADMIN_PASSWORD_UPDATE_REQUEST,
    updateAdminPasswordSaga,
  );
  yield takeLatest(
    actionTypes.ADS_TEXT_MISSING_LINES_REQUEST,
    adsTextMissingLinesRequest,
  );
}
