import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
// Stores
import sessionReducer from '@/services/reducers/session';
import sitesReducer from '@/services/reducers/sites';
import filterReducer from '@/services/reducers/filter';
import moderationReducer from '@/services/reducers/moderation';
import analyticsReducer from '@/services/reducers/analytics';
import realtimeReducer from '@/services/reducers/realtime';
import reportReducer from '@/services/reducers/report';
import revenueReducer from '@/services/reducers/revenue';
import notificationsReducer from '@/services/reducers/notifications';
import settingsReducer from '@/services/reducers/settings';
import adblockManagementReducer from '@/services/reducers/adblockmanagement';
import cloudMessagingReducer from '@/services/reducers/cloudMessaging';
import quizModerationReducer from '@/services/reducers/quizModeration';
import mcmReducer from "@/services/reducers/mcm";
import gamReducer from "@/services/reducers/gam-integration";
import brandSurveyReducer from "@/services/reducers/brand-survey";

// Sagas
import moderationSaga from '@/services/sagas/moderation';
import analyticsSaga from '@/services/sagas/analytics';
import reportSaga from '@/services/sagas/report';
import revenueSaga from '@/services/sagas/revenue';
import sessionSaga from '@/services/sagas/session';
import sitesSaga from '@/services/sagas/sites';
import notificationsSaga from '@/services/sagas/notifications';
import settingsSaga from '@/services/sagas/settings';
import adblockManagementSaga from '@/services/sagas/adblockmanagement';
import publisherReducer from '@/services/reducers/publisher';
import publisheraga from '@/services/sagas/publisher';
import cloudMessagingSaga from '@/services/sagas/cloudMessaging';
import quizModerationSaga from '@/services/sagas/quizModeration';
import mcmSaga from "@/services/sagas/mcm";
import gamSaga from "@/services/sagas/gam-integration";
import brandSurvey from "@/services/sagas/brand-survey";

const rootReducer = combineReducers({
  session: sessionReducer,
  filter: filterReducer,
  sites: sitesReducer,
  moderation: moderationReducer,
  analytics: analyticsReducer,
  realtime: realtimeReducer,
  report: reportReducer,
  revenue: revenueReducer,
  settingsPage: settingsReducer,
  notification: notificationsReducer,
  adblockmanagement: adblockManagementReducer,
  admanager: publisherReducer,
  cloudMessaging: cloudMessagingReducer,
  quizModerate: quizModerationReducer,
  mcm: mcmReducer,
  gam: gamReducer,
  bsr: brandSurveyReducer,
});

export function initializeStore(initialState) {
  const sagaMiddleware = createSagaMiddleware();
  const composeEnhancers = composeWithDevTools({
    trace: true,
  });
  const composeArgs = [sagaMiddleware];

  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(...composeArgs)),
  );

  if (process.env.NODE_ENV === 'development') {
    // sagaMiddleware.run(logActions);
  }

  sagaMiddleware.run(sessionSaga);
  sagaMiddleware.run(sitesSaga);
  sagaMiddleware.run(moderationSaga);
  sagaMiddleware.run(analyticsSaga);
  sagaMiddleware.run(reportSaga);
  sagaMiddleware.run(revenueSaga);
  sagaMiddleware.run(notificationsSaga);
  sagaMiddleware.run(settingsSaga);
  sagaMiddleware.run(adblockManagementSaga);
  sagaMiddleware.run(publisheraga);
  sagaMiddleware.run(cloudMessagingSaga);
  sagaMiddleware.run(quizModerationSaga);
  sagaMiddleware.run(mcmSaga);
  sagaMiddleware.run(gamSaga);
  sagaMiddleware.run(brandSurvey);

  return store;
}

export default initializeStore;
