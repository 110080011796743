import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  createGAMCredentials,
  deleteGAMCredentials,
  editGAMCredentials,
  generateVuukleAudienceSegments,
  getGAMData,
} from '@/services/api/gam-integration';
import { actionTypes } from '@/services/actions/gam-integration';
import { toaster } from 'evergreen-ui';

export function* getGamDataSaga() {
  try {
    const token = yield select(({ session }) => session.token);

    const result = yield call(getGAMData, token);

    yield put({
      type: actionTypes.SET_GAM,
      payload: result.data,
    });
  } catch (e) {
    console.log('err', e);
    yield put({
      type: actionTypes.SET_GAM,
      payload: null,
    });
  }
}

export function* createGAMSaga(action) {
  try {
    const token = yield select(({ session }) => session.token);
    const { networkCode, applicationName } = action.payload;

    const result = yield call(
      createGAMCredentials,
      token,
      networkCode,
      applicationName,
    );

    yield put({
      type: actionTypes.SET_CREATE_GAM,
      payload: result,
    });
  } catch (e) {
    console.log('Error:', e);
  }
}

export function* updateGAMSaga(action) {
  try {
    const token = yield select(({ session }) => session.token);
    const { networkCode, applicationName, id } = action.payload;

    const result = yield call(
      editGAMCredentials,
      token,
      id,
      networkCode,
      applicationName,
    );

    yield put({
      type: actionTypes.SET_UPDATE_GAM,
      payload: result.data,
    });
  } catch (e) {
    console.log('Error:', e);
  }
}

export function* deleteGAMSaga(action) {
  try {
    const token = yield select(({ session }) => session.token);
    const { id } = action.payload;

    const result = yield call(deleteGAMCredentials, token, id);
    if (result.success) {
      yield put({
        type: actionTypes.SET_DELETE_GAM,
        payload: null,
      });
    } else {
      toaster.warning('Something went wrong!');
    }
  } catch (e) {
    console.log('Error:', e);
  }
}

export function* generateVuukleAudienceSegmentsSaga() {
  try {
    const token = yield select(({ session }) => session.token);
    const result = yield call(generateVuukleAudienceSegments, token);
    if (result.success) {
      toaster.success('Successfully added audience segments in GAM');
    } else {
      if (result.errors && result.errors[0]) {
        toaster.warning(result.errors[0]);
      } else {
        toaster.warning('Something went wrong!');
      }
    }
    yield put({
      type: actionTypes.SET_AUDIENCE_SEGMENTS_SUCCESS,
    });
  } catch (e) {
    console.log('Error', e);
  }
}

export default function* gamSaga() {
  yield takeLatest(actionTypes.LOAD_GAM, getGamDataSaga);
  yield takeLatest(actionTypes.LOAD_CREATE_GAM, createGAMSaga);
  yield takeLatest(actionTypes.LOAD_UPDATE_GAM, updateGAMSaga);
  yield takeLatest(actionTypes.LOAD_DELETE_GAM, deleteGAMSaga);
  yield takeLatest(
    actionTypes.SET_AUDIENCE_SEGMENTS_REQUEST,
    generateVuukleAudienceSegmentsSaga,
  );
}
