import { call, put, select, takeLatest, all } from 'redux-saga/effects';
import { toaster } from 'evergreen-ui';
import {
  getAdminProfile,
  getAdminProfileByApiKey,
} from '@/services/api/settings';
import { setAdminProfileData, actionTypes } from '@/services/actions/settings';

import { setProfileSettingsError } from '@/services/actions/settings/profile';
import { setInitialUserListData } from '@/services/actions/settings/comments/userlists';
import { setInitialGeneralSettingsData } from '@/services/actions/settings/comments/general';
import {
  setEmoteAndSharebarData,
  setInitialCommentsSettingsData,
} from '@/services/actions/settings/comments/widget';
import { makeArrayFromOffensiveList } from '@/utils';

import profileSaga from './profile';
import paymentSaga from './payment';
import userManagementSaga from './user';
import commentsSaga from './comments';

export function* fetchAdminProfile(params) {
  try {
    const token = yield select(({ session }) => session.token);
    const host = yield select(({ filter }) => filter.host);
    const hostParam = params.payload;
    const apiKey = yield select(({ session }) => session.apiKey);

    const data = yield call(
      host || hostParam ? getAdminProfile : getAdminProfileByApiKey,
      token,
      host || hostParam || apiKey,
    );

    // Send the data to their respective reducers

    yield put(
      setAdminProfileData({
        adminEmail: data.adminEmail,
        avatar: data.avatar,
        name: data.name,
        adsTxtUpdated: data.adsTxtUpdated,
        adsTxtUrl: data.adsTxtUrl,
        dateOfSiteRegisteration: data.dateOfSiteRegisteration,
        isMcmCompleted: data.isMcmCompleted,
      }),
    );

    yield put(
      setInitialCommentsSettingsData({
        loginTypes: data.loginTypes,
        defaultTotCount: data.defaultTotCount,
        defaultTotInterval: data.toTInterval,
        defaultCommentSorting: data.defaultCommentSorting,
        commentBubbleLoadType: data.commentBubbleLoadType,
        loadCommentBubbleAfter: data.loadCommentBubbleAfter,
        loadCommentBubbleAt: data.loadCommentBubbleAt,
        searchOptions: data.searchOptions,
        hideCommentInputBox: data.hideCommentInputBox,
        commentsPanelEnabled: data.commentsPanelEnabled,
        hideCommentsWidget: data.hideCommentsWidget,
        showCommentCount: data.showCommentCount,
        spamLimit: data.spamLimit,
        toxicityLimit: data.toxicityLimit,
        commentThreadsToDisplay: data.commentThreadsToDisplay,
        darkMode: data.darkMode,
        color: data.color,
        openArticlesInSameTab: data.openArticlesInSameTab,
        talkOfTheTownCommentText: data.talkOfTheTownCommentText,
        emoteHeaderText: data.emoteHeaderText,
        flagThreshold: data.flagThreshold,
        commentsCountToBeLoaded: data.commentsCountToBeLoaded,
        commentsLength: data.commentsLength,
        totWideImages: data.totWideImages,
        emoteVoteText: data.emoteVoteText,
        rteItems: data.rteItems,
        showNewsletterPopUp: data.showNewsletterPopUp,
        pdfEnabled: data.pdfEnabled,
        googleNewsUrl: data.googleNewsUrl,
        instagramUrl: data.instagramUrl,
        enabledFeatures: data.enabledFeatures,
        interactivityHub: data.interactivityHub,
        quizDisabledTopics: data.quizDisabledTopics,
        quizzlyMarketingQuestionsDisabled:
          data.quizzlyMarketingQuestionsDisabled,
        quizBlockedWords: data.quizBlockedWords,
        hideQuizResults: data.hideQuizResults,
        disableQuestionBypass: data.disableQuestionBypass,
        hideDefaultQuestions: data.hideDefaultQuestions,
        quizQuestionsCount: data.quizQuestionsCount,
        defaultQuestionsCount: data.defaultQuestionsCount,
        brandQuestionsIndex: data.brandQuestionsIndex,
        aiQuestionsCount: data.aiQuestionsCount,
        quizLogo: data.quizLogo,
      }),
    );

    yield put(
      setInitialGeneralSettingsData({
        moderation: data.moderation,
        notifyAdmin: data.notifyAdmin,
        notifyModerator: data.notifyModerator,
        objKeywordExactMatch: data.objKeywordExactMatch,
        disableOpenAiCommentScanning: data.disableOpenAiCommentScanning,
        linksToModeration: data.linksToModeration,
        imagesToModeration: data.imagesToModeration,
        emojisToModeration: data.emojisToModeration,
        customCheckbox: data.customPrivacy,
        checkboxText: data.checkboxText,
        privacyPolicyLink: data.privacyPolicyLink,
        privacyPolicyLinkText: data.privacyPolicyLinkText,
        browserNotificationEnabled: data.browserNotificationEnabled,
        disableTOTwebNotification: data.disableTOTwebNotification,
      }),
    );

    yield put(
      setInitialUserListData({
        offensiveEmail: makeArrayFromOffensiveList(data.offensiveEmail),
        offensiveIp: makeArrayFromOffensiveList(data.offensiveIp),
        offensiveKeywords: makeArrayFromOffensiveList(data.offensiveKeywords),
        offensiveUserName: makeArrayFromOffensiveList(data.offensiveUserName),
      }),
    );

    yield put(
      setEmoteAndSharebarData({
        emotesSize: data.emotesSize,
        firstEmoteImg: data.firstEmoteImg,
        firstEmoteName: data.firstEmoteName,
        secondEmoteImg: data.secondEmoteImg,
        secondEmoteName: data.secondEmoteName,
        thirdEmoteImg: data.thirdEmoteImg,
        thirdEmoteName: data.thirdEmoteName,
        fourthEmoteImg: data.fourthEmoteImg,
        fourthEmoteName: data.fourthEmoteName,
        fifthEmoteImg: data.fifthEmoteImg,
        fifthEmoteName: data.fifthEmoteName,
        sixthEmoteImg: data.sixthEmoteImg,
        sixthEmoteName: data.sixthEmoteName,
        powerbarVerticalPosition: data.powerbarVerticalPosition,
        powerbarHorizontalPosition: data.powerbarHorizontalPosition,
        powerbarItems: data.powerbarItems,
        defaultEmote: data.defaultEmote,
        showPBAudioButton: data.showPBAudioButton,
        disablePowerbarEmote: data.disablePowerbarEmote,
        disablePowerbarShowMoreButton: data.disablePowerbarShowMoreButton,
        disablePBCommentIcon: data.disablePBCommentIcon,
        disableBookmarkButton: data.disableBookmarkButton,
        hideRecommendedArticles: data.hideRecommendedArticles,
        disabledEmotes: data.disabledEmotes,
        emoteOrder: data.emoteOrder,
        disabledEmoteLabels: data.disabledEmoteLabels,
        publisherStyles: data.publisherStyles,
      }),
    );
  } catch (e) {
    yield put(setProfileSettingsError());
    // toaster.danger('Could not fetch admin information', {
    //   id: 'unsuccessful-admin-profile',
    // });
    console.log('something went wrong', e.message);
  }
}

export default function* coreSettingsSaga() {
  yield takeLatest(actionTypes.ADMIN_PROFILE_REQUEST, fetchAdminProfile);
  yield all({
    profile: call(profileSaga),
    payment: call(paymentSaga),
    user: call(userManagementSaga),
    comments: call(commentsSaga),
  });
}
