import { call, put, select, takeLatest } from 'redux-saga/effects';
import dayjs from 'dayjs';

import {
  getHostBillingInfo,
  getRevenuesByHostTimeSeries,
  getRevenuesByApiKeyTimeSeries,
  getRevenueByDateByApiKey,
  getRevenueByDateByApiKeyDateWise,
  getPaymentHistoryByApiKey,
  getRevenuesByHost,
  getRevenuesByApiKey,
  getRevenueByAdunitByHostByDate,
  getRevenueByAdunitByApiKeyByDate,
  getAdunitRevenueByAdUnitByHostByDate,
  getAdunitRevenueByAdUnitByApiKeyByDate,
  getFiltesListByHost,
  getFiltesListByApiKey,
  getRevenueByAdunitByDateByApiKey,
  getRefDomainStatsByHostByTime,
  getRefDomainStatsByApiKeyByTime,
  getExitDomainStatsByHostByTime,
  getExitDomainStatsByApiKeyByTime,
  getRevenueByDeviceByApiKey,
  getRevenueByDeviceByHost,
  getRevenueByCategoryByHost,
  getRevenueByCategoryByApiKey,
  getRevenueReportingFilters,
  getRevenueReportingData,
  getRevenuesByOverview,
} from '@/services/api/revenue';

import { actionTypes } from '@/services/actions/revenue';
import { colorArray } from '@/utils';
import map from 'lodash/map';

export function* loadRevenueByDomain(params) {
  try {
    const { host, from, to } = params.payload;

    yield put({
      type: actionTypes.SET_REVENUE_LOADING,
      payload: true,
    });

    const sessionStore = yield select(({ session }) => session);
    const { token, apiKey, isUserAdmin } = sessionStore;

    let result = [];

    if (host) {
      const data = yield call(
        getRevenuesByHostTimeSeries,
        token,
        host,
        from,
        to,
      );

      result = { stats: data[host], total: data.Total };
    } else if (apiKey) {
      const data = yield call(
        getRevenuesByApiKeyTimeSeries,
        token,
        apiKey,
        from,
        to,
        isUserAdmin,
      );

      result = { stats: data.allsites, total: data.Total };
    }

    yield put({
      type: actionTypes.SET_REVENUE,
      payload: result,
    });
  } catch (e) {
    console.log('err', e);
    yield put({
      type: actionTypes.SET_ERROR,
      payload: e.message,
    });
  } finally {
    yield put({
      type: actionTypes.SET_REVENUE_LOADING,
      payload: false,
    });
  }
}

export function* loadAggregatedRevenue(params) {
  try {
    const { fromDate, toDate, groupType, host } = params.payload;

    yield put({
      type: actionTypes.SET_REVENUE_LOADING,
      payload: true,
    });

    const sessionStore = yield select(({ session }) => session);
    const filterStore = yield select(({ filter }) => filter);
    const { token, apiKey } = sessionStore;

    let result = [];

    const data = yield call(
      getRevenuesByOverview,
      token,
      host ? host : null,
      fromDate,
      toDate,
      groupType,
    );

    if (host) {
      result = {
        stats: data[host].map((row) => ({
          ...row,
          statsDayDateObject: new Date(row?.date).getTime(),
        })),
        total: data.Total,
        dataDifference: data.DataDifference[0],
      };
    } else {
      result = {
        stats: data.allsites.map((row) => ({
          ...row,
          statsDayDateObject: new Date(row?.date).getTime(),
        })),
        total: data.Total,
        dataDifference: data.DataDifference[0],
      };
    }

    yield put({
      type: actionTypes.SET_REVENUE,
      payload: result,
    });
  } catch (e) {
    console.log('err', e);
    yield put({
      type: actionTypes.SET_ERROR,
      payload: e.message,
    });
  } finally {
    yield put({
      type: actionTypes.SET_REVENUE_LOADING,
      payload: false,
    });
  }
}

export function* loadReportingFilters() {
  try {
    const { token } = yield select(({ session }) => session);
    const data = yield call(getRevenueReportingFilters, token);
    yield put({
      type: actionTypes.SET_FILTERS,
      payload: data,
    });
  } catch (e) {
    console.log('err', e);
    yield put({
      type: actionTypes.SET_ERROR,
      payload: e.message,
    });
  }
}

export function* loadReportingData(params) {
  try {
    const { token } = yield select(({ session }) => session);
    const { host, dimensions, metrics, from, to, filter, dateGroupType } = params.payload;
    const data = yield call(
      getRevenueReportingData,
      token,
      from,
      to,
      host,
      dimensions,
      metrics,
      dateGroupType,
      filter,
    );
    yield put({
      type: actionTypes.SET_REPORTS,
      payload: data,
    });
  } catch (e) {
    console.log('err', e);
    yield put({
      type: actionTypes.SET_ERROR,
      payload: e.message,
    });
  }
}

export function* loadRevenueByDate(params) {
  try {
    const { from, to } = params.payload;

    yield put({
      type: actionTypes.SET_REVENUE_LOADING,
      payload: true,
    });

    const sessionStore = yield select(({ session }) => session);
    const { token, apiKey } = sessionStore;

    const data = yield call(getRevenueByDateByApiKey, token, apiKey, from, to);

    const result = map(data, (item, key) => ({
      ...item,
      host: key,
    }));

    result.dataDifference = data.DataDifference;

    yield put({
      type: actionTypes.SET_REVENUE,
      payload: { stats: result },
    });
  } catch (e) {
    console.log('err', e);
    yield put({
      type: actionTypes.SET_ERROR,
      payload: e.message,
    });
  } finally {
    yield put({
      type: actionTypes.SET_REVENUE_LOADING,
      payload: false,
    });
  }
}

export function* loadRevenueByDateAndDomain(params) {
  try {
    const { from, to, type } = params.payload;

    yield put({
      type: actionTypes.SET_REVENUE_LOADING,
      payload: true,
    });

    const sessionStore = yield select(({ session }) => session);
    const { token, apiKey } = sessionStore;

    const data = yield call(
      getRevenueByDateByApiKeyDateWise,
      token,
      apiKey,
      from,
      to,
      type,
    );

    const result = map(data, (item) => ({
      ...item.value,
      host: item.key,
      date: item.value.date,
    }));

    result.dataDifference = data.find(
      (elem) => elem.key === 'DataDifference',
    ).value;

    yield put({
      type: actionTypes.SET_REVENUE,
      payload: { stats: result },
    });
  } catch (e) {
    console.log('err', e);
    yield put({
      type: actionTypes.SET_ERROR,
      payload: e.message,
    });
  } finally {
    yield put({
      type: actionTypes.SET_REVENUE_LOADING,
      payload: false,
    });
  }
}

export function* loadRevenueByAdUnits(params) {
  try {
    const { host, from, to } = params.payload;

    yield put({
      type: actionTypes.SET_REVENUE_LOADING,
      payload: true,
    });

    const sessionStore = yield select(({ session }) => session);
    const { token, apiKey } = sessionStore;

    let result = [];

    if (host) {
      const data = yield call(
        getRevenueByAdunitByHostByDate,
        token,
        host,
        from,
        to,
      );

      result = {
        stats: data[host].map((el) => ({
          ...el,
          dateTime: new Date(el.date).getTime(),
        })),
        total: data.total,
        dataDifference: data.DataDifference[0],
      };
    } else if (apiKey) {
      const data = yield call(
        getRevenueByAdunitByApiKeyByDate,
        token,
        apiKey,
        from,
        to,
      );

      result = {
        stats: data.allsites.map((el) => ({
          ...el,
          dateTime: new Date(el.date).getTime(),
        })),
        total: data.total,
        dataDifference: data.dataDifference[0],
      };
    }

    yield put({
      type: actionTypes.SET_REVENUE,
      payload: result,
    });
  } catch (e) {
    console.log('err', e);
    yield put({
      type: actionTypes.SET_ERROR,
      payload: e.message,
    });
  } finally {
    yield put({
      type: actionTypes.SET_REVENUE_LOADING,
      payload: false,
    });
  }
}

export function* loadRevenueAdUnitList() {
  try {
    const { token, apiKey } = yield select(({ session }) => session);
    const { host } = yield select(({ filter }) => filter);

    const data = yield call(
      host ? getFiltesListByHost : getFiltesListByApiKey,
      token,
      host || apiKey,
    );

    yield put({
      type: actionTypes.SET_REVENUE_AD_UNIT_LIST,
      payload: data || [],
    });
  } catch (e) {
    console.log('err', e);
    yield put({
      type: actionTypes.SET_ERROR,
      payload: e.message,
    });
  } finally {
    yield put({
      type: actionTypes.SET_REVENUE_LOADING,
      payload: false,
    });
  }
}

export function* loadAdUnitRevenue(params) {
  try {
    const { adUnit, device, category, from, to, groupType } = params.payload;

    yield put({
      type: actionTypes.SET_REVENUE_LOADING,
      payload: true,
    });

    const { token, apiKey } = yield select(({ session }) => session);
    const { host } = yield select(({ filter }) => filter);

    let result = [];

    if (host) {
      const data = yield call(
        getAdunitRevenueByAdUnitByHostByDate,
        token,
        host,
        adUnit,
        device,
        category,
        from,
        to,
        groupType,
      );
      result = {
        stats: data[host].map((el) => ({
          ...el,
          dateTime: new Date(el.date).getTime(),
        })),
        total: data.total,
        dataDifference: data.DataDifference[0],
      };
    } else if (apiKey) {
      const data = yield call(
        getAdunitRevenueByAdUnitByApiKeyByDate,
        token,
        apiKey,
        adUnit,
        device,
        category,
        from,
        to,
        groupType,
      );
      result = {
        stats: data.allsites.map((el) => ({
          ...el,
          dateTime: new Date(el.date).getTime(),
        })),
        total: data.total,
        dataDifference: data.dataDifference[0],
      };
    }

    yield put({
      type: actionTypes.SET_REVENUE,
      payload: result,
    });
    yield put({
      type: actionTypes.SET_REVENUE_LOADING,
      payload: false,
    });
  } catch (e) {
    console.log('err', e);
    yield put({
      type: actionTypes.SET_ERROR,
      payload: e.message,
    });
  } finally {
    yield put({
      type: actionTypes.SET_REVENUE_LOADING,
      payload: false,
    });
  }
}

export function* loadPaymentHistory() {
  try {
    yield put({
      type: actionTypes.SET_PAYMENTS_LOADING,
      payload: true,
    });

    const sessionStore = yield select(({ session }) => session);
    const { token } = sessionStore;
    // const pageSize = yield select(({ revenue }) => revenue.pageSize);

    const data = yield call(
      getPaymentHistoryByApiKey,
      token,
      '2015-01-01',
      dayjs().format('YYYY-MM-DD'),
      0,
      100,
    );

    const companyData = yield call(getHostBillingInfo, token);

    const totalData = data.total;
    totalData.notes = `Total ${totalData.notes}`;

    const result = [
      totalData,
      ...map(data.items, (item) => ({
        ...item,
        createdTimestamp: item.createdTimestamp * 1000,
        date: dayjs(item.date, 'MM/YYYY'),
      })),
    ];

    yield put({
      type: actionTypes.SET_PAYMENTS,
      payload: result,
    });

    yield put({
      type: actionTypes.SET_COMPANY_INFO,
      payload: companyData,
    });
  } catch (e) {
    console.log('err', e);
    yield put({
      type: actionTypes.SET_ERROR,
      payload: e.message,
    });
  } finally {
    yield put({
      type: actionTypes.SET_PAYMENTS_LOADING,
      payload: false,
    });
  }
}

export function* loadRevenueByDateAdunits(params) {
  try {
    const { from, to } = params.payload;

    const sessionStore = yield select(({ session }) => session);
    const { token } = sessionStore;

    const result = [];

    const data = yield call(getRevenueByAdunitByDateByApiKey, token, from, to);

    Object.keys(data).filter((el) => {
      if (el !== 'DataDifference' && el !== 'Total') {
        result[el] = data[el];
      }
    });

    yield put({
      type: actionTypes.SET_REVENUE_BY_DATE_ADUNITS,
      payload: result,
    });
  } catch (e) {
    console.log('err', e);
    yield put({
      type: actionTypes.SET_ERROR,
      payload: e.message,
    });
  }
}

export function* loadRevenueByTraffic(params) {
  try {
    const { from, to } = params.payload;

    const sessionStore = yield select(({ session }) => session);
    const filterStore = yield select(({ filter }) => filter);
    const { token, apiKey } = sessionStore;
    const { host } = filterStore;

    const data = yield call(
      host ? getRefDomainStatsByHostByTime : getRefDomainStatsByApiKeyByTime,
      token,
      from,
      to,
      host || apiKey,
    );

    let totalPageViews = 0;

    data.siteData
      .filter(
        (el) =>
          el.refDomain !== 'Direct' &&
          el.refDomain !== 'AMP' &&
          el.refDomain !== 'Internal',
      )
      .map((el) => {
        totalPageViews += el.pageViews;
        return el;
      });

    const dataWithPercent = data.siteData
      .filter(
        (el) =>
          el.refDomain !== 'Direct' &&
          el.refDomain !== 'AMP' &&
          el.refDomain !== 'Internal',
      )
      .sort((a, b) => b.pageViews - a.pageViews)
      .map((el, index) => ({
        ...el,
        chartColor: colorArray[index % 5],
        percent: (el.pageViews / totalPageViews) * 100,
      }));

    let totalPageViewsForDomain = 0;

    [data.total.Direct, data.total.AMP, data.total.Internal].map((el) => {
      totalPageViewsForDomain += el.pageViews;
      return el;
    });

    const dataForDomains = [
      data.total.Direct,
      data.total.AMP,
      data.total.Internal,
    ]
      .sort((a, b) => b.pageViews - a.pageViews)
      .map((el, index) => ({
        ...el,
        chartColor: colorArray[index % 5],
        percent: (el.pageViews / totalPageViewsForDomain) * 100,
      }));

    yield put({
      type: actionTypes.SET_REVENUE_BY_TRAFFIC,
      payload: {
        data: dataWithPercent,
        difference: data.diff,
        total: data.total,
        domains: dataForDomains,
      },
    });
  } catch (e) {
    console.log('err', e);
    yield put({
      type: actionTypes.SET_ERROR,
      payload: e.message,
    });
  }
}

export function* loadRevenueByTrafficExit(params) {
  try {
    const { from, to } = params.payload;

    const sessionStore = yield select(({ session }) => session);
    const filterStore = yield select(({ filter }) => filter);
    const { token, apiKey } = sessionStore;
    const { host } = filterStore;

    const data = yield call(
      host ? getExitDomainStatsByHostByTime : getExitDomainStatsByApiKeyByTime,
      token,
      from,
      to,
      host || apiKey,
    );

    let totalPageViews = 0;

    data.siteData
      .filter(
        (el) =>
          el.refDomain !== 'Direct' &&
          el.refDomain !== 'AMP' &&
          el.refDomain !== 'Internal',
      )
      .map((el) => {
        totalPageViews += el.pageViews;
        return el;
      });

    const dataWithPercent = data.siteData
      .filter(
        (el) =>
          el.refDomain !== 'Direct' &&
          el.refDomain !== 'AMP' &&
          el.refDomain !== 'Internal',
      )
      .sort((a, b) => b.pageViews - a.pageViews)
      .map((el, index) => ({
        ...el,
        chartColor: colorArray[index % 5],
        percent: (el.pageViews / totalPageViews) * 100,
      }));

    let totalPageViewsForDomain = 0;

    [data.total.Direct, data.total.AMP, data.total.Internal].map((el) => {
      totalPageViewsForDomain += el.pageViews;
      return el;
    });

    const dataForDomains = [
      data.total.Direct,
      data.total.AMP,
      data.total.Internal,
    ]
      .sort((a, b) => b.pageViews - a.pageViews)
      .map((el, index) => ({
        ...el,
        chartColor: colorArray[index % 5],
        percent: (el.pageViews / totalPageViewsForDomain) * 100,
      }));

    yield put({
      type: actionTypes.SET_REVENUE_BY_TRAFFIC_EXIT,
      payload: {
        data: dataWithPercent,
        difference: data.diff,
        total: data.total,
        domains: dataForDomains,
      },
    });
  } catch (e) {
    console.log('err', e);
    yield put({
      type: actionTypes.SET_ERROR,
      payload: e.message,
    });
  }
}

export function* loadRevenueByDevice(params) {
  try {
    const { from, to, groupType, host } = params.payload;

    const sessionStore = yield select(({ session }) => session);
    // const filterStore = yield select(({ filter }) => filter);
    const { token, apiKey } = sessionStore;

    const data = yield call(
      host ? getRevenueByDeviceByHost : getRevenueByDeviceByApiKey,
      token,
      from,
      to,
      groupType,
      host || apiKey,
    );

    const totalNetRevenue = data.reduce(
      (acc, item) => acc + item.netRevenue,
      0,
    );

    const dataWithPercent = data
      .sort((a, b) => b.netRevenue - a.netRevenue)
      .map((item, index) => ({
        ...item,
        revenuePercent: (item.netRevenue / totalNetRevenue) * 100,
        chartColor: colorArray[index % 5],
      }));

    yield put({
      type: actionTypes.SET_REVENUE_BY_DEVICE,
      payload: dataWithPercent,
    });
  } catch (e) {
    console.log('err', e);
    yield put({
      type: actionTypes.SET_ERROR,
      payload: e.message,
    });
  }
}

export function* loadRevenueByCategory(params) {
  try {
    const { from, to, groupType, host } = params.payload;

    const sessionStore = yield select(({ session }) => session);
    // const filterStore = yield select(({ filter }) => filter);
    const { token, apiKey } = sessionStore;

    const data = yield call(
      host ? getRevenueByCategoryByHost : getRevenueByCategoryByApiKey,
      token,
      from,
      to,
      groupType,
      host || apiKey,
    );

    const totalNetRevenue = data.reduce(
      (acc, item) => acc + item.netRevenue,
      0,
    );

    const dataWithPercent = data
      .sort((a, b) => b.netRevenue - a.netRevenue)
      .map((item, index) => ({
        ...item,
        revenuePercent: (item.netRevenue / totalNetRevenue) * 100,
        chartColor: colorArray[index % 5],
      }));

    yield put({
      type: actionTypes.SET_REVENUE_BY_CATEGORY,
      payload: dataWithPercent,
    });
  } catch (e) {
    console.log('err', e);
    yield put({
      type: actionTypes.SET_ERROR,
      payload: e.message,
    });
  }
}

export default function* revenueSaga() {
  yield takeLatest(actionTypes.LOAD_REVENUE_BY_DOMAIN, loadRevenueByDomain);
  yield takeLatest(actionTypes.LOAD_REVENUE_BY_SINGLEDATE, loadRevenueByDate);
  yield takeLatest(actionTypes.LOAD_AGGREGATED_REVENUE, loadAggregatedRevenue);
  yield takeLatest(
    actionTypes.LOAD_REVENUE_BY_DOMAIN_AND_DATE,
    loadRevenueByDateAndDomain,
  );
  yield takeLatest(actionTypes.LOAD_REVENUE_BY_ADUNITS, loadRevenueByAdUnits);
  yield takeLatest(actionTypes.LOAD_PAYMENTS, loadPaymentHistory);
  yield takeLatest(
    actionTypes.LOAD_REVENUE_AD_UNIT_LIST,
    loadRevenueAdUnitList,
  );
  yield takeLatest(actionTypes.LOAD_AD_UNIT_REVENUE, loadAdUnitRevenue);
  yield takeLatest(
    actionTypes.LOAD_REVENUE_BY_DATE_ADUNITS,
    loadRevenueByDateAdunits,
  );
  yield takeLatest(actionTypes.LOAD_REVENUE_BY_TRAFFIC, loadRevenueByTraffic);
  yield takeLatest(
    actionTypes.LOAD_REVENUE_BY_TRAFFIC_EXIT,
    loadRevenueByTrafficExit,
  );
  yield takeLatest(actionTypes.LOAD_FILTERS, loadReportingFilters);
  yield takeLatest(actionTypes.LOAD_REPORTS, loadReportingData);
  yield takeLatest(actionTypes.LOAD_REVENUE_BY_DEVICE, loadRevenueByDevice);
  yield takeLatest(actionTypes.LOAD_REVENUE_BY_CATEGORY, loadRevenueByCategory);
}
