import { actionTypes } from '@/services/actions/sites';

// ==== 🗄️ Reducer
export const initialState = {
  loading: false,

  site_stats: [],

  totalStats: {
    pageViews: 0,
    comments: 0,
    shares: 0,
    emotes: 0,
    // runChatMessages: 0,
    // runChatOpened: 0,
    // recommendations: 0,
    pendingComments: 0,
    users: 0,
    audio: 0,
  },

  site_stats_comparison: {
    pageViews: 0,
    comments: 0,
    commentViews: 0,
    shares: 0,
    quizAnswers: 0,
    revenue: 0,
    emotes: 0,
    // runChatMessages: 0,
    // runChatOpened: 0,
    // recommendations: 0,
    pendingComments: 0,
    users: 0,
    audio: 0,
  },

  site_last_week_stats_comparison: {
    pageViews: 0,
    comments: 0,
    commentViews: 0,
    quizAnswers: 0,
    revenue: 0,
    shares: 0,
    emotes: 0,
    // runChatMessages: 0,
    // runChatOpened: 0,
    // recommendations: 0,
    pendingComments: 0,
    users: 0,
    audio: 0,
  },

  totalStatsComparison: {
    pageViews: 0,
    comments: 0,
    shares: 0,
    emotes: 0,
    // runChatMessages: 0,
    // runChatOpened: 0,
    // recommendations: 0,
    pendingComments: 0,
    users: 0,
  },

  totalStatsDifference: [
    {
      commentViews: 0,
      comments: 0,
      emotes: 0,
      quizAnswers: 0,
      revenue: 0,
      pageViews: 0,
      // recommends: 0,
      replies: 0,
      // runChatMessages: 0,
      // runChatOpened: 0,
      shares: 0,
      users: 0,
      host: '',
    },
  ],
};

export const sitesReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case actionTypes.SET_LOADING:
      return { ...state, loading: payload };
    case actionTypes.SET_ERROR:
      return { ...state, error: payload };

    case actionTypes.LOAD_SITE_STATS:
      return { ...state, loading: true, site_stats: initialState.site_stats };

    case actionTypes.LOAD_SITE_STATS_COMPARISON:
      return {
        ...state,
        loading: true,
        site_stats_comparison: initialState.site_stats_comparison,
        site_last_week_stats_comparison:
          initialState.site_last_week_stats_comparison,
      };

    case actionTypes.SET_SITE_STATS:
      return { ...state, loading: false, site_stats: payload };

    case actionTypes.SET_SITE_STATS_COMPARISON:
      return { ...state, loading: false, site_stats_comparison: payload };

    case actionTypes.SET_SITE_LAST_WEEK_STATS_COMPARISON:
      return {
        ...state,
        loading: false,
        site_last_week_stats_comparison: payload,
      };

    case actionTypes.SET_TOTAL_STATS:
      return { ...state, totalStats: action.payload };

    case actionTypes.SET_TOTAL_STATS_COMPARISON:
      return { ...state, totalStatsComparison: action.payload };

    case actionTypes.SET_DIFFERENCE_DATA:
      return { ...state, totalStatsDifference: action.payload };

    default:
      return state;
  }
};

export default sitesReducer;
