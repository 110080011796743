import { combineReducers } from 'redux';
import { actionTypes } from '@/services/actions/analytics';
import commentsReducer from './comments';
import emotesReducer from './emotes';
import sharesReducer from './shares';
import recommendationsReducer from './recommendations';
import audioReducer from './audio';
import bookmarksReducer from './bookmarks';
import getSubscribeReducer from './newsletters';
import quizReducer from './quiz';

// ==== 🗄️ Reducer
export const initialState = {
  daily_site_stats: [],
  daily_site_stats_loading: false,

  month_stats: [],
  month_stats_loading: false,
  totalStatsDifference: [
    {
      commentViews: 0,
      quizAnswers: 0,
      revenue: 0,
      comments: 0,
      emotes: 0,
      votes: 0,
      audioClicked: 0,
      pageViews: 0,
      replies: 0,
      shares: 0,
      users: 0,
      refCount: 0,
      host: '',
    },
  ],
};

export const analyticsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_DAILY_SITE_STATS: {
      return {
        ...state,
        daily_site_stats: initialState.daily_site_stats,
        daily_site_stats_loading: true,
      };
    }
    case actionTypes.SET_DAILY_SITE_STATS: {
      return {
        ...state,
        daily_site_stats: action.payload,
        daily_site_stats_loading: false,
      };
    }

    case actionTypes.LOAD_MONTH_STATS: {
      return {
        ...state,
        month_stats: initialState.month_stats,
        month_stats_loading: true,
      };
    }
    case actionTypes.SET_MONTH_STATS: {
      return {
        ...state,
        month_stats: action.payload,
        month_stats_loading: false,
      };
    }

    case actionTypes.LOAD_AGGREGATED_DAILY_SITE_STATS: {
      return {
        ...state,
        daily_site_stats: initialState.daily_site_stats,
        daily_site_stats_loading: true,
      };
    }

    case actionTypes.SET_DIFFERENCE_DATA:
      return { ...state, totalStatsDifference: action.payload };

    default:
      return state;
  }
};

export default combineReducers({
  index: analyticsReducer,
  comments: commentsReducer,
  emotes: emotesReducer,
  recommendations: recommendationsReducer,
  shares: sharesReducer,
  audio: audioReducer,
  subscribe: getSubscribeReducer,
  bookmarks: bookmarksReducer,
  quiz: quizReducer,
});
