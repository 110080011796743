// ==== 💪 Action Types
export const actionTypes = {
  LOAD_FILTERS: "@revenue/load-filters",
  SET_FILTERS: "@revenue/set-filters",

  LOAD_REPORTS: "@revenue/load-reports",
  SET_REPORTS: "@revenue/set-reports",

  SET_ERROR: '@revenue/set-error',
  CLEAR_ERROR: '@revenue/clear-error',

  LOAD_REVENUE_BY_DOMAIN: '@revenue/load-revenue-by-domain',
  LOAD_REVENUE_BY_SINGLEDATE: '@revenue/load-revenue-by-singledate',
  LOAD_REVENUE_BY_DOMAIN_AND_DATE: '@revenue/load-revenue-by-domain-date',
  LOAD_AGGREGATED_REVENUE: '@revenue/load-aggregated-revenue',

  SET_REVENUE: '@revenue/set-revenue',
  SET_REVENUE_LOADING: '@revenue/set-revenue-loading',

  LOAD_PAYMENTS: '@revenue/load-payments',
  LOAD_REVENUE_BY_ADUNITS: '@revenue/load-revenue-by-adunits',
  SET_PAYMENTS: '@revenue/set-payments',
  SET_PAYMENTS_LOADING: '@revenue/set-payments-loading',
  SET_COMPANY_INFO: '@revenue/set-company-info',

  LOAD_REVENUE_AD_UNIT_LIST: '@revenue/load-revenue-ad-unit-list',
  SET_REVENUE_AD_UNIT_LIST: '@revenue/set-revenue-ad-unit-list',
  LOAD_AD_UNIT_REVENUE: '@revenue/load-ad-unit-revenue',
  SET_AD_UNIT_REVENUE: '@revenue/set-ad-unit-revenue',

  LOAD_REVENUE_BY_DATE_ADUNITS: '@revenue/load-revenue-by-date-adunits',
  SET_REVENUE_BY_DATE_ADUNITS: '@revenue/set-revenue-by-date-adunits',

  LOAD_REVENUE_BY_TRAFFIC: '@revenue/load-revenue-by-traffic',
  SET_REVENUE_BY_TRAFFIC: '@revenue/set-revenue-by-traffic',

  LOAD_REVENUE_BY_TRAFFIC_EXIT: '@revenue/load-revenue-by-traffic-exit',
  SET_REVENUE_BY_TRAFFIC_EXIT: '@revenue/set-revenue-by-traffic-exit',

  LOAD_REVENUE_BY_DEVICE: '@revenue/load-revenue-by-device',
  LOAD_REVENUE_BY_CATEGORY: '@revenue/load-revenue-by-category',

  SET_REVENUE_BY_DEVICE: '@revenue/set-revenue-by-device',
  SET_REVENUE_BY_CATEGORY: '@revenue/set-revenue-by-category',
};

export const setError = (error) => ({
  type: actionTypes.SET_ERROR,
  payload: error,
});

export const clearError = () => ({
  type: actionTypes.CLEAR_ERROR,
});

export const loadRevenueByDomain = (host, from, to) => ({
  type: actionTypes.LOAD_REVENUE_BY_DOMAIN,
  payload: {host, from, to},
});

export const loadRevenueByDate = (from, to) => ({
  type: actionTypes.LOAD_REVENUE_BY_SINGLEDATE,
  payload: {from, to},
});

export const loadRevenueByDateByDomain = (from, to, type) => ({
  type: actionTypes.LOAD_REVENUE_BY_DOMAIN_AND_DATE,
  payload: {from, to, type},
});

export const loadAggregatedRevenue = (fromDate, toDate, groupType, host) => ({
  type: actionTypes.LOAD_AGGREGATED_REVENUE,
  payload: {fromDate, toDate, groupType, host},
});

export const loadPaymentHistory = () => ({
  type: actionTypes.LOAD_PAYMENTS,
});

export const loadRevenueByAdUnits = (host, from, to) => ({
  type: actionTypes.LOAD_REVENUE_BY_ADUNITS,
  payload: {host, from, to},
});

export const loadRevenueAdUnitList = () => ({
  type: actionTypes.LOAD_REVENUE_AD_UNIT_LIST,
});

export const setRevenueAdUnitList = (data) => ({
  type: actionTypes.SET_REVENUE_AD_UNIT_LIST,
  payload: {data},
});

export const loadAdUnitRevenue = (adUnit = "", device = "", category = "", from, to, groupType = 1) => ({
  type: actionTypes.LOAD_AD_UNIT_REVENUE,
  payload: {adUnit, device, category, from, to, groupType}
});

export const loadRevenueByDateAdunits = (from, to) => ({
  type: actionTypes.LOAD_REVENUE_BY_DATE_ADUNITS,
  payload: {from, to},
});

export const setRevenueByDateAdunits = (data) => ({
  type: actionTypes.SET_REVENUE_BY_DATE_ADUNITS,
  payload: data,
});

export const loadRevenueByTraffic = (from, to) => ({
  type: actionTypes.LOAD_REVENUE_BY_TRAFFIC,
  payload: {from, to},
});

export const loadRevenueByTrafficExit = (from, to) => ({
  type: actionTypes.LOAD_REVENUE_BY_TRAFFIC_EXIT,
  payload: {from, to},
});

export const loadRevenueByDevice = (from, to, groupType = 1, host) => ({
  type: actionTypes.LOAD_REVENUE_BY_DEVICE,
  payload: {from, to, groupType, host},
});

export const loadRevenueByCategory = (from, to, groupType = 1, host) => ({
  type: actionTypes.LOAD_REVENUE_BY_CATEGORY,
  payload: {from, to, groupType, host},
});

export const getReportingFilters = () => ({
  type: actionTypes.LOAD_FILTERS,
});

export const getRevenueReporting = (host, dimensions, metrics, dateGroupType, from, to, filter) => ({
  type: actionTypes.LOAD_REPORTS,
  payload: { host, dimensions, metrics, dateGroupType, from, to, filter },
});
