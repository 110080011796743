// ==== 💪 Action Types
export const actionTypes = {
  GET_BOOKMARKED_ARTICLES_REQUEST: '@analytics/getBookmarkedArticlesRequest',
  GET_BOOKMARKED_ARTICLES_SUCCESS: '@analytics/getBookmarkedArticlesSuccess',

  GET_TOP_COUNTRIES_REQUEST: '@analytics/bookmarks/load-top-countries',
  GET_TOP_COUNTRIES_SUCCESS: '@analytics/bookmarks/set-top-countries',

  GET_TOP_CITIES_REQUEST: '@analytics/bookmarks/load-top-cities',
  GET_TOP_CITIES_SUCCESS: '@analytics/bookmarks/set-top-cities',

  GET_TOP_OSS_REQUEST: '@analytics/bookmarks/load-top-oss',
  GET_TOP_OSS_SUCCESS: '@analytics/bookmarks/set-top-oss',

  GET_TOP_BROWSERS_REQUEST: '@analytics/bookmarks/load-top-browsers',
  GET_TOP_BROWSERS_SUCCESS: '@analytics/bookmarks/set-top-browsers',

  GET_TOP_DEVICES_REQUEST: '@analytics/bookmarks/load-top-devices',
  GET_TOP_DEVICES_SUCCESS: '@analytics/bookmarks/set-top-devices',

};

// ==== 🎬 Actions
export const getSBookmarkedArticles = () => ({
  type: actionTypes.GET_BOOKMARKED_ARTICLES_REQUEST,
  payload: {},
});

export const loadTopCountries = (count = 500) => ({
  type: actionTypes.GET_TOP_COUNTRIES_REQUEST,
  payload: {count},
});

export const loadTopCities = (count = 500) => ({
  type: actionTypes.GET_TOP_CITIES_REQUEST,
  payload: {count},
});

export const loadTopOss = (count = 500) => ({
  type: actionTypes.GET_TOP_OSS_REQUEST,
  payload: {count},
});

export const loadTopBrowsers = (count = 500) => ({
  type: actionTypes.GET_TOP_BROWSERS_REQUEST,
  payload: {count},
});

export const loadTopDevices = (count = 500) => ({
  type: actionTypes.GET_TOP_DEVICES_REQUEST,
  payload: {count},
});

