import { call, put, select, takeLatest } from 'redux-saga/effects';
import dayjs from 'dayjs';
import { toaster } from 'evergreen-ui';

import {
  getMostQuizAnswers,
  getQuizAnsweredArticles,
  getQuizAnsweredArticlesByFilter,
  getQuizAnsweredPerQuestionInfo,
  getQuizMarketingQuestions,
  getQuizPerArticle,
} from '@/services/api/analytics/quiz';

import { actionTypes } from '@/services/actions/analytics/quiz';
import { initialState } from '@/services/reducers/analytics/quiz';

export function* loadQuizAnsweredArticles() {
  try {
    const { token } = yield select(({ session }) => session);
    const { host, dateRange } = yield select(({ filter }) => filter);

    const result = yield call(
      getQuizAnsweredArticles,
      token,
      dayjs.utc(dayjs(dateRange[0])).startOf('day').unix(),
      dayjs.utc(dayjs(dateRange[1])).endOf('day').unix(),
      host ? host : null,
    );
    yield put({
      type: actionTypes.GET_QUIZ_ANSWERED_ARTICLES_SUCCESS,
      payload: result || initialState.data,
    });
  } catch (e) {
    console.log('err', e);
    toaster.danger('Error fetching quiz answered articles', {
      id: 'analytics-quiz-articles',
    });
  }
}

export function* loadQuizMarketingData() {
  try {
    const { token } = yield select(({ session }) => session);
    const { host, dateRange } = yield select(({ filter }) => filter);

    const result = yield call(
      getQuizMarketingQuestions,
      token,
      dayjs(dateRange[0]).format('YYYY-MM-DD'),
      dayjs(dateRange[1]).format('YYYY-MM-DD'),
      host ? host : null,
      1,
    );
    yield put({
      type: actionTypes.GET_QUIZ_MARKETING_TOPIC_SUCCESS,
      payload: result,
    });
  } catch (e) {
    console.log('err', e);
    toaster.danger('Error fetching quiz marketing data', {
      id: 'analytics-quiz-marketing-data',
    });
  }
}

export function* loadQuizMarketingQuestionData(params) {
  try {
    const { token } = yield select(({ session }) => session);
    const { host, dateRange } = yield select(({ filter }) => filter);
    const { topic } = params.payload;

    const result = yield call(
      getQuizMarketingQuestions,
      token,
      dayjs(dateRange[0]).format('YYYY-MM-DD'),
      dayjs(dateRange[1]).format('YYYY-MM-DD'),
      host ? host : null,
      2,
      topic,
    );
    yield put({
      type: actionTypes.GET_QUIZ_MARKETING_QUESTION_SUCCESS,
      payload: result,
    });
  } catch (e) {
    console.log('err', e);
    toaster.danger('Error fetching quiz marketing data', {
      id: 'analytics-quiz-marketing-data',
    });
  }
}

export function* loadTopQuizAnswers() {
  try {
    const { token } = yield select(({ session }) => session);
    const { host, dateRange } = yield select(({ filter }) => filter);

    const result = yield call(
      getMostQuizAnswers,
      token,
      dayjs.utc(dayjs(dateRange[0])).startOf('day').unix(),
      dayjs.utc(dayjs(dateRange[1])).endOf('day').unix(),
      host ? host : null,
    );
    yield put({
      type: actionTypes.GET_TOP_QUIZ_ANSWERS_SUCCESS,
      payload: result || initialState.answers_data,
    });
  } catch (e) {
    console.log('err', e);
    toaster.danger('Error fetching top quiz answers', {
      id: 'analytics-quiz-answers',
    });
  }
}

export function* loadQuizQuestionAnswers() {
  try {
    const { token } = yield select(({ session }) => session);
    const { host, dateRange } = yield select(({ filter }) => filter);

    const result = yield call(
      getQuizAnsweredPerQuestionInfo,
      token,
      dayjs.utc(dayjs(dateRange[0])).startOf('day').unix(),
      dayjs.utc(dayjs(dateRange[1])).endOf('day').unix(),
      host ? host : null,
    );
    yield put({
      type: actionTypes.GET_QUIZ_ANSWERED_QUESTIONS_SUCCESS,
      payload: result || [],
    });
  } catch (e) {
    console.log('err', e);
    toaster.danger('Error fetching quiz per answers data', {
      id: 'analytics-quiz-answers-data',
    });
  }
}

export function* loadQuizPerArticle() {
  try {
    const { token } = yield select(({ session }) => session);
    const { host, dateRange } = yield select(({ filter }) => filter);

    const result = yield call(
      getQuizPerArticle,
      token,
      dayjs.utc(dayjs(dateRange[0])).startOf('day').unix(),
      dayjs.utc(dayjs(dateRange[1])).endOf('day').unix(),
      host ? host : null,
    );
    yield put({
      type: actionTypes.GET_QUIZ_PER_ARTICLES_SUCCESS,
      payload: result || [],
    });
  } catch (e) {
    console.log('err', e);
    toaster.danger('Error fetching quiz per answers data', {
      id: 'analytics-quiz-answers-data',
    });
  }
}

export function* loadTopCountries(params) {
  try {
    const { token } = yield select(({ session }) => session);
    const { host, dateRange } = yield select(({ filter }) => filter);
    const { count } = params.payload;

    const result = yield call(
      getQuizAnsweredArticlesByFilter,
      token,
      dayjs.utc(dayjs(dateRange[0])).startOf('day').unix(),
      dayjs.utc(dayjs(dateRange[1])).endOf('day').unix(),
      host ? host : null,
      'Country',
      0,
      count,
    );

    yield put({
      type: actionTypes.GET_TOP_COUNTRIES_SUCCESS,
      payload: result || initialState.top_countries,
    });
  } catch (e) {
    console.log('err', e);
    toaster.danger('Error fetching top countries', {
      id: 'analytics-top-countries',
    });
  }
}

export function* loadTopOss(params) {
  try {
    const { token } = yield select(({ session }) => session);
    const { host, dateRange } = yield select(({ filter }) => filter);
    const { count } = params.payload;

    const result = yield call(
      getQuizAnsweredArticlesByFilter,
      token,
      dayjs.utc(dayjs(dateRange[0])).startOf('day').unix(),
      dayjs.utc(dayjs(dateRange[1])).endOf('day').unix(),
      host ? host : null,
      'Os',
      0,
      count,
    );

    yield put({
      type: actionTypes.GET_TOP_OSS_SUCCESS,
      payload: result || initialState.top_oss,
    });
  } catch (e) {
    console.log('err', e);
    toaster.danger('Error fetching top OS', { id: 'analytics-top-os' });
  }
}

export function* loadTopBrowsers(params) {
  try {
    const { token } = yield select(({ session }) => session);
    const { host, dateRange } = yield select(({ filter }) => filter);
    const { count } = params.payload;

    const result = yield call(
      getQuizAnsweredArticlesByFilter,
      token,
      dayjs.utc(dayjs(dateRange[0])).startOf('day').unix(),
      dayjs.utc(dayjs(dateRange[1])).endOf('day').unix(),
      host ? host : null,
      'Browser',
      0,
      count,
    );

    yield put({
      type: actionTypes.GET_TOP_BROWSERS_SUCCESS,
      payload: result || initialState.top_browsers,
    });
  } catch (e) {
    console.log('err', e);
    toaster.danger('Error fetching top browsers', {
      id: 'analytics-top-browsers',
    });
  }
}

export function* loadTopDevices(params) {
  try {
    const { token } = yield select(({ session }) => session);
    const { host, dateRange } = yield select(({ filter }) => filter);
    const { count } = params.payload;

    const result = yield call(
      getQuizAnsweredArticlesByFilter,
      token,
      dayjs.utc(dayjs(dateRange[0])).startOf('day').unix(),
      dayjs.utc(dayjs(dateRange[1])).endOf('day').unix(),
      host ? host : null,
      'Device',
      0,
      count,
    );

    yield put({
      type: actionTypes.GET_TOP_DEVICES_SUCCESS,
      payload: result || initialState.top_devices,
    });
  } catch (e) {
    console.log('err', e);
    toaster.danger('Error fetching top devices', {
      id: 'analytics-top-devices',
    });
  }
}

export default function* quizSaga() {
  yield takeLatest(
    actionTypes.GET_QUIZ_ANSWERED_ARTICLES_REQUEST,
    loadQuizAnsweredArticles,
  );
  yield takeLatest(
    actionTypes.GET_QUIZ_MARKETING_REQUEST,
    loadQuizMarketingData,
  );
  yield takeLatest(
    actionTypes.GET_QUIZ_MARKETING_QUESTION_REQUEST,
    loadQuizMarketingQuestionData,
  );
  yield takeLatest(
    actionTypes.GET_TOP_QUIZ_ANSWERS_REQUEST,
    loadTopQuizAnswers,
  );
  yield takeLatest(
    actionTypes.GET_QUIZ_ANSWERED_QUESTIONS_REQUEST,
    loadQuizQuestionAnswers,
  );
  yield takeLatest(
    actionTypes.GET_QUIZ_PER_ARTICLES_REQUEST,
    loadQuizPerArticle,
  );

  yield takeLatest(actionTypes.GET_TOP_COUNTRIES_REQUEST, loadTopCountries);
  yield takeLatest(actionTypes.GET_TOP_OSS_REQUEST, loadTopOss);
  yield takeLatest(actionTypes.GET_TOP_BROWSERS_REQUEST, loadTopBrowsers);
  yield takeLatest(actionTypes.GET_TOP_DEVICES_REQUEST, loadTopDevices);
}
