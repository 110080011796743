// ==== 💪 Action Types
export const actionTypes = {
  GET_QUIZ_WITH_QUESTION_REQUEST: '@quiz-moderation/get-quiz-question-request',
  GET_QUIZ_WITH_QUESTION_SUCCESS: '@quiz-moderation/get-quiz-question-success',

  GET_QUIZ_FLAGGED_QUESTION_REQUEST: '@quiz-moderation/get-quiz-flagged-question-request',
  GET_QUIZ_FLAGGED_QUESTION_SUCCESS: '@quiz-moderation/get-quiz-flagged-question-success',

  GET_QUIZ_WITH_DEFAULT_QUESTION_REQUEST:
    '@quiz-moderation/get-quiz-default-question-request',
  GET_QUIZ_WITH_DEFAULT_QUESTION_SUCCESS:
    '@quiz-moderation/get-quiz-default-question-success',

  GET_QUIZ_WITH_BRAND_QUESTION_REQUEST:
    '@quiz-moderation/get-quiz-brand-question-request',
  GET_QUIZ_WITH_BRAND_QUESTION_SUCCESS:
    '@quiz-moderation/get-quiz-brand-question-success',

  UPDATE_QUIZ_WITH_QUESTION_REQUEST:
    '@quiz-moderation/update-quiz-question-request',
  UPDATE_QUIZ_WITH_QUESTION_SUCCESS:
    '@quiz-moderation/update-quiz-question-success',

  UPDATE_QUIZ_BRAND_WITH_QUESTION_REQUEST:
    '@quiz-moderation/update-quiz-brand-question-request',
  UPDATE_QUIZ_BRAND_WITH_QUESTION_SUCCESS:
    '@quiz-moderation/update-quiz-brand-question-success',

  CREATE_QUIZ_WITH_QUESTION_REQUEST:
    '@quiz-moderation/create-quiz-question-request',
  CREATE_QUIZ_WITH_QUESTION_SUCCESS:
    '@quiz-moderation/create-quiz-question-success',

  CREATE_QUIZ_DEFAULT_QUESTION_REQUEST:
    '@quiz-moderation/create-quiz-default-question-request',
  CREATE_QUIZ_DEFAULT_QUESTION_SUCCESS:
    '@quiz-moderation/create-quiz-default-question-success',

  CREATE_QUIZ_BRAND_QUESTION_REQUEST:
    '@quiz-moderation/create-quiz-brand-question-request',
  CREATE_QUIZ_BRAND_QUESTION_SUCCESS:
    '@quiz-moderation/create-quiz-brand-question-success',

  GENERATE_QUIZ_BRAND_QUESTION_REQUEST:
    '@quiz-moderation/generate-quiz-brand-question-request',
  GENERATE_QUIZ_BRAND_QUESTION_SUCCESS:
    '@quiz-moderation/generate-quiz-brand-question-success',

  GET_QUIZ_WITH_QUESTION_BY_ID_REQUEST:
    '@quiz-moderation/get-quiz-question-id-request',
  GET_QUIZ_WITH_QUESTION_BY_ID_SUCCESS:
    '@quiz-moderation/get-quiz-question-id-success',

  LANGUAGE_CODES_REQUEST: '@quiz-moderation/get-language-codes-request',
  LANGUAGE_CODES_SUCCESS: '@quiz-moderation/get-language-codes-success',

  DELETE_QUIZ_WITH_QUESTION_REQUEST:
    '@quiz-moderation/update-quiz-question-delete-request',
  DELETE_QUIZ_WITH_QUESTION_SUCCESS:
    '@quiz-moderation/update-quiz-question-delete-success',

  DELETE_QUIZ_WITH_DEFAULT_QUESTION_REQUEST:
    '@quiz-moderation/update-quiz-default-question-delete-request',
  DELETE_QUIZ_WITH_DEFAULT_QUESTION_SUCCESS:
    '@quiz-moderation/update-quiz-default-question-delete-success',

  DELETE_QUIZ_WITH_BRAND_QUESTION_REQUEST:
    '@quiz-moderation/update-quiz-brand-question-delete-request',
  DELETE_QUIZ_WITH_BRAND_QUESTION_SUCCESS:
    '@quiz-moderation/update-quiz-brand-question-delete-success',

  BLOCK_QUIZ_GENERATION_REQUEST:
    '@quiz-moderation/block-quiz-moderation-request',
  BLOCK_QUIZ_GENERATION_SUCCESS:
    '@quiz-moderation/block-quiz-moderation-success',

  DELETE_BLOCKED_QUIZ_GENERATION_REQUEST:
    '@quiz-moderation/delete-blocked-quiz-generation-request',
  DELETE_BLOCKED_QUIZ_GENERATION_SUCCESS:
    '@quiz-moderation/delete-blocked-quiz-generation-success',

  GET_BLOCKED_QUIZ_URIS_REQUEST:
    '@quiz-moderation/get-blocked-quiz-uris-request',
  GET_BLOCKED_QUIZ_URIS_SUCCESS:
    '@quiz-moderation/get-blocked-quiz-uris-success',
};

// ==== 🎬 Actions
export const loadQuizQuestion = (start, pageSize, filters, startFrom0) => ({
  type: actionTypes.GET_QUIZ_WITH_QUESTION_REQUEST,
  payload: { start, pageSize, filters, startFrom0 },
});

export const loadFlaggedQuizQuestion = (start, pageSize, startFrom0) => ({
  type: actionTypes.GET_QUIZ_FLAGGED_QUESTION_REQUEST,
  payload: { start, pageSize, startFrom0 },
});

export const loadQuizDefaultQuestion = () => ({
  type: actionTypes.GET_QUIZ_WITH_DEFAULT_QUESTION_REQUEST,
});

export const loadQuizBrandQuestion = (host) => ({
  type: actionTypes.GET_QUIZ_WITH_BRAND_QUESTION_REQUEST,
  payload: { host },
});

export const updateQuizQuestion = (question) => ({
  type: actionTypes.UPDATE_QUIZ_WITH_QUESTION_REQUEST,
  payload: { question },
});

export const updateQuizBrandQuestion = (question) => ({
  type: actionTypes.UPDATE_QUIZ_BRAND_WITH_QUESTION_REQUEST,
  payload: { question },
});

export const createQuizQuestion = (question) => ({
  type: actionTypes.CREATE_QUIZ_WITH_QUESTION_REQUEST,
  payload: { question },
});

export const createQuizDefaultQuestion = (question) => ({
  type: actionTypes.CREATE_QUIZ_DEFAULT_QUESTION_REQUEST,
  payload: { question },
});

export const createQuizBrandQuestion = (question, brandDetails) => ({
  type: actionTypes.CREATE_QUIZ_BRAND_QUESTION_REQUEST,
  payload: { question, brandDetails },
});

export const generateQuizBrandQuestion = (brand) => ({
  type: actionTypes.GENERATE_QUIZ_BRAND_QUESTION_REQUEST,
  payload: brand,
});

export const loadQuizQuestionsById = (articleId) => ({
  type: actionTypes.GET_QUIZ_WITH_QUESTION_BY_ID_REQUEST,
  payload: { articleId },
});

export const loadLanguageCodes = () => ({
  type: actionTypes.LANGUAGE_CODES_REQUEST,
});

export const deleteQuizQuestion = (quizId) => ({
  type: actionTypes.DELETE_QUIZ_WITH_QUESTION_REQUEST,
  payload: { quizId },
});

export const deleteQuizDefaultQuestion = (quizId) => ({
  type: actionTypes.DELETE_QUIZ_WITH_DEFAULT_QUESTION_REQUEST,
  payload: { quizId },
});

export const deleteQuizBrandQuestion = (quizId) => ({
  type: actionTypes.DELETE_QUIZ_WITH_BRAND_QUESTION_REQUEST,
  payload: { quizId },
});

export const blockQuizGeneration = (uri, checkQueryString) => ({
  type: actionTypes.BLOCK_QUIZ_GENERATION_REQUEST,
  payload: { uri, checkQueryString },
});

export const deleteBlockedUrl = (id) => ({
  type: actionTypes.DELETE_BLOCKED_QUIZ_GENERATION_REQUEST,
  payload: { id },
});

export const getBlockedQuizUris = () => ({
  type: actionTypes.GET_BLOCKED_QUIZ_URIS_REQUEST,
});
