import { actionTypes as indexActions } from '@/services/actions/settings';
import { actionTypes } from '@/services/actions/settings/profile';

const initialState = {
  adminEmail: '',
  avatar: '',
  name: '',
  adsTxtUpdated: false,
  adsTxtUrl: '',
  adsMissingLines: {},
  passwordChanged: false,
  error: false,
  loading: false,
  loadingAds: false,
};

const profileSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADMIN_EMAIL_UPDATE_SUCCESS: {
      return { ...state, loading: false, adminEmail: action.payload.adminEmail };
    }
    case actionTypes.ADMIN_PASSWORD_UPDATE_SUCCESS: {
      return { ...state, loading: false, passwordChanged: true };
    }
    case indexActions.ADMIN_PROFILE_SUCCESS:
    case actionTypes.PROFILE_UPDATE_SUCCESS:
      return { ...state, loading: false, ...action.payload };
    case actionTypes.PROFILE_SETTINGS_SET_LOADING: {
      return { ...state, loading: true };
    }
    case actionTypes.PROFILE_SETTINGS_SET_ERROR: {
      return { ...state, error: true };
    }
    case actionTypes.SET_ADS_TEXT_MISSING_LINES: {
      return { ...state, adsMissingLines: action.payload, loadingAds: false };
    }
    case actionTypes.ADS_TEXT_MISSING_LINES_REQUEST: {
      return { ...state, loadingAds: true };
    }
    default:
      return state;
  }
};

export default profileSettingsReducer;
