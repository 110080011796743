import axios from 'axios';

export const getBlockWordList = (token, host) =>
  axios
    .get(`${process.env.API_BASE_URL}api/v1/Publishers/GetBlockedWords`, {
      params: { host },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      const { success, errors } = response.data;

      if (success) {
        return response.data;
      }

      throw new Error(errors[0]);
    });

export const getTopicsApi = (token) =>
  axios
    .get(`${process.env.API_BASE_URL}api/v1/Articles/GetIabTier`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      throw new Error(errors[0]);
    });

export const updateLoginTypes = (token, host, apiKey, loginTypes) =>
  axios
    .post(
      `${process.env.API_BASE_URL}api/v1/Publishers/updateLoginTypes`,
      {
        host,
        loginTypes,
        apiKey,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      throw new Error(errors[0]);
    });

export const updateLoginTypesByApiKey = (token, host, apiKey, loginTypes) =>
  axios
    .post(
      `${process.env.API_BASE_URL}api/v1/Publishers/updateLoginTypesByApiKey`,
      {
        host,
        loginTypes,
        apiKey,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      throw new Error(errors[0]);
    });

export const updateDefaultTotCount = (token, host, apiKey, defaultTotCount) =>
  axios
    .post(
      `${process.env.API_BASE_URL}api/v1/Publishers/updateDefaultTotCount`,
      {
        host,
        apiKey,
        defaultTotCount,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      throw new Error(errors[0]);
    });

export const updateDefaultTotCountByApiKey = (
  token,
  host,
  apiKey,
  defaultTotCount,
) =>
  axios
    .post(
      `${process.env.API_BASE_URL}api/v1/Publishers/updateDefaultTotCountByApiKey`,
      {
        host,
        apiKey,
        defaultTotCount,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      throw new Error(errors[0]);
    });

export const setTotInterval = (token, host, totInterval) =>
  axios
    .get(`${process.env.API_BASE_URL}api/v1/Publishers/setToTInterval`, {
      params: {
        host,
        totInterval,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      const { data, success } = response.data;

      if (success || response.status === 200) {
        return data || response.data;
      }

      throw new Error('not valid response');
    });

export const setTotIntervalByApiKey = (token, apiKey, totInterval) =>
  axios
    .get(
      `${process.env.API_BASE_URL}api/v1/Publishers/setToTIntervalByApiKey`,
      {
        params: {
          apiKey,
          totInterval,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success } = response.data;

      if (success || response.status === 200) {
        return data;
      }

      throw new Error('not valid response');
    });

export const updateDefaultSorting = (token, host, defaultSorting) =>
  axios
    .post(
      `${process.env.API_BASE_URL}api/v1/Publishers/updateDefaultSorting`,
      {
        host,
        defaultSorting,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { success } = response.data;

      if (success) {
        return success;
      }

      throw new Error('not valid response');
    });

export const updateDefaultSortingByApiKey = (token, apiKey, defaultSorting) =>
  axios
    .post(
      `${process.env.API_BASE_URL}api/v1/Publishers/updateDefaultSortingByApiKey`,
      {
        apiKey,
        defaultSorting,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { success } = response.data;

      if (success) {
        return success;
      }

      throw new Error('not valid response');
    });

export const updateExternalSearch = (token, host, searchOptions) =>
  axios
    .post(
      `${process.env.API_BASE_URL}api/v1/Publishers/updateSearchOptions`,
      {
        host,
        searchOptions,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { success } = response.data;

      if (success) {
        return success;
      }

      throw new Error('not valid response');
    });

export const updateExternalSearchByApiKey = (token, apiKey, searchOptions) =>
  axios
    .post(
      `${process.env.API_BASE_URL}api/v1/Publishers/updateSearchOptionsApiKey`,
      {
        apiKey,
        searchOptions,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { success } = response.data;

      if (success) {
        return success;
      }

      throw new Error('not valid response');
    });

export const setShowCommentCount = (token, host, showCommentCount) =>
  axios
    .put(
      `${process.env.API_BASE_URL}api/v1/Publishers/UpdatePublisherShowCommentCountSettings`,
      {
        host,
        showCommentCount,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { success } = response.data;

      if (success) {
        return success;
      }

      throw new Error('not valid response');
    });

export const setSpamLimit = (token, host, spamLimit) =>
  axios
    .get(`${process.env.API_BASE_URL}api/v1/Publishers/setSpamLimit`, {
      params: {
        host,
        spamLimit,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      const { success } = response.data;

      if (success) {
        return success;
      }

      throw new Error('not valid response');
    });

export const setSpamLimitByApiKey = (token, apiKey, spamLimit) =>
  axios
    .get(`${process.env.API_BASE_URL}api/v1/Publishers/setSpamLimitByApiKey`, {
      params: {
        apiKey,
        spamLimit,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      const { success } = response.data;

      if (success) {
        return success;
      }

      throw new Error('not valid response');
    });

export const setToxicityLimit = (token, host, toxicityLimit) =>
  axios
    .get(`${process.env.API_BASE_URL}api/v1/Publishers/setToxicityLimit`, {
      params: {
        host,
        toxicityLimit,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      const { success } = response.data;

      if (success) {
        return success;
      }

      throw new Error('not valid response');
    });

export const setToxicityLimitByApiKey = (token, apiKey, toxicityLimit) =>
  axios
    .get(
      `${process.env.API_BASE_URL}api/v1/Publishers/setToxicityLimitByApiKey`,
      {
        params: {
          apiKey,
          toxicityLimit,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { success } = response.data;

      if (success) {
        return success;
      }

      throw new Error('not valid response');
    });

export const setColor = (token, host, color) =>
  axios
    .get(`${process.env.API_BASE_URL}api/v1/Publishers/setColor`, {
      params: {
        host,
        color,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      const { success } = response.data;

      if (success) {
        return success;
      }

      throw new Error('not valid response');
    });

export const setColorByApiKey = (token, apiKey, color) =>
  axios
    .get(`${process.env.API_BASE_URL}api/v1/Publishers/setColorByApiKey`, {
      params: {
        apiKey,
        color,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      const { success } = response.data;

      if (success) {
        return success;
      }

      throw new Error('not valid response');
    });

export const setTalkOfTheTownCommentText = (
  token,
  host,
  talkOfTheTownCommentText,
) =>
  axios
    .get(
      `${process.env.API_BASE_URL}api/v1/Publishers/setTalkOfTheTownCommentText`,
      {
        params: {
          host,
          talkOfTheTownCommentText,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { success } = response.data;

      if (success) {
        return success;
      }

      throw new Error('not valid response');
    });

export const setTalkOfTheTownCommentTextByApiKey = (
  token,
  apiKey,
  talkOfTheTownCommentText,
) =>
  axios
    .get(
      `${process.env.API_BASE_URL}api/v1/Publishers/setTalkOfTheTownCommentTextByApiKey`,
      {
        params: {
          apiKey,
          talkOfTheTownCommentText,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { success } = response.data;

      if (success) {
        return success;
      }

      throw new Error('not valid response');
    });

export const setEmoteHeaderText = (token, host, emoteHeaderText) =>
  axios
    .get(`${process.env.API_BASE_URL}api/v1/Publishers/setEmoteHeaderText`, {
      params: {
        host,
        emoteHeaderText,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      const { success } = response.data;

      if (success) {
        return success;
      }

      throw new Error('not valid response');
    });

export const setEmoteHeaderTextByApiKey = (token, apiKey, emoteHeaderText) =>
  axios
    .get(
      `${process.env.API_BASE_URL}api/v1/Publishers/setEmoteHeaderTextByApiKey`,
      {
        params: {
          apiKey,
          emoteHeaderText,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { success } = response.data;

      if (success) {
        return success;
      }

      throw new Error('not valid response');
    });

export const setEmoteVoteText = (token, host, emoteVoteText) =>
  axios
    .get(`${process.env.API_BASE_URL}api/v1/Publishers/setEmoteVoteText`, {
      params: {
        host,
        emoteVoteText,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      const { success } = response.data;

      if (success) {
        return success;
      }

      throw new Error('not valid response');
    });

export const setEmoteVoteTextByApiKey = (token, apiKey, emoteVoteText) =>
  axios
    .get(
      `${process.env.API_BASE_URL}api/v1/Publishers/setEmoteVoteTextByApiKey`,
      {
        params: {
          apiKey,
          emoteVoteText,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { success } = response.data;

      if (success) {
        return success;
      }

      throw new Error('not valid response');
    });

export const updateFlagThreshold = (token, host, apiKey, flagThreshold) =>
  axios
    .post(
      `${process.env.API_BASE_URL}api/v1/Publishers/updateFlagThreshold`,
      {
        host,
        apiKey,
        flagThreshold,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      throw new Error(errors[0]);
    });

export const updateloadedComments = (token, host, loadedComments) =>
  axios
    .get(
      `${process.env.API_BASE_URL}api/v1/Publishers/setLoadVuukleCommentsCount`,
      {
        params: {
          host,
          count: loadedComments,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      throw new Error(errors[0]);
    });

export const updateFlagThresholdByApiKey = (
  token,
  host,
  apiKey,
  flagThreshold,
) =>
  axios
    .post(
      `${process.env.API_BASE_URL}api/v1/Publishers/updateFlagThresholdByApiKey`,
      {
        host,
        apiKey,
        flagThreshold,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      throw new Error(errors[0]);
    });

export const UpdatePublishersEmotesPowerbarSettings = (token, host, params) =>
  axios
    .put(
      `${process.env.API_BASE_URL}api/v1/Publishers/UpdatePublishersEmotesPowerbarSettings`,
      {
        host,
        ...params,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return response.data;
      }

      throw new Error(errors[0]);
    });

export const updateEmotesLabelsSettings = (token, host, disable, emote) =>
  axios
    .put(
      `${process.env.API_BASE_URL}api/v1/Publishers/DisableEmotes`,
      {
        host,
        disable,
        emote,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;
      if (success) {
        return response.data;
      }

      throw new Error(errors[0]);
    });

export const updateEmotesOrderSettings = (token, host, orderedList) =>
  axios
    .put(
      `${process.env.API_BASE_URL}api/v1/Publishers/ChangeEmoteOrder`,
      {
        host,
        orderedList,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;
      if (success) {
        return response.data;
      }

      throw new Error(errors[0]);
    });

export const updateCommentsMaxLength = (token, params) =>
  axios
    .put(
      `${process.env.API_BASE_URL}api/v1/Publishers/updateCommentsMaxLength`,
      {
        ...params,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return response.data;
      }

      throw new Error(errors[0]);
    });
export const UpdateTotWideImages = (token, totWideImages, host) =>
  axios
    .post(
      `${process.env.API_BASE_URL}api/v1/publishers/UpdateTotWideImages`,
      {
        host,
        totWideImages,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      throw new Error(errors[0]);
    });
export const UpdatePublisherRTEoptions = (token, params) =>
  axios
    .put(
      `${process.env.API_BASE_URL}api/v1/Publishers/UpdatePublisherRTEoptions`,
      params,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return response.data;
      }

      throw new Error(errors[0]);
    });
export const SetPublisherPowerbarHorizontalPosition = (token, params) =>
  axios
    .put(
      `${process.env.API_BASE_URL}api/v1/Publishers/SetPublisherPowerbarHorizontalPosition`,
      params,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return response.data;
      }

      throw new Error(errors[0]);
    });
