import { actionTypes } from '@/services/actions/settings/comments/general';

const initialState = {
  getCommentsByUserEmail: [],
  getCommentsByUserEmailLoading: false,
  moderation: false,
  customCheckbox: false,
  notifyAdmin: false,
  notifyModerator: false,
  objKeywordExactMatch: false,
  disableOpenAiCommentScanning: false,
  linksToModeration: false,
  imagesToModeration: false,
  emojisToModeration: false,
  browserNotificationEnabled: false,
  disableTOTwebNotification: true,
  error: false,
  loading: false,
  checkboxText: '',
  privacyPolicyLink: '',
  privacyPolicyLinkText: '',
};

const generalCommentsSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_INITIAL_GENERAL_SETTINGS_DATA:
      return { ...state, ...action.payload };
    case actionTypes.SET_MODERATION_FLAG_SUCCESS:
      return { ...state, loading: false, moderation: action.payload };
    case actionTypes.GET_COMMENTS_BY_USER_EMAIL_REQUEST:
      return {
        ...state,
        getCommentsByUserEmailLoading: true,
      };
    case actionTypes.SET_COMMENTS_BY_USER_EMAIL_SUCCESS:
      return {
        ...state,
        getCommentsByUserEmailLoading: false,
        getCommentsByUserEmail: action.payload,
      };
    case actionTypes.SET_CUSTOM_CHECKBOX_FLAG_SUCCESS:
      return { ...state, loading: false, customCheckbox: action.payload };
    case actionTypes.SET_LINK_MODERATION_FLAG_SUCCESS:
      return { ...state, loading: false, linksToModeration: action.payload };
    case actionTypes.SET_IMAGES_MODERATION_FLAG_SUCCESS:
      return { ...state, loading: false, imagesToModeration: action.payload };
    case actionTypes.SET_EMOGISTO_MODERATION_FLAG_SUCCESS:
      return { ...state, loading: false, emojisToModeration: action.payload };
    case actionTypes.SET_EXACT_BLOCK_WORD_MATCH_SUCCESS:
      return { ...state, loading: false, objKeywordExactMatch: action.payload };
    case actionTypes.SET_DISABLE_OPENAI_SUCCESS:
      return {
        ...state,
        loading: false,
        disableOpenAiCommentScanning: action.payload,
      };
    case actionTypes.SET_NOTIFICATION_FOR_ADMIN_SUCCESS:
      return { ...state, loading: false, notifyAdmin: action.payload };
    case actionTypes.SET_NOTIFICATION_FOR_MODERATOR_SUCCESS:
      return { ...state, loading: false, notifyModerator: action.payload };
    case actionTypes.SET_PRIVACY_POLICY_DATA: {
      return {
        ...state,
        checkboxText: action.payload.checkboxText,
        privacyPolicyLink: action.payload.privacyPolicyLink,
        privacyPolicyLinkText: action.payload.privacyPolicyLinkText,
      };
    }
    case actionTypes.SET_BROWSER_NOTIFICATIONS_SUCCESS: {
      return {
        ...state,
        loading: false,
        browserNotificationEnabled: action.payload,
      };
    }
    case actionTypes.SET_AUTO_NOTIFICATIONS_SUCCESS: {
      return {
        ...state,
        loading: false,
        disableTOTwebNotification: action.payload,
      };
    }
    case actionTypes.SET_MODERATION_FLAG_REQUEST:
    case actionTypes.SET_CUSTOM_CHECKBOX_FLAG_REQUEST:
    case actionTypes.UPDATE_PRIVACY_POLICY_REQUEST:
    case actionTypes.SET_LINK_MODERATION_FLAG_REQUEST:
    case actionTypes.SET_IMAGES_MODERATION_FLAG_REQUEST:
    case actionTypes.SET_EMOGISTO_MODERATION_FLAG_REQUEST:
    case actionTypes.SET_EXACT_BLOCK_WORD_MATCH_REQUEST:
    case actionTypes.SET_DISABLE_OPENAI_REQUEST:
    case actionTypes.SET_NOTIFICATION_FOR_ADMIN_REQUEST:
    case actionTypes.SET_NOTIFICATION_FOR_MODERATOR_REQUEST:
    case actionTypes.SET_AUTO_NOTIFICATIONS_REQUEST:
      return { ...state, loading: true };
    case actionTypes.SET_BROWSER_NOTIFICATIONS_REQUEST:
      return { ...state, loading: true };
    case actionTypes.SET_FLAG_ERROR:
      return { ...state, error: true };
    default:
      return state;
  }
};

export default generalCommentsSettingsReducer;
