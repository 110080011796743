import axios from 'axios';

export const getNotificationsByHost = (token, host, from, to) =>
  axios
    .get(
      `${process.env.API_BASE_URL}api/v1/RTNotifications/getNotificationsByHostByTime`,
      {
        params: { host, from, to },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success } = response.data;

      if (success) {
        return data;
      }

      throw new Error('not valid details');
    });
export const getNotificationsByTimeSeries = (token, from, to) =>
  axios
    .get(
      `${process.env.API_BASE_URL}api/v1/RTNotifications/getNotificationsByTime`,
      {
        params: { from, to },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      throw new Error(errors[0]);
    });
