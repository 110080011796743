// ==== 💪 Action Types
export const actionTypes = {
  SET_ERROR: '@report/error',

  LOAD_SCHEDULES: '@report/load-schedules',
  SET_SCHEDULES: '@report/set-schedules',

  LOAD_ACTIVE_SURVEYS: '@report/load-active-surveys',
  SET_ACTIVE_SURVEYS: '@report/set-active-surveys',

  LOAD_TIMEZONES: '@report/load-timezones',
  SET_TIMEZONES: '@report/set-timezones',
};

// ==== 🎬 Actions
export const loadSchedules = () => ({
  type: actionTypes.LOAD_SCHEDULES,
});

export const loadTimezones = () => ({
  type: actionTypes.LOAD_TIMEZONES,
});

export const loadActiveSurveys = () => ({
  type: actionTypes.LOAD_ACTIVE_SURVEYS,
});
