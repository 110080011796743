import axios from 'axios';

export const getGAMData = (token) =>
  axios
    .get(
      `${process.env.API_BASE_URL}api/v1/Publishers/PublisherGAMCredentials`,
      {
        params: {},
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      return response.data;
    });

export const editGAMCredentials = (token, id, networkCode, applicationName) =>
  axios
    .put(
      `${process.env.API_BASE_URL}api/v1/Publishers/PublisherGAMCredentials`,
      {
        id,
        networkCode,
        applicationName,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      return response.data;
    });

export const createGAMCredentials = (token, networkCode, applicationName) =>
  axios
    .post(
      `${process.env.API_BASE_URL}api/v1/Publishers/PublisherGAMCredentials`,
      {
        networkCode,
        applicationName,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      return response.data;
    });

export const deleteGAMCredentials = (token, id) =>
  axios
    .delete(
      `${process.env.API_BASE_URL}api/v1/Publishers/PublisherGAMCredentials/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      return response.data;
    });

export const generateVuukleAudienceSegments = (token) =>
  axios
    .get(`${process.env.API_BASE_URL}api/v1/GAM/GenerateVuukleAudience`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    });
