// ==== 💪 Action Types
export const actionTypes = {
  LOAD_EMOTE_STATE: '@analytics/emotes/load-emote-state',
  SET_EMOTE_STATE: '@analytics/emotes/set-emote-state',

  SET_EMOTE: '@analytics/emotes/set-emote',

  LOAD_TOP_ARTICLES_WITH_FILTERING: '@analytics/emotes/load-top-articles-with-filtering',
  LOAD_TOP_ARTICLES: '@analytics/emotes/load-top-articles',
  SET_TOP_ARTICLES: '@analytics/emotes/set-top-articles',

  LOAD_TOP_COUNTRIES: '@analytics/emotes/load-top-countries',
  SET_TOP_COUNTRIES: '@analytics/emotes/set-top-countries',

  LOAD_TOP_CITIES: '@analytics/emotes/load-top-cities',
  SET_TOP_CITIES: '@analytics/emotes/set-top-cities',

  LOAD_TOP_OSS: '@analytics/emotes/load-top-oss',
  SET_TOP_OSS: '@analytics/emotes/set-top-oss',

  LOAD_TOP_BROWSERS: '@analytics/emotes/load-top-browsers',
  SET_TOP_BROWSERS: '@analytics/emotes/set-top-browsers',

  LOAD_TOP_DEVICES: '@analytics/emotes/load-top-devices',
  SET_TOP_DEVICES: '@analytics/emotes/set-top-devices',

  LOAD_TOP_ARTICLES_TX: '@analytics/emotes/load-top-articles-tx',
  LOAD_TOP_ARTICLES_WITH_FILTERING_TX: '@analytics/emotes/load-top-articles-with-filtering-tx',

  SET_EMOTE_DATA_DIFFERENCE: '@analytics/emotes/set-emote-data-difference',
};

// ==== 🎬 Actions
export const loadEmoteState = (from, to) => ({
  type: actionTypes.LOAD_EMOTE_STATE,
  payload: {from, to}
});

export const updateEmote = (emote) => ({
  type: actionTypes.SET_EMOTE,
  payload: emote,
});

export const loadTopArticles = (from, to) => ({
  type: actionTypes.LOAD_TOP_ARTICLES,
  payload: {from, to}
});

export const loadTopArticlesWithFiltering = (from, to, filter, filterName) => ({
  type: actionTypes.LOAD_TOP_ARTICLES_WITH_FILTERING,
  payload: {from, to, filter, filterName}
});

export const loadTopArticlesTX = (from, to) => ({
  type: actionTypes.LOAD_TOP_ARTICLES_TX,
  payload: {from, to}
});

export const loadTopArticlesWithFilteringTX = (from, to, filter, filterName) => ({
  type: actionTypes.LOAD_TOP_ARTICLES_WITH_FILTERING_TX,
  payload: {from, to, filter, filterName}
});

export const loadTopCountries = (count = 500, tx = false) => ({
  type: actionTypes.LOAD_TOP_COUNTRIES,
  payload: {count, tx},
});

export const loadTopCities = (count = 500, tx = false) => ({
  type: actionTypes.LOAD_TOP_CITIES,
  payload: {count, tx},
});

export const loadTopOss = (count = 500, tx = false, from = '', to = '') => ({
  type: actionTypes.LOAD_TOP_OSS,
  payload: {count, tx, from, to},
});

export const loadTopBrowsers = (count = 500, tx = false, from = '', to = '') => ({
  type: actionTypes.LOAD_TOP_BROWSERS,
  payload: {count, tx, from, to},
});

export const loadTopDevices = (count = 500, tx = false, from = '', to = '') => ({
  type: actionTypes.LOAD_TOP_DEVICES,
  payload: {count, tx, from, to},
});
