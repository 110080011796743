import axios from 'axios';

export const getAdminProfile = (token, host) =>
  axios
    .get(`${process.env.API_BASE_URL}api/v1/Publishers/adminProfile`, {
      params: { host },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }
      throw new Error(errors[0]);
    });

export const getBrands = (token, host) =>
  axios
    .get(`${process.env.API_BASE_URL}api/v1/Quiz/GetQuizBrand?host=${host}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }
      throw new Error(errors[0]);
    });

export const getAdminProfileByApiKey = (token, apiKey) =>
  axios
    .get(`${process.env.API_BASE_URL}api/v1/Publishers/AdminProfileByApiKey`, {
      params: { apiKey },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      throw new Error(errors[0]);
    });
export const UpdatePublisherFlag = (
  token,
  hostOrApiKey,
  flagName,
  state,
  objValue,
) =>
  axios
    .put(
      `${process.env.API_BASE_URL}api/v1/Publishers/UpdatePublisherFlag`,
      {
        ...hostOrApiKey,
        flagName,
        state,
        objValue: objValue ? objValue : null,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { success, errors } = response.data;

      if (success) {
        return response.data;
      }

      throw new Error(errors[0]);
    });
export const createBrandForQuiz = (
  token,
  host,
  logoType,
  brandName,
  brandTitle,
  brandLogo,
  brandColor,
  BrandLogoClickUri,
) =>
  axios
    .post(
      `${process.env.API_BASE_URL}api/v1/Quiz/CreateQuizBrand`,
      {
        host,
        logoType,
        brandName,
        brandTitle,
        brandLogo,
        brandColor,
        BrandLogoClickUri,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      return response.data;
    });
export const editBrandForQuiz = (token, id, fields) =>
  axios
    .put(
      `${process.env.API_BASE_URL}api/v1/Quiz/EditQuizBrand`,
      {
        id,
        fields,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { success, errors } = response.data;

      if (success) {
        return response.data;
      }

      throw new Error(errors[0]);
    });
export const DeleteLogo = (token, hostOrApiKey) =>
  axios
    .delete(
      `${process.env.API_BASE_URL}api/v1/Publishers/DeleteQuizLogo?host=${hostOrApiKey}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { success, errors } = response.data;

      if (success) {
        return response.data;
      }

      throw new Error(errors[0]);
    });

export const DeleteBrandLogo = (token, id) =>
  axios
    .delete(`${process.env.API_BASE_URL}api/v1/Quiz/DeleteQuizBrand/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      const { success, errors } = response.data;
      if (success) {
        return response.data;
      }

      throw new Error(errors[0]);
    });

export const DeleteSpecBrandLogo = (token, url) =>
  axios
    .delete(`${process.env.API_BASE_URL}api/v1/Quiz/DeleteQuizBrandLogo?imageUri=${url}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      const { success, errors } = response.data;
      if (success) {
        return response.data;
      }

      throw new Error(errors[0]);
    });

export const UpdatePublisherFlagStringValue = (
  token,
  hostOrApiKey,
  flagName,
  stringValue,
) =>
  axios
    .put(
      `${process.env.API_BASE_URL}api/v1/Publishers/UpdatePublisherFlag`,
      {
        ...hostOrApiKey,
        flagName,
        stringValue,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { success, errors } = response.data;

      if (success) {
        return response.data;
      }

      throw new Error(errors[0]);
    });
export const UpdatePublisherFlagObjValue = (
  token,
  hostOrApiKey,
  flagName,
  objValue,
) =>
  axios
    .put(
      `${process.env.API_BASE_URL}api/v1/Publishers/UpdatePublisherFlag`,
      {
        ...hostOrApiKey,
        flagName,
        objValue,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { success, errors } = response.data;

      if (success) {
        return response.data;
      }

      throw new Error(errors[0]);
    });
export const setNotifyFlag = (token, hostOrApiKey, emails, notify) =>
  axios
    .post(
      `${process.env.API_BASE_URL}api/v1/admin_users/setNotifyFlag`,
      {
        ...hostOrApiKey,
        emails,
        notify,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { success, errors } = response.data;

      if (success) {
        return response.data;
      }

      throw new Error(errors[0]);
    });
