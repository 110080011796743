import { actionTypes } from '@/services/actions/publisher';

export const initialState = {
  order_company_data: {},
  extra_order_company_data: {
    adUnitIds: [],
    geoLocations: [],
    targetedDevice: [],
  },
  lines_items: [],
  creative_for_line_item: [],
  creative_for_line_item_loading: false,
  line_items_status: [],
  advertisers_exists: false,
  lines_items_loading: false,
  line_item_selected_locations: [],

  gam_report_data: [],
  gam_report_loading: false,

  privacy_issues: {
    issues: [],
    total: 0,
    mustFix: 0
  },
  privacy_issues_loading: false,
};

export const publisherReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_ORDER_COMPANY_DATA: {
      return { ...state, order_company_data: action.payload };
    }
    case actionTypes.SET_EXTRA_INFO_FOR_LINE_ITEMS_REQUEST_AD_UNITS: {
      return {
        ...state,
        extra_order_company_data: {
          ...state.extra_order_company_data,
          adUnitIds: action.payload,
        },
      };
    }
    case actionTypes.SET_EXTRA_INFO_FOR_LINE_ITEMS_REQUEST_LOCATIONS: {
      return {
        ...state,
        extra_order_company_data: {
          ...state.extra_order_company_data,
          geoLocations: action.payload,
        },
      };
    }
    case actionTypes.SET_EXTRA_INFO_FOR_LINE_ITEMS_REQUEST_DEVICES: {
      return {
        ...state,
        extra_order_company_data: {
          ...state.extra_order_company_data,
          targetedDevice: action.payload,
        },
      };
    }
    case actionTypes.SET_GAM_REPORT_LOADING: {
      return { ...state, gam_report_loading: action.payload };
    }
    case actionTypes.GET_GAM_REPORT_DATA: {
      return { ...state, gam_report_loading: true };
    }
    case actionTypes.SET_GAM_REPORT_DATA: {
      return {
        ...state,
        gam_report_data: action.payload,
        gam_report_loading: false,
      };
    }
    case actionTypes.GET_LINES_ITEM: {
      return { ...state, lines_items_loading: true };
    }
    case actionTypes.SET_ONLY_LINE_ITEMS: {
      return { ...state, lines_items: action.payload };
    }
    case actionTypes.SET_LINES_ITEM: {
      return {
        ...state,
        lines_items: action.payload.data,
        advertisers_exists: action.payload.flag,
        line_items_status: action.payload.status,
      };
    }
    case actionTypes.SET_CREATIVE_FOR_LINE_ITEM: {
      return {
        ...state,
        creative_for_line_item: action.payload,
        creative_for_line_item_loading: false,
      };
    }
    case actionTypes.ADD_NEW_CREATIVE: {
      return {
        ...state,
        creative_for_line_item: [
          action.payload,
          ...state.creative_for_line_item,
        ],
      };
    }
    case actionTypes.GET_CREATIVE_FOR_LINE_ITEM: {
      return { ...state, creative_for_line_item_loading: true };
    }
    case actionTypes.SET_FINISH_LINE_ITEM_LOADING: {
      return { ...state, lines_items_loading: action.payload };
    }
    case actionTypes.SET_TARGETED_LOCATIONS_FOR_LINE_ITEM: {
      return { ...state, line_item_selected_locations: action.payload };
    }
    case actionTypes.GET_HOST_POLICY_ISSUES: {
      return { ...state, privacy_issues_loading: true };
    }
    case actionTypes.SET_HOST_POLICY_ISSUES: {
      return { ...state, privacy_issues: action.payload, privacy_issues_loading: false };
    }
    default:
      return state;
  }
};

export default publisherReducer;
