import { actionTypes } from '@/services/actions/gam-integration';

// ==== 🗄️ Reducer
export const initialState = {
  gam: null,
  gam_loading: false,
};

export const gamReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_GAM: {
      return {
        ...state,
        gam_loading: true,
      };
    }
    case actionTypes.LOAD_CREATE_GAM: {
      return {
        ...state,
        gam_loading: true,
      };
    }
    case actionTypes.LOAD_UPDATE_GAM: {
      return {
        ...state,
        gam_loading: true,
      };
    }
    case actionTypes.LOAD_DELETE_GAM: {
      return {
        ...state,
        gam_loading: true,
      };
    }
    case actionTypes.SET_AUDIENCE_SEGMENTS_REQUEST: {
      return {
        ...state,
        gam_loading: true,
      };
    }

    case actionTypes.SET_GAM: {
      return { ...state, gam: action.payload, gam_loading: false };
    }

    case actionTypes.SET_CREATE_GAM: {
      return { ...state, gam: action.payload, gam_loading: false };
    }

    case actionTypes.SET_UPDATE_GAM: {
      return { ...state, gam: action.payload, gam_loading: false };
    }

    case actionTypes.SET_DELETE_GAM: {
      return { ...state, gam: null, gam_loading: false };
    }
    case actionTypes.SET_AUDIENCE_SEGMENTS_SUCCESS: {
      return {
        ...state,
        gam_loading: false,
      };
    }

    default:
      return state;
  }
};

export default gamReducer;
