import { actionTypes } from '@/services/actions/settings/payment';

const initialState = {
  paymentData: {},
  loading: false,
  error: false,
};

const paymentSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PAYMENT_DETAILS_SUCCESS: {
      return { ...state, loading: false, paymentData: action.payload };
    }
    case actionTypes.SAVE_OR_UPDATE_PAYMENT_DETAILS_REQUEST: {
      return { ...state, loading: true };
    }
    case actionTypes.PAYMENT_DETAILS_ERROR: {
      return { ...state, error: true };
    }
    case actionTypes.FINISH_LOADING: {
      return { ...state, loading: false };
    }
    default:
      return state;
  }
};

export default paymentSettingsReducer;
