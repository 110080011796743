import axios from 'axios';

export const getShareClicksbyNetworkbyTime = (token, network, from, to) =>
  axios
    .get(
      `${process.env.API_BASE_URL}api/v1/RevenueManagement/getShareClicksbyNetworkbyTime`,
      {
        params: { network, from, to },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data } = response;

      return data;
    });

export const getShareClicksbyNetworkbyTimebyHost = (
  token,
  host,
  network,
  from,
  to,
) =>
  axios
    .get(
      `${process.env.API_BASE_URL}api/v1/RevenueManagement/getShareClicksbyNetworkbyTimebyHost`,
      {
        params: {
          network,
          from,
          to,
          host,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data } = response;

      return data;
    });

export const getShareClicksbyNetworkbyTimebyApikey = (
  token,
  apiKey,
  network,
  from,
  to,
) =>
  axios
    .get(
      `${process.env.API_BASE_URL}api/v1/RevenueManagement/getShareClicksbyNetworkbyTimebyApikey`,
      {
        params: {
          network,
          from,
          to,
          apiKey,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data } = response;

      return data;
    });

export const getMostSharedArticlesbyNetworkbyTime = (
  token,
  network,
  from,
  to,
  page,
) =>
  axios
    .get(
      `${process.env.API_BASE_URL}api/v1/RevenueManagement/getMostSharedArticlesByNetworkbyTime`,
      {
        params: {
          network,
          from,
          to,
          start: page,
          pageSize: 10,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, status } = response;

      if (status === 200) {
        return data;
      }

      throw new Error('not valid details');
    });

export const getMostSharedArticlesByNetworkbyTimebyHost = (
  token,
  host,
  network,
  from,
  to,
  page,
  pageSize = 10,
) =>
  axios
    .get(
      `${process.env.API_BASE_URL}api/v1/RevenueManagement/getMostSharedArticlesByNetworkbyTimebyHost`,
      {
        params: {
          network,
          from,
          to,
          start: page * pageSize,
          pageSize,
          host,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, status } = response;

      if (status === 200) {
        return data;
      }

      throw new Error('not valid details');
    });

export const getMostSharedArticlesByNetworkbyTimebyApiKey = (
  token,
  apiKey,
  network,
  from,
  to,
  page,
  pageSize = 10,
) =>
  axios
    .get(
      `${process.env.API_BASE_URL}api/v1/RevenueManagement/getMostSharedArticlesByNetworkbyTimebyApiKey`,
      {
        params: {
          network,
          from,
          to,
          start: page * pageSize,
          pageSize,
          apiKey,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, status } = response;

      if (status === 200) {
        return data;
      }

      throw new Error('not valid details');
    });

export const getShareClicksbyTime = (apiKey, from, to, token) =>
  axios
    .get(
      `${process.env.API_BASE_URL}api/v1/RevenueManagement/getShareClicksbyTime`,
      {
        params: { from, to, apiKey },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success } = response.data;

      if (success) {
        return data;
      }

      throw new Error('not valid details');
    });

export const getShareClicksbyTimebyApiKey = (
  apiKey,
  from,
  to,
  token,
  groupType = 1,
) =>
  axios
    .get(
      `${process.env.API_BASE_URL}api/v2/Articles/getShareClicksbyTimebyApiKey`,
      {
        params: {
          from,
          to,
          apiKey,
          groupType,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { Data, success } = response.data;

      if (success) {
        return Data;
      }

      throw new Error('not valid details');
    });

export const getShareClicksByTimeByHost = (
  host,
  from,
  to,
  token,
  groupType = 1,
) =>
  axios
    .get(
      `${process.env.API_BASE_URL}api/v2/Articles/getShareClicksbyTimebyHost`,
      {
        params: {
          host,
          from,
          to,
          groupType,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { Data, success } = response.data;

      if (success) {
        return Data;
      }

      throw new Error('not valid details');
    });
