import axios from 'axios';

export const getRevenuesByHostTimeSeries = (token, host, fromDate, toDate) =>
  axios
    .get(
      process.env.API_BASE_URL + 'api/v2/revenues/getRevenuesByHostTimeSeries',
      {
        params: { host, fromDate, toDate },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      throw new Error(errors[0]);
    });

export const getRevenuesByApiKeyTimeSeries = (
  token,
  apiKey,
  fromDate,
  toDate,
  isUserAdmin,
) =>
  axios
    .get(
      process.env.API_BASE_URL +
        'api/v2/revenues/getRevenuesByApiKeyTimeSeries',
      {
        params: { apiKey, fromDate, toDate, isUserAdmin },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      throw new Error(errors[0]);
    });

export const getRevenueByDateByApiKey = (token, apiKey, from, to) =>
  axios
    .get(
      process.env.API_BASE_URL + 'api/v2/revenues/getRevenueByDateByApiKey',
      {
        params: { apiKey, from, to },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      throw new Error(errors[0]);
    });

export const getRevenueByDateByApiKeyDateWise = (
  token,
  apiKey,
  from,
  to,
  type,
) =>
  axios
    .get(
      process.env.API_BASE_URL +
        'api/v2/revenues/getRevenueByDateByApiKeyDateWise',
      {
        params: { apiKey, from, to, type },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      throw new Error(errors[0]);
    });

export const getRevenueByAdunitByApiKeyByDate = (token, apiKey, from, to) =>
  axios
    .get(
      process.env.API_BASE_URL +
        'api/v1/AdUnitRevenue/getRevenueByAdunitByApiKeyByDate',
      {
        params: { apiKey, from, to },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      throw new Error(errors[0]);
    });

export const getPaymentHistoryByApiKey = (token, from, to, start, pageSize) =>
  axios
    .get(
      process.env.API_BASE_URL +
        'api/v2/PaymentHistory/getPaymentHistoryByApiKey',
      {
        params: { from, to, start, pageSize },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      throw new Error(errors[0]);
    });

export const getHostBillingInfo = (token) =>
  axios
    .get(process.env.API_BASE_URL + 'api/v1/HostBillingInfos/billing', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      throw new Error(errors[0]);
    });

export const getRevenuesByHost = (token, host, fromDate, toDate, groupType) =>
  axios
    .get(process.env.API_BASE_URL + 'api/v2/revenues/getRevenuesByHost', {
      params: { host, fromDate, toDate, groupType },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      throw new Error(errors[0]);
    });

export const getRevenuesByOverview = (token, host, fromDate, toDate, groupType) =>
  axios
    .get(process.env.API_BASE_URL + 'api/v2/revenues/getRevenuesOverview', {
      params: { host, fromDate, toDate, groupType },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      throw new Error(errors[0]);
    });

export const getRevenueByAdunitByHostByDate = (token, host, from, to) =>
  axios
    .get(
      process.env.API_BASE_URL +
        'api/v1/AdUnitRevenue/getRevenueByAdunitByHostByDate',
      {
        params: { host, from, to },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      throw new Error(errors[0]);
    });

export const getRevenuesByApiKey = (
  token,
  apiKey,
  fromDate,
  toDate,
  groupType,
) =>
  axios
    .get(process.env.API_BASE_URL + 'api/v2/revenues/getRevenuesByApiKey', {
      params: { apiKey, fromDate, toDate, groupType },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      throw new Error(errors[0]);
    });

export const getFiltesListByHost = (token, host) =>
  axios
    .get(
      process.env.API_BASE_URL + 'api/v1/AdUnitRevenue/GetFilterListByHost',
      {
        params: { host },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      throw new Error(errors[0]);
    });

export const getFiltesListByApiKey = (token, apiKey) =>
  axios
    .get(
      process.env.API_BASE_URL + 'api/v1/AdUnitRevenue/GetFilterListByApiKey',
      {
        params: { apiKey },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      throw new Error(errors[0]);
    });

export const getAdunitRevenueByAdUnitByHostByDate = (
  token,
  host,
  adUnit,
  device,
  category,
  from,
  to,
  groupType,
) =>
  axios
    .get(
      process.env.API_BASE_URL +
        'api/v1/AdUnitRevenue/GetAdunitRevenueByAdUnitByHostByDate',
      {
        params: { host, adUnit, device, category, from, to, groupType },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      throw new Error(errors[0]);
    });

export const getAdunitRevenueByAdUnitByApiKeyByDate = (
  token,
  apiKey,
  adUnit,
  device,
  category,
  from,
  to,
  groupType,
) =>
  axios
    .get(
      process.env.API_BASE_URL +
        'api/v1/AdUnitRevenue/GetAdunitRevenueByAdUnitByApiKeyByDate',
      {
        params: { apiKey, adUnit, device, category, from, to, groupType },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      throw new Error(errors[0]);
    });

export const getRevenueByAdunitByDateByApiKey = (token, from, to) =>
  axios
    .get(
      process.env.API_BASE_URL +
        'api/v1/AdUnitRevenue/getRevenueByAdunitByDateByApiKey',
      {
        params: { from, to },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      throw new Error(errors[0]);
    });

export const getRefDomainStatsByHostByTime = (token, from, to, host) =>
  axios
    .get(
      process.env.API_BASE_URL +
        'api/v1/RefDomainStats/getRefDomainStatsByHostByTime',
      {
        params: { from, to, host },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      throw new Error(errors[0]);
    });

export const getRefDomainStatsByApiKeyByTime = (token, from, to, apiKey) =>
  axios
    .get(
      process.env.API_BASE_URL +
        'api/v1/RefDomainStats/getRefDomainStatsByApiKeyByTime',
      {
        params: { from, to, apiKey },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      throw new Error(errors[0]);
    });

export const getExitDomainStatsByHostByTime = (token, from, to, host) =>
  axios
    .get(
      process.env.API_BASE_URL +
        'api/v1/ExitDomainStats/getExitDomainStatsByHostByTime',
      {
        params: { from, to, host },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      throw new Error(errors[0]);
    });

export const getExitDomainStatsByApiKeyByTime = (token, from, to, apiKey) =>
  axios
    .get(
      process.env.API_BASE_URL +
        'api/v1/ExitDomainStats/getExitDomainStatsByApiKeyByTime',
      {
        params: { from, to, apiKey },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      throw new Error(errors[0]);
    });

export const getRevenueByDeviceByApiKey = (
  token,
  from,
  to,
  groupType = 1,
  apiKey,
) =>
  axios
    .get(
      process.env.API_BASE_URL +
        'api/v1/AdUnitRevenue/getRevenueByDeviceByApiKey',
      {
        params: { from, to, groupType, apiKey },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      throw new Error(errors[0]);
    });

export const getRevenueByDeviceByHost = (
  token,
  from,
  to,
  groupType = 1,
  host,
) =>
  axios
    .get(
      process.env.API_BASE_URL +
        'api/v1/AdUnitRevenue/getRevenueByDeviceByHost',
      {
        params: { from, to, groupType, host },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      throw new Error(errors[0]);
    });

export const getRevenueByCategoryByApiKey = (
  token,
  from,
  to,
  groupType = 1,
  apiKey,
) =>
  axios
    .get(
      process.env.API_BASE_URL +
        'api/v1/AdUnitRevenue/getRevenueByCategoryByApiKey',
      {
        params: { from, to, groupType, apiKey },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      throw new Error(errors[0]);
    });

export const getRevenueByCategoryByHost = (
  token,
  from,
  to,
  groupType = 1,
  host,
) =>
  axios
    .get(
      process.env.API_BASE_URL +
        'api/v1/AdUnitRevenue/getRevenueByCategoryByHost',
      {
        params: { from, to, groupType, host },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      throw new Error(errors[0]);
    });

export const getRevenueReportingFilters = (token) =>
  axios
    .get(process.env.API_BASE_URL + 'api/RevenueReports/Filters', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      const { data, success } = response.data;

      if (success) {
        return data;
      }

      throw new Error(response.data.errors[0]);
    });

export const getRevenueReportingData = (
  token,
  from,
  to,
  host,
  dimensions,
  metrics,
  dateGroupType,
  filter,
) =>
  axios
    .post(
      process.env.API_BASE_URL + 'api/v2/RevenueReports/Report',
      {
        from,
        to,
        host,
        dimensions,
        metrics,
        dateGroupType,
        filter,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success } = response.data;
      if (success) {
        return data;
      }

      throw new Error(response.data.errors[0]);
    });
