import { call, put, select, takeLatest } from 'redux-saga/effects';
import dayjs from 'dayjs';
import { toaster } from 'evergreen-ui';

import {
  getBookmarkedArticlesByHost,
  getBookmarkedArticlesByFilter,
} from '@/services/api/analytics/bookmarks';

import { actionTypes } from '@/services/actions/analytics/bookmarks';
import { initialState } from '@/services/reducers/analytics/bookmarks';

export function* loadBookmarkedArticles() {
  try {
    const { token } = yield select(({ session }) => session);
    const { host, dateRange } = yield select(({ filter }) => filter);

    const result = yield call(
      getBookmarkedArticlesByHost,
      token,
      dayjs.utc(dayjs(dateRange[0])).startOf('day').format('YYYY-MM-DD'),
      dayjs.utc(dayjs(dateRange[1])).endOf('day').format('YYYY-MM-DD'),
      host ? host : null,
    );
    yield put({
      type: actionTypes.GET_BOOKMARKED_ARTICLES_SUCCESS,
      payload: result || initialState.data,
    });
  } catch (e) {
    console.log('err', e);
    toaster.danger('Error fetching bookmarked articles', {
      id: 'analytics-bookmarked-articles',
    });
  }
}

export function* loadTopCountries(params) {
  try {
    const { token } = yield select(({ session }) => session);
    const { host, dateRange } = yield select(({ filter }) => filter);
    const { count } = params.payload;

    const result = yield call(
      getBookmarkedArticlesByFilter,
      token,
      dayjs.utc(dayjs(dateRange[0])).startOf('day').unix(),
      dayjs.utc(dayjs(dateRange[1])).endOf('day').unix(),
      host ? host : null,
      'Country',
      0,
      count,
    );

    yield put({
      type: actionTypes.GET_TOP_COUNTRIES_SUCCESS,
      payload: result || initialState.top_countries,
    });
  } catch (e) {
    console.log('err', e);
    toaster.danger('Error fetching top countries', {
      id: 'analytics-top-countries',
    });
  }
}

export function* loadTopCities(params) {
  try {
    const { token } = yield select(({ session }) => session);
    const { host, dateRange } = yield select(({ filter }) => filter);
    const { count } = params.payload;

    const result = yield call(
      getBookmarkedArticlesByFilter,
      token,
      dayjs.utc(dayjs(dateRange[0])).startOf('day').unix(),
      dayjs.utc(dayjs(dateRange[1])).endOf('day').unix(),
      host ? host : null,
      'City',
      0,
      count,
    );
    yield put({
      type: actionTypes.GET_TOP_CITIES_SUCCESS,
      payload: result || initialState.top_cities,
    });
  } catch (e) {
    console.log('err', e);
    toaster.danger('Error fetching top cities', { id: 'analytics-top-cities' });
  }
}

export function* loadTopOss(params) {
  try {
    const { token } = yield select(({ session }) => session);
    const { host, dateRange } = yield select(({ filter }) => filter);
    const { count } = params.payload;

    const result = yield call(
      getBookmarkedArticlesByFilter,
      token,
      dayjs.utc(dayjs(dateRange[0])).startOf('day').unix(),
      dayjs.utc(dayjs(dateRange[1])).endOf('day').unix(),
      host ? host : null,
      'Os',
      0,
      count,
    );

    yield put({
      type: actionTypes.GET_TOP_OSS_SUCCESS,
      payload: result || initialState.top_oss,
    });
  } catch (e) {
    console.log('err', e);
    toaster.danger('Error fetching top OS', { id: 'analytics-top-os' });
  }
}

export function* loadTopBrowsers(params) {
  try {
    const { token } = yield select(({ session }) => session);
    const { host, dateRange } = yield select(({ filter }) => filter);
    const { count } = params.payload;

    const result = yield call(
      getBookmarkedArticlesByFilter,
      token,
      dayjs.utc(dayjs(dateRange[0])).startOf('day').unix(),
      dayjs.utc(dayjs(dateRange[1])).endOf('day').unix(),
      host ? host : null,
      'Browser',
      0,
      count,
    );

    yield put({
      type: actionTypes.GET_TOP_BROWSERS_SUCCESS,
      payload: result || initialState.top_browsers,
    });
  } catch (e) {
    console.log('err', e);
    toaster.danger('Error fetching top browsers', {
      id: 'analytics-top-browsers',
    });
  }
}

export function* loadTopDevices(params) {
  try {
    const { token } = yield select(({ session }) => session);
    const { host, dateRange } = yield select(({ filter }) => filter);
    const { count } = params.payload;

    const result = yield call(
      getBookmarkedArticlesByFilter,
      token,
      dayjs.utc(dayjs(dateRange[0])).startOf('day').unix(),
      dayjs.utc(dayjs(dateRange[1])).endOf('day').unix(),
      host ? host : null,
      'Device',
      0,
      count,
    );

    yield put({
      type: actionTypes.GET_TOP_DEVICES_SUCCESS,
      payload: result || initialState.top_devices,
    });
  } catch (e) {
    console.log('err', e);
    toaster.danger('Error fetching top devices', {
      id: 'analytics-top-devices',
    });
  }
}

export default function* bookmarksSaga() {
  yield takeLatest(
    actionTypes.GET_BOOKMARKED_ARTICLES_REQUEST,
    loadBookmarkedArticles,
  );

  yield takeLatest(actionTypes.GET_TOP_COUNTRIES_REQUEST, loadTopCountries);
  yield takeLatest(actionTypes.GET_TOP_CITIES_REQUEST, loadTopCities);
  yield takeLatest(actionTypes.GET_TOP_OSS_REQUEST, loadTopOss);
  yield takeLatest(actionTypes.GET_TOP_BROWSERS_REQUEST, loadTopBrowsers);
  yield takeLatest(actionTypes.GET_TOP_DEVICES_REQUEST, loadTopDevices);
}
