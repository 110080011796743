export const actionTypes = {
  SET_LOGIN_TYPES_REQUEST: '@settings/comments/widget/set-login-types-request',
  SET_LOGIN_TYPES_SUCCESS: '@settings/comments/widget/set-login-types-success',
  SET_LOGIN_TO_SSO_REQUEST:
    '@settings/comments/widget/set-login-to-sso-request',
  SET_LOGIN_TO_SSO_SUCCESS:
    '@settings/comments/widget/set-login-to-sso-success',
  UPDATE_TOT_COUNT_REQUEST: '@settings/comments/widget/update-tot-request',
  UPDATE_TOT_COUNT_SUCCESS: '@settings/comments/widget/update-tot-success',
  UPDATE_TOT_INTERVAL_REQUEST:
    '@settings/comments/widget/update-tot-interval-request',
  UPDATE_TOT_INTERVAL_SUCCESS:
    '@settings/comments/widget/update-tot-interval-success',
  UPDATE_DEFAULT_SORTING_REQUEST:
    '@settings/comments/widget/update-default-sorting-request',
  UPDATE_DEFAULT_SORTING_SUCCESS:
    '@settings/comments/widget/update-default-sorting-success',
  UPDATE_EXTERNAL_SEARCH_REQUEST:
    '@settings/comments/widget/update-external-search-request',
  UPDATE_EXTERNAL_SEARCH_SUCCESS:
    '@settings/comments/widget/update-external-search-success',
  UPDATE_BUBBLE_TYPE_REQUEST:
    '@settings/comments/widget/update-bubble-type-request',
  UPDATE_BUBBLE_TYPE_SUCCESS:
    '@settings/comments/widget/update-bubble-type-success',
  UPDATE_FLAG_THRESHOLD_REQUEST:
    '@settings/comments/widget/update-flag-threshold-request',
  UPDATE_PB_AUDIO_SETTINGS_SWITCH:
    '@settings/comments/widget/update-pb-audio-settings-switch',
  PB_AUDIO_SETTINGS_SWITCH_REQUEST:
    '@settings/comments/widget/pb-audio-settings-switch-request',
  UPDATE_PDF_BUTTON_SETTINGS_SWITCH:
    '@settings/comments/widget/update-pdf-button-settings-switch',
  PDF_BUTTON_SETTINGS_SWITCH_REQUEST:
    '@settings/comments/widget/pdf-button-settings-switch-request',
  UPDATE_INSTAGRAM_BUTTON_SETTINGS_SWITCH:
    '@settings/comments/widget/update-instagram-button-settings-switch',
  INSTAGRAM_BUTTON_SETTINGS_SWITCH_REQUEST:
    '@settings/comments/widget/instagram-button-settings-switch-request',
  UPDATE_GOOGLE_BUTTON_SETTINGS_SWITCH:
    '@settings/comments/widget/update-google-button-settings-switch',
  GOOGLE_BUTTON_SETTINGS_SWITCH_REQUEST:
    '@settings/comments/widget/google-button-settings-switch-request',
  UPDATE_PW_BUTTONS_SETTINGS_SWITCH:
    '@settings/comments/widget/update-pw-buttons-settings-switch',
  PW_BUTTONS_SETTINGS_SWITCH_REQUEST:
    '@settings/comments/widget/pw-buttons-settings-switch-request',
  SET_PW_COMMENT_BUTTON_VALUE:
    '@settings/comments/widget/set-pw-button-comment-value',
  UPDATE_EMOTE_RECOMMENDED_SETTINGS_SWITCH:
    '@settings/comments/widget/update-emotes-recommended-settings-switch',
  EMOTE_RECOMMENDED_SETTINGS_SWITCH_REQUEST:
    '@settings/comments/widget/update-emotes-recommended-settings-switch',
  UPDATE_EMOTES_LABELS_SETTINGS_SWITCH:
    '@settings/comments/widget/update-emotes-labels-settings-switch',
  EMOTE_LABELS_SETTINGS_SWITCH_REQUEST:
    '@settings/comments/widget/update-emotes-labels-settings-switch',
  UPDATE_EMOTES_ORDER_SETTINGS:
    '@settings/comments/widget/update-emotes-order-settings',
  EMOTE_ORDER_SETTINGS_REQUEST:
    '@settings/comments/widget/update-emotes-order-settings',
  UPDATE_LOADED_COMMENTS_REQUEST:
    '@settings/comments/widget/update-loaded-comments-request',
  UPDATE_FLAG_THRESHOLD_SUCCESS:
    '@settings/comments/widget/update-flag-threshold-success',
  SET_HIDE_COMMENTS_WIDGET_REQUEST:
    '@settings/comments/widget/set-hide-comments-widget-request',
  SET_HIDE_COMMENTS_WIDGET_SUCCESS:
    '@settings/comments/widget/set-hide-comments-widget-success',
  GET__BLOCK_WORD_LIST_REQUEST: '@settings/get-block-word-list-request',
  GET__BLOCK_WORD_LIST_SUCCESS: '@settings/get-block-word-list-success',
  SET_SPAM_LIMIT_REQUEST: '@settings/comments/widget/set-spam-limit-request',
  SET_SPAM_LIMIT_SUCCESS: '@settings/comments/widget/set-spam-limit-success',
  SET_TREADS_TO_DISPLAY_REQUEST:
    '@settings/comments/widget/set-treads-to-display-request',
  SET_TREADS_TO_DISPLAY_SUCCESS:
    '@settings/comments/widget/set-treads-to-display-success',
  SET_BUBBLE_LOAD_TIME_REQUEST:
    '@settings/comments/widget/set-bubble-load-time-request',
  SET_BUBBLE_LOAD_TIME_SUCCESS:
    '@settings/comments/widget/set-bubble-load-time-success',
  SET_BUBBLE_LOAD_AT_REQUEST:
    '@settings/comments/widget/set-bubble-load-at-request',
  SET_BUBBLE_LOAD_AT_SUCCESS:
    '@settings/comments/widget/set-bubble-load-at-success',
  SET_TOXICITY_LIMIT_REQUEST:
    '@settings/comments/widget/set-toxicity-limit-request',
  SET_TOXICITY_LIMIT_SUCCESS:
    '@settings/comments/widget/set-toxicity-limit-success',
  SET_DARK_MODE_REQUEST: '@settings/comments/widget/set-dark-mode-request',
  SET_DARK_MODE_SUCCESS: '@settings/comments/widget/set-dark-mode-success',
  SET_COLOR_REQUEST: '@settings/comments/widget/set-color-request',
  SET_COLOR_SUCCESS: '@settings/comments/widget/set-color-success',
  SET_OPEN_ARTICLES_IN_SAME_TAB_REQUEST:
    '@settings/comments/widget/set-open-articles-in-same-tab-request',
  SET_OPEN_ARTICLES_IN_SAME_TAB_SUCCESS:
    '@settings/comments/widget/set-open-articles-in-same-tab-success',
  SET_TOT_TEXT_REQUEST: '@settings/comments/widget/set-tot-text-request',
  SET_TOT_TEXT_SUCCESS: '@settings/comments/widget/set-tot-text-success',
  SET_COMMENTS_COUNT_SHOW_REQUEST:
    '@settings/comments/widget/set-comments-count-show-request',
  SET_COMMENTS_COUNT_SHOW_SUCCESS:
    '@settings/comments/widget/set-comments-count-show-success',
  SET_EMOTE_HEADER_REQUEST:
    '@settings/comments/widget/set-emote-header-request',
  SET_EMOTE_HEADER_SUCCESS:
    '@settings/comments/widget/set-emote-header-success',
  SET_EMOTE_VOTE_REQUEST: '@settings/comments/widget/set-emote-vote-request',
  SET_EMOTE_VOTE_SUCCESS: '@settings/comments/widget/set-emote-vote-success',
  SET_INITIAL_COMMENTS_SETTINGS_DATA: '@settings/comments/widget/set-data',
  COMMENTS_SETTINGS_ERROR: '@settings/comments/widget/comments-settings-error',
  SET_HIDE_INPUT_BOX_COMMENTS_WIDGET_REQUEST:
    '@settings/comments/widget/set-hide-input-box-comments-widget-request',
  SET_HIDE_INPUT_BOX_COMMENTS_WIDGET_SUCCESS:
    '@settings/comments/widget/set-hide-input-box-comments-widget-success',
  SET_COMMENT_PANEL_REQUEST:
    '@settings/comments/widget/set-comments-panel-request',
  SET_COMMENT_PANEL_SUCCESS:
    '@settings/comments/widget/set-comments-panel-success',
  SET_EMOTE_AND_SHAREBAR_DATA:
    '@settings/comments/widget/set-emote-and-sharebar-data',
  EMOTE_AND_SHAREBAR_EDIT_REQUEST:
    '@settings/comments/widget/emote-and-sharebar-edit-request',
  SET_COMMENTS_LENGTH: '@settings/comments/widget/set-comments-length',
  SET_COMMENTS_LENGTH_SUCCESS:
    '@settings/comments/widget/set-comments-length-success',
  SET_TOT_WIDE_IMAGES_ON: '@settings/comments/widget/set-tot-wide-images-on',
  SET_RTE_OPTION: '@settings/comments/widget/set-rte-option',
  CHANGE_HORIZONTAL_POSITION:
    '@settings/comments/widget/change-horizontal-position',
  SET_HORIZONTAL_POSITION: '@settings/comments/widget/set-horizontal-position',
  CHANGE_SHOW_NEWSLETTER_POPUP:
    '@settings/comments/widget/change-show-newsletter-popup',
  SET_ENABLED_WIDGETS: '@settings/comments/widget/set-enabled-widgets',
  UPDATE_ENABLED_WIDGETS: '@settings/comments/widget/update-enabled-widgets',
  SET_PUBLISHER_STYLES: '@settings/comments/widget/set-publisher-styles',
  UPDATE_PUBLISHER_STYLES: '@settings/comments/widget/update-publisher-styles',
  SET_INTERACTIVITY_HUB: '@settings/comments/widget/set-interactivity-hub',
  UPDATE_INTERACTIVITY_HUB:
    '@settings/comments/widget/update-interactivity-hub',
  SET_DISABLED_TOPICS: '@settings/quiz/widget/set-disabled-topics',
  UPDATE_DISABLED_TOPICS: '@settings/quiz/widget/update-disabled-topics',
  SET_DISABLE_RESULTS: '@settings/comments/widget/set-disable-results',
  UPDATE_DISABLE_RESULTS: '@settings/comments/widget/update-disable-results',
  SET_DISABLE_NEXT_BUTTON: '@settings/comments/widget/set-disable-next-button',
  UPDATE_DISABLE_NEXT_BUTTON:
    '@settings/comments/widget/update-disable-next-button',
  SET_DISABLE_DEFAULT: '@settings/comments/widget/set-disable-default',
  UPDATE_DISABLE_DEFAULT: '@settings/comments/widget/update-disable-default',
  SET_DISABLE_MARKETING:
    '@settings/comments/widget/set-disable-marketing-questions',
  UPDATE_DISABLE_MARKETING:
    '@settings/comments/widget/update-disable-marketing-questions',
  SET_QUESTIONS_COUNT: '@settings/comments/widget/set-question-count',
  UPDATE_QUESTIONS_COUNT: '@settings/comments/widget/update-question-count',
  SET_DEFAULT_QUESTIONS_COUNT:
    '@settings/comments/widget/set-default-question-count',
  UPDATE_BRAND_QUESTIONS_INDEX:
    '@settings/comments/widget/update-brand-question-index',
  SET_BRAND_QUESTIONS_INDEX:
    '@settings/comments/widget/set-brand-question-index',
  UPDATE_DEFAULT_QUESTIONS_COUNT:
    '@settings/comments/widget/update-default-question-count',
  SET_AI_QUESTIONS_COUNT: '@settings/comments/widget/set-ai-question-count',
  UPDATE_AI_QUESTIONS_COUNT:
    '@settings/comments/widget/update-ai-question-count',
  SET_BLOCK_WORD_QW: '@settings/comments/widget/set-qw-block-word',
  UPDATE_BLOCK_WORD_QW: '@settings/comments/widget/update-qw-block-word',
  SET_BRAND_CREATE: '@settings/comments/widget/set-brand',
  UPDATE_BRAND_CREATE: '@settings/comments/widget/update-brand',
  SET_BRAND_EDIT: '@settings/comments/widget/set-brand-edit',
  UPDATE_BRAND_EDIT: '@settings/comments/widget/update-brand-edit',
  SET_DELETE_LOGO: '@settings/comments/widget/set/delete-logo',
  UPDATE_DELETE_LOGO: '@settings/comments/widget/update/delete-logo',
  SET_DELETE_BRAND_LOGO: '@settings/comments/widget/set/delete-brand-logo',
  SET_DELETE_SPEC_BRAND_LOGO:
    '@settings/comments/widget/set/delete-spec-brand-logo',
  UPDATE_DELETE_BRAND_LOGO:
    '@settings/comments/widget/update/delete-brand-logo',
  UPDATE_DELETE_SPEC_BRAND_LOGO:
    '@settings/comments/widget/update/delete-spec-brand-logo',
  GET_BRANDS: '@settings/comments/widget/get-brands',
  SET_BRANDS: '@settings/comments/widget/set-brands',

  GET_TOPICS_REQUEST: '@settings/get-topics-request',
  GET_TOPICS_SUCCESS: '@settings/get-topics-success',
};

export const changeShowNewsletterValue = (value, allSites) => ({
  type: actionTypes.CHANGE_SHOW_NEWSLETTER_POPUP,
  payload: { value, allSites },
});

export const setInitialCommentsSettingsData = (data) => ({
  type: actionTypes.SET_INITIAL_COMMENTS_SETTINGS_DATA,
  payload: data,
});

export const setLoginTypes = (
  loginTypes,
  allSites = false,
  loginTypeName,
  val,
) => ({
  type: actionTypes.SET_LOGIN_TYPES_REQUEST,
  loginTypes,
  allSites,
  loginTypeName,
  val,
});

export const getTopics = () => ({
  type: actionTypes.GET_TOPICS_REQUEST,
});

export const setLoginToSSO = (allSites = false) => ({
  type: actionTypes.SET_LOGIN_TO_SSO_REQUEST,
  allSites,
});

export const setLoginTypesSuccess = (loginTypes) => ({
  type: actionTypes.SET_LOGIN_TYPES_SUCCESS,
  loginTypes,
});

export const commentsSettingsError = () => ({
  type: actionTypes.COMMENTS_SETTINGS_ERROR,
});

export const updateTotRequest = (count, allSites = false) => ({
  type: actionTypes.UPDATE_TOT_COUNT_REQUEST,
  count,
  allSites,
});

export const updateTotSuccess = () => ({
  type: actionTypes.UPDATE_TOT_COUNT_SUCCESS,
});

export const updateTotIntervalRequest = (count, allSites = false) => ({
  type: actionTypes.UPDATE_TOT_INTERVAL_REQUEST,
  count,
  allSites,
});

export const updateTotIntervalSuccess = () => ({
  type: actionTypes.UPDATE_TOT_INTERVAL_SUCCESS,
});

export const updateFlagThresholdRequest = (
  flagThreshold,
  allSites = false,
) => ({
  type: actionTypes.UPDATE_FLAG_THRESHOLD_REQUEST,
  flagThreshold,
  allSites,
});

export const updateLoadedCommentsRequest = (
  loadedComments,
  allSites = false,
) => ({
  type: actionTypes.UPDATE_LOADED_COMMENTS_REQUEST,
  loadedComments,
  allSites,
});

export const updateFlagThresholdSuccess = () => ({
  type: actionTypes.UPDATE_FLAG_THRESHOLD_SUCCESS,
});

export const updateDefaultSortingRequest = (sorting, allSites = false) => ({
  type: actionTypes.UPDATE_DEFAULT_SORTING_REQUEST,
  sorting,
  allSites,
});

export const updateDefaultSortingSuccess = (sorting) => ({
  type: actionTypes.UPDATE_TOT_INTERVAL_SUCCESS,
  payload: sorting,
});

export const updateExternalSearchRequest = (
  searchOptions,
  allSites = false,
) => ({
  type: actionTypes.UPDATE_EXTERNAL_SEARCH_REQUEST,
  searchOptions,
  allSites,
});

export const updateBubbleTypeRequest = (
  commentBubbleLoadType,
  allSites = false,
) => ({
  type: actionTypes.UPDATE_BUBBLE_TYPE_REQUEST,
  commentBubbleLoadType,
  allSites,
});

export const updateBubbleTypeSuccess = (commentBubbleLoadType) => ({
  type: actionTypes.UPDATE_BUBBLE_TYPE_SUCCESS,
  payload: commentBubbleLoadType,
});

export const updateExternalSearchSuccess = (searchOptions) => ({
  type: actionTypes.UPDATE_EXTERNAL_SEARCH_SUCCESS,
  payload: searchOptions,
});

export const setHideCommentsWidgetRequest = (
  hideCommentsWidget,
  allSites = false,
) => ({
  type: actionTypes.SET_HIDE_COMMENTS_WIDGET_REQUEST,
  hideCommentsWidget,
  allSites,
});

export const setHideInputBoxCommentsWidgetRequest = (
  hideInputBox,
  allSites = false,
) => ({
  type: actionTypes.SET_HIDE_INPUT_BOX_COMMENTS_WIDGET_REQUEST,
  hideInputBox,
  allSites,
});

export const setCommentPanelRequest = (
  commentsPanelEnabled,
  allSites = false,
) => ({
  type: actionTypes.SET_COMMENT_PANEL_REQUEST,
  commentsPanelEnabled,
  allSites,
});

export const setHideCommentsWidgetSuccess = (hideCommentsWidget) => ({
  type: actionTypes.SET_HIDE_COMMENTS_WIDGET_SUCCESS,
  payload: hideCommentsWidget,
});

export const setHideInputBoxCommentsWidgetSuccess = (
  hideInputBoxCommentsWidget,
) => ({
  type: actionTypes.SET_HIDE_INPUT_BOX_COMMENTS_WIDGET_SUCCESS,
  payload: hideInputBoxCommentsWidget,
});

export const setCommentPanelSuccess = (
    commentsPanelEnabled,
) => ({
  type: actionTypes.SET_COMMENT_PANEL_SUCCESS,
  payload: commentsPanelEnabled,
});

export const getBlockWordsRequest = (host) => ({
  type: actionTypes.GET__BLOCK_WORD_LIST_REQUEST,
  payload: host,
});

export const setTreadsToDisplayRequest = (
  commentThreadsToDisplay,
  allSites = false,
) => ({
  type: actionTypes.SET_TREADS_TO_DISPLAY_REQUEST,
  commentThreadsToDisplay,
  allSites,
});

export const setTreadsToDisplaySuccess = (commentThreadsToDisplay) => ({
  type: actionTypes.SET_TREADS_TO_DISPLAY_SUCCESS,
  payload: commentThreadsToDisplay,
});

export const setBubbleLoadTimeRequest = (
  loadCommentBubbleAfter,
  allSites = false,
) => ({
  type: actionTypes.SET_BUBBLE_LOAD_TIME_REQUEST,
  loadCommentBubbleAfter,
  allSites,
});

export const setBubbleLoadTimeSuccess = (loadCommentBubbleAfter) => ({
  type: actionTypes.SET_BUBBLE_LOAD_TIME_SUCCESS,
  payload: loadCommentBubbleAfter,
});

export const setBubbleLoadAtRequest = (
  loadCommentBubbleAt,
  allSites = false,
) => ({
  type: actionTypes.SET_BUBBLE_LOAD_AT_REQUEST,
  loadCommentBubbleAt,
  allSites,
});

export const setBubbleLoadAtSuccess = (loadCommentBubbleAt) => ({
  type: actionTypes.SET_BUBBLE_LOAD_AT_SUCCESS,
  payload: loadCommentBubbleAt,
});

export const setSpamLimitRequest = (spamLimit, allSites = false) => ({
  type: actionTypes.SET_SPAM_LIMIT_REQUEST,
  spamLimit,
  allSites,
});

export const setSpamLimitSuccess = (spamLimit) => ({
  type: actionTypes.SET_SPAM_LIMIT_SUCCESS,
  payload: spamLimit,
});

export const setToxicityLimitRequest = (toxicityLimit, allSites = false) => ({
  type: actionTypes.SET_TOXICITY_LIMIT_REQUEST,
  toxicityLimit,
  allSites,
});

export const setToxicityLimitSuccess = (toxicityLimit) => ({
  type: actionTypes.SET_TOXICITY_LIMIT_SUCCESS,
  payload: toxicityLimit,
});

export const setDarkModeRequest = (darkMode, allSites = false) => ({
  type: actionTypes.SET_DARK_MODE_REQUEST,
  darkMode,
  allSites,
});

export const setDarkModeSuccess = (darkMode) => ({
  type: actionTypes.SET_DARK_MODE_SUCCESS,
  payload: darkMode,
});

export const setCommentsCountShowRequest = (
  showCommentCount,
  allSites = false,
) => ({
  type: actionTypes.SET_COMMENTS_COUNT_SHOW_REQUEST,
  payload: { showCommentCount, allSites },
});

export const setColorRequest = (color, allSites = false) => ({
  type: actionTypes.SET_COLOR_REQUEST,
  color,
  allSites,
});

export const setColorSuccess = (color) => ({
  type: actionTypes.SET_COLOR_SUCCESS,
  payload: color,
});

export const setOpenArticlesInSameTabRequest = (
  openArticlesInSameTab,
  allSites = false,
) => ({
  type: actionTypes.SET_OPEN_ARTICLES_IN_SAME_TAB_REQUEST,
  openArticlesInSameTab,
  allSites,
});

export const setOpenArticlesInSameTabSuccess = (openArticlesInSameTab) => ({
  type: actionTypes.SET_OPEN_ARTICLES_IN_SAME_TAB_SUCCESS,
  payload: openArticlesInSameTab,
});

export const setToTTextRequest = (talkOfTheTownText, allSites = false) => ({
  type: actionTypes.SET_TOT_TEXT_REQUEST,
  talkOfTheTownText,
  allSites,
});

export const setToTTextSuccess = (talkOfTheTownText) => ({
  type: actionTypes.SET_TOT_TEXT_SUCCESS,
  payload: talkOfTheTownText,
});

export const setEmotesHeaderRequest = (emotesHeader, allSites = false) => ({
  type: actionTypes.SET_EMOTE_HEADER_REQUEST,
  emotesHeader,
  allSites,
});

export const setEmotesHeaderSuccess = (emotesHeader) => ({
  type: actionTypes.SET_EMOTE_HEADER_SUCCESS,
  payload: emotesHeader,
});

export const setEmoteAndSharebarData = (data) => ({
  type: actionTypes.SET_EMOTE_AND_SHAREBAR_DATA,
  payload: data,
});

export const pbAudioButtonSettingsRequest = (data, allSites) => ({
  type: actionTypes.PB_AUDIO_SETTINGS_SWITCH_REQUEST,
  payload: { data, allSites },
});

export const pdfButtonButtonSettingsRequest = (data, allSites) => ({
  type: actionTypes.PDF_BUTTON_SETTINGS_SWITCH_REQUEST,
  payload: { data, allSites },
});

export const instagramButtonSettingsRequest = (data, allSites) => ({
  type: actionTypes.INSTAGRAM_BUTTON_SETTINGS_SWITCH_REQUEST,
  payload: { data, allSites },
});

export const googleNewsButtonSettingsRequest = (data, allSites) => ({
  type: actionTypes.GOOGLE_BUTTON_SETTINGS_SWITCH_REQUEST,
  payload: { data, allSites },
});

export const pwButtonsSettingsRequest = (data, first, allSites) => ({
  type: actionTypes.PW_BUTTONS_SETTINGS_SWITCH_REQUEST,
  payload: { data, first, allSites },
});

export const emotesRecommendationSettingsRequest = (data, allSites) => ({
  type: actionTypes.EMOTE_RECOMMENDED_SETTINGS_SWITCH_REQUEST,
  payload: { data, allSites },
});

export const emotesLabelsSettingsRequest = (
  data,
  emote,
  allSites,
  updatedState,
) => ({
  type: actionTypes.EMOTE_LABELS_SETTINGS_SWITCH_REQUEST,
  payload: { data, allSites, emote, updatedState },
});

export const emotesOrderSettingsRequest = (orderedList, allSites) => ({
  type: actionTypes.EMOTE_ORDER_SETTINGS_REQUEST,
  payload: { orderedList, allSites },
});

export const emoteAndSharebarEditRequest = (
  data,
  allSites,
  notifyText = '',
) => ({
  type: actionTypes.EMOTE_AND_SHAREBAR_EDIT_REQUEST,
  payload: { data, allSites, notifyText },
});

export const setCommentsLength = (data, allSites) => ({
  type: actionTypes.SET_COMMENTS_LENGTH,
  payload: { data, allSites },
});

export const setTotWideImagesOn = (data, allSites) => ({
  type: actionTypes.SET_TOT_WIDE_IMAGES_ON,
  payload: { data, allSites },
});

export const setEmotesVoteRequest = (emoteVoteText, allSites = false) => ({
  type: actionTypes.SET_EMOTE_VOTE_REQUEST,
  emoteVoteText,
  allSites,
});

export const setRTEOptions = (data, allSites) => ({
  type: actionTypes.SET_RTE_OPTION,
  payload: { data, allSites },
});

export const changeHorizontalPosition = (data, allSites) => ({
  type: actionTypes.CHANGE_HORIZONTAL_POSITION,
  payload: { data, allSites },
});

export const setEnabledWidgets = (data, allSites) => ({
  type: actionTypes.SET_ENABLED_WIDGETS,
  payload: { data, allSites },
});

export const setPublisherStyles = (data, allSites) => ({
  type: actionTypes.SET_PUBLISHER_STYLES,
  payload: { data, allSites },
});

export const setInteractivityHub = (data, allSites) => ({
  type: actionTypes.SET_INTERACTIVITY_HUB,
  payload: { data, allSites },
});

export const setDisabledTopics = (data, allSites) => ({
  type: actionTypes.SET_DISABLED_TOPICS,
  payload: { data, allSites },
});

export const setDisableResults = (data, allSites) => ({
  type: actionTypes.SET_DISABLE_RESULTS,
  payload: { data, allSites },
});

export const setDisableNextButton = (data, allSites) => ({
  type: actionTypes.SET_DISABLE_NEXT_BUTTON,
  payload: { data, allSites },
});

export const setDisableDefault = (data, allSites) => ({
  type: actionTypes.SET_DISABLE_DEFAULT,
  payload: { data, allSites },
});

export const setDisableMarketing = (data, allSites) => ({
  type: actionTypes.SET_DISABLE_MARKETING,
  payload: { data, allSites },
});

export const setQuizQuestionsCount = (data, allSites) => ({
  type: actionTypes.SET_QUESTIONS_COUNT,
  payload: { data, allSites },
});

export const setQuizAIQuestionsCount = (data, allSites) => ({
  type: actionTypes.SET_AI_QUESTIONS_COUNT,
  payload: { data, allSites },
});

export const setQuizBlockWord = (data, allSites) => ({
  type: actionTypes.SET_BLOCK_WORD_QW,
  payload: { data, allSites },
});

export const setQuizDefaultQuestionsCount = (data, allSites) => ({
  type: actionTypes.SET_DEFAULT_QUESTIONS_COUNT,
  payload: { data, allSites },
});

export const setQuizBrandQuestionIndex = (data, allSites) => ({
  type: actionTypes.SET_BRAND_QUESTIONS_INDEX,
  payload: { data, allSites },
});

export const setCreateBrand = (
  logoType,
  brandName,
  brandTitle,
  brandLogo,
  brandColor,
  BrandLogoClickUri,
  hostFromSurvey,
) => ({
  type: actionTypes.SET_BRAND_CREATE,
  payload: {
    logoType,
    brandName,
    brandTitle,
    brandLogo,
    brandColor,
    BrandLogoClickUri,
    hostFromSurvey,
  },
});

export const setEditBrand = (
  id,
  logoType,
  brandName,
  brandTitle,
  brandLogo,
  brandColor,
  BrandLogoClickUri,
) => ({
  type: actionTypes.SET_BRAND_EDIT,
  payload: {
    id,
    logoType,
    brandName,
    brandTitle,
    brandLogo,
    brandColor,
    BrandLogoClickUri,
  },
});

export const setDeleteLogo = (allSites) => ({
  type: actionTypes.SET_DELETE_LOGO,
  payload: { allSites },
});

export const setDeleteBrandLogo = (id) => ({
  type: actionTypes.SET_DELETE_BRAND_LOGO,
  payload: { id },
});

export const setDeleteSpecBrandLogo = (url) => ({
  type: actionTypes.SET_DELETE_SPEC_BRAND_LOGO,
  payload: { url },
});

export const setBrands = (host) => ({
  type: actionTypes.GET_BRANDS,
  payload: { host },
});
