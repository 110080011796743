export const actionTypes = {
  BLOCK_WORD_REQUEST: '@settings/comments/userlists/block-word-request',
  BLOCK_WORD_SUCCESS: '@settings/comments/userlists/block-word-success',
  BLOCK_IP_REQUEST: '@settings/comments/userlists/block-ip-request',
  BLOCK_IP_SUCCESS: '@settings/comments/userlists/block-ip-success',
  BLOCK_EMAIL_REQUEST: '@settings/comments/userlists/block-email-request',
  BLOCK_EMAIL_SUCCESS: '@settings/comments/userlists/block-email-success',
  BLOCK_USERNAME_REQUEST: '@settings/comments/userlists/block-username-request',
  BLOCK_USERNAME_SUCCESS: '@settings/comments/userlists/block-username-success',
  UNBLOCK_WORD_REQUEST: '@settings/comments/userlists/unblock-word-request',
  UNBLOCK_WORD_SUCCESS: '@settings/comments/userlists/unblock-word-success',
  UNBLOCK_IP_REQUEST: '@settings/comments/userlists/unblock-ip-request',
  UNBLOCK_IP_SUCCESS: '@settings/comments/userlists/unblock-ip-success',
  UNBLOCK_EMAIL_REQUEST: '@settings/comments/userlists/unblock-email-request',
  UNBLOCK_EMAIL_SUCCESS: '@settings/comments/userlists/unblock-email-success',
  UNBLOCK_USERNAME_REQUEST:
    '@settings/comments/userlists/unblock-username-request',
  UNBLOCK_USERNAME_SUCCESS:
    '@settings/comments/userlists/unblock-username-success',
  WHITELIST_GET_REQUEST: '@settings/comments/userlists/whitelist-get-request',
  WHITELIST_GET_SUCCESS: '@settings/comments/userlists/whitelist-get-success',
  WHITELIST_ADD_EMAIL_REQUEST:
    '@settings/comments/userlists/whitelist-add-email-request',
  WHITELIST_ADD_EMAIL_SUCCESS:
    '@settings/comments/userlists/whitelist-add-email-success',
  WHITELIST_REMOVE_EMAIL_REQUEST:
    '@settings/comments/userlists/whitelist-remove-email-request',
  WHITELIST_REMOVE_EMAIL_SUCCESS:
    '@settings/comments/userlists/whitelist-remove-email-success',
  WHITELIST_COUNT_REQUEST:
    '@settings/comments/userlists/whitelist-count-request',
  WHITELIST_COUNT_SUCCESS:
    '@settings/comments/userlists/whitelist-count-success',
  SET_INITIAL_USERLIST_DATA: '@settings/comments/userlists/set-data',
  BLOCK_ERROR: '@settings/comments/userlists/block-error',
};

export const setInitialUserListData = (data) => ({
  type: actionTypes.SET_INITIAL_USERLIST_DATA,
  payload: data,
});

export const setBlockRequest = (
  actionType,
  offValues,
  offensiveType,
  decision,
  allSites = false,
  notes = '',
  host = null,
  sendEmailToUser = false,
) => ({
  type: actionType,
  offValues,
  offensiveType,
  decision,
  allSites,
  notes,
  host,
  sendEmailToUser,
});

export const setBlockError = () => ({
  type: actionTypes.BLOCK_ERROR,
});

export const whitelistAddRequest = (emails, allSites = false, notes = '') => ({
  type: actionTypes.WHITELIST_ADD_EMAIL_REQUEST,
  emails,
  allSites,
  notes,
});

export const whitelistAddSuccess = (emails, host, allSites = false) => ({
  type: actionTypes.WHITELIST_ADD_EMAIL_SUCCESS,
  emails,
  host,
  allSites,
});

export const whitelistDeleteRequest = (emails, allSites) => ({
  type: actionTypes.WHITELIST_REMOVE_EMAIL_REQUEST,
  emails,
  allSites,
});

export const whitelistDeleteSuccess = (emails, host, allSites) => ({
  type: actionTypes.WHITELIST_REMOVE_EMAIL_SUCCESS,
  emails,
  host,
  allSites,
});

export const whitelistGetRequest = (host) => ({
  type: actionTypes.WHITELIST_GET_REQUEST,
  payload: { host },
});

export const whitelistGetSuccess = (emails, host) => ({
  type: actionTypes.WHITELIST_GET_SUCCESS,
  emails,
  host,
});

export const whitelistCountRequest = (host) => ({
  type: actionTypes.WHITELIST_COUNT_REQUEST,
  host,
});

export const whitelistCountSuccess = (host, count) => ({
  type: actionTypes.WHITELIST_COUNT_SUCCESS,
  host,
  count,
});
