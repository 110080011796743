// ==== 💪 Action Types
export const actionTypes = {
  CREATE_COMPANY_REQUEST: "@publisher/create-company-request",
  CREATE_LINE_ITEM_REQUEST: "@publisher/create-line-item-request",
  GET_EXTRA_INFO_FOR_LINE_ITEMS_REQUEST_AD_UNITS: "@publisher/get-extra-info-for-line-items-request-ad-units",
  GET_EXTRA_INFO_FOR_LINE_ITEMS_REQUEST_LOCATIONS: "@publisher/get-extra-info-for-line-items-request-locations",
  GET_EXTRA_INFO_FOR_LINE_ITEMS_REQUEST_DEVICES: "@publisher/get-extra-info-for-line-items-request-devices",
  ADD_CREATIVE_DATA_FOR_LINE_ITEM: "@publisher/add-creative-data-for-line-item",
  GET_LINES_ITEM: "@publisher/get-lines-item",
  EDIT_LINE_ITEM_DATA: "@publisher/edit-line-item-data",
  GET_CREATIVE_FOR_LINE_ITEM: "@publisher/get-creative-for-line-item",
  UPDATE_CREATIVE_DATA: "@publisher/update-creative-data",
  GET_GAM_REPORT_DATA: "@publisher/get-gam-report-data",
  COPY_LINE_ITEMS_REQUEST: "@publisher/copy-line-items-request",
  UPDATE_LINE_ITEM_NAME: "@publisher/update-line-item-name",
  SET_ONLY_LINE_ITEMS: "@publisher/set-only-line-items",
  SET_GAM_REPORT_LOADING: "@publisher/set-gam-report-loading",
  GET_TARGETED_LOCATIONS_FOR_LINE_ITEM: "@publisher/get-targeted-locations-for-line-item",

  SET_ORDER_COMPANY_DATA: "@publisher/set-order-company-data",
  SET_LINES_ITEM: "@publisher/set-lines-item",
  SET_EXTRA_INFO_FOR_LINE_ITEMS_REQUEST_AD_UNITS: "@publisher/set-extra-info-for-line-items-request-ad-units",
  SET_EXTRA_INFO_FOR_LINE_ITEMS_REQUEST_LOCATIONS: "@publisher/set-extra-info-for-line-items-request-locations",
  SET_EXTRA_INFO_FOR_LINE_ITEMS_REQUEST_DEVICES: "@publisher/set-extra-info-for-line-items-request-devices",
  SET_FINISH_LINE_ITEM_LOADING: "@publisher/set-finish_line_item_loading",
  SET_CREATIVE_FOR_LINE_ITEM: "@publisher/set-creative-for-line-item",
  SET_GAM_REPORT_DATA: "@publisher/set-gam-report-data",
  ADD_NEW_CREATIVE: "@publisher/add-new-creative",
  SET_TARGETED_LOCATIONS_FOR_LINE_ITEM: "@publisher/set-targeted-locations-for-line-item",

  DELETE_CREATIVE_ITEM: "@publisher/delete-creative-item",

  CHANGE_LINE_ITEM_STATE: "@publisher/change-line-item-state",

  GET_HOST_POLICY_ISSUES: "@publisher/get-policy-issues",
  SET_HOST_POLICY_ISSUES: "@publisher/set-policy-issues",
};

export const createCompanyRequest = (companyName) => ({
  type: actionTypes.CREATE_COMPANY_REQUEST,
  payload: {companyName},
});

export const getExtraInfoForLineItemsRequestAdUnits = (name) => ({
  type: actionTypes.GET_EXTRA_INFO_FOR_LINE_ITEMS_REQUEST_AD_UNITS,
  payload: {name},
});

export const getExtraInfoForLineItemsRequestLocations = () => ({
  type: actionTypes.GET_EXTRA_INFO_FOR_LINE_ITEMS_REQUEST_LOCATIONS,
});

export const getExtraInfoForLineItemsRequestDevices = () => ({
  type: actionTypes.GET_EXTRA_INFO_FOR_LINE_ITEMS_REQUEST_DEVICES,
});

export const createLineItemRequest = (lineItemName, orderName, costPerUnit, quantity, startTime, endTime, selectedAdUnitIds, selectedLocations, selectedDevices, widthAndHeight, callback, primaryGoalType, setShowProgresBar, setProgressBarPercent) => ({
  type: actionTypes.CREATE_LINE_ITEM_REQUEST,
  payload: {
    lineItemName,
    orderName,
    costPerUnit,
    quantity,
    startTime,
    endTime,
    selectedAdUnitIds,
    selectedLocations,
    selectedDevices,
    widthAndHeight,
    callback,
    primaryGoalType,
    setShowProgresBar,
    setProgressBarPercent
  },
});

export const addCreativeDataForLineItem = (AdvertiserName, LineItemName, CreativeDestinationUrl, formFile, callback, startTime, endTime, height, width, orderId, setShowProgresBar, setProgressBarPercent) => ({
  type: actionTypes.ADD_CREATIVE_DATA_FOR_LINE_ITEM,
  payload: {
    AdvertiserName,
    LineItemName,
    CreativeDestinationUrl,
    formFile,
    callback,
    startTime,
    endTime,
    height,
    width,
    orderId,
    setShowProgresBar,
    setProgressBarPercent
  },
});

export const getLinesItem = (orderName) => ({
  type: actionTypes.GET_LINES_ITEM,
  payload: {orderName},
});

export const setOrderCompanyData = (data) => ({
  type: actionTypes.SET_ORDER_COMPANY_DATA,
  payload: data
});

export const editLineItemData = (id, rate, quantity, startTime, endTime, height, width, adUnitIds, geoLocations, targetedDevice, callback, setShowProgresBar, setProgressBarPercent) => ({
  type: actionTypes.EDIT_LINE_ITEM_DATA,
  payload: {
    id,
    rate,
    quantity,
    startTime,
    endTime,
    height,
    width,
    adUnitIds,
    geoLocations,
    targetedDevice,
    callback,
    setShowProgresBar,
    setProgressBarPercent
  },
});

export const getCreativeForLineItem = (name) => ({
  type: actionTypes.GET_CREATIVE_FOR_LINE_ITEM,
  payload: {name},
});

export const updateCreativeData = (formFile, Id, lineitemName, CreativeDestinationUrl, callback, startTime, endTime) => ({
  type: actionTypes.UPDATE_CREATIVE_DATA,
  payload: {formFile, Id, lineitemName, CreativeDestinationUrl, callback, startTime, endTime},
});

export const getGamReportData = (itemsList, orderName, from, to, reportType, adUnitNames = [], country = [], deviceIds = [], selectedCreativeSizes = [], setShowProgresBar, setProgressBarPercent) => ({
  type: actionTypes.GET_GAM_REPORT_DATA,
  payload: {
    itemsList,
    orderName,
    from,
    to,
    reportType,
    adUnitNames,
    country,
    deviceIds,
    selectedCreativeSizes,
    setShowProgresBar,
    setProgressBarPercent
  },
});

export const setGamReportLoading = (value) => ({
  type: actionTypes.SET_GAM_REPORT_LOADING,
  payload: value,
});

export const getPrivacyIssues = () => ({
  type: actionTypes.GET_HOST_POLICY_ISSUES,
});

export const deleteCreativeItem = (lineItemId, creativeId) => ({
  type: actionTypes.DELETE_CREATIVE_ITEM,
  payload: {lineItemId, creativeId},
});

export const changeLineItemState = (id, state, callback) => ({
  type: actionTypes.CHANGE_LINE_ITEM_STATE,
  payload: {id, state, callback},
});

export const copyLineItemsRequest = (lineItemIds, count, withCreative, callback) => ({
  type: actionTypes.COPY_LINE_ITEMS_REQUEST,
  payload: {lineItemIds, count, withCreative, callback},
});

export const updateLineItemName = (id, name, callback) => ({
  type: actionTypes.UPDATE_LINE_ITEM_NAME,
  payload: {id, name, callback},
});

export const addNewCreativeAction = (newCreative) => ({
  type: actionTypes.ADD_NEW_CREATIVE,
  payload: newCreative,
});

export const getTargetedLocationsForLineItem = (lineItemId) => ({
  type: actionTypes.GET_TARGETED_LOCATIONS_FOR_LINE_ITEM,
  payload: {lineItemId},
});
