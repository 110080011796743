import { actionTypes } from '@/services/actions/notifications';

// ==== 🗄️ Reducer
export const initialState = {
  notifications: [],
  loaded: false,
};

export const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_NOTIFICATIONS_BY_HOST: {
      return { ...state, loaded: true };
    }
    case actionTypes.SET_NOTIFICATIONS: {
      return { ...state, notifications: action.payload, loaded: false };
    }
    default:
      return state;
  }
};

export default notificationsReducer;
