import axios from 'axios';

export const getMcm = (token) =>
  axios
    .get(`${process.env.API_BASE_URL}api/v1/GAM/GetMcmCompany`, {
      params: {},
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    });

export const createMcm = (token, childNetworkCode, gamEmail) =>
  axios
    .post(
      `${process.env.API_BASE_URL}api/v1/GAM/CreateMcmCompany`,
      {
        childNetworkCode,
        gamEmail,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      return response.data;
    });
