import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import Router from 'next/router';
import dayjs from 'dayjs';
import { toaster } from 'evergreen-ui';

import { hashCode } from '@/utils';
import {
  getCommentCountModeration,
  getTagsByHostModeration,
  getByFilterByPeriodPaged,
  setCommentStatus,
  setOffensive,
  setOffensiveByApiKey,
  setTopComment,
  setCommentEditedStatus,
  setArticleStatus,
  setArticleMeta,
  postComment,
  loadCommentThread,
  whitelistEmail,
  getCommentLikes,
  getCommentDislikes,
  getCommentFlags,
  getWhitelistedEmails,
  getFlaggingUsersPaged,
} from '@/services/api/moderation';
import { whitelistEmailByApiKey } from '@/services/api/settings';
import { actionTypes } from '@/services/actions/moderation';

export function* loadCommentsCount() {
  try {
    yield put({ type: actionTypes.SET_LOADING, payload: true });

    const token = yield select(({ session }) => session.token);
    const dateRange = yield select(({ filter }) => filter.dateRange);
    const host = yield select(({ filter }) => filter.host);
    const moderationStore = yield select(({ moderation }) => moderation);
    const { tag, search_type, search_param } = moderationStore;
    const Search_type = search_type
      ? search_type[0].toUpperCase() + search_type.substring(1)
      : search_type;

    if (token) {
      const result = yield call(
        getCommentCountModeration,
        token,
        dayjs.utc(dayjs(dateRange[0])).startOf('day').unix(),
        dayjs.utc(dayjs(dateRange[1])).endOf('day').unix(),
        host,
        tag,
        Search_type,
        Search_type === 'ContainLinks' ? true : search_param,
        0,
      );

      yield put({
        type: actionTypes.SET_COMMENTSCOUNT,
        payload: {
          pending: result.pendingComments,
          rejected: result.rejectedComments,
          approved: result.approvedComments,
          flagged: result.flaggedComments,
        },
      });
    }

    yield put({ type: actionTypes.SET_LOADING, payload: false });
  } catch (e) {
    console.log('err', e);
    yield put({ type: actionTypes.SET_ERROR });
  }
}

export function* loadTags() {
  try {
    yield put({ type: actionTypes.SET_LOADING, payload: true });

    const token = yield select(({ session }) => session.token);
    const dateRange = yield select(({ filter }) => filter.dateRange);
    const host = yield select(({ filter }) => filter.host);

    const result = yield call(
      getTagsByHostModeration,
      token,
      dayjs.utc(dayjs(dateRange[0])).startOf('day').unix(),
      dayjs.utc(dayjs(dateRange[1])).endOf('day').unix(),
      host,
    );

    yield put({
      type: actionTypes.SET_TAGS,
      payload: result.tags,
    });

    yield put({ type: actionTypes.SET_LOADING, payload: false });
  } catch (e) {
    console.log('err', e);
    yield put({ type: actionTypes.SET_ERROR });
  }
}

export function* loadFlaggingUsers() {
  try {
    const token = yield select(({ session }) => session.token);
    const dateRange = yield select(({ filter }) => filter.dateRange);
    const host = yield select(({ filter }) => filter.host);

    const result = yield call(
      getFlaggingUsersPaged,
      token,
      dayjs.utc(dayjs(dateRange[0])).startOf('day').unix(),
      dayjs.utc(dayjs(dateRange[1])).endOf('day').unix(),
      host,
    );

    yield put({
      type: actionTypes.FETCH_FLAGGING_USERS,
      payload: result,
    });
  } catch (e) {
    console.log('err', e);
    yield put({ type: actionTypes.SET_ERROR });
  }
}

export function* loadModerations() {
  try {
    yield put({ type: actionTypes.SET_LOADING, payload: true });

    const token = yield select(({ session }) => session.token);
    const dateRange = yield select(({ filter }) => filter.dateRange);
    const host = yield select(({ filter }) => filter.host);
    const moderationStore = yield select(({ moderation }) => moderation);
    const { pageSize, state, tag, search_type, search_param, sort_dir } =
      moderationStore;

    if (token) {
      const result = yield call(
        getByFilterByPeriodPaged,
        token,
        dayjs.utc(dayjs(dateRange[0])).startOf('day').unix(),
        dayjs.utc(dayjs(dateRange[1])).endOf('day').unix(),
        host,
        pageSize,
        state,
        tag,
        search_type,
        search_type === 'ContainLinks' ? true : search_param,
        0,
      );

      yield put({
        type: actionTypes.SET_LASTFETCHCOUNT,
        payload: result.items.length,
      });

      result.items.map((item) => {
        if (item.moderatorTimeStamp) {
          const start = dayjs.unix(item.createdTimestamp);
          const end = dayjs.unix(item.moderatorTimeStamp);
          const diff = Math.round(end.diff(start, 'minutes'));
          item.delay = diff;
        } else {
          item.delay = 0;
        }
        return item;
      });

      const moderations = result.items;
      moderations.sort((m1, m2) =>
        m1.createdTimestamp > m2.createdTimestamp ? -sort_dir : sort_dir,
      );

      yield put({
        type: actionTypes.SET_MODERATIONS,
        payload: moderations,
      });
    }

    yield put({ type: actionTypes.SET_MOD_SELECTS, payload: [] });

    yield put({ type: actionTypes.SET_LOADING, payload: false });
  } catch (e) {
    console.log('err', e);
    yield put({ type: actionTypes.SET_ERROR });
  }
}

export function* fetchModerations() {
  try {
    yield put({ type: actionTypes.SET_LOADING, payload: true });

    const token = yield select(({ session }) => session.token);
    const dateRange = yield select(({ filter }) => filter.dateRange);
    const host = yield select(({ filter }) => filter.host);
    const moderationStore = yield select(({ moderation }) => moderation);
    const { pageSize, state, tag, search_type, search_param, sort_dir } =
      moderationStore;

    const result = yield call(
      getByFilterByPeriodPaged,
      token,
      dayjs.utc(dayjs(dateRange[0])).startOf('day').unix(),
      dayjs.utc(dayjs(dateRange[1])).endOf('day').unix(),
      host,
      pageSize,
      state,
      tag,
      search_type,
      search_param,
      moderationStore.moderations.length,
    );

    yield put({
      type: actionTypes.SET_LASTFETCHCOUNT,
      payload: result.items.length,
    });

    result.items.map((item) => {
      if (item.moderatorTimeStamp) {
        const start = dayjs.unix(item.createdTimestamp);
        const end = dayjs.unix(item.moderatorTimeStamp);
        const diff = Math.round(end.diff(start, 'minutes'));
        item.delay = diff;
      } else {
        item.delay = 0;
      }
      return item;
    });

    const moderations =
      moderationStore.moderations &&
      moderationStore.moderations.concat(result.items);
    moderations.sort((m1, m2) =>
      m1.createdTimestamp > m2.createdTimestamp ? -sort_dir : sort_dir,
    );

    yield put({
      type: actionTypes.SET_MODERATIONS,
      payload: moderations,
    });

    yield put({ type: actionTypes.SET_LOADING, payload: false });
  } catch (e) {
    console.log('err', e);
    yield put({ type: actionTypes.SET_ERROR });
  }
}

function* syncRouterParams(key, value) {
  const { query } = Router;
  if (value != (query[key] || '')) {
    const { pathname } = Router;
    query[key] = value;
    Router.replace({ pathname, query });
  }
}

export function* onSetPageSize(params) {
  yield call(syncRouterParams, 'pageSize', params.payload);

  yield put({ type: actionTypes.LOAD_COMMENTSCOUNT });
  yield put({ type: actionTypes.LOAD_MODERATIONS });
}

export function* onSetState(params) {
  yield call(syncRouterParams, 'state', params.payload);

  yield put({ type: actionTypes.LOAD_COMMENTSCOUNT });
  yield put({ type: actionTypes.LOAD_MODERATIONS });
}

export function* onSetTag(params) {
  yield call(syncRouterParams, 'tag', params.payload);

  yield put({ type: actionTypes.LOAD_COMMENTSCOUNT });
  yield put({ type: actionTypes.LOAD_MODERATIONS });
}

export function* onRefreshRequest() {
  yield call(syncRouterParams, 'tag', '');
  yield call(syncRouterParams, 'search_type', '');
  yield call(syncRouterParams, 'search_param', '');
  yield call(syncRouterParams, 'pageSize', 25);
  yield call(syncRouterParams, 'sort_dir', 1);

  yield put({ type: actionTypes.LOAD_COMMENTSCOUNT });
  yield put({ type: actionTypes.LOAD_MODERATIONS });
}

export function* onSetSearchType(params) {
  yield call(syncRouterParams, 'search_type', params.payload);
  if (params.payload === 'ContainLinks') {
    yield call(syncRouterParams, 'search_param', 'true');
  }

  const moderationStore = yield select(({ moderation }) => moderation);
  if (moderationStore.search_param != '' || params.payload === 'ContainLinks') {
    yield put({ type: actionTypes.LOAD_MODERATIONS });
  }
}

export function* onSetSearchParam(params) {
  yield call(syncRouterParams, 'search_param', params.payload);

  const moderationStore = yield select(({ moderation }) => moderation);
  if (moderationStore.search_type != '') {
    yield put({ type: actionTypes.LOAD_COMMENTSCOUNT });
    yield put({ type: actionTypes.LOAD_MODERATIONS });
  }
}

export function* onSetSearchTypeAndParam(params) {
  const { type, param } = params.payload;
  yield call(syncRouterParams, 'search_type', type);
  yield call(syncRouterParams, 'search_param', param);

  yield put({ type: actionTypes.LOAD_COMMENTSCOUNT });
  yield put({ type: actionTypes.LOAD_MODERATIONS });
}

export function* onSetSortDir(params) {
  yield call(syncRouterParams, 'sort_dir', params.payload);
}

export function* onSetFilterParams(params) {}

export function* onSetCommentStatus(params) {
  try {
    const { commentIDs, state } = params.payload;

    const token = yield select(({ session }) => session.token);
    const moderationStore = yield select(({ moderation }) => moderation);

    yield call(setCommentStatus, token, commentIDs, state);

    const stateTypes = ['approved', 'rejected', 'pending', 'flagged'];
    const { commentsCount, moderations, mod_selects } = moderationStore;
    commentsCount[stateTypes[moderationStore.state]] -= commentIDs.length;
    commentsCount[stateTypes[state]] += commentIDs.length;
    yield put({
      type: actionTypes.SET_COMMENTSCOUNT,
      payload: commentsCount,
    });

    yield put({
      type: actionTypes.SET_MODERATIONS,
      payload: moderations.filter((item) => !commentIDs.includes(item.id)),
    });

    yield put({
      type: actionTypes.SET_MOD_SELECTS,
      payload: mod_selects.filter((item) => !commentIDs.includes(item)),
    });

    // yield put({ type: actionTypes.LOAD_COMMENTSCOUNT });
    // yield put({ type: actionTypes.LOAD_MODERATIONS });
    toaster.success(`Successfully ${stateTypes[state]}!`, {
      id: 'moderation-comment-status-change',
    });
  } catch (e) {
    console.log('err', e);
    yield put({ type: actionTypes.SET_ERROR });
    toaster.danger('Error. Please refresh the page and try again.', {
      id: 'moderation-comment-status-change',
    });
  }
}

export function* onSetOffensive(params) {
  const { offValues, offensiveType, note, sendEmailToUser, allSites } =
    params.payload;

  const { token, apiKey } = yield select(({ session }) => session);
  const host = yield select(({ filter }) => filter.host);

  try {
    yield call(
      !allSites ? setOffensive : setOffensiveByApiKey,
      token,
      !allSites ? host : apiKey,
      offValues,
      offensiveType,
      note || '',
      sendEmailToUser,
    );

    if (offensiveType == 2) {
      toaster.success('Success', {
        id: 'block-email',
        description:
          "The email is now added to the Blocked Commenter's Email List",
        duration: 5,
      });
    } else if (offensiveType == 1) {
      toaster.success('Success', {
        id: 'block-ip',
        description: `${offValues} blocked`,
        duration: 5,
      });
    }
  } catch (e) {
    console.log('err', e);

    let msg_description = '';

    const subject = {
      1: 'ip',
      2: 'email',
    };
    if (e.message === 'already_in_whitelist') {
      msg_description = `This ${subject[offensiveType]} is already whitelisted.`;
    } else if (e.message === 'already_blocked') {
      msg_description = `This ${subject[offensiveType]} is already blocked.`;
    } else if (e.message === 'already_in_blacklist') {
      msg_description = `This ${subject[offensiveType]} is blacklisted for ${host}`;
    } else if (e.message === 'in_master_blacklist') {
      msg_description = `This ${subject[offensiveType]} can not be whitelisted because they're blocked from using Vuukle globally.`;
    } else {
      msg_description = `Could not whitelist ${offValues}`;
    }
    toaster.danger('Error', {
      description: msg_description,
      duration: 5,
      id: 'moderation-comment-set-offensive',
    });
    yield put({ type: actionTypes.SET_ERROR });
  }
}

export function* onSetTopComment(params) {
  try {
    const token = yield select(({ session }) => session.token);
    const { commentID, topComment } = params.payload;

    yield call(setTopComment, token, commentID, topComment);
  } catch (e) {
    console.log('err', e);
    yield put({ type: actionTypes.SET_ERROR });
  }
}

export function* onSetCommentEditedStatus(params) {
  try {
    const token = yield select(({ session }) => session.token);
    const { commentIDs, newText, state } = params.payload;

    yield call(setCommentEditedStatus, token, commentIDs, newText, state);

    yield put({ type: actionTypes.LOAD_COMMENTSCOUNT });
    yield put({ type: actionTypes.LOAD_MODERATIONS });
    toaster.success('Success. Changes are successfully saved.');
  } catch (e) {
    console.log('err', e);
    yield put({ type: actionTypes.SET_ERROR });
    toaster.danger(
      'Error. Changes are not saved. Please refresh and try again.',
    );
  }
}

export function* onSetArticleStatus(params) {
  try {
    const token = yield select(({ session }) => session.token);
    const host = yield select(({ filter }) => filter.host);
    const { articleId, disabled, host: hostInCaseOfAllSites } = params.payload;

    yield call(
      setArticleStatus,
      token,
      host ? host : hostInCaseOfAllSites,
      articleId,
      disabled,
    );

    toaster.warning(`Article Commenting ${disabled ? 'Disabled' : 'Enabled'}`, {
      id: 'article-disable',
      duration: 5,
    });
  } catch (e) {
    console.log('err', e);
    toaster.danger(`${e}`);
    yield put({ type: actionTypes.SET_ERROR });
  }
}

export function* onSetArticleMeta(params) {
  try {
    const token = yield select(({ session }) => session.token);
    const host = yield select(({ filter }) => filter.host);
    const { articleId, title, uri, avatar, tags } = params.payload;

    yield call(
      setArticleMeta,
      token,
      host,
      articleId,
      title,
      uri,
      avatar,
      tags,
    );
    toaster.success('Success. Changes are successfully saved.');
  } catch (e) {
    console.log('err', e);
    yield put({ type: actionTypes.SET_ERROR });
    toaster.danger(
      'Error. Changes are not saved. Please refresh the page and try again.',
    );
  }
}

export function* onPostComment(params) {
  try {
    const token = yield select(({ session }) => session.token);
    const comment = params.payload;
    const r = hashCode(comment.commentText);
    const s = hashCode(comment.commentText + comment.apiKey);
    yield call(postComment, token, comment, r, s);
    toaster.success('Success. Reply has been successfully sent.', {
      id: 'moderation-comment-successful-reply',
    });
  } catch (e) {
    console.log('err', e);
    yield put({ type: actionTypes.SET_ERROR });
    toaster.danger(
      'Error. Reply was not sent. Please refresh the page and try again',
      { id: 'moderation-comment-error-reply' },
    );
  }
}

export function* onLoadCommentThread(params) {
  try {
    yield put({ type: actionTypes.SET_LOADINGTHREAD, payload: true });

    const token = yield select(({ session }) => session.token);

    const parentId = params.payload;

    const result = yield call(loadCommentThread, token, parentId);

    yield put({ type: actionTypes.SET_COMMENTTHREAD, payload: result });

    yield put({ type: actionTypes.SET_LOADINGTHREAD, payload: false });
  } catch (e) {
    console.log('err', e);
    yield put({ type: actionTypes.SET_ERROR });
  }
}

export function* onSelectMod(params) {
  const { mod_ids, sel } = params.payload;

  const moderationStore = yield select(({ moderation }) => moderation);
  const { mod_selects } = moderationStore;

  let mod_sels = [];
  if (sel) {
    const diff = mod_ids.filter((id) => !mod_selects.includes(id));
    mod_sels = mod_selects && mod_selects.concat(diff);
  } else {
    mod_sels = mod_selects && mod_selects.filter((id) => !mod_ids.includes(id));
  }
  yield put({ type: actionTypes.SET_MOD_SELECTS, payload: mod_sels });
}

export function* onSelectModAll(params) {
  const sel = params.payload;

  if (sel) {
    const moderations = yield select(
      ({ moderation }) => moderation.moderations,
    );
    const mod_sels = moderations.map((item) => item.id);

    yield put({ type: actionTypes.SET_MOD_SELECTS, payload: mod_sels });
  } else {
    yield put({ type: actionTypes.SET_MOD_SELECTS, payload: [] });
  }
}

export function* onWhitelistEmail(params) {
  const { email, note } = params.payload;
  const token = yield select(({ session }) => session.token);
  const host = yield select(({ filter }) => filter.host);
  const apiKey = yield select(({ session }) => session.apiKey);

  try {
    yield call(
      host ? whitelistEmail : whitelistEmailByApiKey,
      token,
      host || apiKey,
      email,
      note || '',
    );
    toaster.success('Success', {
      description: `${email} is now whitelisted`,
      duration: 5,
      id: 'whitelist-email-success',
    });
  } catch (e) {
    yield put({ type: actionTypes.SET_ERROR });
    if (e.message === 'already_whitelisted') {
      toaster.danger('Error', {
        description: 'This user is already whitelisted.',
        duration: 5,
        id: 'whitelist-email-error',
      });
    } else if (e.message === 'already_in_blacklist') {
      toaster.danger('Error', {
        description: `This user is blacklisted for ${host}`,
        duration: 5,
        id: 'whitelist-email-error',
      });
    } else if (e.message === 'in_master_blacklist') {
      toaster.danger('Error', {
        description:
          'This user can not be whitelisted because they are blocked from using Vuukle globally.',
        duration: 5,
        id: 'whitelist-email-error',
      });
    } else if (e.message === "admin_can't_be_blocked") {
      toaster.danger('Error', {
        description: "Admin can't be blocked.",
        duration: 5,
        id: 'whitelist-email-error',
      });
    } else {
      toaster.danger('Error', {
        description: `Could not whitelist ${email}`,
        duration: 5,
        id: 'whitelist-email-error',
      });
    }
  }
}

export function* whitelistFetchSaga() {
  const { host } = yield select(({ filter }) => filter);
  try {
    const token = yield select(({ session }) => session.token);

    const data = yield call(getWhitelistedEmails, token, host, 0);

    yield put({
      type: actionTypes.SET_WHITELISTED_EMAILS_FOR_HOST,
      payload: data,
    });
  } catch (e) {
    console.log('Something went wrong', e.message);
  }
}

export function* onLoadCommentActiveUsers(params) {
  try {
    const { type, host, commentId } = params.payload;
    const token = yield select(({ session }) => session.token);

    const result = yield call(
      type === 'like'
        ? getCommentLikes
        : type === 'dislike'
        ? getCommentDislikes
        : type === 'report'
        ? getCommentFlags
        : null,
      token,
      host,
      commentId,
    );

    yield put({ type: actionTypes.LOAD_COMMENT_ACTIVE_USERS, payload: result });
  } catch (err) {
    console.log(err);
  }
}

export default function* moderationSaga() {
  yield takeLatest(actionTypes.LOAD_COMMENTSCOUNT, loadCommentsCount);
  yield takeLatest(actionTypes.LOAD_TAGS, loadTags);
  yield takeLatest(actionTypes.LOAD_MODERATIONS, loadModerations);
  yield takeLatest(actionTypes.FETCH_MODERATIONS, fetchModerations);

  yield takeLatest(actionTypes.SET_PAGESIZE, onSetPageSize);
  yield takeLatest(actionTypes.SET_STATE, onSetState);
  yield takeLatest(actionTypes.SET_TAG, onSetTag);
  yield takeLatest(actionTypes.REFRESH_REQUEST, onRefreshRequest);
  yield takeLatest(actionTypes.SET_SEARCH_TYPE, onSetSearchType);
  yield takeLatest(actionTypes.SET_SEARCH_PARAM, onSetSearchParam);
  yield takeLatest(
    actionTypes.SET_SEARCH_TYPE_AND_PARAM,
    onSetSearchTypeAndParam,
  );
  yield takeLatest(actionTypes.SET_SORTDIR, onSetSortDir);
  yield takeLatest(actionTypes.SET_FILTER_PARAMS, onSetFilterParams);

  yield takeEvery(actionTypes.SET_COMMENTSTATUS, onSetCommentStatus);
  yield takeLatest(actionTypes.SET_OFFENSIVE, onSetOffensive);
  yield takeLatest(actionTypes.SET_TOPCOMMENT, onSetTopComment);
  yield takeLatest(
    actionTypes.SET_COMMENTEDITEDSTATUS,
    onSetCommentEditedStatus,
  );
  yield takeLatest(actionTypes.SET_ARTICLESTATUS, onSetArticleStatus);
  yield takeLatest(actionTypes.SET_ARTICLEMETA, onSetArticleMeta);
  yield takeLatest(actionTypes.POST_COMMENT, onPostComment);

  yield takeLatest(actionTypes.LOAD_COMMENTTHREAD, onLoadCommentThread);

  yield takeLatest(actionTypes.SELECT_MOD, onSelectMod);
  yield takeLatest(actionTypes.SELECT_MOD_ALL, onSelectModAll);

  yield takeLatest(actionTypes.WHITELIST_EMAIL, onWhitelistEmail);
  yield takeLatest(actionTypes.LOAD_FLAGGING_USERS, loadFlaggingUsers);
  yield takeLatest(
    actionTypes.SET_COMMENT_ACTIVE_USERS_TYPE,
    onLoadCommentActiveUsers,
  );
  yield takeLatest(
    actionTypes.GET_WHITELISTED_EMAILS_FOR_HOST,
    whitelistFetchSaga,
  );
}
