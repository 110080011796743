import { call, put, select, takeLatest } from 'redux-saga/effects';
import {fetchSchedules, fetchTimezones, getActiveSurveys} from '@/services/api/report';
import { actionTypes } from '@/services/actions/report';

export function* loadSchedules() {
  try {
    const token = yield select(({ session }) => session.token);

    const result = yield call(fetchSchedules, token);

    yield put({
      type: actionTypes.SET_SCHEDULES,
      payload: result,
    });
  } catch (e) {
    console.log('err', e);
    yield put({ type: actionTypes.SET_ERROR });
  }
}

export function* loadActiveSurveysSaga() {
  try {
    const token = yield select(({ session }) => session.token);

    const result = yield call(getActiveSurveys, token);

    yield put({
      type: actionTypes.SET_ACTIVE_SURVEYS,
      payload: result,
    });
  } catch (e) {
    console.log('err', e);
    yield put({ type: actionTypes.SET_ERROR });
  }
}

export function* loadTimezones() {
  try {
    const token = yield select(({ session }) => session.token);

    const result = yield call(fetchTimezones, token);

    yield put({
      type: actionTypes.SET_TIMEZONES,
      payload: result,
    });
  } catch (e) {
    console.log('err', e);
    yield put({ type: actionTypes.SET_ERROR });
  }
}

export default function* reportSaga() {
  yield takeLatest(actionTypes.LOAD_SCHEDULES, loadSchedules);
  yield takeLatest(actionTypes.LOAD_TIMEZONES, loadTimezones);
  yield takeLatest(actionTypes.LOAD_ACTIVE_SURVEYS, loadActiveSurveysSaga);
}
