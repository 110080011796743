import { call, put, select, takeLatest } from 'redux-saga/effects';
import { toaster } from 'evergreen-ui';

import {
  deleteBrandSurveyApi,
  editSurveyApi,
  getQuestionsByBrandIDApi,
  getSurveyDataApi,
  getSurveyQuestionsAnswersAPI,
  getSurveyQuestionStatsAPI,
  getSurveyQuestionStatsDemographicAPI,
  getSurveyQuestionStatsDemographicGroupAPI,
  getSurveyQuestionStatsPerDayAPI,
  getSurveyStatsByCityAPI,
  getSurveyStatsByDayAPI,
  getSurveyStatsByGeoAPI,
  copyQuizSurveyApi,
  runSurveyApi,
  getTableDataAPI,
  getSurveyStatsByAgeAPI,
  getSurveyStatsByGenderAPI,
  getShareAnalyticsApi,
  getShareAnalyticsTokenApi,
  getSurveyDataSingleApi,
  getQuizzlyLeadsClickCountApi,
  getResponseGroupStatsAPI,
  getCompletionRateAPI,
  getSurveysAllApi,
  getEmailsListApi,
  getSurveyReportApi,
  getCitiesAPI,
} from '@/services/api/brandSurvey';
import { actionTypes } from '@/services/actions/brandSurvey';

export function* createSurvey(params) {
  try {
    const token = yield select(({ session }) => session.token);
    const { runSurvey } = params.payload;
    const data = yield call(runSurveyApi, token, runSurvey);
    if (data.success) {
      toaster.success('Successfully created');
    } else {
      toaster.danger(data.errors[0] ? data.errors[0] : 'Something went wrong');
    }
    yield put({
      type: actionTypes.POST_SURVEY_DATA_SUCCESS,
      // data,
    });
  } catch (e) {
    console.log('err', e);
    toaster.danger(`${e}`);
  }
}

export function* copySurveySaga(params) {
  try {
    const token = yield select(({ session }) => session.token);
    const { quizBrandSurveyId, host } = params.payload;
    const data = yield call(copyQuizSurveyApi, token, quizBrandSurveyId, host);
    if (data.success) {
      toaster.success('Successfully created');
    } else {
      toaster.danger(data.errors[0] ? data.errors[0] : 'Something went wrong');
    }
    yield put({
      type: actionTypes.POST_COPY_SURVEY_SUCCESS,
    });
  } catch (e) {
    console.log('err', e);
    toaster.danger(`${e}`);
  }
}

export function* editSurvey(params) {
  try {
    const token = yield select(({ session }) => session.token);
    const { surveyData, showStatus, state, deleteAction } = params.payload;
    const data = yield call(editSurveyApi, token, surveyData);

    if (data.success) {
      toaster.success(
        showStatus
          ? state === 1
            ? 'Successfully started'
            : 'Successfully paused'
          : deleteAction
          ? 'Question successfully removed from survey'
          : 'Successfully edited',
      );
    } else {
      toaster.danger(data.errors[0] ? data.errors[0] : 'Something went wrong');
    }
    yield put({
      type: actionTypes.PUT_SURVEY_DATA_SUCCESS,
      // data,
    });
  } catch (e) {
    console.log('err', e);
    toaster.danger(`${e}`);
  }
}

export function* getSurveyData(params) {
  try {
    const token = yield select(({ session }) => session.token);
    const { host } = params.payload;
    const data = yield call(getSurveyDataApi, token, host);

    yield put({
      type: actionTypes.GET_SURVEY_DATA_SUCCESS,
      data,
    });
  } catch (e) {
    console.log('err', e);
    toaster.danger(`${e}`);
  }
}

export function* getCitiesSaga() {
  try {
    const data = yield call(getCitiesAPI);

    yield put({
      type: actionTypes.GET_CITIES_SUCCESS,
      data,
    });
  } catch (e) {
    console.log('err', e);
    toaster.danger(`${e}`);
  }
}

export function* getEmailsData(params) {
  try {
    const token = yield select(({ session }) => session.token);
    const { surveyId, questionId } = params.payload;
    const data = yield call(getEmailsListApi, token, surveyId, questionId);

    yield put({
      type: actionTypes.GET_EMAILS_LIST_SUCCESS,
      data,
    });
  } catch (e) {
    console.log('err', e);
    toaster.danger(`${e}`);
  }
}

export function* getReportData(params) {
  try {
    const token = yield select(({ session }) => session.token);
    const { id, from, to } = params.payload;
    const data = yield call(getSurveyReportApi, token, id, from, to);

    yield put({
      type: actionTypes.GET_REPORT_DATA_SUCCESS,
      data,
    });
  } catch (e) {
    console.log('err', e);
    toaster.danger(`${e}`);
  }
}

export function* getSurveySingleData(params) {
  try {
    const token = yield select(({ session }) => session.token);
    const { host, share_token, surveyId } = params.payload;
    const data = yield call(
      getSurveyDataSingleApi,
      token,
      host,
      share_token,
      surveyId,
    );

    yield put({
      type: actionTypes.GET_SURVEY_SINGLE_DATA_SUCCESS,
      data,
    });
  } catch (e) {
    console.log('err', e);

    yield put({
      type: actionTypes.GET_SURVEY_SINGLE_DATA_SUCCESS,
      data: e.response.status === 403 ? 'ACCESS DENIED' : null,
    });
    toaster.danger(`${e}`);
  }
}

export function* getBrandQuestions(params) {
  try {
    const token = yield select(({ session }) => session.token);
    const { brandId } = params.payload;
    const data = yield call(getQuestionsByBrandIDApi, token, brandId);

    yield put({
      type: actionTypes.GET_BRAND_QUESTIONS_BY_BRAND_ID_SUCCESS,
      data,
    });
  } catch (e) {
    console.log('err', e);
    toaster.danger(`${e}`);
  }
}

export function* getStatsBySurveyIdData(params) {
  try {
    const token = yield select(({ session }) => session.token);
    const { surveyId, from, to } = params.payload;
    const data = yield call(getSurveyStatsByDayAPI, token, surveyId, from, to);

    yield put({
      type: actionTypes.GET_STATS_BY_SURVEY_ID_DATA_SUCCESS,
      data,
    });
  } catch (e) {
    console.log('err', e);
    toaster.danger(`${e}`);
  }
}

export function* getStatsTableBySurveyIdData(params) {
  try {
    const token = yield select(({ session }) => session.token);
    const { surveyId, from, to, share_token } = params.payload;
    const data = yield call(
      getTableDataAPI,
      token,
      surveyId,
      from,
      to,
      share_token,
    );

    yield put({
      type: actionTypes.GET_TABLE_STATS_SUCCESS,
      data,
    });
  } catch (e) {
    console.log('err', e);
    toaster.danger(`${e}`);
  }
}

export function* getStatsByQuestionSurveyIdData(params) {
  try {
    const token = yield select(({ session }) => session.token);
    const { surveyId, from, to, questionId, share_token } = params.payload;
    const data = yield call(
      getSurveyQuestionStatsAPI,
      token,
      surveyId,
      from,
      to,
      questionId,
      share_token,
    );

    yield put({
      type: actionTypes.GET_SURVEY_QUESTIONS_STATS_DATA_SUCCESS,
      data,
    });
  } catch (e) {
    console.log('err', e);
    toaster.danger(`${e}`);
  }
}

export function* getStatsResponseGroupData(params) {
  try {
    const token = yield select(({ session }) => session.token);
    const { surveyId, from, to, share_token } = params.payload;
    const data = yield call(
      getResponseGroupStatsAPI,
      token,
      surveyId,
      from,
      to,
      share_token,
    );

    yield put({
      type: actionTypes.GET_RESPONSE_GROUP_STATS_SUCCESS,
      data,
    });
  } catch (e) {
    console.log('err', e);
    toaster.danger(`${e}`);
  }
}

export function* getSurveysSaga() {
  try {
    const token = yield select(({ session }) => session.token);
    const data = yield call(getSurveysAllApi, token);

    yield put({
      type: actionTypes.GET_SURVEYS_DATA_SUCCESS,
      data: data.flatMap(({ host, surveys }) =>
        surveys.map((survey) => ({ ...survey, host })),
      ),
    });
  } catch (e) {
    console.log('err', e);
    toaster.danger(`${e}`);
  }
}

export function* getCompletionRate(params) {
  try {
    const token = yield select(({ session }) => session.token);
    const { surveyId, from, to, share_token } = params.payload;
    const data = yield call(
      getCompletionRateAPI,
      token,
      surveyId,
      from,
      to,
      share_token,
    );

    yield put({
      type: actionTypes.GET_COMPLETION_RATE_SUCCESS,
      data,
    });
  } catch (e) {
    console.log('err', e);
    toaster.danger(`${e}`);
  }
}

export function* getAnalyticsAccessCodeSaga(params) {
  try {
    const token = yield select(({ session }) => session.token);
    const { surveyId, ExpiresAt, filters } = params.payload;
    const data = yield call(
      getShareAnalyticsApi,
      token,
      surveyId,
      ExpiresAt,
      filters,
    );

    yield put({
      type: actionTypes.GET_ANALYTICS_ACCESS_CODE_SUCCESS,
      data: data.code,
    });
  } catch (e) {
    console.log('err', e);
    toaster.danger(`${e}`);
  }
}

export function* getAnalyticsTokenSaga(params) {
  try {
    const { code } = params.payload;
    const data = yield call(getShareAnalyticsTokenApi, code);

    yield put({
      type: actionTypes.GET_SHARED_ANALYTICS_TOKEN_SUCCESS,
      data,
    });
  } catch (e) {
    console.log('err', e);
    toaster.danger(`${e}`);
  }
}

export function* getStatsByQuestionOptionSurveyIdData(params) {
  try {
    const token = yield select(({ session }) => session.token);
    const { surveyId, from, to, questionId, option, share_token } =
      params.payload;
    const data = yield call(
      getSurveyQuestionStatsDemographicAPI,
      token,
      surveyId,
      from,
      to,
      questionId,
      option,
      share_token,
    );

    const group = yield call(
      getSurveyQuestionStatsDemographicGroupAPI,
      token,
      surveyId,
      from,
      to,
      questionId,
      option,
      share_token,
    );

    yield put({
      type: actionTypes.GET_SURVEY_QUESTIONS_DEMOGRAPHIC_DATA_SUCCESS,
      data,
      group,
    });
  } catch (e) {
    console.log('err', e);
    toaster.danger(`${e}`);
  }
}

export function* getStatsByQuestionSurveyData(params) {
  try {
    const token = yield select(({ session }) => session.token);
    const { surveyId, from, to, share_token } = params.payload;
    const data = yield call(
      getSurveyQuestionsAnswersAPI,
      token,
      surveyId,
      from,
      to,
      share_token,
    );
    yield put({
      type: actionTypes.GET_QUESTIONS_STATS_DATA_SUCCESS,
      data,
    });
  } catch (e) {
    console.log('err', e);
    toaster.danger(`${e}`);
  }
}

export function* getStatsByQuestionPerDaySurveyData(params) {
  try {
    const token = yield select(({ session }) => session.token);
    const { surveyId, from, to } = params.payload;
    const data = yield call(
      getSurveyQuestionStatsPerDayAPI,
      token,
      surveyId,
      from,
      to,
    );
    yield put({
      type: actionTypes.GET_QUESTIONS_STATS_DATA_PER_DAY_SUCCESS,
      data,
    });
  } catch (e) {
    console.log('err', e);
    toaster.danger(`${e}`);
  }
}

export function* getStatsByGeo(params) {
  try {
    const token = yield select(({ session }) => session.token);
    const { surveyId, from, to, share_token, questionId, option } =
      params.payload;
    const data = yield call(
      getSurveyStatsByGeoAPI,
      token,
      surveyId,
      from,
      to,
      share_token,
      questionId,
      option,
    );
    yield put({
      type: actionTypes.GET_STATS_BY_GEO_SUCCESS,
      data,
    });
  } catch (e) {
    console.log('err', e);
    toaster.danger(`${e}`);
  }
}

export function* getStatsByCity(params) {
  try {
    const token = yield select(({ session }) => session.token);
    const { surveyId, from, to, share_token, questionId, option } =
      params.payload;
    const data = yield call(
      getSurveyStatsByCityAPI,
      token,
      surveyId,
      from,
      to,
      share_token,
      questionId,
      option,
    );
    yield put({
      type: actionTypes.GET_STATS_BY_CITY_SUCCESS,
      data,
    });
  } catch (e) {
    console.log('err', e);
    toaster.danger(`${e}`);
  }
}

export function* getStatsByAge(params) {
  try {
    const token = yield select(({ session }) => session.token);
    const { surveyId, from, to, share_token } = params.payload;
    const data = yield call(
      getSurveyStatsByAgeAPI,
      token,
      surveyId,
      from,
      to,
      share_token,
    );
    yield put({
      type: actionTypes.GET_STATS_BY_AGE_SUCCESS,
      data,
    });
  } catch (e) {
    console.log('err', e);
    toaster.danger(`${e}`);
  }
}

export function* getStatsByGender(params) {
  try {
    const token = yield select(({ session }) => session.token);
    const { surveyId, from, to, share_token } = params.payload;
    const data = yield call(
      getSurveyStatsByGenderAPI,
      token,
      surveyId,
      from,
      to,
      share_token,
    );
    yield put({
      type: actionTypes.GET_STATS_BY_GENDER_SUCCESS,
      data,
    });
  } catch (e) {
    console.log('err', e);
    toaster.danger(`${e}`);
  }
}

export function* getLeadClicksSaga(params) {
  try {
    const token = yield select(({ session }) => session.token);
    const { surveyId, from, to, share_token } = params.payload;
    const data = yield call(
      getQuizzlyLeadsClickCountApi,
      token,
      surveyId,
      from,
      to,
      share_token,
    );
    yield put({
      type: actionTypes.GET_LEADS_CLICK_SUCCESS,
      data: data[0],
    });
  } catch (e) {
    console.log('err', e);
    toaster.danger(`${e}`);
  }
}

export function* deleteBrandSurvey(params) {
  try {
    const token = yield select(({ session }) => session.token);
    const { id } = params.payload;
    const success = yield call(deleteBrandSurveyApi, token, id);

    if (success) {
      yield put({
        type: actionTypes.DELETE_SURVEY_DATA_SUCCESS,
      });
      toaster.success('Successfully deleted');
    }
  } catch (e) {
    console.log('err', e);
    toaster.danger(`${e}`);
  }
}

export default function* brandSurveySaga() {
  yield takeLatest(actionTypes.POST_SURVEY_DATA_REQUEST, createSurvey);
  yield takeLatest(
    actionTypes.GET_SHARED_ANALYTICS_TOKEN_REQUEST,
    getAnalyticsTokenSaga,
  );
  yield takeLatest(
    actionTypes.GET_ANALYTICS_ACCESS_CODE_REQUEST,
    getAnalyticsAccessCodeSaga,
  );
  yield takeLatest(actionTypes.PUT_SURVEY_DATA_REQUEST, editSurvey);
  yield takeLatest(actionTypes.GET_EMAILS_LIST_REQUEST, getEmailsData);
  yield takeLatest(actionTypes.GET_REPORT_DATA_REQUEST, getReportData);
  yield takeLatest(actionTypes.GET_SURVEY_DATA_REQUEST, getSurveyData);
  yield takeLatest(
    actionTypes.GET_SURVEY_SINGLE_DATA_REQUEST,
    getSurveySingleData,
  );
  yield takeLatest(actionTypes.GET_STATS_BY_GEO_REQUEST, getStatsByGeo);
  yield takeLatest(actionTypes.GET_STATS_BY_CITY_REQUEST, getStatsByCity);
  yield takeLatest(
    actionTypes.GET_RESPONSE_GROUP_STATS_REQUEST,
    getStatsResponseGroupData,
  );
  yield takeLatest(actionTypes.GET_COMPLETION_RATE_REQUEST, getCompletionRate);
  yield takeLatest(actionTypes.GET_STATS_BY_AGE_REQUEST, getStatsByAge);
  yield takeLatest(actionTypes.GET_STATS_BY_GENDER_REQUEST, getStatsByGender);
  yield takeLatest(actionTypes.GET_LEADS_CLICK_REQUEST, getLeadClicksSaga);
  yield takeLatest(actionTypes.POST_COPY_SURVEY_REQUEST, copySurveySaga);
  yield takeLatest(actionTypes.GET_SURVEYS_DATA_REQUEST, getSurveysSaga);
  yield takeLatest(actionTypes.GET_CITIES_REQUEST, getCitiesSaga);
  yield takeLatest(
    actionTypes.GET_BRAND_QUESTIONS_BY_BRAND_ID_REQUEST,
    getBrandQuestions,
  );
  yield takeLatest(
    actionTypes.GET_STATS_BY_SURVEY_ID_DATA_REQUEST,
    getStatsBySurveyIdData,
  );
  yield takeLatest(
    actionTypes.GET_TABLE_STATS_REQUEST,
    getStatsTableBySurveyIdData,
  );
  yield takeLatest(
    actionTypes.GET_QUESTIONS_STATS_DATA_REQUEST,
    getStatsByQuestionSurveyData,
  );
  yield takeLatest(
    actionTypes.GET_QUESTIONS_STATS_DATA_PER_DAY_REQUEST,
    getStatsByQuestionPerDaySurveyData,
  );
  yield takeLatest(actionTypes.DELETE_SURVEY_DATA_REQUEST, deleteBrandSurvey);
  yield takeLatest(
    actionTypes.GET_SURVEY_QUESTIONS_STATS_DATA_REQUEST,
    getStatsByQuestionSurveyIdData,
  );
  yield takeLatest(
    actionTypes.GET_SURVEY_QUESTIONS_DEMOGRAPHIC_DATA_REQUEST,
    getStatsByQuestionOptionSurveyIdData,
  );
}
