// ==== 💪 Action Types
export const actionTypes = {
  LOAD_MCM: '@mcm/load-mcm',
  SET_MCM: '@mcm/set-mcm',

  LOAD_CREATE_MCM: '@mcm/load-create-mcm',
  SET_CREATE_MCM: '@mcm/set-create-mcm',
};

// ==== 🎬 Actions
export const loadMcm = () => ({
  type: actionTypes.LOAD_MCM,
});

export const createMcm = (childNetworkCode, gamEmail) => ({
  type: actionTypes.LOAD_CREATE_MCM,
  payload: {childNetworkCode, gamEmail}
});
