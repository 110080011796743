import axios from 'axios';

export const getBillingInfo = (token) =>
  axios
    .get(`${process.env.API_BASE_URL}api/v1/HostBillingInfos/billing`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      throw new Error(errors[0]);
    });

export const setBillingInfo = (
  token,
  apiKey,
  {
    firstName,
    lastName,
    companyName,
    companyAddress,
    bankAcNo,
    bankCity,
    bankName,
    branchName,
    accountType,
    swift,
    achRoutingNumber,
    paypalEmailID,
  },
) =>
  axios
    .post(
      `${process.env.API_BASE_URL}api/v1/HostBillingInfos/billing`,
      {
        apiKey,
        companyName,
        firstName,
        lastName,
        companyAddress,
        bankAcNo,
        bankCity,
        bankName,
        branchName,
        accountType,
        swift,
        achRoutingNumber,
        paypalEmailID,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      throw new Error(errors[0]);
    });

export const updateBillingInfo = (
  token,
  apiKey,
  {
    companyName,
    companyAddress,
    firstName,
    lastName,
    bankAcNo,
    bankCity,
    bankName,
    branchName,
    accountType,
    swift,
    achRoutingNumber,
    paypalEmailID,
  },
) =>
  axios
    .put(
      `${process.env.API_BASE_URL}api/v1/HostBillingInfos/billing`,
      {
        apiKey,
        companyName,
        companyAddress,
        bankAcNo,
        bankCity,
        firstName,
        lastName,
        bankName,
        branchName,
        accountType,
        swift,
        achRoutingNumber,
        paypalEmailID,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      throw new Error(errors[0]);
    });
