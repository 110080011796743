import { actionTypes } from '@/services/actions/settings/comments/userlists';

const initialState = {
  offensiveEmail: [],
  offensiveIp: [],
  offensiveKeywords: [],
  offensiveUserName: [],
  whitelistedEmail: {},
  whitelistedEmailCounts: {},
  hasNextWhitelistPage: false,
  whiteListLoading: false,
  error: false,
  loading: false,
  loadingMod: false,
};

// TODO: Move some logic from here to sagas or find simpler solutions, eliminate possible dead code
const userlistSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_INITIAL_USERLIST_DATA:
      return { ...state, ...action.payload };
    case actionTypes.BLOCK_EMAIL_SUCCESS:
      if (action.allSites) {
        return {
          ...state,
          loading: false,
          loadingMod: false,
          offensiveEmail: [
            ...action.emails.split(','),
            ...state.offensiveEmail,
          ],
        };
      }
      return { ...state, loading: false, loadingMod: false, offensiveEmail: action.payload };
    case actionTypes.UNBLOCK_EMAIL_SUCCESS:
      if (action.allSites) {
        const emails = action.emails.split(',');
        return {
          ...state,
          loading: false,
          loadingMod: false,
          offensiveEmail: [
            ...state.offensiveEmail.filter((email) => !emails.includes(email)),
          ],
        };
      }
      return { ...state, loading: false, loadingMod: false, offensiveEmail: action.payload };
    case actionTypes.BLOCK_IP_SUCCESS:
      if (action.allSites) {
        return {
          ...state,
          loading: false,
          offensiveIp: [...action.ips.split(','), ...state.offensiveIp],
        };
      }
      return { ...state, loading: false, offensiveIp: action.payload };
    case actionTypes.UNBLOCK_IP_SUCCESS:
      if (action.allSites) {
        const ips = action.ips.split(',');
        return {
          ...state,
          loading: false,
          offensiveIp: [...state.offensiveIp.filter((ip) => !ips.includes(ip))],
        };
      }
      return { ...state, loading: false, offensiveIp: action.payload };
    case actionTypes.BLOCK_USERNAME_SUCCESS:
      if (action.allSites) {
        return {
          ...state,
          loading: false,
          offensiveUserName: [
            ...action.usernames.split(','),
            ...state.offensiveUserName,
          ],
        };
      }
      return { ...state, loading: false, offensiveUserName: action.payload };
    case actionTypes.BLOCK_WORD_SUCCESS:
      if (action.allSites) {
        return {
          ...state,
          loading: false,
          offensiveKeywords: [
            ...action.words.split(','),
            ...state.offensiveKeywords,
          ],
        };
      }
      return { ...state, loading: false, offensiveKeywords: action.payload };
    case actionTypes.UNBLOCK_USERNAME_SUCCESS:
      if (action.allSites) {
        const usernames = action.usernames.split(',');
        return {
          ...state,
          loading: false,
          offensiveUserName: [
            ...state.offensiveUserName.filter(
              (word) => !usernames.includes(word),
            ),
          ],
        };
      }
      return { ...state, loading: false, offensiveUserName: action.payload };
    case actionTypes.UNBLOCK_WORD_SUCCESS:
      if (action.allSites) {
        const words = action.words.split(',');
        return {
          ...state,
          loading: false,
          offensiveKeywords: [
            ...state.offensiveKeywords.filter((word) => !words.includes(word)),
          ],
        };
      }
      return { ...state, loading: false, offensiveKeywords: action.payload };
    case actionTypes.WHITELIST_COUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        whiteListLoading: false,
        whitelistedEmailCounts: {
          ...state.whitelistedEmailCounts,
          [action.host]: action.count,
        },
      };

    case actionTypes.WHITELIST_GET_SUCCESS:
      const fetchedEmailList = state.whitelistedEmail[action.host]
        ? [...state.whitelistedEmail[action.host], ...action.emails]
        : [...action.emails];
      return {
        ...state,
        loading: false,
        whiteListLoading: false,
        whitelistedEmail: {
          ...state.whitelistedEmail,
          [action.host]: [...fetchedEmailList],
        },
        hasNextWhitelistPage:
          action.emails.length === 10 ||
          fetchedEmailList.length === 10 ||
          fetchedEmailList % 10 === 0,
      };

    case actionTypes.WHITELIST_REMOVE_EMAIL_SUCCESS:
      const emailsToRemove = action.emails.split(',');
      const currentEmailsForRemove = { ...state.whitelistedEmail };
      if (action.allSites && currentEmailsForRemove) {
        const hosts = Object.keys(currentEmailsForRemove);
        if (hosts.length >= 0) {
          for (const key in currentEmailsForRemove) {
            currentEmailsForRemove[key] = currentEmailsForRemove[key].filter(
              (email) => !emailsToRemove.includes(email),
            );
          }

          return {
            ...state,
            loading: false,
            whiteListLoading: false,
            whitelistedEmail: {
              ...state.whitelistedEmail,
              ...currentEmailsForRemove,
            },
          };
        }
      } else {
        return {
          ...state,
          loading: false,
          whiteListLoading: false,
          whitelistedEmail: {
            ...state.whitelistedEmail,
            [action.host]: [
              ...state.whitelistedEmail[action.host].filter(
                (email) => !emailsToRemove.includes(email),
              ),
            ],
          },
        };
      }

    case actionTypes.WHITELIST_ADD_EMAIL_SUCCESS:
      const emailsToAdd = action.emails.split(',');
      const currentEmailsForAdd = { ...state.whitelistedEmail };
      if (action.allSites && currentEmailsForAdd) {
        const hosts = Object.keys(currentEmailsForAdd);
        if (hosts.length >= 0) {
          for (const key in currentEmailsForAdd) {
            currentEmailsForAdd[key] = [
              ...state.whitelistedEmail[key],
              ...emailsToAdd,
            ];
          }

          return {
            ...state,
            loading: false,
            whiteListLoading: false,
            whitelistedEmail: {
              ...state.whitelistedEmail,
              ...currentEmailsForAdd,
            },
          };
        }
      } else {
        return {
          ...state,
          loading: false,
          whiteListLoading: false,
          whitelistedEmail: {
            ...state.whitelistedEmail,
            [action.host]: state.whitelistedEmail[action.host]
              ? [...state.whitelistedEmail[action.host], ...emailsToAdd]
              : [...emailsToAdd],
          },
        };
      }

    case actionTypes.BLOCK_EMAIL_REQUEST:
      return { ...state, loadingMod: true };
    case actionTypes.BLOCK_IP_REQUEST:
    case actionTypes.BLOCK_WORD_REQUEST:
    case actionTypes.BLOCK_USERNAME_REQUEST:
    case actionTypes.UNBLOCK_EMAIL_REQUEST: {
      return { ...state, loadingMod: true };
    }
    case actionTypes.UNBLOCK_IP_REQUEST:
    case actionTypes.UNBLOCK_WORD_REQUEST:
    case actionTypes.UNBLOCK_USERNAME_REQUEST:
      return { ...state, loading: true };
    case actionTypes.WHITELIST_GET_REQUEST:
    case actionTypes.WHITELIST_ADD_EMAIL_REQUEST:
    case actionTypes.WHITELIST_REMOVE_EMAIL_REQUEST:
    case actionTypes.WHITELIST_COUNT_REQUEST:
      return { ...state, loading: true, whiteListLoading: true };
    case actionTypes.SET_FLAG_ERROR:
      return { ...state, error: true };
    case actionTypes.BLOCK_ERROR:
      return { ...state, whiteListLoading: false, loading: false,  loadingMod: false };
    default:
      return state;
  }
};

export default userlistSettingsReducer;
