import { actionTypes } from '@/services/actions/brandSurvey';
import { colors5 } from '@/utils';

// ==== 🗄️ Reducer
export const initialState = {
  create_loading: false,
  edit_loading: false,
  delete_loading: false,
  copy_loading: false,

  cities: null,

  data_loading: false,
  data: [],

  emails_loading: false,
  emails: null,

  surveys_all_loading: false,
  surveys_all: [],

  data_single_loading: false,
  data_single: [],
  access_denied: false,

  data_table_loading: false,
  data_table: [],

  brand_questions_loading: false,
  brand_questions: [],

  stats_loading: false,
  stats: null,

  report_data_loading: false,
  report_data: null,

  share_code_loading: false,
  share_code: '',

  share_token_loading: false,
  share_token: '',
  share_filters: {},

  stats_by_geo_loading: false,
  stats_by_geo: null,

  stats_group_loading: false,
  stats_group: null,

  completion_rate_loading: false,
  completion_rate: null,

  stats_by_city_loading: false,
  stats_by_city: null,

  lead_clicks_loading: false,
  lead_clicks: null,

  stats_by_age_loading: false,
  stats_by_age: null,

  stats_by_gender_loading: false,
  stats_by_gender: null,

  stats_by_question_loading: false,
  stats_by_question: null,

  stats_by_single_question_loading: false,
  stats_by_single_question: null,

  stats_by_single_question_answer_loading: false,
  stats_by_single_question_answer: null,
  stats_by_single_question_answer_group: null,

  stats_by_question_per_day_loading: false,
  stats_by_question_per_day: null,

  active_survey_amount: 0,
};

export const brandSurveyReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.POST_SURVEY_DATA_REQUEST: {
      return { ...state, create_loading: true };
    }
    case actionTypes.POST_SURVEY_DATA_SUCCESS: {
      return {
        ...state,
        create_loading: false,
      };
    }
    case actionTypes.PUT_SURVEY_DATA_REQUEST: {
      return { ...state, edit_loading: true };
    }
    case actionTypes.GET_CITIES_REQUEST: {
      return { ...state };
    }
    case actionTypes.GET_CITIES_SUCCESS: {
      return {
        ...state,
        cities: action.data,
      };
    }
    case actionTypes.PUT_SURVEY_DATA_SUCCESS: {
      return {
        ...state,
        edit_loading: false,
      };
    }
    case actionTypes.POST_COPY_SURVEY_REQUEST: {
      return { ...state, copy_loading: true };
    }
    case actionTypes.POST_COPY_SURVEY_SUCCESS: {
      return {
        ...state,
        copy_loading: false,
      };
    }
    case actionTypes.GET_SURVEYS_DATA_REQUEST: {
      return { ...state, surveys_all_loading: true };
    }
    case actionTypes.GET_SURVEYS_DATA_SUCCESS: {
      return {
        ...state,
        surveys_all_loading: false,
        surveys_all: action.data,
      };
    }
    case actionTypes.GET_TABLE_STATS_REQUEST: {
      return { ...state, data_table_loading: true };
    }
    case actionTypes.GET_TABLE_STATS_SUCCESS: {
      return {
        ...state,
        data_table_loading: false,
        data_table: action.data,
      };
    }
    case actionTypes.GET_SURVEY_DATA_REQUEST: {
      return { ...state, data_loading: true };
    }
    case actionTypes.GET_SURVEY_DATA_SUCCESS: {
      return {
        ...state,
        data_loading: false,
        data: action.data[0],
        active_survey_amount: action.data[1],
      };
    }
    case actionTypes.GET_EMAILS_LIST_REQUEST: {
      return { ...state, emails_loading: true };
    }
    case actionTypes.GET_EMAILS_LIST_SUCCESS: {
      return {
        ...state,
        emails_loading: false,
        emails: action.data,
      };
    }
    case actionTypes.GET_SURVEY_SINGLE_DATA_REQUEST: {
      return { ...state, data_single_loading: true };
    }
    case actionTypes.GET_SURVEY_SINGLE_DATA_SUCCESS: {
      return {
        ...state,
        data_single_loading: false,
        data_single: action.data[0],
        access_denied: action.data === 'ACCESS DENIED',
      };
    }
    case actionTypes.GET_RESPONSE_GROUP_STATS_REQUEST: {
      return { ...state, stats_group_loading: true };
    }
    case actionTypes.GET_RESPONSE_GROUP_STATS_SUCCESS: {
      return {
        ...state,
        stats_group_loading: false,
        stats_group: action.data.map((el, index) => ({
          ...el,
          color: colors5[index % 5],
          percent:
            action.data[0].answersCount + action.data[1].answersCount > 0 &&
            el.answersCount
              ? (
                  (el.answersCount * 100) /
                  (action.data[0].answersCount + action.data[1].answersCount)
                ).toFixed(0)
              : 0,
        })),
      };
    }
    case actionTypes.GET_COMPLETION_RATE_REQUEST: {
      return { ...state, completion_rate_loading: true };
    }
    case actionTypes.GET_COMPLETION_RATE_SUCCESS: {
      const sum = action.data.reduce((acc, cur) => acc + cur.usersCount, 0);
      return {
        ...state,
        completion_rate_loading: false,
        completion_rate: action.data
          ?.sort((a, b) => b.questionsCompleted - a.questionsCompleted)
          .map((el, index) => ({
            ...el,
            color: colors5[index % 5],
            percent:
              sum > 0 && el.usersCount
                ? ((el.usersCount * 100) / sum).toFixed(0)
                : 0,
          })),
      };
    }
    case actionTypes.GET_SURVEY_QUESTIONS_STATS_DATA_REQUEST: {
      return { ...state, stats_by_single_question_loading: true };
    }
    case actionTypes.GET_SURVEY_QUESTIONS_STATS_DATA_SUCCESS: {
      return {
        ...state,
        stats_by_single_question_loading: false,
        stats_by_single_question: action.data,
      };
    }
    case actionTypes.GET_SURVEY_QUESTIONS_DEMOGRAPHIC_DATA_REQUEST: {
      return { ...state, stats_by_single_question_answer_loading: true };
    }
    case actionTypes.GET_SURVEY_QUESTIONS_DEMOGRAPHIC_DATA_SUCCESS: {
      return {
        ...state,
        stats_by_single_question_answer_loading: false,
        stats_by_single_question_answer: action.data,
        stats_by_single_question_answer_group: action.group,
      };
    }
    case actionTypes.GET_BRAND_QUESTIONS_BY_BRAND_ID_REQUEST: {
      return { ...state, brand_questions_loading: true };
    }
    case actionTypes.GET_BRAND_QUESTIONS_BY_BRAND_ID_SUCCESS: {
      return {
        ...state,
        brand_questions_loading: false,
        brand_questions: action.data,
      };
    }
    case actionTypes.GET_STATS_BY_GEO_REQUEST: {
      return { ...state, stats_by_geo_loading: true };
    }
    case actionTypes.GET_STATS_BY_GEO_SUCCESS: {
      const sum = action.data.reduce((acc, cur) => acc + cur.AnswersCount, 0);
      return {
        ...state,
        stats_by_geo_loading: false,
        stats_by_geo: action.data
          ?.sort((a, b) => b.AnswersCount - a.AnswersCount)
          .map((el, index) => ({
            ...el,
            color: colors5[index % 5],
            percent: el.AnswersCount
              ? ((el.AnswersCount * 100) / sum).toFixed(0)
              : 0,
          })),
      };
    }
    case actionTypes.GET_STATS_BY_CITY_REQUEST: {
      return { ...state, stats_by_city_loading: true };
    }
    case actionTypes.GET_STATS_BY_CITY_SUCCESS: {
      const sum = action.data.reduce((acc, cur) => acc + cur.AnswersCount, 0);
      return {
        ...state,
        stats_by_city_loading: false,
        stats_by_city: action.data
          ?.sort((a, b) => b.AnswersCount - a.AnswersCount)
          .map((el, index) => ({
            ...el,
            color: colors5[index % 5],
            percent: el.AnswersCount
              ? ((el.AnswersCount * 100) / sum).toFixed(0)
              : 0,
          })),
      };
    }
    case actionTypes.GET_LEADS_CLICK_REQUEST: {
      return { ...state, lead_clicks_loading: true };
    }
    case actionTypes.GET_LEADS_CLICK_SUCCESS: {
      const sum = action.data.reduce((acc, cur) => acc + cur.actionsCount, 0);
      return {
        ...state,
        lead_clicks_loading: false,
        lead_clicks: action.data
          ?.sort((a, b) => b.actionsCount - a.actionsCount)
          .map((el, index) => ({
            ...el,
            color: colors5[index % 5],
            percent:
              sum > 0 && el.actionsCount
                ? ((el.actionsCount * 100) / sum).toFixed(0)
                : 0,
          })),
      };
    }
    case actionTypes.GET_STATS_BY_AGE_REQUEST: {
      return { ...state, stats_by_age_loading: true };
    }
    case actionTypes.GET_STATS_BY_AGE_SUCCESS: {
      const sum = action.data.reduce((acc, cur) => acc + cur.AnswersCount, 0);
      return {
        ...state,
        stats_by_age_loading: false,
        stats_by_age: action.data
          ?.sort((a, b) => b.AnswersCount - a.AnswersCount)
          .map((el, index) => ({
            ...el,
            color: colors5[index % 5],
            percent: el.AnswersCount
              ? ((el.AnswersCount * 100) / sum).toFixed(0)
              : 0,
          })),
      };
    }
    case actionTypes.GET_STATS_BY_GENDER_REQUEST: {
      return { ...state, stats_by_gender_loading: true };
    }
    case actionTypes.GET_STATS_BY_GENDER_SUCCESS: {
      const sum = action.data.reduce((acc, cur) => acc + cur.AnswersCount, 0);
      return {
        ...state,
        stats_by_gender_loading: false,
        stats_by_gender: action.data
          ?.sort((a, b) => b.AnswersCount - a.AnswersCount)

          .map((el, index) => ({
            ...el,
            color: colors5[index % 5],
            percent: el.AnswersCount
              ? ((el.AnswersCount * 100) / sum).toFixed(0)
              : 0,
          })),
      };
    }
    case actionTypes.GET_STATS_BY_SURVEY_ID_DATA_REQUEST: {
      return { ...state, stats_loading: true };
    }
    case actionTypes.GET_STATS_BY_SURVEY_ID_DATA_SUCCESS: {
      return {
        ...state,
        stats_loading: false,
        stats: action.data,
      };
    }
    case actionTypes.GET_REPORT_DATA_REQUEST: {
      return { ...state, report_data_loading: true };
    }
    case actionTypes.GET_REPORT_DATA_SUCCESS: {
      return {
        ...state,
        report_data_loading: false,
        report_data: action.data.data,
      };
    }
    case actionTypes.GET_ANALYTICS_ACCESS_CODE_REQUEST: {
      return { ...state, share_code_loading: true };
    }
    case actionTypes.GET_ANALYTICS_ACCESS_CODE_SUCCESS: {
      return {
        ...state,
        share_code_loading: false,
        share_code: action.data,
      };
    }
    case actionTypes.GET_SHARED_ANALYTICS_TOKEN_REQUEST: {
      return { ...state, share_token_loading: true };
    }
    case actionTypes.GET_SHARED_ANALYTICS_TOKEN_SUCCESS: {
      return {
        ...state,
        share_token_loading: false,
        share_token: action.data.data.authTicket.token,
        share_filters: action.data.data.filters,
      };
    }
    case actionTypes.GET_QUESTIONS_STATS_DATA_REQUEST: {
      return { ...state, stats_by_question_loading: true };
    }
    case actionTypes.GET_QUESTIONS_STATS_DATA_SUCCESS: {
      return {
        ...state,
        stats_by_question_loading: false,
        stats_by_question: action.data,
      };
    }
    case actionTypes.GET_QUESTIONS_STATS_DATA_PER_DAY_REQUEST: {
      return { ...state, stats_by_question_per_day_loading: true };
    }
    case actionTypes.GET_QUESTIONS_STATS_DATA_PER_DAY_SUCCESS: {
      return {
        ...state,
        stats_by_question_per_day_loading: false,
        stats_by_question_per_day: action.data,
      };
    }
    case actionTypes.DELETE_SURVEY_DATA_REQUEST: {
      return { ...state, delete_loading: true };
    }
    case actionTypes.DELETE_SURVEY_DATA_SUCCESS: {
      return {
        ...state,
        delete_loading: false,
      };
    }

    default:
      return state;
  }
};

export default brandSurveyReducer;
