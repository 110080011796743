import { call, put, select, takeLatest } from 'redux-saga/effects';
import Cookies from 'js-cookie';
import {
  getPermissionsByEmail,
  getUserProfile,
  getPublisherKeys,
  getSiteListByEmail,
} from '@/services/api/session';
import { getUserType } from '@/services/api/auth';
import * as actions from '@/services/actions/session';
import { actionTypes } from '@/services/actions/session';
import { actionTypes as filterActionTypes } from '@/services/actions/filter';

import {
  matchesRoute,
  checkForRouteAndRedirect,
  setTokenCookie,
  removeTokenCookie,
  removeAgreedWithTermsCookie,
} from '@/utils';

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export function* loadApiKeys() {
  try {
    const sessionStore = yield select(({ session }) => session);
    const { token, apiKey } = sessionStore;
    if (!apiKey) {
      const data = yield call(getPublisherKeys, token);
      yield put(actions.setKeys(data.apiKey, data.secretKey));
    }
  } catch (err) {
    console.log('err', err);
    yield put(actions.resetSession());
  }
}

export function* loadUserProfile() {
  try {
    const token = yield select(({ session }) => session.token);

    const data = yield call(getUserProfile, token);

    yield put(actions.setUserProfile(data.user));
  } catch (err) {
    console.log('err', err);
    yield put(actions.resetSession());
  }
}

export function* loadProfilePermissions(needToRemove) {
  try {
    const token = yield select(({ session }) => session.token);

    const data = yield call(getPermissionsByEmail, token);

    yield put(
      actions.setPermissions({
        permissions: data.permissions == '' ? [] : data.permissions,
        isUserAdmin: data.isUserAdmin,
        isRevenueEnabled: data.isRevenueEnabled,
        isBillingComplete: data.isBillingComplete,
        isRealtimeEnabled: data.isRealtimeEnabled,
        isEmailVerified: data.isEmailVerified,
        isAdManagerEnabled: data.isAdManagerEnabled,
        hostPermissions: data.hostPermissions,
      }),
    );
  } catch (err) {
    console.log('err', err);
    if (err.message === 'user_not_admin' && !needToRemove) {
      yield put({ type: actionTypes.REMOVE_TOKEN });
    } else if (!needToRemove) {
      yield put(actions.resetSession());
    }
  }
}

export function* loadSites() {
  try {
    const token = yield select(({ session }) => session.token);

    const list = yield call(getSiteListByEmail, token);

    const sites = [];
    list?.sites?.map((site) => {
      if (!sites.includes(site)) {
        sites.push(site);
      }
      return site;
    });
    yield put(actions.setSites({ ...list, sites: sites }));
  } catch (err) {
    console.log('err', err);
    yield put(actions.setError());
  }
}

function* saveTokenAndLoadInfo(action) {
  setTokenCookie(action.payload);

  // const apiKey = yield select(({ session }) => session.apiKey);
  // if (!apiKey) {
  try {
    yield put(actions.setLoading(true));
    yield call(loadUserProfile);
    const profile = yield select(({ session }) => session.user);
    const userType = yield call(getUserType, profile.email);

    if (userType === 'comment') {
      if (!matchesRoute('change-password')) {
        checkForRouteAndRedirect({
          routeToCheck: 'login',
          comparison: 'doesNotMatchRoute',
          redirectLink: '/login',
        });
      }
    } else if (userType === 'dashboard') {
      yield call(
        loadProfilePermissions,
        profile.email === 'support@vuukle.com',
      );
      yield call(loadSites);
      yield call(loadApiKeys);

      checkForRouteAndRedirect({
        routeToCheck: 'login',
        comparison: 'matchesRoute',
        redirectLink: '/',
      });

      yield put(actions.setLoading(false));
    }
  } catch (e) {
    if (!matchesRoute('change-password')) {
      checkForRouteAndRedirect({
        routeToCheck: 'login',
        comparison: 'doesNotMatchRoute',
        redirectLink: '/login',
      });
    }
  }
  // }
}

function* removeTokenAndResetSession() {
  removeTokenCookie();
  removeAgreedWithTermsCookie();

  yield put({
    type: filterActionTypes.SET_HOST,
    payload: '',
  });
  yield put(actions.resetSession());
}

export default function* sessionSaga() {
  yield takeLatest(actionTypes.SAVE_TOKEN, saveTokenAndLoadInfo);
  yield takeLatest(actionTypes.REMOVE_TOKEN, removeTokenAndResetSession);

  while (true) {
    const cookieToken = Cookies.get('dash_token');
    const sessionToken = yield select(({ session }) => session.token);
    const isGoogleAuth = yield select(({ session }) => session.isGoogleAuth);

    if (!cookieToken) {
      // yield put(actions.removeToken());
    } else if (!sessionToken && !isGoogleAuth) {
      yield put(actions.saveToken(cookieToken));
    } else if (cookieToken != sessionToken && !isGoogleAuth) {
      yield put(actions.saveToken(cookieToken));
    }
    yield delay(10000);
  }
}
