import axios from 'axios';

export const getAdblockSettings = (token, host) =>
  axios
    .get(`${process.env.API_BASE_URL}api/v1/getModal`, {
      params: { domain: host },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      const { data, success } = response.data;

      if (success) {
        return data;
      }

      throw new Error('not valid details');
    });

export const updateAdminProfileAdblock = (
  token,
  host,
  showAdBlockModal,
  noAdBlockFreeArticles,
  adBlockModalMessage,
) =>
  axios
    .put(
      `${process.env.API_BASE_URL}api/v1/Publishers/updateprofile`,
      {
        host,
        showAdBlockModal,
        noAdBlockFreeArticles,
        adBlockModalMessage,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      throw new Error(errors[0]);
    });

export const updateAdBlockModalSettingsByApikey = (token, params) =>
  axios
    .put(
      `${process.env.API_BASE_URL}api/v1/Publishers/updateAdBlockModalSettingsByApikey`,
      {
        ...params,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      throw new Error(errors[0]);
    });
