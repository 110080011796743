import { call, put, select, takeLatest } from 'redux-saga/effects';
import { getMcm, createMcm } from '@/services/api/mcm';
import { actionTypes } from '@/services/actions/mcm';
import { toaster } from 'evergreen-ui';

export function* getMcmSaga() {
  try {
    const token = yield select(({ session }) => session.token);

    const result = yield call(getMcm, token);

    yield put({
      type: actionTypes.SET_MCM,
      payload: result.data,
    });
  } catch (e) {
    console.log('err', e);
    yield put({
      type: actionTypes.SET_MCM,
      payload: null,
    });
  }
}

export function* createSaga(action) {
  try {
    const token = yield select(({ session }) => session.token);
    const { childNetworkCode, gamEmail } = action.payload;

    const result = yield call(createMcm, token, childNetworkCode, gamEmail);

    yield put({
      type: actionTypes.SET_CREATE_MCM,
      payload: result.data,
    });

    if (!result.success) {
      toaster.danger(
        result.errors[0] === 'CommonError.NOT_FOUND'
          ? 'Sorry, the GAM ID is not found.'
          : result.errors[0],
      );
    } else {
      toaster.success('MCM invitation was send successfully!');
    }
  } catch (e) {
    console.log('Error:', e);
  }
}

export default function* mcmSaga() {
  yield takeLatest(actionTypes.LOAD_MCM, getMcmSaga);
  yield takeLatest(actionTypes.LOAD_CREATE_MCM, createSaga);
}
