// ==== 💪 Action Types
export const actionTypes = {
  LOAD_GAM: '@gam/load-gam-data',
  SET_GAM: '@gam/set-gam-data',

  LOAD_CREATE_GAM: '@gam/load-create-gam',
  SET_CREATE_GAM: '@gam/set-create-gam',

  LOAD_UPDATE_GAM: '@gam/load-update-gam',
  SET_UPDATE_GAM: '@gam/set-update-gam',

  LOAD_DELETE_GAM: '@gam/load-delete-gam',
  SET_DELETE_GAM: '@gam/set-delete-gam',

  SET_AUDIENCE_SEGMENTS_REQUEST: '@gam/set-audience-segments-request',
  SET_AUDIENCE_SEGMENTS_SUCCESS: '@gam/set-audience-segments-success',
};

// ==== 🎬 Actions
export const loadGAM = () => ({
  type: actionTypes.LOAD_GAM,
});

export const setAudienceSegments = () => ({
  type: actionTypes.SET_AUDIENCE_SEGMENTS_REQUEST,
});

export const createGAM = (networkCode, applicationName) => ({
  type: actionTypes.LOAD_CREATE_GAM,
  payload: { networkCode, applicationName },
});

export const updateGAM = (id, networkCode, applicationName) => ({
  type: actionTypes.LOAD_UPDATE_GAM,
  payload: { id, networkCode, applicationName },
});

export const deleteGAM = (id) => ({
  type: actionTypes.LOAD_DELETE_GAM,
  payload: { id },
});
