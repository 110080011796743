// ==== 💪 Action Types
export const actionTypes = {
  LOAD_DAILY_SITE_STATS: '@analytics/load-daily-site-stats',
  SET_DAILY_SITE_STATS: '@analytics/set-daily-site-stats',

  LOAD_AGGREGATED_DAILY_SITE_STATS: '@analytics/load-aggregated-daily-site-stats',

  LOAD_MONTH_STATS: '@analytics/load-month-stats',
  SET_MONTH_STATS: '@analytics/set-month-stats',
  SET_DIFFERENCE_DATA: '@analytics/set-difference-data',
};

// ==== 🎬 Actions
export const loadDailySiteStats = (from, to) => ({
  type: actionTypes.LOAD_DAILY_SITE_STATS,
  payload: {from, to},
});

export const loadAggregatedDailySiteStats = (from, to, groupType) => ({
  type: actionTypes.LOAD_AGGREGATED_DAILY_SITE_STATS,
  payload: {from, to, groupType},
});

export const loadMonthStats = () => ({
  type: actionTypes.LOAD_MONTH_STATS,
});
