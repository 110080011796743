import { combineReducers } from 'redux';
import profileSettings from './profile';
import paymentSettings from './payment';
import userSettings from './user';
import commentsSettings from './comments';

export default combineReducers({
  profile: profileSettings,
  payment: paymentSettings,
  user: userSettings,
  comments: commentsSettings,
});
