import App from 'next/app';
import Head from 'next/head';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import { toaster } from 'evergreen-ui';
import 'react-tooltip/dist/react-tooltip.css';

import '@/styles/index.scss';
import '@/styles/dashforge.scss';
import '@/scenes/Moderation/styles/index.scss';
import '@/scenes/Moderation/styles/sidebar.scss';
import '@/scenes/Moderation/styles/table.scss';
import '@/scenes/Realtime/styles/index.scss';
import '@/scenes/Realtime/styles/articles.scss';
import '@/scenes/Realtime/styles/browser-colors.scss';
import '@/scenes/Realtime/styles/os-colors.scss';
import '@/scenes/AdblockManagement/styles/index.scss';
import '@/scenes/Settings/styles/index.scss';
import '@/scenes/Revenue/styles/index.scss';
import '@/scenes/EmailConfirmation/styles/index.scss';
import '@/scenes/Moderation/styles/select-tooltip.css';
import { StyleSheetManager } from 'styled-components';
import isPropValid from '@emotion/is-prop-valid';
import withReduxStore from '@/HOC/withReduxStore';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import duration from 'dayjs/plugin/duration';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(utc);
dayjs.extend(duration);
dayjs.extend(customParseFormat);
dayjs.extend(relativeTime);

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn:
      'https://345c4faadc4b4e029513de50f4a57e03@o485102.ingest.sentry.io/5624169',
    release:
      process.env.ENV === 'staging'
        ? `${process.env.VERSION}-DEV`
        : `${process.env.VERSION}-PROD`,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: process.env.ENV === 'staging' ? 1.0 : 0.1,
  });
}

async function detectAdBlock() {
  let adBlockEnabled = false;
  const googleAdUrl =
    'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
  try {
    await fetch(new Request(googleAdUrl)).catch(() => (adBlockEnabled = true));
  } catch (e) {
    adBlockEnabled = true;
  } finally {
    if (adBlockEnabled) {
      toaster.warning('Please disable your AdBlocker!', {
        id: 'ad-block-detected',
        duration: 1000,
      });
    }
  }
}

function shouldForwardProp(propName, target) {
  if (typeof target === "string") {
      // For HTML elements, forward the prop if it is a valid HTML attribute
      return isPropValid(propName);
  }
  // For other elements, forward all props
  return true;
}

class MyApp extends App {
  /** Report caught errors to sentry */
  componentDidCatch(error, errorInfo) {
    if (process.env.NODE_ENV !== 'development') {
      Sentry.withScope((scope) => {
        Object.keys(errorInfo).forEach((key) => {
          scope.setExtra(key, errorInfo[key]);
        });

        Sentry.captureException(error);
      });

      super.componentDidCatch(error, errorInfo);
    }
  }

  componentDidMount() {
    detectAdBlock();
    setTimeout(() => {
      const elem = document.getElementsByTagName('iframe');
      if (elem.length) {
        for (let i = 0; i < elem.length; i++) {
          if (
            elem[i].attributes &&
            elem[i].attributes.name &&
            elem[i].attributes.name.nodeValue === 'LPFrame' &&
            elem[i].parentElement.style.position === 'fixed'
          ) {
            elem[i].parentElement.style.position = 'relative';
          }
        }
      }
    }, 1000);

    if (window.top !== window.self) {
      const bodyElem = document.getElementsByTagName('body')[0];
      if (bodyElem) {
        bodyElem.innerHTML = '';
      }
    }

    if (
      window.navigator &&
      window.navigator.connection &&
      window.navigator.connection.effectiveType
    ) {
      switch (window.navigator.connection.effectiveType) {
        case '4g':
          break;
        default:
          setTimeout(
            () =>
              toaster.warning('Your internet connection is slow!', {
                id: 'slow-internet-connection',
              }),
            500,
          );
          break;
      }
    }
  }

  render() {
    const { Component, pageProps, reduxStore } = this.props;
    return (
      <Provider store={reduxStore}>
        <Head>
          <title>Vuukle Dashboard</title>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />
          <meta
            httpEquiv="Cache-Control"
            content="no-cache, no-store, must-revalidate"
          />
          <meta httpEquiv="Pragma" content="no-cache" />
          <meta httpEquiv="Expires" content="0" />
        </Head>
        <StyleSheetManager shouldForwardProp={shouldForwardProp}>
          <Component {...pageProps} />
        </StyleSheetManager>
      </Provider>
    );
  }
}

export default withReduxStore(MyApp);
/** Ad-manager page */
//import { useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { useRouter } from 'next/router';
//
// import AuthProtectedPage from '@/HOC/withAuthProtection';
// import AsideLayout from '@/layouts/AsideLayout';
//
// const PermissionProtectedPage = dynamic(() =>
//   import('@/HOC/withPermissionProtection'),
// );
// const PublisherGAMPane = dynamic(() => import('@/scenes/AdManager'));
//
// import { updateHost } from '@/services/actions/filter';
// import { adjustHost } from '@/utils';
//
// import Header from '@/scenes/AdblockManagement/components/Header';
//
// import dynamic from 'next/dynamic';
// import useTitle from '@/hooks/useTitle';
//
// const AdManagerPage = () => {
//   const dispatch = useDispatch();
//   const router = useRouter();
//
//   const { pathname } = router;
//   const { query } = router;
//
//   const { sites } = useSelector(({ session }) => session);
//   const filterStore = useSelector(({ filter }) => filter);
//
//   useTitle('Ad Manager');
//
//   useEffect(() => {
//     const host = adjustHost(query.host, filterStore.host || sites[0], sites);
//
//     if (host != query.host) {
//       query.host = host;
//       router.replace({ pathname, query });
//     }
//
//     if (host != filterStore.host) {
//       dispatch(updateHost(host));
//     }
//   }, [query, filterStore.host]);
//
//   return (
//     <AsideLayout>
//       <PermissionProtectedPage
//         page="AdManager"
//         message="This feature is a paid feature"
//         Header={() => <Header page="Ad Manager" />}
//       >
//         <PublisherGAMPane />
//       </PermissionProtectedPage>
//     </AsideLayout>
//   );
// };
//
// export async function getStaticProps() {
//   return {
//     props: {},
//   };
// }
//
// export default AuthProtectedPage(AdManagerPage);
