import { call, put, select, takeLatest } from 'redux-saga/effects';
import { toaster } from 'evergreen-ui';
import dayjs from 'dayjs';
import {
  getSiteStatsByToken,
  addNewHost,
  getSiteStatsComparisonByToken,
} from '@/services/api/sites';
import { actionTypes } from '@/services/actions/sites';
import {
  calculateTotalStatsFromAPIResponse,
  calculateTotalDifferencePercentageFromAPIResponse,
  calculateAverageOfSiteStatsFromAPIResponse,
} from '@/utils';

export function* loadSiteStats() {
  try {
    const token = yield select(({ session }) => session.token);

    const site_stats = yield call(
      getSiteStatsByToken,
      token,
      dayjs.utc().subtract(1, 'days').startOf('day').unix(),
      dayjs.utc().subtract(1, 'days').endOf('day').unix(),
    );
    site_stats.sort((a, b) => b.pageViews - a.pageViews);
    const data = site_stats.map((row) => ({
      ...row,
      tAudio:
        (row.audioClicked || 0) +
        (row.audioPaused || 0) +
        (row.audioResumed || 0) +
        (row.audioRestarted || 0),
    }));
    yield put({
      type: actionTypes.SET_SITE_STATS,
      payload: data,
    });

    const total_stats = calculateTotalStatsFromAPIResponse(site_stats);
    yield put({
      type: actionTypes.SET_TOTAL_STATS,
      payload: total_stats,
    });
  } catch (e) {
    console.log('err', e);
  }
}

export function* loadSiteStatsComparison() {
  try {
    const token = yield select(({ session }) => session.token);

    const siteStatsData = yield call(
      getSiteStatsComparisonByToken,
      token,
      dayjs().utc().unix(),
    );

    const { dataDifference, dataDifferenceWithApiKey } = siteStatsData;

    yield put({
      type: actionTypes.SET_DIFFERENCE_DATA,
      payload: dataDifference,
    });

    const siteStats = siteStatsData.dataDifferenceWithTodays;
    siteStats.sort((a, b) => b.pageViews - a.pageViews);
    const averageSiteStats =
      calculateAverageOfSiteStatsFromAPIResponse(siteStats);

    yield put({
      type: actionTypes.SET_SITE_STATS_COMPARISON,
      payload: averageSiteStats,
    });
    const averageLastWeekSiteStats = {
      pageViews: dataDifferenceWithApiKey[0]?.pageViews || 0,
      comments: dataDifferenceWithApiKey[0]?.comments || 0,
      commentViews: dataDifferenceWithApiKey[0]?.commentViews || 0,
      shares: dataDifferenceWithApiKey[0]?.shares || 0,
      emotes: dataDifferenceWithApiKey[0]?.emotes || 0,
      // recommendations: dataDifferenceWithApiKey[0]?.recommends || 0,
      pendingComments: dataDifferenceWithApiKey[0]?.pending || 0,
      quizAnswers: dataDifferenceWithApiKey[0]?.quizAnswers || 0,
      revenue: dataDifferenceWithApiKey[0]?.revenue || 0,
      users: dataDifferenceWithApiKey[0]?.users || 0,
      audio: dataDifferenceWithApiKey[0]?.audioClicked || 0,
      // runChatOpened: dataDifferenceWithApiKey[0]?.runChatOpened || 0,
      // runChatMessages: dataDifferenceWithApiKey[0]?.runChatMessages || 0,
      newsletterSubscribes:
        dataDifferenceWithApiKey[0]?.newsletterSubscribes || 0,
    };
    yield put({
      type: actionTypes.SET_SITE_LAST_WEEK_STATS_COMPARISON,
      payload: averageLastWeekSiteStats,
    });

    // calculateTotalStatsFromAPIResponse(siteStats);
    const totalStats = calculateTotalDifferencePercentageFromAPIResponse(
      siteStatsData.lastWeekData,
      siteStatsData.yesterdaysData,
    );
    yield put({
      type: actionTypes.SET_TOTAL_STATS_COMPARISON,
      payload: totalStats,
    });
  } catch (e) {
    console.log('err', e);
  }
}

export function* addNewSite(params) {
  yield put({ type: actionTypes.SET_LOADING, payload: true });
  try {
    const site = params.payload;
    const token = yield select(({ session }) => session.token);

    yield call(addNewHost, token, site);
    yield call(loadSiteStats);
    toaster.success(`${site} was successfully added`, {
      id: 'add-new-site-success',
    });
  } catch (e) {
    if (e.message === 'account_exists') {
      toaster.warning('That site already exists', {
        id: 'add-new-site-failure',
      });
    } else if (e.message === 'user_not_admin') {
      toaster.warning('Website can not be added', {
        description: "This account doesn't have admin rights",
        id: 'add-new-site-failure',
      });
    } else {
      toaster.warning('Something went wrong with the request!', {
        id: 'add-new-site-failure',
      });
    }
  } finally {
    yield put({ type: actionTypes.SET_LOADING, payload: false });
  }
}

export default function* sitesSaga() {
  yield takeLatest(actionTypes.LOAD_SITE_STATS, loadSiteStats);
  yield takeLatest(
    actionTypes.LOAD_SITE_STATS_COMPARISON,
    loadSiteStatsComparison,
  );
  yield takeLatest(actionTypes.ADD_NEW_SITE, addNewSite);
}
