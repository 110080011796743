import { actionTypes } from '@/services/actions/mcm';

// ==== 🗄️ Reducer
export const initialState = {
  mcm: null,
  mcm_loading: false,
};

export const mcmReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_MCM: {
      return {
        ...state,
        mcm_loading: true,
      };
    }
    case actionTypes.LOAD_CREATE_MCM: {
      return {
        ...state,
        mcm_loading: true,
      };
    }

    case actionTypes.SET_MCM: {
      return { ...state, mcm: action.payload, mcm_loading: false };
    }

    case actionTypes.SET_CREATE_MCM: {
      return { ...state, mcm: action.payload, mcm_loading: false };
    }

    default:
      return state;
  }
};

export default mcmReducer;
