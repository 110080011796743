import { call, put, select, takeLatest } from 'redux-saga/effects';
import { toaster } from 'evergreen-ui';
import {
  updateLoginTypes,
  updateDefaultTotCount,
  updateDefaultTotCountByApiKey,
  updateLoginTypesByApiKey,
  setTotIntervalByApiKey,
  setTotInterval,
  updateDefaultSorting,
  updateDefaultSortingByApiKey,
  updateExternalSearch,
  updateExternalSearchByApiKey,
  setSpamLimit,
  setSpamLimitByApiKey,
  setToxicityLimit,
  setToxicityLimitByApiKey,
  setColor,
  setColorByApiKey,
  setTalkOfTheTownCommentText,
  setTalkOfTheTownCommentTextByApiKey,
  setEmoteHeaderText,
  setEmoteHeaderTextByApiKey,
  updateFlagThreshold,
  updateloadedComments,
  updateFlagThresholdByApiKey,
  UpdatePublishersEmotesPowerbarSettings,
  updateCommentsMaxLength,
  UpdateTotWideImages,
  setEmoteVoteText,
  setEmoteVoteTextByApiKey,
  UpdatePublisherRTEoptions,
  updateEmotesLabelsSettings,
  updateEmotesOrderSettings,
  SetPublisherPowerbarHorizontalPosition,
  setShowCommentCount,
  getBlockWordList,
  getTopicsApi,
} from '@/services/api/settings/comments/widget';
import {
  actionTypes,
  setLoginTypesSuccess,
  setHideCommentsWidgetSuccess,
  setSpamLimitSuccess,
  setToxicityLimitSuccess,
  setDarkModeSuccess,
  setColorSuccess,
  setOpenArticlesInSameTabSuccess,
  setToTTextSuccess,
  setEmotesHeaderSuccess,
  updateDefaultSortingSuccess,
  commentsSettingsError,
  setHideInputBoxCommentsWidgetSuccess,
  setTreadsToDisplaySuccess,
  updateBubbleTypeSuccess,
  setBubbleLoadTimeSuccess,
  setBubbleLoadAtSuccess,
  setCommentPanelSuccess,
} from '@/services/actions/settings/comments/widget';
import { calculateLoginTypes2 } from '@/utils';
import {
  createBrandForQuiz,
  DeleteBrandLogo,
  DeleteLogo,
  DeleteSpecBrandLogo,
  editBrandForQuiz,
  getBrands,
  UpdatePublisherFlag,
  UpdatePublisherFlagObjValue,
  UpdatePublisherFlagStringValue,
} from '@/services/api/settings/index';

const getValueOfHorizontalPos = (value) => {
  if (value == '0') {
    return 'Unset';
  }
  if (value == '1') {
    return 'Left';
  }
  if (value == '2') {
    return 'Right';
  }
  if (value == '3') {
    return 'Center';
  }
};

export function* setLoginTypes(action) {
  try {
    const token = yield select(({ session }) => session.token);
    const apiKey = yield select(({ session }) => session.apiKey);
    const host = yield select(({ filter }) => filter.host);

    const data = yield call(
      action.allSites ? updateLoginTypesByApiKey : updateLoginTypes,
      token,
      action.allSites ? '' : host,
      action.allSites ? apiKey : '',
      action.loginTypes,
    );

    yield put(
      setLoginTypesSuccess(
        calculateLoginTypes2(
          action.allSites ? action.loginTypes : data.currentLoginTypes,
          action.allSites,
        ),
      ),
    );

    toaster.success(
      `${
        action.loginTypeName !== 'vuuklePassword'
          ? action.loginTypeName.split('Auth')[0].charAt(0).toUpperCase() +
            action.loginTypeName.split('Auth')[0].slice(1)
          : action.loginTypeName
              .replace('Password', ' Password')
              .charAt(0)
              .toUpperCase() +
            action.loginTypeName.replace('Password', ' Password').slice(1)
      } login is successfully ${action.val ? 'enabled' : 'disabled'}`,
      {
        id: 'successful-set-login-types',
      },
    );
  } catch (e) {
    yield put(commentsSettingsError());
    toaster.danger(
      'Something went wrong while trying to update the login types.',
      {
        id: 'unsuccessful-set-login-types',
      },
    );
    console.log('Something went wrong', e.message);
  }
}

export function* setLoginTypesToSSOOnly(action) {
  try {
    const token = yield select(({ session }) => session.token);
    const apiKey = yield select(({ session }) => session.apiKey);
    const host = yield select(({ filter }) => filter.host);

    yield call(
      action.allSites ? updateLoginTypesByApiKey : updateLoginTypes,
      token,
      action.allSites ? '' : host,
      action.allSites ? apiKey : '',
      [
        {
          loginType: 1,
          isAllowed: false,
        },
        {
          loginType: 2,
          isAllowed: false,
        },
        {
          loginType: 4,
          isAllowed: false,
        },
        {
          loginType: 8,
          isAllowed: false,
        },
        {
          loginType: 16,
          isAllowed: false,
        },
        {
          loginType: 32,
          isAllowed: false,
        },
        {
          loginType: 64,
          isAllowed: true,
        },
      ],
    );
    yield put(setLoginTypesSuccess(64));
    toaster.success('Login type is successfully set to SSO only', {
      id: 'successful-set-login-types',
    });
  } catch (e) {
    yield put(commentsSettingsError());
    toaster.danger(
      'Something went wrong while trying to set login type to SSO only.',
      {
        id: 'unsuccessful-set-login-types',
      },
    );
    console.log('Something went wrong', e.message);
  }
}

export function* updateTotSaga(action) {
  try {
    const token = yield select(({ session }) => session.token);
    const apiKey = yield select(({ session }) => session.apiKey);
    const host = yield select(({ filter }) => filter.host);

    yield call(
      action.allSites ? updateDefaultTotCountByApiKey : updateDefaultTotCount,
      token,
      action.allSites ? '' : host,
      action.allSites ? apiKey : '',
      action.count,
    );
    toaster.success('Talk of the town article count updated successfully', {
      id: 'successful-update-tot',
    });
  } catch (e) {
    if (e.message === 'invalid_tot_count') {
      toaster.danger(
        'Talk of the town article count should be a number in range of 0 and 12',
        {
          id: 'unsuccessful-update-tot',
        },
      );
    }
    yield put(commentsSettingsError());
    console.log('Something went wrong', e.message);
  }
}

export function* updateTotIntervalSaga(action) {
  try {
    const token = yield select(({ session }) => session.token);
    const apiKey = yield select(({ session }) => session.apiKey);
    const host = yield select(({ filter }) => filter.host);

    const data = yield call(
      action.allSites ? setTotIntervalByApiKey : setTotInterval,
      token,
      action.allSites ? apiKey : host,
      action.count,
    );

    if (data && data.errors && data.errors.length) {
      toaster.warning('Invalid interval', {
        id: 'error-update-tot-interval',
      });
    } else {
      toaster.success('Talk of the Town interval updated successfully', {
        id: 'successful-update-tot-interval',
      });
    }
  } catch (e) {
    yield put(commentsSettingsError());
    console.log('Something went wrong', e.message);
  }
}

export function* getBlockListSaga(action) {
  try {
    const token = yield select(({ session }) => session.token);

    const data = yield call(getBlockWordList, token, action.payload);

    if (data.success) {
      yield put({
        type: actionTypes.GET__BLOCK_WORD_LIST_SUCCESS,
        payload: data.data,
      });
    }
  } catch (e) {
    yield put(commentsSettingsError());
    console.log('Something went wrong', e.message);
  }
}

export function* updateDefaultSortingSaga(action) {
  try {
    const token = yield select(({ session }) => session.token);
    const apiKey = yield select(({ session }) => session.apiKey);
    const host = yield select(({ filter }) => filter.host);

    yield call(
      action.allSites ? updateDefaultSortingByApiKey : updateDefaultSorting,
      token,
      action.allSites ? apiKey : host,
      action.sorting,
    );
    toaster.success('Comments default sorting updated successfully', {
      id: 'successful-update-default-sorting',
    });
    yield put(updateDefaultSortingSuccess(action.sorting));
  } catch (e) {
    yield put(commentsSettingsError());
    console.log('Something went wrong', e.message);
  }
}

export function* updateHideCommentsWidgetSaga(action) {
  try {
    const token = yield select(({ session }) => session.token);
    const apiKey = yield select(({ session }) => session.apiKey);
    const host = yield select(({ filter }) => filter.host);

    yield call(
      UpdatePublisherFlag,
      token,
      action.allSites ? { apiKey } : { host },
      15,
      action.hideCommentsWidget,
    );
    toaster.success('Comments widget display settings updated successfully', {
      id: 'successful-update-hide-widget',
    });
    yield put(setHideCommentsWidgetSuccess(action.hideCommentsWidget));
  } catch (e) {
    yield put(commentsSettingsError());
    console.log('Something went wrong', e.message);
  }
}

export function* updateBubbleButtonTypeSaga(action) {
  try {
    const token = yield select(({ session }) => session.token);
    const apiKey = yield select(({ session }) => session.apiKey);
    const host = yield select(({ filter }) => filter.host);

    yield call(
      UpdatePublisherFlag,
      token,
      action.allSites ? { apiKey } : { host },
      52,
      null,
      action.commentBubbleLoadType || '0',
    );
    toaster.success('Comments bubble button type updated successfully', {
      id: 'successful-update-bubble-type',
    });
    yield put(updateBubbleTypeSuccess(action.commentBubbleLoadType));
  } catch (e) {
    yield put(commentsSettingsError());
    console.log('Something went wrong', e.message);
  }
}

export function* updateBubbleButtonLoadTimeSaga(action) {
  try {
    const token = yield select(({ session }) => session.token);
    const apiKey = yield select(({ session }) => session.apiKey);
    const host = yield select(({ filter }) => filter.host);

    yield call(
      UpdatePublisherFlag,
      token,
      action.allSites ? { apiKey } : { host },
      54,
      null,
      action.loadCommentBubbleAfter,
    );
    toaster.success('Comments bubble button load time updated successfully', {
      id: 'successful-update-bubble-time',
    });
    yield put(setBubbleLoadTimeSuccess(action.loadCommentBubbleAfter));
  } catch (e) {
    yield put(commentsSettingsError());
    console.log('Something went wrong', e.message);
  }
}

export function* updateBubbleButtonAtSaga(action) {
  try {
    const token = yield select(({ session }) => session.token);
    const apiKey = yield select(({ session }) => session.apiKey);
    const host = yield select(({ filter }) => filter.host);

    yield call(
      UpdatePublisherFlag,
      token,
      action.allSites ? { apiKey } : { host },
      53,
      null,
      action.loadCommentBubbleAt,
    );
    toaster.success(
      'Comments bubble button scroll size changed updated successfully',
      {
        id: 'successful-update-bubble-at',
      },
    );
    yield put(setBubbleLoadAtSuccess(action.loadCommentBubbleAt));
  } catch (e) {
    yield put(commentsSettingsError());
    console.log('Something went wrong', e.message);
  }
}

export function* updateShowNewsletterPopupValue(action) {
  try {
    const token = yield select(({ session }) => session.token);
    const apiKey = yield select(({ session }) => session.apiKey);
    const host = yield select(({ filter }) => filter.host);

    yield call(
      UpdatePublisherFlag,
      token,
      action.payload.allSites ? { apiKey } : { host },
      29,
      action.payload.value,
    );
    toaster.success('Show newsletter popup state updated successfully', {
      id: 'successful-update-hide-widget',
    });
  } catch (e) {
    yield put(commentsSettingsError());
    console.log('Something went wrong', e.message);
  }
}

export function* updateHideInputBoxCommentsWidgetSaga(action) {
  try {
    const token = yield select(({ session }) => session.token);
    const apiKey = yield select(({ session }) => session.apiKey);
    const host = yield select(({ filter }) => filter.host);

    yield call(
      UpdatePublisherFlag,
      token,
      action.allSites ? { apiKey } : { host },
      16,
      action.hideInputBox,
    );
    toaster.success('Comments widget display settings updated successfully', {
      id: 'successful-update-hide-widget',
    });
    yield put(setHideInputBoxCommentsWidgetSuccess(action.hideInputBox));
  } catch (e) {
    yield put(commentsSettingsError());
    console.log('Something went wrong', e.message);
  }
}

export function* updateCommentPanelSaga(action) {
  try {
    const token = yield select(({ session }) => session.token);
    const apiKey = yield select(({ session }) => session.apiKey);
    const host = yield select(({ filter }) => filter.host);

    yield call(
      UpdatePublisherFlag,
      token,
      action.allSites ? { apiKey } : { host },
      55,
      action.commentsPanelEnabled,
    );
    toaster.success(
      `Comment panel ${
        action.commentsPanelEnabled ? 'enabled' : 'disabled'
      } successfully`,
      {
        id: 'successful-update-comment-panel',
      },
    );
    yield put(setCommentPanelSuccess(action.commentsPanelEnabled));
  } catch (e) {
    yield put(commentsSettingsError());
    console.log('Something went wrong', e.message);
  }
}

export function* updateSpamLimitSaga(action) {
  try {
    const token = yield select(({ session }) => session.token);
    const apiKey = yield select(({ session }) => session.apiKey);
    const host = yield select(({ filter }) => filter.host);

    yield call(
      action.allSites ? setSpamLimitByApiKey : setSpamLimit,
      token,
      action.allSites ? apiKey : host,
      action.spamLimit,
    );
    toaster.success('Spam score threshold updated successfully', {
      id: 'successful-update-spam-limit',
    });
    yield put(setSpamLimitSuccess(action.spamLimit));
  } catch (e) {
    yield put(commentsSettingsError());
    console.log('Something went wrong', e.message);
  }
}

export function* updateToxicityLimitSaga(action) {
  try {
    const token = yield select(({ session }) => session.token);
    const apiKey = yield select(({ session }) => session.apiKey);
    const host = yield select(({ filter }) => filter.host);

    yield call(
      action.allSites ? setToxicityLimitByApiKey : setToxicityLimit,
      token,
      action.allSites ? apiKey : host,
      action.toxicityLimit,
    );
    toaster.success('Toxicity score threshold updated successfully', {
      id: 'successful-update-toxicity-limit',
    });
    yield put(setToxicityLimitSuccess(action.toxicityLimit));
  } catch (e) {
    yield put(commentsSettingsError());
    console.log('Something went wrong', e.message);
  }
}

export function* updateDarkModeSaga(action) {
  try {
    const token = yield select(({ session }) => session.token);
    const apiKey = yield select(({ session }) => session.apiKey);
    const host = yield select(({ filter }) => filter.host);

    yield call(
      UpdatePublisherFlag,
      token,
      action.allSites ? { apiKey } : { host },
      17,
      action.darkMode,
    );
    toaster.success('Dark mode toggled successfully', {
      id: 'successful-update-dark-mode',
    });
    yield put(setDarkModeSuccess(action.darkMode));
  } catch (e) {
    yield put(commentsSettingsError());
    console.log('Something went wrong', e.message);
  }
}

export function* updateCommentsTreadShowSaga(action) {
  try {
    const token = yield select(({ session }) => session.token);
    const apiKey = yield select(({ session }) => session.apiKey);
    const host = yield select(({ filter }) => filter.host);

    yield call(
      UpdatePublisherFlag,
      token,
      action.allSites ? { apiKey } : { host },
      51,
      null,
      action.commentThreadsToDisplay,
    );
    toaster.success('Comments tread display amount successfully updated', {
      id: 'successful-update-treads-amount',
    });
    yield put(setTreadsToDisplaySuccess(action.commentThreadsToDisplay));
  } catch (e) {
    yield put(commentsSettingsError());
    console.log('Something went wrong', e.message);
  }
}

export function* updateColorSaga(action) {
  try {
    const token = yield select(({ session }) => session.token);
    const apiKey = yield select(({ session }) => session.apiKey);
    const host = yield select(({ filter }) => filter.host);

    yield call(
      action.allSites ? setColorByApiKey : setColor,
      token,
      action.allSites ? apiKey : host,
      action.color,
    );
    toaster.success('Comments widget theme color updated successfully', {
      id: 'successful-update-theme-color',
    });
    yield put(setColorSuccess(action.color));
  } catch (e) {
    yield put(commentsSettingsError());
    console.log('Something went wrong', e.message);
  }
}

export function* updateOpenInSameTabSaga(action) {
  try {
    const token = yield select(({ session }) => session.token);
    const apiKey = yield select(({ session }) => session.apiKey);
    const host = yield select(({ filter }) => filter.host);

    yield call(
      UpdatePublisherFlag,
      token,
      action.allSites ? { apiKey } : { host },
      18,
      action.openArticlesInSameTab,
    );
    toaster.success('Article opening toggled successfully', {
      id: 'successful-update-open-in-same-tab',
    });
    yield put(setOpenArticlesInSameTabSuccess(action.openArticlesInSameTab));
  } catch (e) {
    yield put(commentsSettingsError());
    console.log('Something went wrong', e.message);
  }
}

export function* updateToTTextSaga(action) {
  try {
    const token = yield select(({ session }) => session.token);
    const apiKey = yield select(({ session }) => session.apiKey);
    const host = yield select(({ filter }) => filter.host);

    yield call(
      action.allSites
        ? setTalkOfTheTownCommentTextByApiKey
        : setTalkOfTheTownCommentText,
      token,
      action.allSites ? apiKey : host,
      action.talkOfTheTownText,
    );
    toaster.success('Talk of the town header text updated successfully', {
      id: 'successful-update-tot-text',
    });
    yield put(setToTTextSuccess(action.talkOfTheTownText));
  } catch (e) {
    yield put(commentsSettingsError());
    console.log('Something went wrong', e.message);
  }
}

export function* updateEmotesHeaderSaga(action) {
  try {
    const token = yield select(({ session }) => session.token);
    const apiKey = yield select(({ session }) => session.apiKey);
    const host = yield select(({ filter }) => filter.host);

    yield call(
      action.allSites ? setEmoteHeaderTextByApiKey : setEmoteHeaderText,
      token,
      action.allSites ? apiKey : host,
      action.emotesHeader,
    );
    toaster.success('Emotes header text updated successfully', {
      id: 'successful-update-emotes-header',
    });
    yield put(setEmotesHeaderSuccess(action.emotesHeader));
  } catch (e) {
    yield put(commentsSettingsError());
    console.log('Something went wrong', e.message);
  }
}

export function* updateEmotesVoteSaga(action) {
  try {
    const token = yield select(({ session }) => session.token);
    const apiKey = yield select(({ session }) => session.apiKey);
    const host = yield select(({ filter }) => filter.host);

    yield call(
      action.allSites ? setEmoteVoteTextByApiKey : setEmoteVoteText,
      token,
      action.allSites ? apiKey : host,
      action.emoteVoteText,
    );
    toaster.success('Emotes header text updated successfully', {
      id: 'successful-update-emotes-header',
    });
    yield put({ type: actionTypes.SET_EMOTE_VOTE_SUCCESS });
  } catch (e) {
    yield put(commentsSettingsError());
    console.log('Something went wrong', e.message);
  }
}

export function* updateExternalSearchSaga(action) {
  try {
    const token = yield select(({ session }) => session.token);
    const apiKey = yield select(({ session }) => session.apiKey);
    const host = yield select(({ filter }) => filter.host);

    yield call(
      action.allSites ? updateExternalSearchByApiKey : updateExternalSearch,
      token,
      action.allSites ? apiKey : host,
      action.searchOptions,
    );
    toaster.success('Comments external search option updated successfully', {
      id: 'successful-update-external-search-option',
    });
    yield put(updateDefaultSortingSuccess(action.sorting));
  } catch (e) {
    yield put(commentsSettingsError());
    console.log('Something went wrong', e.message);
  }
}

export function* updateFlagThresholdSaga(action) {
  try {
    const token = yield select(({ session }) => session.token);
    const apiKey = yield select(({ session }) => session.apiKey);
    const host = yield select(({ filter }) => filter.host);

    yield call(
      action.allSites ? updateFlagThresholdByApiKey : updateFlagThreshold,
      token,
      action.allSites ? '' : host,
      action.allSites ? apiKey : '',
      action.flagThreshold,
    );
    toaster.success('Comment report number for flagging updated successfully', {
      id: 'successful-update-threshold',
    });
  } catch (e) {
    yield put(commentsSettingsError());
    console.log('Something went wrong', e.message);
  }
}

export function* updateLoadedCommentsSaga(action) {
  try {
    const token = yield select(({ session }) => session.token);
    const host = yield select(({ filter }) => filter.host);

    yield call(
      updateloadedComments,
      token,
      action.allSites ? '' : host,
      action.loadedComments,
    );
    toaster.success(
      'Default count of loaded comments on the page updated successfully',
      {
        id: 'successful-update-threshold',
      },
    );
  } catch (e) {
    yield put(actions.commentsSettingsError());
    console.log('Something went wrong', e.message);
  }
}

export function* emoteAndSharebarEditRequest(action) {
  try {
    const configObj = action.payload.data;
    const allSites = action.payload.allSites;
    const notifyText = action.payload.notifyText;
    const host = yield select(({ filter }) => filter.host);
    const token = yield select(({ session }) => session.token);

    const hostData = allSites ? '' : host;

    if (configObj.powerbarVerticalPosition < 0) {
      toaster.danger("Vertical Position can't be less than 1px!", {
        id: 'emote-vertical-position',
        duration: 10,
      });
      return;
    }

    if (configObj.powerbarVerticalPosition > 200) {
      toaster.danger("Vertical Position can't be larger than 200px!", {
        id: 'emote-vertical-position',
        duration: 10,
      });
      return;
    }

    const data = yield call(
      UpdatePublishersEmotesPowerbarSettings,
      token,
      hostData,
      configObj,
    );

    if (data.success) {
      if (notifyText !== 'null') {
        toaster.success(notifyText ? notifyText : 'Sucessfully Updated!', {
          id: 'emote-sharebar-config-update',
        });
      }
    }
    yield put({
      type: actionTypes.SET_EMOTE_AND_SHAREBAR_DATA,
      payload: configObj,
    });
  } catch (err) {
    console.log('Something went wrong', e.message);
  }
}

export function* pbAudioSettingsSwitch(action) {
  try {
    const { allSites, data: showPBAudioButton } = action.payload;
    const host = yield select(({ filter }) => filter.host);
    const token = yield select(({ session }) => session.token);
    const apiKey = yield select(({ session }) => session.apiKey);
    yield put({
      type: actionTypes.UPDATE_PB_AUDIO_SETTINGS_SWITCH,
      payload: showPBAudioButton,
    });
    const data = yield call(
      UpdatePublisherFlag,
      token,
      allSites ? { apiKey } : { host },
      21,
      showPBAudioButton,
    );
    if (data.success) {
      toaster.success(
        `Successfully ${showPBAudioButton ? 'Enabled' : 'Disabled'}!`,
      );
    }
  } catch (err) {
    console.log('Something went wrong', err.message);
  }
}

export function* pdfButtonSettingsSwitch(action) {
  try {
    const { allSites, data: disablePDF } = action.payload;
    const host = yield select(({ filter }) => filter.host);
    const token = yield select(({ session }) => session.token);
    const apiKey = yield select(({ session }) => session.apiKey);
    yield put({
      type: actionTypes.UPDATE_PDF_BUTTON_SETTINGS_SWITCH,
      payload: disablePDF,
    });
    const data = yield call(
      UpdatePublisherFlag,
      token,
      allSites ? { apiKey } : { host },
      30,
      disablePDF,
    );
    if (data.success) {
      toaster.success(`Successfully ${disablePDF ? 'Enabled' : 'Disabled'}!`);
    }
  } catch (err) {
    console.log('Something went wrong', err.message);
  }
}

export function* updatePublisherStyles(action) {
  try {
    const { allSites, data: publisherStyles } = action.payload;
    const host = yield select(({ filter }) => filter.host);
    const token = yield select(({ session }) => session.token);
    const apiKey = yield select(({ session }) => session.apiKey);
    yield put({
      type: actionTypes.UPDATE_PUBLISHER_STYLES,
      payload: publisherStyles,
    });
    const data = yield call(
      UpdatePublisherFlag,
      token,
      allSites ? { apiKey } : { host },
      38,
      publisherStyles,
    );
    if (data.success) {
      toaster.success(
        `Successfully ${publisherStyles ? 'Enabled' : 'Disabled'}!`,
      );
    }
  } catch (err) {
    console.log('Something went wrong', err.message);
  }
}

export function* updateInteractivityHub(action) {
  try {
    const { allSites, data: interactivityHub } = action.payload;
    const host = yield select(({ filter }) => filter.host);
    const token = yield select(({ session }) => session.token);
    const apiKey = yield select(({ session }) => session.apiKey);
    yield put({
      type: actionTypes.UPDATE_INTERACTIVITY_HUB,
      payload: interactivityHub,
    });
    const data = yield call(
      UpdatePublisherFlag,
      token,
      allSites ? { apiKey } : { host },
      39,
      interactivityHub,
    );
    if (data.success) {
      toaster.success(
        `Successfully ${interactivityHub ? 'Enabled' : 'Disabled'}!`,
      );
    }
  } catch (err) {
    console.log('Something went wrong', err.message);
  }
}

export function* updateDisabledTopics(action) {
  try {
    const { allSites, data: quizDisabledTopics } = action.payload;
    const host = yield select(({ filter }) => filter.host);
    const token = yield select(({ session }) => session.token);
    const apiKey = yield select(({ session }) => session.apiKey);
    yield put({
      type: actionTypes.UPDATE_DISABLED_TOPICS,
      payload: quizDisabledTopics,
    });
    const data = yield call(
      UpdatePublisherFlag,
      token,
      allSites ? { apiKey } : { host },
      42,
      null,
      quizDisabledTopics,
    );
    if (data.success) {
      toaster.success(`Successfully updated!`);
    }
  } catch (err) {
    console.log('Something went wrong', err.message);
  }
}

export function* disableQuizResultsScreen(action) {
  try {
    const { allSites, data: hideQuizResults } = action.payload;
    const host = yield select(({ filter }) => filter.host);
    const token = yield select(({ session }) => session.token);
    const apiKey = yield select(({ session }) => session.apiKey);
    yield put({
      type: actionTypes.UPDATE_DISABLE_RESULTS,
      payload: hideQuizResults,
    });
    const data = yield call(
      UpdatePublisherFlag,
      token,
      allSites ? { apiKey } : { host },
      43,
      hideQuizResults,
    );
    if (data.success) {
      toaster.success(
        `Successfully ${!hideQuizResults ? 'Enabled' : 'Disabled'}!`,
      );
    }
  } catch (err) {
    console.log('Something went wrong', err.message);
  }
}

export function* disableQuizNextButton(action) {
  try {
    const { allSites, data: disableQuestionBypass } = action.payload;
    const host = yield select(({ filter }) => filter.host);
    const token = yield select(({ session }) => session.token);
    const apiKey = yield select(({ session }) => session.apiKey);
    yield put({
      type: actionTypes.UPDATE_DISABLE_NEXT_BUTTON,
      payload: disableQuestionBypass,
    });
    const data = yield call(
      UpdatePublisherFlag,
      token,
      allSites ? { apiKey } : { host },
      44,
      disableQuestionBypass,
    );
    if (data.success) {
      toaster.success(
        `Successfully ${!disableQuestionBypass ? 'Enabled' : 'Disabled'}!`,
      );
    }
  } catch (err) {
    console.log('Something went wrong', err.message);
  }
}

export function* disableQuizDefaultQuestions(action) {
  try {
    const { allSites, data: hideDefaultQuestions } = action.payload;
    const host = yield select(({ filter }) => filter.host);
    const token = yield select(({ session }) => session.token);
    const apiKey = yield select(({ session }) => session.apiKey);
    yield put({
      type: actionTypes.UPDATE_DISABLE_DEFAULT,
      payload: hideDefaultQuestions,
    });
    const data = yield call(
      UpdatePublisherFlag,
      token,
      allSites ? { apiKey } : { host },
      47,
      hideDefaultQuestions,
    );
    if (data.success) {
      toaster.success(
        `Successfully ${!hideDefaultQuestions ? 'Enabled' : 'Disabled'}!`,
      );
    }
  } catch (err) {
    console.log('Something went wrong', err.message);
  }
}

export function* disableQuizMarketingQuestions(action) {
  try {
    const { allSites, data: quizzlyMarketingQuestionsDisabled } =
      action.payload;
    const host = yield select(({ filter }) => filter.host);
    const token = yield select(({ session }) => session.token);
    const apiKey = yield select(({ session }) => session.apiKey);
    yield put({
      type: actionTypes.UPDATE_DISABLE_MARKETING,
      payload: quizzlyMarketingQuestionsDisabled,
    });
    const data = yield call(
      UpdatePublisherFlag,
      token,
      allSites ? { apiKey } : { host },
      50,
      quizzlyMarketingQuestionsDisabled,
    );
    if (data.success) {
      toaster.success(
        `Successfully ${
          !quizzlyMarketingQuestionsDisabled ? 'Enabled' : 'Disabled'
        }!`,
      );
    }
  } catch (err) {
    console.log('Something went wrong', err.message);
  }
}

export function* updateQuizQuestionsCount(action) {
  try {
    const { allSites, data: quizQuestionsCount } = action.payload;
    const host = yield select(({ filter }) => filter.host);
    const token = yield select(({ session }) => session.token);
    const apiKey = yield select(({ session }) => session.apiKey);
    yield put({
      type: actionTypes.UPDATE_QUESTIONS_COUNT,
      payload: quizQuestionsCount,
    });
    const data = yield call(
      UpdatePublisherFlag,
      token,
      allSites ? { apiKey } : { host },
      40,
      null,
      quizQuestionsCount,
    );
    if (data.success) {
      toaster.success(`Successfully updated`);
    }
  } catch (err) {
    console.log('Something went wrong', err.message);
  }
}

export function* getTopicsSaga() {
  try {
    const token = yield select(({ session }) => session.token);
    const data = yield call(getTopicsApi, token);
    yield put({
      type: actionTypes.GET_TOPICS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    console.log('Something went wrong', err.message);
  }
}

export function* updateAIQuizQuestionsCount(action) {
  try {
    const { allSites, data: aiQuestionsCount } = action.payload;
    const host = yield select(({ filter }) => filter.host);
    const token = yield select(({ session }) => session.token);
    const apiKey = yield select(({ session }) => session.apiKey);
    yield put({
      type: actionTypes.UPDATE_AI_QUESTIONS_COUNT,
      payload: aiQuestionsCount,
    });
    const data = yield call(
      UpdatePublisherFlag,
      token,
      allSites ? { apiKey } : { host },
      46,
      null,
      aiQuestionsCount,
    );
    if (data.success) {
      toaster.success(`Successfully updated`);
    }
  } catch (err) {
    console.log('Something went wrong', err.message);
  }
}

export function* updateQuizBlockWords(action) {
  try {
    const { allSites, data: quizBlockedWords } = action.payload;
    const host = yield select(({ filter }) => filter.host);
    const token = yield select(({ session }) => session.token);
    const apiKey = yield select(({ session }) => session.apiKey);
    yield put({
      type: actionTypes.UPDATE_BLOCK_WORD_QW,
      payload: quizBlockedWords,
    });
    const data = yield call(
      UpdatePublisherFlag,
      token,
      allSites ? { apiKey } : { host },
      49,
      null,
      quizBlockedWords,
    );
    if (data.success) {
      toaster.success(`Successfully updated`);
      yield put({
        type: actionTypes.UPDATE_BLOCK_WORD_QW,
        payload: data,
      });
    } else {
      toaster.danger(`${data.errors[0]}`);
    }
  } catch (err) {
    console.log('Something went wrong', err.message);
  }
}

export function* updateDefaultQuizQuestionsCount(action) {
  try {
    const { allSites, data: defaultQuestionsCount } = action.payload;
    const host = yield select(({ filter }) => filter.host);
    const token = yield select(({ session }) => session.token);
    const apiKey = yield select(({ session }) => session.apiKey);
    yield put({
      type: actionTypes.UPDATE_DEFAULT_QUESTIONS_COUNT,
      payload: defaultQuestionsCount,
    });
    const data = yield call(
      UpdatePublisherFlag,
      token,
      allSites ? { apiKey } : { host },
      45,
      null,
      defaultQuestionsCount,
    );
    if (data.success) {
      toaster.success(`Successfully updated`);
    }
  } catch (err) {
    console.log('Something went wrong', err.message);
  }
}

export function* updateBrandQuizQuestionsIndex(action) {
  try {
    const { allSites, data: brandQuestionsIndex } = action.payload;
    const host = yield select(({ filter }) => filter.host);
    const token = yield select(({ session }) => session.token);
    const apiKey = yield select(({ session }) => session.apiKey);
    yield put({
      type: actionTypes.UPDATE_BRAND_QUESTIONS_INDEX,
      payload: brandQuestionsIndex,
    });
    const data = yield call(
      UpdatePublisherFlag,
      token,
      allSites ? { apiKey } : { host },
      48,
      null,
      brandQuestionsIndex,
    );
    if (data.success) {
      toaster.success(`Successfully updated`);
    }
  } catch (err) {
    console.log('Something went wrong', err.message);
  }
}

export function* createBrandSaga(action) {
  try {
    const {
      logoType,
      brandName,
      brandTitle,
      brandLogo,
      brandColor,
      BrandLogoClickUri,
      hostFromSurvey,
    } = action.payload;
    const host = yield select(({ filter }) => filter.host);
    const token = yield select(({ session }) => session.token);
    const data = yield call(
      createBrandForQuiz,
      token,
      hostFromSurvey ? hostFromSurvey : host,
      logoType,
      brandName,
      brandTitle,
      brandLogo,
      brandColor,
      BrandLogoClickUri,
    );
    if (data.success) {
      toaster.success(`Successfully created`);
      yield put({
        type: actionTypes.UPDATE_BRAND_CREATE,
        payload: data,
      });
    } else {
      toaster.danger(`${data.errors[0]}`);
    }
  } catch (err) {
    toaster.danger(`${err.message}`);
    console.log('Something went wrong', err.message);
  }
}

export function* editBrandSaga(action) {
  try {
    const {
      id,
      logoType,
      brandName,
      brandTitle,
      brandLogo,
      brandColor,
      BrandLogoClickUri,
    } = action.payload;
    const token = yield select(({ session }) => session.token);
    const fields = [
      {
        key: 'BrandColor',
        value: brandColor,
      },
      {
        key: 'LogoType',
        value: logoType,
      },
      {
        key: 'BrandName',
        value: brandName,
      },
      {
        key: 'BrandLogo',
        value: brandLogo,
      },
      {
        key: 'BrandTitle',
        value: brandTitle,
      },
      {
        key: 'BrandLogoClickUri',
        value: BrandLogoClickUri,
      },
    ];
    const data = yield call(editBrandForQuiz, token, id, fields);
    if (data.success) {
      toaster.success(`Successfully edited`);
      yield put({
        type: actionTypes.UPDATE_BRAND_EDIT,
        payload: data,
      });
    } else {
      toaster.danger(`${data.errors[0]}`);
    }
  } catch (err) {
    toaster.danger(`${err.message}`);
    console.log('Something went wrong', err.message);
  }
}

export function* updateQuizLogo(action) {
  try {
    const { allSites } = action.payload;
    const host = yield select(({ filter }) => filter.host);
    const token = yield select(({ session }) => session.token);
    const data = yield call(DeleteLogo, token, allSites ? null : host);
    if (data.success) {
      yield put({
        type: actionTypes.UPDATE_DELETE_LOGO,
      });
      toaster.success(`Successfully deleted`);
    }
  } catch (err) {
    console.log('Something went wrong', err.message);
  }
}

export function* updateQuizBrand(action) {
  try {
    const { id } = action.payload;
    const token = yield select(({ session }) => session.token);
    const host = yield select(({ filter }) => filter.host);
    const del = yield call(DeleteBrandLogo, token, id);
    if (del.success) {
      const data = yield call(getBrands, token, host);

      yield put({
        type: actionTypes.SET_BRANDS,
        payload: data,
      });
      yield put({
        type: actionTypes.UPDATE_DELETE_BRAND_LOGO,
      });
      toaster.success(`Successfully deleted`);
    }
  } catch (err) {
    console.log('Something went wrong', err.message);
  }
}

export function* deleteQuizBrandLogo(action) {
  try {
    const { url } = action.payload;
    const token = yield select(({ session }) => session.token);
    const del = yield call(DeleteSpecBrandLogo, token, url);
    if (del.success) {
      yield put({
        type: actionTypes.UPDATE_DELETE_SPEC_BRAND_LOGO,
      });
      toaster.success(`Successfully deleted`);
    }
  } catch (err) {
    console.log('Something went wrong', err.message);
  }
}

export function* instagramButtonSettingsSwitch(action) {
  try {
    const { allSites, data: URL } = action.payload;
    const host = yield select(({ filter }) => filter.host);
    const token = yield select(({ session }) => session.token);
    const apiKey = yield select(({ session }) => session.apiKey);
    yield put({
      type: actionTypes.UPDATE_INSTAGRAM_BUTTON_SETTINGS_SWITCH,
      payload: URL,
    });
    const data = yield call(
      UpdatePublisherFlagStringValue,
      token,
      allSites ? { apiKey } : { host },
      32,
      URL,
    );
    if (data.success) {
      toaster.success(`Successfully Updated`);
    }
  } catch (err) {
    console.log('Something went wrong,', err.message);
    toaster.danger(err.message);
  }
}

export function* updateEnabledWidgets(action) {
  try {
    const { allSites, data: objValue } = action.payload;
    const host = yield select(({ filter }) => filter.host);
    const token = yield select(({ session }) => session.token);
    const apiKey = yield select(({ session }) => session.apiKey);

    const mappedEnableWidgets = [];
    objValue?.forEach((value) => {
      if (value === 1) {
        mappedEnableWidgets.push('CommentsWidget');
      } else if (value === 2) {
        mappedEnableWidgets.push('EmotesWidget');
      } else if (value === 3) {
        mappedEnableWidgets.push('ShareBar');
      }
    });

    yield put({
      type: actionTypes.UPDATE_ENABLED_WIDGETS,
      payload: mappedEnableWidgets,
    });

    const data = yield call(
      UpdatePublisherFlagObjValue,
      token,
      allSites ? { apiKey } : { host },
      36,
      objValue,
    );
    if (data.success) {
      toaster.success(`Successfully Updated`);
    }
  } catch (err) {
    console.log('Something went wrong,', err.message);
    toaster.danger(err.message);
  }
}

export function* googleButtonSettingsSwitch(action) {
  try {
    const { allSites, data: URL } = action.payload;
    const host = yield select(({ filter }) => filter.host);
    const token = yield select(({ session }) => session.token);
    const apiKey = yield select(({ session }) => session.apiKey);
    yield put({
      type: actionTypes.UPDATE_GOOGLE_BUTTON_SETTINGS_SWITCH,
      payload: URL,
    });
    const data = yield call(
      UpdatePublisherFlagStringValue,
      token,
      allSites ? { apiKey } : { host },
      31,
      URL,
    );
    if (data.success) {
      toaster.success(`Successfully Updated`);
    }
  } catch (err) {
    console.log('Something went wrong', err.message);
    toaster.danger(err.message);
  }
}

export function* pwButtonsSettingsSwitch(action) {
  try {
    const { allSites, data: state, first } = action.payload;
    const host = yield select(({ filter }) => filter.host);
    const token = yield select(({ session }) => session.token);
    const apiKey = yield select(({ session }) => session.apiKey);
    yield put({
      type: actionTypes.UPDATE_PW_BUTTONS_SETTINGS_SWITCH,
      payload: { state, first },
    });
    if (first === 3) {
      yield put({
        type: actionTypes.SET_PW_COMMENT_BUTTON_VALUE,
        payload: state,
      });
    }
    const data = yield call(
      UpdatePublisherFlag,
      token,
      allSites ? { apiKey } : { host },
      first === 1 ? 26 : first === 2 ? 27 : first === 3 ? 28 : 37,
      state,
    );
    if (data.success) {
      toaster.success(`Successfully ${!state ? 'Enabled' : 'Disabled'}!`);
    }
  } catch (err) {
    console.log('Something went wrong', err.message);
  }
}

export function* updateShowCommentsCount(action) {
  try {
    const { allSites, showCommentCount } = action.payload;
    const host = yield select(({ filter }) => filter.host);
    const token = yield select(({ session }) => session.token);
    const hostData = allSites ? '' : host;
    const data = yield call(
      setShowCommentCount,
      token,
      hostData,
      showCommentCount,
    );

    if (data) {
      toaster.success(
        `Successfully ${showCommentCount ? 'Enabled' : 'Disabled'}!`,
      );
      yield put({
        type: actionTypes.SET_COMMENTS_COUNT_SHOW_SUCCESS,
        payload: showCommentCount,
      });
    }
  } catch (err) {
    console.log('Something went wrong', err.message);
  }
}

export function* emotesRecommendedSettingsSwitch(action) {
  try {
    const { allSites, data: hideRecommendedArticles } = action.payload;
    const apiKey = yield select(({ session }) => session.apiKey);
    const host = yield select(({ filter }) => filter.host);
    const token = yield select(({ session }) => session.token);
    const data = yield call(
      UpdatePublisherFlag,
      token,
      allSites ? { apiKey } : { host },
      22,
      hideRecommendedArticles,
    );
    if (data.success) {
      toaster.success(`Successfully Updated!`);
    }
  } catch (err) {
    console.log('Something went wrong', err.message);
  }
}

export function* emotesLabelsSettingsSwitch(action) {
  try {
    const { allSites, data: disable, emote, updatedState } = action.payload;
    const host = yield select(({ filter }) => filter.host);
    const token = yield select(({ session }) => session.token);
    const hostData = allSites ? '' : host;
    const data = yield call(
      updateEmotesLabelsSettings,
      token,
      hostData,
      disable,
      emote,
    );
    if (data.success) {
      toaster.success(`${emote ? 'Emote' : 'Emote label'} ${updatedState}`);
    }
  } catch (err) {
    console.log('Something went wrong', err.message);
  }
}

export function* emotesOrderSettings(action) {
  try {
    const { allSites, orderedList } = action.payload;
    const host = yield select(({ filter }) => filter.host);
    const token = yield select(({ session }) => session.token);
    const hostData = allSites ? '' : host;
    const data = yield call(
      updateEmotesOrderSettings,
      token,
      hostData,
      orderedList,
    );
    if (data.success) {
      toaster.success(`Emotes reordered successfully`);
    }
  } catch (err) {
    console.log('Something went wrong', err.message);
  }
}

export function* updateCommentsLength(params) {
  try {
    const host = yield select(({ filter }) => filter.host);
    const token = yield select(({ session }) => session.token);
    const apiKey = yield select(({ session }) => session.apiKey);

    const config = {};

    if (!params.payload.allSites) {
      config.host = host;
    } else {
      config.apiKey = apiKey;
    }

    config.commentsLength = params.payload.data;

    yield call(updateCommentsMaxLength, token, config);

    yield put({
      type: actionTypes.SET_COMMENTS_LENGTH_SUCCESS,
      payload: params.payload.data,
    });

    toaster.success(
      'Maximum count of characters on comments updated successfully',
      {
        id: 'successful-update-threshold',
      },
    );
  } catch (e) {
    console.log('err', e);
  }
}

export function* setTotWideImagesOn(params) {
  try {
    const host = yield select(({ filter }) => filter.host);
    const token = yield select(({ session }) => session.token);

    const { data, allSites } = params.payload;

    yield call(UpdateTotWideImages, token, data, allSites ? '' : host);

    toaster.success('ToT landscape Images state updated successfully', {
      id: 'successful-update-threshold',
    });
  } catch (e) {
    console.log('err', e);
  }
}

export function* setRTEOptions(params) {
  try {
    const host = yield select(({ filter }) => filter.host);
    const token = yield select(({ session }) => session.token);
    const apiKey = yield select(({ session }) => session.apiKey);

    const { data, allSites } = params.payload;

    let config = {
      rteItems: data,
    };

    if (allSites) {
      config.apiKey = apiKey;
    } else {
      config.host = host;
    }
    yield call(UpdatePublisherRTEoptions, token, config);

    toaster.success('RTE options updated successfully', {
      id: 'successful-update-threshold',
    });
  } catch (e) {
    console.log('err', e);
  }
}

export function* setBrands(action) {
  try {
    const token = yield select(({ session }) => session.token);
    const { host } = action.payload;

    const data = yield call(getBrands, token, host);
    yield put({
      type: actionTypes.SET_BRANDS,
      payload: data,
    });
  } catch (e) {
    console.log('err', e);
  }
}

export function* changeHorizontalPosition(params) {
  try {
    const host = yield select(({ filter }) => filter.host);
    const token = yield select(({ session }) => session.token);
    const apiKey = yield select(({ session }) => session.apiKey);

    const { data, allSites } = params.payload;

    let config = {
      powerbarHorizontalPosition: Number(data),
    };

    if (allSites) {
      config.apiKey = apiKey;
    } else {
      config.host = host;
    }

    yield call(SetPublisherPowerbarHorizontalPosition, token, config);

    yield put({
      type: actionTypes.SET_HORIZONTAL_POSITION,
      payload: getValueOfHorizontalPos(data),
    });
  } catch (e) {
    console.log('err', e);
  }
}

export default function* commentsWidgetSettingsSaga() {
  yield takeLatest(
    actionTypes.CHANGE_SHOW_NEWSLETTER_POPUP,
    updateShowNewsletterPopupValue,
  );
  yield takeLatest(
    actionTypes.EMOTE_AND_SHAREBAR_EDIT_REQUEST,
    emoteAndSharebarEditRequest,
  );
  yield takeLatest(
    actionTypes.PDF_BUTTON_SETTINGS_SWITCH_REQUEST,
    pdfButtonSettingsSwitch,
  );
  yield takeLatest(
    actionTypes.PB_AUDIO_SETTINGS_SWITCH_REQUEST,
    pbAudioSettingsSwitch,
  );
  yield takeLatest(
    actionTypes.PW_BUTTONS_SETTINGS_SWITCH_REQUEST,
    pwButtonsSettingsSwitch,
  );
  yield takeLatest(
    actionTypes.INSTAGRAM_BUTTON_SETTINGS_SWITCH_REQUEST,
    instagramButtonSettingsSwitch,
  );
  yield takeLatest(
    actionTypes.GOOGLE_BUTTON_SETTINGS_SWITCH_REQUEST,
    googleButtonSettingsSwitch,
  );
  yield takeLatest(
    actionTypes.SET_COMMENTS_COUNT_SHOW_REQUEST,
    updateShowCommentsCount,
  );
  yield takeLatest(
    actionTypes.EMOTE_RECOMMENDED_SETTINGS_SWITCH_REQUEST,
    emotesRecommendedSettingsSwitch,
  );
  yield takeLatest(
    actionTypes.EMOTE_LABELS_SETTINGS_SWITCH_REQUEST,
    emotesLabelsSettingsSwitch,
  );
  yield takeLatest(
    actionTypes.EMOTE_ORDER_SETTINGS_REQUEST,
    emotesOrderSettings,
  );
  yield takeLatest(actionTypes.SET_LOGIN_TYPES_REQUEST, setLoginTypes);
  yield takeLatest(
    actionTypes.SET_LOGIN_TO_SSO_REQUEST,
    setLoginTypesToSSOOnly,
  );

  yield takeLatest(actionTypes.UPDATE_TOT_COUNT_REQUEST, updateTotSaga);
  yield takeLatest(
    actionTypes.UPDATE_TOT_INTERVAL_REQUEST,
    updateTotIntervalSaga,
  );
  yield takeLatest(
    actionTypes.UPDATE_DEFAULT_SORTING_REQUEST,
    updateDefaultSortingSaga,
  );
  yield takeLatest(
    actionTypes.UPDATE_EXTERNAL_SEARCH_REQUEST,
    updateExternalSearchSaga,
  );
  yield takeLatest(
    actionTypes.UPDATE_FLAG_THRESHOLD_REQUEST,
    updateFlagThresholdSaga,
  );
  yield takeLatest(
    actionTypes.UPDATE_LOADED_COMMENTS_REQUEST,
    updateLoadedCommentsSaga,
  );
  yield takeLatest(
    actionTypes.SET_HIDE_COMMENTS_WIDGET_REQUEST,
    updateHideCommentsWidgetSaga,
  );
  yield takeLatest(
    actionTypes.UPDATE_BUBBLE_TYPE_REQUEST,
    updateBubbleButtonTypeSaga,
  );
  yield takeLatest(
    actionTypes.SET_BUBBLE_LOAD_TIME_REQUEST,
    updateBubbleButtonLoadTimeSaga,
  );
  yield takeLatest(
    actionTypes.SET_BUBBLE_LOAD_AT_REQUEST,
    updateBubbleButtonAtSaga,
  );
  yield takeLatest(
    actionTypes.SET_HIDE_INPUT_BOX_COMMENTS_WIDGET_REQUEST,
    updateHideInputBoxCommentsWidgetSaga,
  );
  yield takeLatest(
    actionTypes.SET_COMMENT_PANEL_REQUEST,
    updateCommentPanelSaga,
  );
  yield takeLatest(actionTypes.SET_SPAM_LIMIT_REQUEST, updateSpamLimitSaga);
  yield takeLatest(actionTypes.GET__BLOCK_WORD_LIST_REQUEST, getBlockListSaga);
  yield takeLatest(
    actionTypes.SET_TOXICITY_LIMIT_REQUEST,
    updateToxicityLimitSaga,
  );
  yield takeLatest(actionTypes.SET_DARK_MODE_REQUEST, updateDarkModeSaga);
  yield takeLatest(
    actionTypes.SET_TREADS_TO_DISPLAY_REQUEST,
    updateCommentsTreadShowSaga,
  );
  yield takeLatest(actionTypes.SET_COLOR_REQUEST, updateColorSaga);
  yield takeLatest(
    actionTypes.SET_OPEN_ARTICLES_IN_SAME_TAB_REQUEST,
    updateOpenInSameTabSaga,
  );
  yield takeLatest(actionTypes.SET_TOT_TEXT_REQUEST, updateToTTextSaga);
  yield takeLatest(
    actionTypes.SET_EMOTE_HEADER_REQUEST,
    updateEmotesHeaderSaga,
  );
  yield takeLatest(actionTypes.SET_COMMENTS_LENGTH, updateCommentsLength);
  yield takeLatest(actionTypes.SET_TOT_WIDE_IMAGES_ON, setTotWideImagesOn);
  yield takeLatest(actionTypes.SET_EMOTE_VOTE_REQUEST, updateEmotesVoteSaga);
  yield takeLatest(actionTypes.SET_BRAND_CREATE, createBrandSaga);
  yield takeLatest(actionTypes.SET_BRAND_EDIT, editBrandSaga);
  yield takeLatest(actionTypes.GET_BRANDS, setBrands);
  yield takeLatest(actionTypes.SET_RTE_OPTION, setRTEOptions);
  yield takeLatest(
    actionTypes.CHANGE_HORIZONTAL_POSITION,
    changeHorizontalPosition,
  );
  yield takeLatest(actionTypes.SET_ENABLED_WIDGETS, updateEnabledWidgets);
  yield takeLatest(actionTypes.SET_PUBLISHER_STYLES, updatePublisherStyles);
  yield takeLatest(actionTypes.SET_INTERACTIVITY_HUB, updateInteractivityHub);
  yield takeLatest(actionTypes.SET_DISABLED_TOPICS, updateDisabledTopics);
  yield takeLatest(actionTypes.SET_DISABLE_RESULTS, disableQuizResultsScreen);
  yield takeLatest(actionTypes.SET_DISABLE_NEXT_BUTTON, disableQuizNextButton);
  yield takeLatest(
    actionTypes.SET_DISABLE_DEFAULT,
    disableQuizDefaultQuestions,
  );
  yield takeLatest(
    actionTypes.SET_DISABLE_MARKETING,
    disableQuizMarketingQuestions,
  );
  yield takeLatest(actionTypes.SET_QUESTIONS_COUNT, updateQuizQuestionsCount);
  yield takeLatest(
    actionTypes.SET_AI_QUESTIONS_COUNT,
    updateAIQuizQuestionsCount,
  );
  yield takeLatest(actionTypes.SET_BLOCK_WORD_QW, updateQuizBlockWords);
  yield takeLatest(
    actionTypes.SET_DEFAULT_QUESTIONS_COUNT,
    updateDefaultQuizQuestionsCount,
  );
  yield takeLatest(
    actionTypes.SET_BRAND_QUESTIONS_INDEX,
    updateBrandQuizQuestionsIndex,
  );
  yield takeLatest(actionTypes.SET_DELETE_LOGO, updateQuizLogo);
  yield takeLatest(actionTypes.GET_TOPICS_REQUEST, getTopicsSaga);
  yield takeLatest(actionTypes.SET_DELETE_BRAND_LOGO, updateQuizBrand);
  yield takeLatest(actionTypes.SET_DELETE_SPEC_BRAND_LOGO, deleteQuizBrandLogo);
}
