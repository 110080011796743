import axios from 'axios';

export const getUsers = (token) =>
  axios
    .get(`${process.env.API_BASE_URL}api/v1/admin_users/getUsersByApiKey`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      throw new Error(errors[0]);
    });

export const addUser = (token, host, email, name, permissions) =>
  axios
    .post(
      `${process.env.API_BASE_URL}api/v1/admin_users/add`,
      {
        email,
        name,
        permissions,
        host,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      if (errors) {
        throw new Error(errors[0]);
      }

      throw new Error('not valid response');
    });

export const addForHosts = (token, hosts, email, name, permissions, autoAddForNewHost) =>
  axios
    .post(
      `${process.env.API_BASE_URL}api/v1/admin_users/addForHosts`,
      {
        admin: { email, name, permissions, autoAddForNewHost },
        hosts,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      if (errors) {
        throw new Error(errors[0]);
      }

      throw new Error('not valid response');
    });

export const addUserByApiKey = (token, apiKey, email, name, permissions) =>
  axios
    .post(
      `${process.env.API_BASE_URL}api/v1/admin_users/addByApiKey`,
      {
        email,
        name,
        permissions,
        apiKey,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      throw new Error(errors[0]);
    });

export const updateUser = (token, host, email, permissions) =>
  axios
    .put(
      `${process.env.API_BASE_URL}api/v1/admin_users/update`,
      {
        email,
        permissions,
        host,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      throw new Error(errors[0]);
    });

export const updateUserByApiKey = (token, apiKey, email, permissions) =>
  axios
    .put(
      `${process.env.API_BASE_URL}api/v1/admin_users/updateByApiKey`,
      {
        apiKey,
        email,
        permissions,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      throw new Error(errors[0]);
    });

export const deleteUser = (token, host, email) =>
  axios
    .delete(`${process.env.API_BASE_URL}api/v1/admin_users/delete`, {
      data: {
        email,
        host,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      throw new Error(errors[0]);
    });

export const deleteUserByApiKey = (token, apiKey, email) =>
  axios
    .delete(`${process.env.API_BASE_URL}api/v1/admin_users/deleteByApiKey`, {
      data: {
        email,
        apiKey,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      throw new Error(errors[0]);
    });

export const changePasswordForUser = (token, email, newPassword) =>
  axios
    .post(
      `${process.env.API_BASE_URL}api/v1/Auth/changePasswordForUser`,
      {
        email,
        newPassword,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      throw new Error(errors[0]);
    });
