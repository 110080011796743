// ==== 💪 Action Types
export const actionTypes = {
  LOAD_SHARE_CLICKS: '@analytics/shares/load-share-clicks',
  SET_SHARE_CLICKS: '@analytics/shares/set-share-clicks',

  LOAD_MOST_SHARED_ARTICLES: '@analytics/shares/load-most-shared-articles',
  SET_MOST_SHARED_ARTICLES: '@analytics/comments/set-most-shared-articles',

  LOAD_SHARE_CLICKS_BY_TIME: '@analytics/shares/load-share-clicks-by-time',
  SET_SHARE_CLICKS_BY_TIME: '@analytics/shares/set-share-clicks-by-time',

  LOAD_AGGREGATED_SHARE_CLICKS_BY_TIME: '@analytics/shares/load-aggregated-share-clicks-by-time',
};

// ==== 🎬 Actions
export const loadShareClickss = (network) => ({
  type: actionTypes.LOAD_SHARE_CLICKS,
  payload: network,
});

export const loadMostSharedArticles = (network) => ({
  type: actionTypes.LOAD_MOST_SHARED_ARTICLES,
  payload: network,
});

export const loadShareClicksByTime = () => ({
  type: actionTypes.LOAD_SHARE_CLICKS_BY_TIME,
});

export const loadAggregatedShareClicksByTime = (from, to, groupType) => ({
  type: actionTypes.LOAD_AGGREGATED_SHARE_CLICKS_BY_TIME,
  payload: {from, to, groupType},
});
