import axios from 'axios';

export const WebNotificationTopicsGETHost = (token, host) =>
  axios
    .get(process.env.API_BASE_URL + 'api/v1/Publishers/WebNotificationTopics', {
      params: { host },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      throw new Error(errors[0]);
    });
export const WebNotificationGetTotalData = (token, host) =>
  axios
    .get(process.env.API_BASE_URL + 'api/v1/WebNotificationStats/TotalStats', {
      params: { host },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      throw new Error(errors[0]);
    });
export const WebNotificationTopicsGETAll = (token, host) =>
  axios
    .get(process.env.API_BASE_URL + 'api/v2/Publishers/WebNotificationTopics', {
      params: { host },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      throw new Error(errors[0]);
    });
export const WebNotificationTopicsPOST = (token, host, topic) =>
  axios
    .post(
      process.env.API_BASE_URL + 'api/v1/Publishers/WebNotificationTopics',
      {
        host,
        topic,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      if (response) {
        return response.data;
      }
    });
export const WebNotificationTopicsDELETE = (token, id, allSites) =>
  axios
    .delete(
      process.env.API_BASE_URL +
        `api/v1/Publishers/WebNotificationTopics/${id}?allSites=${allSites}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { success } = response.data;

      if (success) {
        return success;
      }
      return false;
    });
export const notificationGet = (token, host) =>
  axios
    .get(process.env.API_BASE_URL + 'api/v1/WebNotification/notification', {
      params: { host },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      throw new Error(errors[0]);
    });
export const notificationPost = (token, data) =>
  axios
    .post(
      process.env.API_BASE_URL + 'api/v1/WebNotification/notification',
      {
        ...data,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      throw new Error(errors[0]);
    });
export const testnotification = (token, data) =>
  axios
    .post(
      process.env.API_BASE_URL + 'api/v1/WebNotification/testnotification',
      {
        ...data,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return success;
      }

      throw new Error(errors[0]);
    });
export const SendNotification = (token, id) =>
  axios
    .post(
      process.env.API_BASE_URL +
        `api/v1/WebNotification/SendNotification/${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { success } = response.data;

      if (success) {
        return success;
      }
      return false;
    });
export const ScheduleNotification = (token, id, scheduleTimeUtc) =>
  axios
    .post(
      process.env.API_BASE_URL +
        `api/v1/WebNotification/ScheduleNotification/${id}`,
      {
        scheduleTimeUtc,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    });
export const reportsGet = (token, from, to, host) =>
  axios
    .get(process.env.API_BASE_URL + 'api/v1/WebNotificationStats/Stats', {
      params: { from, to, host },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      throw new Error(errors[0]);
    });
