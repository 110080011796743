import { call, put, select, takeLatest } from 'redux-saga/effects';
import { toaster } from 'evergreen-ui';
import {
  blockForOffence,
  unblockOffenceItem,
  blockForOffenceByApiKey,
  unblockOffenceItemByApiKey,
  whitelistEmail,
  whitelistEmailByApiKey,
  deleteWhitelistedEmails,
  deleteWhitelistedEmailsByApiKey,
  getWhitelistedEmails,
  getWhitelistedCount,
} from '@/services/api/settings/comments/userlists';
import {
  actionTypes,
  whitelistAddSuccess,
  whitelistGetSuccess,
  whitelistCountSuccess,
  whitelistDeleteSuccess,
  setBlockError,
} from '@/services/actions/settings/comments/userlists';
import { makeArrayFromOffensiveList } from '@/utils';

/** TODO: Maybe split setOffensiveSaga function into more functions instead of handling all action types in here */
export function* setOffensiveSaga(action) {
  try {
    const token = yield select(({ session }) => session.token);
    const apiKey = yield select(({ session }) => session.apiKey);
    const host = yield select(({ filter }) => filter.host);

    let data;

    action.decision === 'block'
      ? (data = yield call(
          !action.allSites ? blockForOffence : blockForOffenceByApiKey,
          token,
          !action.allSites ? host || action.host : apiKey,
          action.offValues,
          action.offensiveType,
          action.notes || '',
          action.sendEmailToUser,
        ))
      : (data = yield call(
          !action.allSites ? unblockOffenceItem : unblockOffenceItemByApiKey,
          token,
          !action.allSites ? host || action.host : apiKey,
          action.offValues,
          action.offensiveType,
        ));

    switch (action.type) {
      case actionTypes.BLOCK_WORD_REQUEST:
        yield put({
          type: actionTypes.BLOCK_WORD_SUCCESS,
          payload: makeArrayFromOffensiveList(data.offensiveKeywords),
          allSites: action.allSites,
          words: action.offValues,
        });
        toaster.success('Word(s) successfully blocked', {
          id: 'successful-word-block',
        });
        break;
      case actionTypes.UNBLOCK_WORD_REQUEST:
        yield put({
          type: actionTypes.UNBLOCK_WORD_SUCCESS,
          payload: makeArrayFromOffensiveList(data.offensiveKeywords),
          allSites: action.allSites,
          words: action.offValues,
        });
        toaster.success('Word successfully unblocked', {
          id: 'successful-word-unblock',
        });
        break;
      case actionTypes.BLOCK_USERNAME_REQUEST:
        yield put({
          type: actionTypes.BLOCK_USERNAME_SUCCESS,
          payload: makeArrayFromOffensiveList(data.offensiveUserName),
          allSites: action.allSites,
          usernames: action.offValues,
        });
        toaster.success('User name(s) successfully blocked', {
          id: 'successful-username-block',
        });
        break;
      case actionTypes.UNBLOCK_USERNAME_REQUEST:
        yield put({
          type: actionTypes.UNBLOCK_USERNAME_SUCCESS,
          payload: makeArrayFromOffensiveList(data.offensiveUserName),
          allSites: action.allSites,
          usernames: action.offValues,
        });
        toaster.success('User name successfully unblocked', {
          id: 'successful-username-unblock',
        });
        break;
      case actionTypes.BLOCK_EMAIL_REQUEST:
        yield put({
          type: actionTypes.BLOCK_EMAIL_SUCCESS,
          payload: makeArrayFromOffensiveList(data.offensiveEmail),
          allSites: action.allSites,
          emails: action.offValues,
        });
        toaster.success('Email(s) successfully blocked', {
          id: 'successful-email-block',
        });
        break;
      case actionTypes.UNBLOCK_EMAIL_REQUEST:
        yield put({
          type: actionTypes.UNBLOCK_EMAIL_SUCCESS,
          payload: makeArrayFromOffensiveList(data.offensiveEmail),
          allSites: action.allSites,
          emails: action.offValues,
        });
        toaster.success('Email successfully unblocked', {
          id: 'successful-email-unblock',
        });
        break;
      case actionTypes.BLOCK_IP_REQUEST:
        yield put({
          type: actionTypes.BLOCK_IP_SUCCESS,
          payload: makeArrayFromOffensiveList(data.offensiveIp),
          allSites: action.allSites,
          ips: action.offValues,
        });
        toaster.success('IP(s) successfully blocked', {
          id: 'successful-ip-block',
        });
        break;
      case actionTypes.UNBLOCK_IP_REQUEST:
        yield put({
          type: actionTypes.UNBLOCK_IP_SUCCESS,
          payload: makeArrayFromOffensiveList(data.offensiveIp),
          allSites: action.allSites,
          ips: action.offValues,
        });
        toaster.success('IP successfully unblocked', {
          id: 'successful-ip-unblock',
        });
        break;
    }
  } catch (e) {
    let msg_description =
      'Something went wrong with your request! Please try again later.';

    if (e.message === 'already_blocked') {
      if (action.offensiveType === 0) {
        msg_description = 'This word is already blocked';
      } else if (action.offensiveType === 1) {
        msg_description = 'This ip is already blocked';
      } else if (action.offensiveType === 2) {
        msg_description = 'This email is already blocked';
      } else if (action.offensiveType === 3) {
        msg_description = 'This username is already blocked';
      }
    } else if (e.message === 'already_in_whitelist') {
      msg_description = 'This email is already whitelisted';
    } else if (e.message === 'you_cant_block_single_char') {
      msg_description = 'Blocking a single character is not allowed';
    } else if (e.message === 'offensive_values_are_empty') {
      msg_description = 'Empty value';
    } else if (e.message === 'offensive_blocked_on_master') {
      msg_description = 'This word was blocked by Vuukle administration ';
    } else if (e.message === 'blocked_value_too_long') {
      msg_description = "The blocked word can't be more than 36 chars.";
    } else if (e.message === 'blocked_value_too_short') {
      msg_description = 'Blocking two characters is not allowed';
    }

    toaster.danger('Error', {
      description: msg_description,
      duration: 5,
      id: 'unsuccessful-block-unblock',
    });
    yield put(setBlockError());
  }
}

export function* whitelistFetchSaga(params) {
  const session = yield select(({ session }) => session);
  const { host } = yield select(({ filter }) => filter);
  const permissions = session.permissions.find((perm) => perm.host === host);

  if (
    session.isUserAdmin ||
    permissions?.userPermissions?.includes('Settings')
  ) {
    try {
      const token = yield select(({ session }) => session.token);
      const emails = yield select(
        ({ settingsPage }) =>
          settingsPage.comments.userlists.whitelistedEmail[host],
      );

      const data = yield call(
        getWhitelistedEmails,
        token,
        host,
        emails ? emails.length : 0,
      );

      yield put(whitelistGetSuccess(data, host));
    } catch (e) {
      console.log('Something went wrong', e.message);
    }
  }
}

export function* whitelistCountSaga() {
  const session = yield select(({ session }) => session);
  const host = yield select(({ filter }) => filter.host);
  const permissions = session.permissions.find((perm) => perm.host === host);

  if (
    session.isUserAdmin ||
    permissions?.userPermissions?.includes('Settings')
  ) {
    try {
      const token = yield select(({ session }) => session.token);
      const count = yield select(
        ({ settingsPage }) =>
          settingsPage.comments.userlists.whitelistedEmailCounts[host],
      );
      let data;

      if (!count) {
        data = yield call(getWhitelistedCount, token, host);
      } else {
        data = count;
      }

      yield put(whitelistCountSuccess(host, data));
    } catch (e) {
      console.log('Something went wrong', e.message);
    }
  }
}

export function* whitelistAddSaga(action) {
  try {
    const token = yield select(({ session }) => session.token);
    const apiKey = yield select(({ session }) => session.apiKey);
    const host = yield select(({ filter }) => filter.host);

    yield call(
      action.allSites ? whitelistEmailByApiKey : whitelistEmail,
      token,
      action.allSites ? apiKey : host,
      action.emails,
      action.notes || '',
    );

    yield put(whitelistAddSuccess(action.emails, host, action.allSites));
    toaster.success('The email(s) were successfully whitelisted', {
      id: 'successful-add-whitelist',
    });
  } catch (e) {
    yield put(setBlockError());
    let errorMessage =
      'Something went wrong when trying to add email(s) to the whitelist.';
    if (e.message === 'already_whitelisted') {
      errorMessage = 'This user is already whitelisted.';
    } else if (e.message === 'already_in_blacklist') {
      errorMessage = 'This user is already blacklisted.';
    } else if (e.message === 'in_master_blacklist') {
      errorMessage =
        "This user can not be whitelisted because they're blocked from using Vuukle globally.";
    } else if (e.message === 'invalid_user_email') {
      errorMessage = 'The email you have provided is invalid';
    }

    toaster.danger('Whitelisting error.', {
      description: errorMessage,
      id: 'unsuccessful-add-whitelist',
    });
    console.log('Something went wrong', e.message);
  }
}

export function* whitelistDeleteSaga(action) {
  try {
    const token = yield select(({ session }) => session.token);
    const apiKey = yield select(({ session }) => session.apiKey);
    const host = yield select(({ filter }) => filter.host);

    yield call(
      action.allSites
        ? deleteWhitelistedEmailsByApiKey
        : deleteWhitelistedEmails,
      token,
      action.allSites ? apiKey : host,
      action.emails,
    );

    yield put(whitelistDeleteSuccess(action.emails, host, action.allSites));
    toaster.success(`${action.emails} is now removed from the whitelist`, {
      id: 'successful-remove-whitelist',
    });
  } catch (e) {
    yield put(setBlockError());
    toaster.danger(
      'Something went wrong when trying to remove email(s) from the whitelist.',
      {
        id: 'unsuccessful-remove-whitelist',
      },
    );
    console.log('Something went wrong', e.message);
  }
}

export default function* userListsSettingsSaga() {
  yield takeLatest(actionTypes.BLOCK_WORD_REQUEST, setOffensiveSaga);
  yield takeLatest(actionTypes.BLOCK_IP_REQUEST, setOffensiveSaga);
  yield takeLatest(actionTypes.BLOCK_EMAIL_REQUEST, setOffensiveSaga);
  yield takeLatest(actionTypes.BLOCK_USERNAME_REQUEST, setOffensiveSaga);

  yield takeLatest(actionTypes.UNBLOCK_WORD_REQUEST, setOffensiveSaga);
  yield takeLatest(actionTypes.UNBLOCK_IP_REQUEST, setOffensiveSaga);
  yield takeLatest(actionTypes.UNBLOCK_EMAIL_REQUEST, setOffensiveSaga);
  yield takeLatest(actionTypes.UNBLOCK_USERNAME_REQUEST, setOffensiveSaga);

  yield takeLatest(actionTypes.WHITELIST_ADD_EMAIL_REQUEST, whitelistAddSaga);
  yield takeLatest(
    actionTypes.WHITELIST_REMOVE_EMAIL_REQUEST,
    whitelistDeleteSaga,
  );
  yield takeLatest(actionTypes.WHITELIST_GET_REQUEST, whitelistFetchSaga);
  yield takeLatest(actionTypes.WHITELIST_COUNT_REQUEST, whitelistCountSaga);
}
