import { call, put, select, takeLatest } from 'redux-saga/effects';
import { toaster } from 'evergreen-ui';
import {
  getUsers,
  deleteUser,
  deleteUserByApiKey,
  updateUser,
  updateUserByApiKey,
  changePasswordForUser,
  addForHosts,
} from '@/services/api/settings/user';
import {
  getUserSuccess,
  setUserPasswordSuccess,
  userManagementError,
  actionTypes,
} from '@/services/actions/settings/user';

export function* addUserSaga(action) {
  try {
    const token = yield select(({ session }) => session.token);

    yield call(
      addForHosts,
      token,
      action.hosts,
      action.email,
      action.name,
      action.permissions,
      action.autoAddForNewHost,
    );
    yield put({ type: actionTypes.GET_USER_REQUEST });
    toaster.success('User added successfully', { id: 'successful-add-user' });
  } catch (e) {
    yield put(userManagementError());
    if (e.message === 'user_exists') {
      toaster.danger('The user you are adding already exists', {
        id: 'unsuccessful-add-user',
      });
    } else if (e.message === 'user_already_admin_for_another_account') {
      toaster.danger('This user is already an admin for another account.', {
        id: 'unsuccessful-add-user',
      });
    } else {
      toaster.danger(e.message, {
        id: 'unsuccessful-add-user',
      });
    }
    console.log('Something went wrong', e.message);
  }
}

export function* getUserSaga() {
  try {
    const token = yield select(({ session }) => session.token);
    const data = yield call(getUsers, token);

    const addedEmails = [];
    let newData = [];

    data.forEach((el) => {
      if (!addedEmails.includes(el.email)) {
        addedEmails.push(el.email);
        newData.push(el);
      } else {
        const existedEl = newData.find((user) => user.email == el.email);
        if (existedEl && !existedEl.anotherPerm) {
          existedEl.anotherPerm = [];
        }
        existedEl.anotherPerm = [...existedEl.anotherPerm, el];
        const newCustomData = newData.filter((user) => user.email !== el.email);
        newData = [...newCustomData, existedEl];
      }
    });

    yield put(getUserSuccess(data, newData));
  } catch (e) {
    yield put(userManagementError());
    toaster.danger(
      'Something went wrong while trying to fetch the user list.',
      {
        id: 'unsuccessful-fetch-user',
      },
    );
    console.log('Something went wrong', e.message);
  }
}

export function* deleteUserSaga(action) {
  try {
    const token = yield select(({ session }) => session.token);
    const apiKey = yield select(({ session }) => session.apiKey);

    yield call(
      action.allSites ? deleteUserByApiKey : deleteUser,
      token,
      action.allSites ? apiKey : action.host,
      action.email,
    );
    yield put({ type: actionTypes.GET_USER_REQUEST });
    toaster.success('User deleted successfully', {
      id: 'successful-deleted-user',
    });
  } catch (e) {
    yield put(userManagementError());
    toaster.danger('Something went wrong while trying to delete a user.', {
      id: 'unsuccessful-delete-user',
    });
    console.log('Something went wrong', e.message);
  }
}

export function* updateUserSaga(action) {
  try {
    const token = yield select(({ session }) => session.token);
    const apiKey = yield select(({ session }) => session.apiKey);

    yield call(
      action.host && action.host != '' ? updateUser : updateUserByApiKey,
      token,
      action.host || apiKey,
      action.email,
      action.permissions,
    );
    yield put({ type: actionTypes.GET_USER_REQUEST });
    toaster.success('User updated successfully', {
      id: 'successful-update-user',
    });
  } catch (e) {
    yield put(userManagementError());
    toaster.danger('Something went wrong while trying to update the user.', {
      id: 'unsuccessful-update-user',
    });
    console.log('Something went wrong', e.message);
  }
}

export function* changePasswordSaga(action) {
  try {
    const token = yield select(({ session }) => session.token);

    yield call(changePasswordForUser, token, action.email, action.newPassword);
    yield put(setUserPasswordSuccess());
    toaster.success('User password updated', {
      id: 'successful-user-password-update',
    });
  } catch (e) {
    yield put(userManagementError());
    toaster.danger(
      "Something went wrong while trying to update the user's password.",
      {
        id: 'unsuccessful-user-password-update',
      },
    );
    console.log('Something went wrong', e.message);
  }
}

export default function* userManagementSaga() {
  yield takeLatest(actionTypes.GET_USER_REQUEST, getUserSaga);
  yield takeLatest(actionTypes.ADD_USER_REQUEST, addUserSaga);
  yield takeLatest(actionTypes.UPDATE_USER_REQUEST, updateUserSaga);
  yield takeLatest(actionTypes.DELETE_USER_REQUEST, deleteUserSaga);
  yield takeLatest(actionTypes.SET_USER_PASSWORD_REQUEST, changePasswordSaga);
}
