// ==== 💪 Action Types
export const actionTypes = {
  LOAD_AUDIO_STATE: '@analytics/audio/load-audio-state',
  SET_AUDIO_STATE: '@analytics/audio/set-audio-state',

  SET_AUDIO: '@analytics/audio/set-audio',

  LOAD_TOP_ARTICLES_WITH_FILTERING: '@analytics/audio/load-top-articles-with-filtering',
  LOAD_TOP_ARTICLES: '@analytics/audio/load-top-articles',
  SET_TOP_ARTICLES: '@analytics/audio/set-top-articles',

  LOAD_TOP_COUNTRIES: '@analytics/audio/load-top-countries',
  SET_TOP_COUNTRIES: '@analytics/audio/set-top-countries',

  LOAD_TOP_CITIES: '@analytics/audio/load-top-cities',
  SET_TOP_CITIES: '@analytics/audio/set-top-cities',

  LOAD_TOP_OSS: '@analytics/audio/load-top-oss',
  SET_TOP_OSS: '@analytics/audio/set-top-oss',

  LOAD_TOP_BROWSERS: '@analytics/audio/load-top-browsers',
  SET_TOP_BROWSERS: '@analytics/audio/set-top-browsers',

  LOAD_TOP_DEVICES: '@analytics/audio/load-top-devices',
  SET_TOP_DEVICES: '@analytics/audio/set-top-devices',

  LOAD_TOP_ARTICLES_TX: '@analytics/audio/load-top-articles-tx',

  SET_AUDIO_DATA_DIFFERENCE: '@analytics/audio/set-emote-data-difference',
};

// ==== 🎬 Actions
export const loadAudioState = (from, to) => ({
  type: actionTypes.LOAD_AUDIO_STATE,
  payload: {from, to}
});

export const updateAudio = (emote) => ({
  type: actionTypes.SET_AUDIO,
  payload: emote,
});

export const loadTopArticles = (from, to) => ({
  type: actionTypes.LOAD_TOP_ARTICLES,
  payload: {from, to}
});

export const loadTopArticlesWithFiltering = (from, to, filter, filterName) => ({
  type: actionTypes.LOAD_TOP_ARTICLES_WITH_FILTERING,
  payload: {from, to, filter, filterName}
});

export const loadTopArticlesTX = () => ({
  type: actionTypes.LOAD_TOP_ARTICLES_TX,
});

export const loadTopCountries = (count = 500, tx = false) => ({
  type: actionTypes.LOAD_TOP_COUNTRIES,
  payload: {count, tx},
});

export const loadTopCities = (count = 500, tx = false) => ({
  type: actionTypes.LOAD_TOP_CITIES,
  payload: {count, tx},
});

export const loadTopOss = (count = 500, tx = false, from, to) => ({
  type: actionTypes.LOAD_TOP_OSS,
  payload: {count, tx, from, to},
});

export const loadTopBrowsers = (count = 500, tx = false, from, to) => ({
  type: actionTypes.LOAD_TOP_BROWSERS,
  payload: {count, tx, from, to},
});

export const loadTopDevices = (count = 500, tx = false, from, to) => ({
  type: actionTypes.LOAD_TOP_DEVICES,
  payload: {count, tx, from, to},
});
