import { call, put, select, takeLatest } from 'redux-saga/effects';
import dayjs from 'dayjs';
import { toaster } from 'evergreen-ui';

import {
  getTopByFilter,
  getTopByFilterByApiKey,
  getTopArticles,
  getTopArticlesByApiKey,
  getEmoteState,
  getEmoteStateByApiKey,
  getTopArticlesByFilter,
  getTopArticlesByFilterByApiKey,
} from '@/services/api/analytics/audio';

import { actionTypes } from '@/services/actions/analytics/audio';
import { initialState } from '@/services/reducers/analytics/audio';

export function* loadEmoteState(params) {
  try {
    const { token, apiKey } = yield select(({ session }) => session);
    const { host } = yield select(({ filter }) => filter);
    const { from, to } = params.payload;

    const result = yield call(
      host ? getEmoteState : getEmoteStateByApiKey,
      token,
      dayjs.utc(from).startOf('day').unix(),
      dayjs.utc(to).endOf('day').unix(),
      host || apiKey,
    );

    yield put({
      type: actionTypes.SET_AUDIO_STATE,
      payload: result.total,
    });
  } catch (e) {
    console.log('err', e);
    toaster.danger('Error fetching emote state', {
      id: 'analytics-emote-state',
    });
    yield put({
      type: actionTypes.SET_AUDIO_STATE,
      payload: initialState.emote_state,
    });
  }
}

export function* loadTopArticles(params) {
  try {
    const token = yield select(({ session }) => session.token);
    const apiKey = yield select(({ session }) => session.apiKey);
    const { host } = yield select(({ filter }) => filter);
    const emote = yield select(({ analytics }) => analytics.emotes.emote);
    const { from, to } = params.payload;

    const result = yield call(
      host ? getTopArticles : getTopArticlesByApiKey,
      token,
      host || apiKey,
      dayjs.utc(from).startOf('day').unix(),
      dayjs.utc(to).endOf('day').unix(),
      emote,
    );

    yield put({
      type: actionTypes.SET_TOP_ARTICLES,
      payload: result || initialState.top_articles,
    });
  } catch (e) {
    console.log('err', e);
    toaster.danger('Error fetching top articles', {
      id: 'analytics-top-articles',
    });
    yield put({
      type: actionTypes.SET_TOP_ARTICLES,
      payload: initialState.top_articles,
    });
  }
}

export function* loadTopCountries(params) {
  try {
    const { token, apiKey } = yield select(({ session }) => session);
    const { host, dateRange } = yield select(({ filter }) => filter);
    const { count } = params.payload;
    const { tx } = params.payload;

    const result = yield call(
      host ? getTopByFilter : getTopByFilterByApiKey,
      token,
      host || apiKey,
      dayjs.utc(dayjs(dateRange[0])).startOf('day').unix(),
      dayjs.utc(dayjs(dateRange[1])).endOf('day').unix(),
      'Country',
      0,
      count,
      !tx && 'Country',
    );

    yield put({
      type: actionTypes.SET_TOP_COUNTRIES,
      payload: result || initialState.top_countries,
    });
  } catch (e) {
    console.log('err', e);
    toaster.danger('Error fetching top countries', {
      id: 'analytics-top-countries',
    });
    yield put({
      type: actionTypes.SET_TOP_COUNTRIES,
      payload: initialState.top_countries,
    });
  }
}

export function* loadTopCities(params) {
  try {
    const { token, apiKey } = yield select(({ session }) => session);
    const { host, dateRange } = yield select(({ filter }) => filter);
    const count = params.payload;

    const result = yield call(
      host ? getTopByFilter : getTopByFilterByApiKey,
      token,
      host || apiKey,
      dayjs.utc(dayjs(dateRange[0])).startOf('day').unix(),
      dayjs.utc(dayjs(dateRange[1])).endOf('day').unix(),
      'City',
      0,
      count,
      'City',
    );

    yield put({
      type: actionTypes.SET_TOP_CITIES,
      payload: result || initialState.top_cities,
    });
  } catch (e) {
    console.log('err', e);
    toaster.danger('Error fetching top cities', { id: 'analytics-top-cities' });
    yield put({
      type: actionTypes.SET_TOP_CITIES,
      payload: initialState.top_cities,
    });
  }
}

export function* loadTopOss(params) {
  try {
    const { token, apiKey } = yield select(({ session }) => session);
    const { host } = yield select(({ filter }) => filter);
    const { count, tx, from, to } = params.payload;

    const result = yield call(
      host ? getTopByFilter : getTopByFilterByApiKey,
      token,
      host || apiKey,
      dayjs.utc(from).startOf('day').unix(),
      dayjs.utc(to).endOf('day').unix(),
      'Os',
      0,
      count,
      !tx && 'Country',
    );

    yield put({
      type: actionTypes.SET_TOP_OSS,
      payload: result || initialState.top_oss,
    });
  } catch (e) {
    console.log('err', e);
    toaster.danger('Error fetching top OS', { id: 'analytics-top-os' });
    yield put({
      type: actionTypes.SET_TOP_OSS,
      payload: initialState.top_oss,
    });
  }
}

export function* loadTopBrowsers(params) {
  try {
    const { token, apiKey } = yield select(({ session }) => session);
    const { host } = yield select(({ filter }) => filter);
    const { count, tx, from, to } = params.payload;

    const result = yield call(
      host ? getTopByFilter : getTopByFilterByApiKey,
      token,
      host || apiKey,
      dayjs.utc(from).startOf('day').unix(),
      dayjs.utc(to).endOf('day').unix(),
      'Browser',
      0,
      count,
      !tx && 'Country',
    );

    yield put({
      type: actionTypes.SET_TOP_BROWSERS,
      payload: result || initialState.top_browsers,
    });
  } catch (e) {
    console.log('err', e);
    toaster.danger('Error fetching top browsers', {
      id: 'analytics-top-browsers',
    });
    yield put({
      type: actionTypes.SET_TOP_BROWSERS,
      payload: initialState.top_browsers,
    });
  }
}

export function* loadTopDevices(params) {
  try {
    const { token, apiKey } = yield select(({ session }) => session);
    const { host } = yield select(({ filter }) => filter);
    const { count, tx, from, to } = params.payload;

    const result = yield call(
      host ? getTopByFilter : getTopByFilterByApiKey,
      token,
      host || apiKey,
      dayjs.utc(from).startOf('day').unix(),
      dayjs.utc(to).endOf('day').unix(),
      'Device',
      0,
      count,
      !tx && 'Country',
    );

    yield put({
      type: actionTypes.SET_TOP_DEVICES,
      payload: result || initialState.top_devices,
    });
  } catch (e) {
    console.log('err', e);
    toaster.danger('Error fetching top devices', {
      id: 'analytics-top-devices',
    });
    yield put({
      type: actionTypes.SET_TOP_DEVICES,
      payload: initialState.top_devices,
    });
  }
}

export function* loadTopArticlesWithFiltering(params) {
  try {
    const token = yield select(({ session }) => session.token);
    const apiKey = yield select(({ session }) => session.apiKey);
    const { host } = yield select(({ filter }) => filter);
    const { from, to, filter, filterName } = params.payload;

    const result = yield call(
      host ? getTopArticlesByFilter : getTopArticlesByFilterByApiKey,
      token,
      host || apiKey,
      dayjs.utc(from).startOf('day').unix(),
      dayjs.utc(to).endOf('day').unix(),
      filter,
      0,
      100,
      filterName,
    );

    const data = result?.map((item) => ({ ...item, ArticleUrl: item.uri }));

    yield put({
      type: actionTypes.SET_TOP_ARTICLES,
      payload: data || [],
    });
  } catch (e) {
    console.log('err', e);
    toaster.danger('Error fetching top articles', {
      id: 'analytics-top-articles',
    });
    yield put({
      type: actionTypes.SET_TOP_ARTICLES,
      payload: initialState.top_articles,
    });
  }
}

export default function* audioSaga() {
  yield takeLatest(actionTypes.LOAD_AUDIO_STATE, loadEmoteState);

  yield takeLatest(actionTypes.LOAD_TOP_ARTICLES, loadTopArticles);

  yield takeLatest(actionTypes.LOAD_TOP_COUNTRIES, loadTopCountries);
  yield takeLatest(actionTypes.LOAD_TOP_CITIES, loadTopCities);

  yield takeLatest(actionTypes.LOAD_TOP_OSS, loadTopOss);
  yield takeLatest(actionTypes.LOAD_TOP_BROWSERS, loadTopBrowsers);
  yield takeLatest(actionTypes.LOAD_TOP_DEVICES, loadTopDevices);
  yield takeLatest(
    actionTypes.LOAD_TOP_ARTICLES_WITH_FILTERING,
    loadTopArticlesWithFiltering,
  );
}
