import { actionTypes } from '@/services/actions/settings/comments/widget';

const initialState = {
  loginTypes: 0,
  blockedWordsList: [],
  enabledFeatures: [],
  defaultCommentSorting: 0,
  commentBubbleLoadType: 0,
  loadCommentBubbleAfter: 5,
  loadCommentBubbleAt: 20,
  defaultTotCount: 0,
  defaultTotInterval: 0,
  searchOptions: 1,
  flagThreshold: 1,
  hideCommentInputBox: false,
  commentsPanelEnabled: false,
  hideCommentsWidget: false,
  showCommentCount: false,
  showNewsletterPopUp: false,
  spamLimit: 90,
  toxicityLimit: 80,
  commentThreadsToDisplay: 2,
  darkMode: false,
  color: '#1e88e5',
  openArticlesInSameTab: false,
  talkOfTheTownCommentText: 'Talk of the town',
  emoteHeaderText: 'What is your reaction?',
  error: false,
  loading: false,
  commentsLength: 1,
  pdfEnabled: false,
  googleNewsUrl: '',
  instagramUrl: '',
  emoteAndSharebarConfig: {
    emotesSize: 0,
    firstEmoteImg: null,
    firstEmoteName: null,
    secondEmoteImg: null,
    secondEmoteName: null,
    thirdEmoteImg: null,
    thirdEmoteName: null,
    fourthEmoteImg: null,
    fourthEmoteName: null,
    fifthEmoteImg: null,
    fifthEmoteName: null,
    sixthEmoteImg: null,
    sixthEmoteName: null,
    powerbarVerticalPosition: 0,
    powerbarHorizontalPosition: 'Unset',
    disabledEmotes: [],
    emoteOrder: [],
    disabledEmoteLabels: [],
    powerbarItems: [],
    defaultEmote: 0,
    showPBAudioButton: true,
    disablePowerbarEmote: true,
    disablePowerbarShowMoreButton: true,
    disableBookmarkButton: true,
    disablePBCommentIcon: true,
    disableOpenAiCommentScanning: false,
    hideRecommendedArticles: false,
    publisherStyles: false,
  },
  interactivityHub: false,
  quizDisabledTopics: [],
  quizzlyMarketingQuestionsDisabled: false,
  quizBlockedWords: [],
  quizBlockedWordsLoading: false,
  hideQuizResults: false,
  disableQuestionBypass: false,
  hideDefaultQuestions: false,
  quizQuestionsCount: 4,
  aiQuestionsCount: 7,
  defaultQuestionsCount: 3,
  brandQuestionsIndex: 3,
  quizLogo: null,
  brands: [],
  brandCreated: [],
  brandsLoading: false,
  brandCreateLoading: false,
  brandEditLoading: false,
  loadingDeleteBrand: false,
  loadingDeleteSpecBrand: false,
  deleteLoading: false,
  topics: [],
  topicsLoading: false,
};

const widgetSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_PDF_BUTTON_SETTINGS_SWITCH: {
      return { ...state, pdfEnabled: action.payload };
    }
    case actionTypes.UPDATE_INSTAGRAM_BUTTON_SETTINGS_SWITCH: {
      return { ...state, ...action.payload };
    }
    case actionTypes.UPDATE_GOOGLE_BUTTON_SETTINGS_SWITCH: {
      return { ...state, ...action.payload };
    }
    case actionTypes.SET_INITIAL_COMMENTS_SETTINGS_DATA:
      return { ...state, ...action.payload };
    case actionTypes.SET_LOGIN_TYPES_SUCCESS:
      return { ...state, loading: false, loginTypes: action.loginTypes };
    case actionTypes.UPDATE_DEFAULT_SORTING_SUCCESS:
      return {
        ...state,
        loading: false,
        defaultCommentSorting: action.payload,
      };
    case actionTypes.UPDATE_BUBBLE_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        commentBubbleLoadType: action.payload,
      };
    case actionTypes.SET_BUBBLE_LOAD_TIME_SUCCESS:
      return {
        ...state,
        loading: false,
        loadCommentBubbleAfter: action.payload,
      };
    case actionTypes.SET_BUBBLE_LOAD_AT_SUCCESS:
      return {
        ...state,
        loading: false,
        loadCommentBubbleAt: action.payload,
      };
    case actionTypes.UPDATE_EXTERNAL_SEARCH_SUCCESS:
      return { ...state, loading: false, searchOptions: action.payload };
    case actionTypes.SET_COLOR_SUCCESS:
      return { ...state, loading: false, color: action.payload };
    case actionTypes.SET_DARK_MODE_SUCCESS:
      return { ...state, loading: false, darkMode: action.payload };
    case actionTypes.SET_TREADS_TO_DISPLAY_SUCCESS:
      return { ...state, loading: false, commentThreadsToDisplay: action.payload };
    case actionTypes.SET_EMOTE_HEADER_SUCCESS:
      return { ...state, loading: false, emoteHeaderText: action.payload };
    case actionTypes.SET_OPEN_ARTICLES_IN_SAME_TAB_SUCCESS:
      return {
        ...state,
        loading: false,
        openArticlesInSameTab: action.payload,
      };
    case actionTypes.SET_SPAM_LIMIT_SUCCESS:
      return { ...state, loading: false, spamLimit: action.payload };
    case actionTypes.SET_TOT_TEXT_SUCCESS:
      return {
        ...state,
        loading: false,
        talkOfTheTownCommentText: action.payload,
      };
    case actionTypes.SET_TOXICITY_LIMIT_SUCCESS:
      return { ...state, loading: false, toxicityLimit: action.payload };
    case actionTypes.SET_HIDE_COMMENTS_WIDGET_SUCCESS:
      return { ...state, loading: false, hideCommentsWidget: action.payload };
    case actionTypes.SET_HIDE_INPUT_BOX_COMMENTS_WIDGET_SUCCESS:
      return { ...state, loading: false, hideCommentInputBox: action.payload };
    case actionTypes.SET_COMMENT_PANEL_SUCCESS:
      return { ...state, loading: false, commentsPanelEnabled: action.payload };
    case actionTypes.GET__BLOCK_WORD_LIST_SUCCESS:
      return { ...state, blockedWordsList: action.payload };
    case actionTypes.SET_COMMENTS_LENGTH_SUCCESS: {
      return { ...state, commentsLength: action.payload, loading: false };
    }
    case actionTypes.SET_EMOTE_VOTE_REQUEST:
    case actionTypes.SET_LOGIN_TYPES_REQUEST:
    case actionTypes.SET_LOGIN_TO_SSO_REQUEST:
    case actionTypes.SET_COLOR_REQUEST:
    case actionTypes.SET_DARK_MODE_REQUEST:
    case actionTypes.SET_TREADS_TO_DISPLAY_REQUEST:
    case actionTypes.SET_EMOTE_HEADER_REQUEST:
    case actionTypes.SET_OPEN_ARTICLES_IN_SAME_TAB_REQUEST:
    case actionTypes.SET_SPAM_LIMIT_REQUEST:
    case actionTypes.GET__BLOCK_WORD_LIST_REQUEST:
    case actionTypes.SET_TOT_TEXT_REQUEST:
    case actionTypes.SET_TOXICITY_LIMIT_REQUEST:
    case actionTypes.SET_HIDE_COMMENTS_WIDGET_REQUEST:
      return { ...state, loading: true };
    case actionTypes.SET_LOGIN_TO_SSO_SUCCESS:
    case actionTypes.UPDATE_TOT_COUNT_SUCCESS:
    case actionTypes.UPDATE_TOT_INTERVAL_SUCCESS:
    case actionTypes.UPDATE_FLAG_THRESHOLD_SUCCESS:
      return { ...state };
    case actionTypes.UPDATE_EMOTE_RECOMMENDED_SETTINGS_SWITCH:
      return { ...state };
    case actionTypes.UPDATE_EMOTES_LABELS_SETTINGS_SWITCH:
      return { ...state };
    case actionTypes.SET_COMMENTS_COUNT_SHOW_REQUEST:
    case actionTypes.SET_COMMENTS_COUNT_SHOW_SUCCESS:
      return { ...state };
    case actionTypes.SET_EMOTE_VOTE_SUCCESS:
      return { ...state, loading: false };
    case actionTypes.SET_FLAG_ERROR:
      return { ...state, error: true };
    case actionTypes.SET_EMOTE_AND_SHAREBAR_DATA:
      return {
        ...state,
        emoteAndSharebarConfig: {
          ...action.payload,
        },
      };
    case actionTypes.SET_HORIZONTAL_POSITION:
      return {
        ...state,
        emoteAndSharebarConfig: {
          ...state.emoteAndSharebarConfig,
          powerbarHorizontalPosition: action.payload,
        },
      };
    case actionTypes.SET_PW_COMMENT_BUTTON_VALUE:
      return {
        ...state,
        emoteAndSharebarConfig: {
          ...state.emoteAndSharebarConfig,
          disablePBCommentIcon: action.payload,
        },
      };
    case actionTypes.UPDATE_PB_AUDIO_SETTINGS_SWITCH:
      return {
        ...state,
        emoteAndSharebarConfig: {
          ...state.emoteAndSharebarConfig,
          showPBAudioButton: action.payload,
        },
      };
    case actionTypes.UPDATE_PW_BUTTONS_SETTINGS_SWITCH:
      if (action.payload.first == 1) {
        return {
          ...state,
          emoteAndSharebarConfig: {
            ...state.emoteAndSharebarConfig,
            disablePowerbarEmote: action.payload.state,
          },
        };
      } else if (action.payload.first == 2) {
        return {
          ...state,
          emoteAndSharebarConfig: {
            ...state.emoteAndSharebarConfig,
            disablePowerbarShowMoreButton: action.payload.state,
          },
        };
      } else if (action.payload.first == 4) {
        return {
          ...state,
          emoteAndSharebarConfig: {
            ...state.emoteAndSharebarConfig,
            disableBookmarkButton: action.payload.state,
          },
        };
      }
      return { ...state };
    case actionTypes.UPDATE_ENABLED_WIDGETS:
      return { ...state, enabledFeatures: action.payload };
    case actionTypes.UPDATE_PUBLISHER_STYLES:
      return {
        ...state,
        emoteAndSharebarConfig: {
          ...state.emoteAndSharebarConfig,
          publisherStyles: action.payload,
        },
      };
    case actionTypes.UPDATE_INTERACTIVITY_HUB:
      return { ...state, interactivityHub: action.payload };
    case actionTypes.UPDATE_DISABLED_TOPICS:
      return { ...state, quizDisabledTopics: action.payload };
    case actionTypes.UPDATE_DISABLE_RESULTS:
      return { ...state, hideQuizResults: action.payload };
    case actionTypes.UPDATE_DISABLE_NEXT_BUTTON:
      return { ...state, disableQuestionBypass: action.payload };
    case actionTypes.UPDATE_DISABLE_DEFAULT:
      return { ...state, hideDefaultQuestions: action.payload };
    case actionTypes.UPDATE_DISABLE_MARKETING:
      return { ...state, quizzlyMarketingQuestionsDisabled: action.payload };
    case actionTypes.UPDATE_QUESTIONS_COUNT:
      return { ...state, quizQuestionsCount: action.payload };
    case actionTypes.UPDATE_AI_QUESTIONS_COUNT:
      return { ...state, aiQuestionsCount: action.payload };
    case actionTypes.UPDATE_DEFAULT_QUESTIONS_COUNT:
      return { ...state, defaultQuestionsCount: action.payload };
    case actionTypes.UPDATE_BRAND_QUESTIONS_INDEX:
      return { ...state, brandQuestionsIndex: action.payload };
    case actionTypes.SET_BRAND_CREATE:
      return { ...state, brandCreateLoading: true, brandCreated: [] };
    case actionTypes.UPDATE_BRAND_CREATE:
      return { ...state, brands: [...state.brands, action.payload.data], brandCreated: [action.payload.data], brandCreateLoading: false };
    case actionTypes.SET_BLOCK_WORD_QW:
      return { ...state, quizBlockedWordsLoading: true, };
    case actionTypes.UPDATE_BLOCK_WORD_QW:
      return { ...state, quizBlockedWordsLoading: false };
    case actionTypes.SET_BRAND_EDIT:
      return { ...state, brandEditLoading: true };
    case actionTypes.UPDATE_BRAND_EDIT:
      return { ...state, brands: [...state.brands.filter(item => item.id !== action.payload.data.id), action.payload.data], brandEditLoading: false };
    case actionTypes.GET_BRANDS:
      return { ...state, brandsLoading: true };
    case actionTypes.SET_BRANDS:
      return { ...state, brands: action.payload, brandsLoading: false };
    case actionTypes.GET_TOPICS_REQUEST:
      return { ...state, topicsLoading: true };
    case actionTypes.GET_TOPICS_SUCCESS:
      return { ...state, topicsLoading: false, topics: action.payload };
    case actionTypes.SET_DELETE_LOGO:
      return { ...state, deleteLoading: true };
    case actionTypes.UPDATE_DELETE_LOGO:
      return { ...state, quizLogo: null, deleteLoading: false };
    case actionTypes.SET_DELETE_BRAND_LOGO:
      return { ...state, loadingDeleteBrand: true };
    case actionTypes.SET_DELETE_SPEC_BRAND_LOGO:
      return { ...state, loadingDeleteSpecBrand: true };
    case actionTypes.UPDATE_DELETE_BRAND_LOGO:
      return { ...state, loadingDeleteBrand: false };
    case actionTypes.UPDATE_DELETE_SPEC_BRAND_LOGO:
      return { ...state, loadingDeleteSpecBrand: false };
    default:
      return state;
  }
};

export default widgetSettingsReducer;
