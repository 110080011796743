import axios from 'axios';
import dayjs from 'dayjs';

export const blockForOffence = (
  token,
  host,
  offValues,
  offensiveType,
  note = '',
  sendEmailToUser = false,
) =>
  axios
    .post(
      `${process.env.API_BASE_URL}api/v1/Publishers/offensive`,
      {
        offValues,
        offensiveType,
        host,
        note,
        sendEmailToUser,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      throw new Error(errors[0]);
    });

export const blockForOffenceByApiKey = (
  token,
  apiKey,
  offValues,
  offensiveType,
  note = '',
) =>
  axios
    .post(
      `${process.env.API_BASE_URL}api/v1/Publishers/offensiveByApiKey`,
      {
        offValues,
        offensiveType,
        apiKey,
        note,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      throw new Error(errors[0]);
    });

export const unblockOffenceItem = (
  token,
  host,
  offValues,
  offensiveType,
  note = '',
) =>
  axios
    .delete(`${process.env.API_BASE_URL}api/v1/Publishers/offensive`, {
      data: {
        offValues,
        offensiveType,
        host,
        note,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      throw new Error(errors[0]);
    });

export const unblockOffenceItemByApiKey = (
  token,
  apiKey,
  offValues,
  offensiveType,
  note = '',
) =>
  axios
    .delete(`${process.env.API_BASE_URL}api/v1/Publishers/offensiveByApiKey`, {
      data: {
        offValues,
        offensiveType,
        apiKey,
        note,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      throw new Error(errors[0]);
    });

export const whitelistEmail = (token, host, email, note = '') =>
  axios
    .post(
      `${process.env.API_BASE_URL}api/v1/WhiteList/addWhitelisted`,
      { host, email, note },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      if (errors) {
        throw new Error(errors[0]);
      }

      throw new Error('not valid details');
    });

export const getWhitelistedEmails = (token, host, start, pageSize = 10) =>
  axios
    .get(`${process.env.API_BASE_URL}api/v1/WhiteList/getWhitelisted`, {
      params: {
        host,
        start,
        pageSize,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      const { data, success } = response.data;

      if (success) {
        return data.items;
      }

      throw new Error('not valid response');
    });

export const deleteWhitelistedEmails = (token, host, email) =>
  axios
    .delete(`${process.env.API_BASE_URL}api/v1/WhiteList/deleteWhitelisted`, {
      data: {
        host,
        email,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      const { success } = response.data;

      if (success || response.status === 200) {
        return true;
      }

      throw new Error('not valid response');
    });

export const getWhitelistedCount = (token, host) =>
  axios
    .get(`${process.env.API_BASE_URL}api/v1/WhiteList/getWhitelistedCount`, {
      params: {
        host,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      const { data, success } = response.data;

      if (success) {
        return data;
      }

      throw new Error('not valid response');
    });

export const whitelistEmailByApiKey = (token, apiKey, email, note = '') =>
  axios
    .post(
      `${process.env.API_BASE_URL}api/v1/WhiteList/addWhitelistedByApiKey`,
      { apiKey, email, note },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success } = response.data;

      if (success && !data[0].error) {
        return data;
      }

      if (data[0].error) {
        throw new Error(data[0].error[0]);
      }

      throw new Error('not valid details');
    });

export const deleteWhitelistedEmailsByApiKey = (token, apiKey, email) =>
  axios
    .delete(
      `${process.env.API_BASE_URL}api/v1/WhiteList/deleteWhitelistedByApiKey`,
      {
        data: {
          apiKey,
          email,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { success } = response.data;

      if (success || response.status === 200) {
        return true;
      }

      throw new Error('not valid response');
    });

export const getWhitelistedEmailNote = (token, host, email) =>
  axios
    .get(
      `${process.env.API_BASE_URL}api/v1/OffensiveNotes/getOffensiveNotesWhiteListByEmail`,
      {
        params: {
          host,
          email,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { success } = response.data;

      if (
        success &&
        response.data &&
        response.data.data &&
        response.data.data[0]
      ) {
        return `${response.data.data[0].note} ${dayjs(
          response.data.data[0].createdTimestamp * 1000,
        ).format('MM:DD:YYYY')}`;
      }
    });

export const getBlacklistedEmailNote = (token, host, email) =>
  axios
    .get(
      `${process.env.API_BASE_URL}api/v1/OffensiveNotes/getOffensiveNotesBlackListByEmail`,
      {
        params: {
          host,
          email,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { success } = response.data;

      if (!success) {
        throw new Error(errors ? errors[0] : errors);
      }

      if (
        success &&
        response.data &&
        response.data.data &&
        response.data.data[0]
      ) {
        return `${response.data.data[0].note} ${
          response.data.data[0].createdTimestamp
            ? dayjs(response.data.data[0].createdTimestamp * 1000).format(
                'MM:DD:YYYY',
              )
            : ''
        }`;
      }
    });

export const getBlacklistedIPNote = (token, host, ip) =>
  axios
    .get(
      `${process.env.API_BASE_URL}api/v1/OffensiveNotes/getOffensiveNotesBlackListByIP`,
      {
        params: {
          host,
          ip,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { success } = response.data;

      if (
        success &&
        response.data &&
        response.data.data &&
        response.data.data[0]
      ) {
        return `${response.data.data[0].note} ${
          response.data.data[0].createdTimestamp
            ? dayjs(response.data.data[0].createdTimestamp * 1000).format(
                'MM:DD:YYYY',
              )
            : ''
        }`;
      }
    });

export const updateNote = (token, host, offVal, offType, note) =>
  axios
    .put(
      `${process.env.API_BASE_URL}api/v1/OffensiveNotes/updateOffensiveNote?host=${host}&offVal=${offVal}&note=${note}&offType=${offType}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data.isUpdated;
      }
    });
