import { actionTypes } from '@/services/actions/adblockmanagement';

// ==== 🗄️ Reducer
export const initialState = {
  loading: false,
  showAdBlockModal: false,
  noAdBlockFreeArticles: 0,
  adBlockModalMessage: '',
};

export const adblockManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_DATA: {
      return { ...state, ...action.payload };
    }
    case actionTypes.SET_LOADING: {
      return { ...state, loading: action.payload };
    }
    default:
      return state;
  }
};

export default adblockManagementReducer;
