import { actionTypes } from '@/services/actions/cloudMessaging';

// ==== 🗄️ Reducer
export const initialState = {
  topics: [],
  topics_loading: false,

  create_topic_loading: false,

  notification: [],
  notification_loading: false,

  create_notification_loading: false,

  reports_data: [],
  totals_data: [{ Open: 0, Sends: 0, Subscribes: 0 }],
  totals_data_loading: false,
  reports_data_loading: false,
};

export const cloudMessagingReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_REPORTS_DATA: {
      return { ...state, reports_data_loading: true };
    }
    case actionTypes.GET_NOTIFICATION_TOTALS_REQUEST: {
      return { ...state, totals_data_loading: true };
    }
    case actionTypes.SET_REPORTS_DATA: {
      return {
        ...state,
        reports_data_loading: false,
        reports_data: action.payload,
      };
    }
    case actionTypes.GET_NOTIFICATION_TOTALS_FINISHED: {
      return {
        ...state,
        totals_data_loading: false,
        totals_data: action.payload,
      };
    }
    case actionTypes.GET_TOPICS_REQUEST:
      return { ...state, topics_loading: true };
    case actionTypes.GET_TOPICS_FINISHED:
      return { ...state, topics_loading: false, topics: action.payload };

    case actionTypes.CREATE_TOPICS_REQUEST:
      return { ...state, create_topic_loading: true };
    case actionTypes.CREATE_TOPICS_FINISHED:
      return { ...state, create_topic_loading: false };

    case actionTypes.GET_NOTIFICATION_REQUEST:
      return { ...state, notification_loading: true };
    case actionTypes.GET_NOTIFICATION_FINISHED:
      return {
        ...state,
        notification_loading: false,
        notification: action.payload,
      };

    case actionTypes.SCHEDULE_NOTIFICATION_REQUEST:
      return { ...state, notification_loading: true };
    case actionTypes.SCHEDULE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notification_loading: false,
        id: action.payload.id,
        scheduleTimeUtc: action.payload.scheduleTimeUtc,
      };

    case actionTypes.CREATE_NOTIFICATION_REQUEST:
      return { ...state, create_notification_loading: true };
    case actionTypes.CREATE_NOTIFICATION_REQUEST:
      return { ...state, create_notification_loading: false };

    default:
      return state;
  }
};

export default cloudMessagingReducer
