import { call, put, select, takeLatest } from 'redux-saga/effects';
import { toaster } from 'evergreen-ui';
import {
  setFlag,
  setFlagByApiKey,
  setCustomCheckboxFlag,
  updatePrivacyPolicyAPI,
  getCommentsByUserEmail,
} from '@/services/api/settings/comments/general';
import {
  actionTypes,
  setAutoNotificationsSuccess,
  setBrowserNotificationsSuccess,
  setFlagError,
} from '@/services/actions/settings/comments/general';
import {
  setNotifyFlag,
  UpdatePublisherFlag,
} from '@/services/api/settings/index';

/** TODO: Maybe split this function into more functions instead of handling all action types in here */
export function* setFlagSaga(action) {
  try {
    const token = yield select(({ session }) => session.token);
    const host = yield select(({ filter }) => filter.host);
    const apiKey = yield select(({ session }) => session.apiKey);

    yield call(
      UpdatePublisherFlag,
      token,
      action.allSites ? { apiKey } : { host },
      action.flagName,
      action.flagValue,
    );
    switch (action.type) {
      case actionTypes.SET_MODERATION_FLAG_REQUEST:
        yield put({
          type: actionTypes.SET_MODERATION_FLAG_SUCCESS,
          payload: action.flagValue,
        });
        toaster.success(
          `Moderation is now ${action.flagValue ? 'on' : 'off'}!`,
          { id: 'general-moderation-flag-update' },
        );
        break;
      case actionTypes.SET_LINK_MODERATION_FLAG_REQUEST:
        yield put({
          type: actionTypes.SET_LINK_MODERATION_FLAG_SUCCESS,
          payload: action.flagValue,
        });
        toaster.success(
          `Link moderation in comments is now ${
            action.flagValue ? 'on' : 'off'
          }!`,
          { id: 'link-moderation-flag-update' },
        );
        break;
      case actionTypes.SET_IMAGES_MODERATION_FLAG_REQUEST:
        yield put({
          type: actionTypes.SET_IMAGES_MODERATION_FLAG_SUCCESS,
          payload: action.flagValue,
        });
        toaster.success(
          `Image moderation in comments is now ${
            action.flagValue ? 'on' : 'off'
          }!`,
          { id: 'images-moderation-flag-update' },
        );
        break;
      case actionTypes.SET_EMOGISTO_MODERATION_FLAG_REQUEST:
        yield put({
          type: actionTypes.SET_EMOGISTO_MODERATION_FLAG_SUCCESS,
          payload: action.flagValue,
        });
        toaster.success(
          `Emojies moderation in comments is now ${
            action.flagValue ? 'on' : 'off'
          }!`,
          { id: 'emojies-moderation-flag-update' },
        );
        break;
      case actionTypes.SET_EXACT_BLOCK_WORD_MATCH_REQUEST:
        yield put({
          type: actionTypes.SET_EXACT_BLOCK_WORD_MATCH_SUCCESS,
          payload: action.flagValue,
        });
        toaster.success(
          `Exact block word matching is now ${
            action.flagValue ? 'on' : 'off'
          }!`,
          { id: 'word-matching-flag-update' },
        );
        break;
      case actionTypes.SET_DISABLE_OPENAI_REQUEST:
        yield put({
          type: actionTypes.SET_DISABLE_OPENAI_SUCCESS,
          payload: action.flagValue,
        });
        toaster.success(
          `OpenAI comment moderation ${action.flagValue ? 'off' : 'on'}!`,
          { id: 'openai-flag-update' },
        );
        break;
      case actionTypes.SET_NOTIFICATION_FOR_MODERATOR_REQUEST:
        toaster.success(`Notifications for moderators updated!`, {
          id: 'notif-moderator-flag-update',
        });
        break;
    }
  } catch (e) {
    yield put(setFlagError());
    toaster.danger(
      'Something went wrong with your request! Please try again later.',
      { id: 'unsuccessful-set-flag' },
    );
    console.log('Something went wrong', e.message);
  }
}

export function* setModeratorFlagSaga(action) {
  try {
    const token = yield select(({ session }) => session.token);
    const host = yield select(({ filter }) => filter.host);
    const apiKey = yield select(({ session }) => session.apiKey);

    yield call(
      setNotifyFlag,
      token,
      action.allSites ? { apiKey } : { host },
      action.flagName || 'test',
      action.flagValue,
    );

    switch (action.type) {
      case actionTypes.SET_NOTIFICATION_FOR_ADMIN_REQUEST:
        yield put({
          type: actionTypes.SET_NOTIFICATION_FOR_ADMIN_SUCCESS,
          payload: action.flagValue,
        });
        yield call(
          UpdatePublisherFlag,
          token,
          action.allSites ? { apiKey } : { host },
          2,
          action.flagValue,
        );
        toaster.success(
          `Notifications for admin are now ${action.flagValue ? 'on' : 'off'}!`,
          { id: 'notif-admin-flag-update' },
        );
        break;
      case actionTypes.SET_NOTIFICATION_FOR_MODERATOR_REQUEST:
        toaster.success(`Notifications for moderators updated!`, {
          id: 'notif-moderator-flag-update',
        });
        break;
    }
  } catch (e) {
    yield put(setFlagError());
    toaster.danger(
      'Something went wrong with your request! Please try again later.',
      { id: 'unsuccessful-set-flag' },
    );
    console.log('Something went wrong', e.message);
  }
}

export function* setCustomCheckboxFlagSaga(action) {
  try {
    const token = yield select(({ session }) => session.token);
    const host = yield select(({ filter }) => filter.host);
    const apiKey = yield select(({ session }) => session.apiKey);
    const { value, allSites } = action.payload;

    yield call(
      UpdatePublisherFlag,
      token,
      allSites ? { apiKey } : { host },
      20,
      value,
    );

    yield put({
      type: actionTypes.SET_CUSTOM_CHECKBOX_FLAG_SUCCESS,
      payload: action.payload.value,
    });
    toaster.success(
      `Custom checkbox is now ${action.payload.value ? 'on' : 'off'}!`,
      { id: 'general-custom-checkbox-flag-update' },
    );
  } catch (e) {
    yield put(setFlagError());
    toaster.danger(
      'Something went wrong with your request! Please try again later.',
      { id: 'unsuccessful-set-flag' },
    );
    console.log('Something went wrong', e.message);
  }
}

export function* setCommentsByUserEmailSaga(action) {
  try {
    const token = yield select(({ session }) => session.token);
    const host = yield select(({ filter }) => filter.host);
    const { userEmail, allSites } = action.payload;

    const data = yield call(
      getCommentsByUserEmail,
      token,
      allSites ? null : host,
      userEmail,
    );
    if (data.success) {
      yield put({
        type: actionTypes.SET_COMMENTS_BY_USER_EMAIL_SUCCESS,
        payload: data.data,
      });    } else {
      toaster.danger(
          'Something went wrong with your request! Please try again later.',
          { id: 'unsuccessful-get-comments' },
      );
    }
  } catch (e) {
    yield put(setFlagError());
    toaster.danger(
      'Something went wrong with your request! Please try again later.',
      { id: 'unsuccessful-get-comments' },
    );
    console.log('Something went wrong', e.message);
  }
}

export function* updatePrivacyPolicy(action) {
  try {
    const token = yield select(({ session }) => session.token);
    const host = yield select(({ filter }) => filter.host);

    yield call(updatePrivacyPolicyAPI, token, action.payload, host);

    yield put({
      type: actionTypes.SET_PRIVACY_POLICY_DATA,
      payload: {
        checkboxText: action.payload.customCheckboxText,
        privacyPolicyLink: action.payload.customPrivacyPolicyLink,
        privacyPolicyLinkText: action.payload.customPolicyLinkText,
      },
    });

    toaster.success('Privacy Policy update success!', {
      id: 'custom-privacy-policy-update',
    });
  } catch (e) {
    yield put(setFlagError());
    toaster.danger(
      'Settings are saved but will not appear until you turn ON the privacy checkbox.',
      { id: 'unsuccessful-custom-privacy-policy-update' },
    );
    console.log('Something went wrong', e.message);
  }
}

export function* updateBrowserNotificationsSaga(action) {
  try {
    const token = yield select(({ session }) => session.token);
    const apiKey = yield select(({ session }) => session.apiKey);
    const host = yield select(({ filter }) => filter.host);

    yield call(
      UpdatePublisherFlag,
      token,
      action.payload.allSites ? { apiKey } : { host },
      24,
      action.payload.data,
    );
    toaster.success('Web push notification settings updated successfully', {
      id: 'successful-update-web-push',
    });
    yield put(setBrowserNotificationsSuccess(action.payload.data));
  } catch (e) {
    yield put(setFlagError());
    toaster.danger(
      'Something went wrong with your request! Please try again later.',
      { id: 'unsuccessful-set-web-push' },
    );
    console.log('Something went wrong', e.message);
  }
}

export function* updateAutoNotificationsSaga(action) {
  try {
    const token = yield select(({ session }) => session.token);
    const host = yield select(({ filter }) => filter.host);

    yield call(
      UpdatePublisherFlag,
      token,
      action.payload.allSites ? { host: '' } : { host },
      35,
      action.payload.data,
    );
    toaster.success(
      'Web push automated notification settings updated successfully',
      {
        id: 'successful-update-auto-web-push',
      },
    );
    yield put(setAutoNotificationsSuccess(action.payload.data));
  } catch (e) {
    yield put(setFlagError());
    toaster.danger(
      'Something went wrong with your request! Please try again later.',
      { id: 'unsuccessful-set-auto-web-push' },
    );
    console.log('Something went wrong', e.message);
  }
}

export default function* generalCommentsSettingsSaga() {
  yield takeLatest(actionTypes.SET_MODERATION_FLAG_REQUEST, setFlagSaga);
  yield takeLatest(
    actionTypes.SET_CUSTOM_CHECKBOX_FLAG_REQUEST,
    setCustomCheckboxFlagSaga,
  );
  yield takeLatest(
    actionTypes.UPDATE_PRIVACY_POLICY_REQUEST,
    updatePrivacyPolicy,
  );
  yield takeLatest(actionTypes.SET_LINK_MODERATION_FLAG_REQUEST, setFlagSaga);
  yield takeLatest(actionTypes.SET_IMAGES_MODERATION_FLAG_REQUEST, setFlagSaga);
  yield takeLatest(
    actionTypes.SET_EMOGISTO_MODERATION_FLAG_REQUEST,
    setFlagSaga,
  );
  yield takeLatest(actionTypes.SET_EXACT_BLOCK_WORD_MATCH_REQUEST, setFlagSaga);
  yield takeLatest(actionTypes.SET_DISABLE_OPENAI_REQUEST, setFlagSaga);
  yield takeLatest(
    actionTypes.SET_NOTIFICATION_FOR_ADMIN_REQUEST,
    setModeratorFlagSaga,
  );
  yield takeLatest(
    actionTypes.SET_BROWSER_NOTIFICATIONS_REQUEST,
    updateBrowserNotificationsSaga,
  );
  yield takeLatest(
    actionTypes.SET_AUTO_NOTIFICATIONS_REQUEST,
    updateAutoNotificationsSaga,
  );
  yield takeLatest(
    actionTypes.SET_NOTIFICATION_FOR_MODERATOR_REQUEST,
    setModeratorFlagSaga,
  );
  yield takeLatest(
    actionTypes.GET_COMMENTS_BY_USER_EMAIL_REQUEST,
      setCommentsByUserEmailSaga,
  );
}
