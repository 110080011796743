import axios from 'axios';

export const getQuizAnsweredArticles = (token, from, to, host) =>
  axios
    .get(
      `${process.env.API_BASE_URL}api/v1/QuizAnalytics/GetTopArticles`,
      {
        params: {
          from,
          to,
          host,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success } = response.data;
      if (success) {
        return data;
      }

      throw new Error('not valid details');
    });

export const getQuizMarketingQuestions = (token, startDay , endDay, host, groupingParameter, topic) =>
  axios
    .get(
      `${process.env.API_BASE_URL}api/v1/QuizAnalytics/GetMarketingQuestionsAnalytics`,
      {
        params: {
          startDay ,
          endDay,
          host,
          groupingParameter,
          topic
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success } = response.data;
      if (success) {
        return data;
      }

      throw new Error('not valid details');
    });

export const getQuizAnsweredPerQuestionInfo = (token, from, to, host) =>
  axios
    .get(
      `${process.env.API_BASE_URL}api/v1/QuizAnalytics/GetUserAnswerPerQuiz`,
      {
        params: {
          from,
          to,
          host,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success } = response.data;
      if (success) {
        return data;
      }

      throw new Error('not valid details');
    });

export const getQuizPerArticle = (token, from, to, host) =>
  axios
    .get(
      `${process.env.API_BASE_URL}api/v1/QuizAnalytics/GetUserAnswerPerArticlePerQuiz`,
      {
        params: {
          from,
          to,
          host,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success } = response.data;
      if (success) {
        return data;
      }

      throw new Error('not valid details');
    });

export const getMostQuizAnswers = (token, from, to, host) =>
  axios
    .get(
      `${process.env.API_BASE_URL}api/v1/QuizAnalytics/GetTopQuestionsAnalytics`,
      {
        params: {
          from,
          to,
          host,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success } = response.data;
      if (success) {
        return data;
      }

      throw new Error('not valid details');
    });

export const getQuizAnsweredArticlesByFilter = (
  token,
  from,
  to,
  host,
  filter,
  fromCount,
  toCount,
) =>
  axios
    .get(`${process.env.API_BASE_URL}api/v1/QuizAnalytics/getTopByFilter`, {
      params: {
        host,
        from,
        to,
        filter,
        fromCount,
        toCount,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      const { data, success } = response.data;
      if (success) {
        return data.reduce((result, currentObject) => {
            /** Merge the current object into the result object for having structure like another pages */
            Object.assign(result, currentObject);
            return result;
        }, {});
      }

      throw new Error('not valid details');
    });
