import axios from 'axios';

export const getSubscribedEmailsByHost = (token, from, to, host) =>
  axios
    .get(
      `${process.env.API_BASE_URL}api/v1/Newsletters/getSubscribedEmailsByHost`,
      {
        params: {
          from,
          to,
          host,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success } = response.data;
      if (success) {
        return data;
      }

      throw new Error('not valid details');
    });

export const getSubscribedEmailsByApiKey = (token, from, to, apiKey) =>
  axios
    .get(
      `${process.env.API_BASE_URL}api/v1/Newsletters/getSubscribedEmailsByApiKey`,
      {
        params: {
          from,
          to,
          apiKey,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success } = response.data;
      if (success) {
        return data;
      }

      throw new Error('not valid details');
    });

export const getNewslettersSubscribersTopByFilter = (
  token,
  from,
  to,
  host,
  filter,
  fromCount,
  toCount,
) =>
  axios
    .get(`${process.env.API_BASE_URL}api/v1/Newsletters/getTopByFilter`, {
      params: {
        host,
        from,
        to,
        filter,
        fromCount,
        toCount,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      const { data, success } = response.data;
      if (success) {
        return data;
      }

      throw new Error('not valid details');
    });

export const getNewslettersSubscribersTopByFilterByApiKey = (
  token,
  from,
  to,
  apikey,
  filter,
  fromCount,
  toCount,
) =>
  axios
    .get(
      `${process.env.API_BASE_URL}api/v1/Newsletters/getTopByFilterByApiKey`,
      {
        params: {
          from,
          to,
          apikey,
          filter,
          fromCount,
          toCount,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success } = response.data;
      if (success) {
        return data;
      }

      throw new Error('not valid details');
    });
