import axios from 'axios';

export const CreateCompany = (token, companyName) =>
  axios
    .post(
      process.env.API_BASE_URL + 'api/v1/GAM/CreateCompany',
      {
        companyName,
        orderName: companyName,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { success, errors } = response.data;

      if (success) {
        return response.data;
      }

      if (errors[0] !== 'company_is_already_exist_with_specified_name') {
        throw new Error(errors[0]);
      } else {
        return response.data;
      }
    });
export const CreateLineItem = (
  token,
  lineItemName,
  orderName,
  costPerUnit,
  quantity,
  startTime,
  endTime,
  adUnitIds,
  geoLocations,
  excludedGeoLocations = [],
  targetedDevice,
  primaryGoalType,
  creativePlaceholderForLineItem,
  setProgressBarPercent,
) =>
  axios
    .post(
      process.env.API_BASE_URL + 'api/v1/GAM/CreateLineItem',
      {
        lineItemName,
        orderName,
        costPerUnit,
        quantity,
        startTime,
        primaryGoalType,
        endTime,
        adUnitIds,
        geoLocations,
        excludedGeoLocations,
        targetedDevice,
        creativePlaceholderForLineItem,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        onUploadProgress: (p) =>
          setProgressBarPercent((p?.loaded / p?.total) * 100),
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      throw new Error(errors[0]);
    });
export const AdUnits = (token, name) =>
  axios
    .get(process.env.API_BASE_URL + `api/v1/GAM/Units`, {
      params: {
        name,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      throw new Error(errors[0]);
    });
export const GetTargetableLocations = (token) =>
  axios
    .get(process.env.API_BASE_URL + 'api/v1/GAM/GetTargetableLocations', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      throw new Error(errors[0]);
    });
export const GetTargetDevices = (token) =>
  axios
    .get(process.env.API_BASE_URL + 'api/v1/GAM/GetTargetDevices', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      throw new Error(errors[0]);
    });
export const AddCreativeForLineItem = (
  token,
  formData,
  setProgressBarPercent,
) =>
  axios
    .post(
      process.env.API_BASE_URL + 'api/v1/GAM/AddCreativeForLineItem',
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        onUploadProgress: (p) =>
          setProgressBarPercent((p?.loaded / p?.total) * 100),
      },
    )
    .then((response) => {
      const { success, errors } = response.data;

      if (!errors) {
        return response;
      }

      throw new Error(errors[0]);
    });
export const GetLineItemsByOrderName = (token, orderName) =>
  axios
    .get(process.env.API_BASE_URL + 'api/v1/GAM/GetLineItemsByOrderName', {
      params: {
        orderName,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return response.data;
      }

      throw new Error(errors[0]);
    });
export const EditLineItemById = (
  token,
  id,
  rate,
  quantity,
  startTime,
  endTime,
  height,
  width,
  adUnitIds,
  geoLocations,
  excludedGeoLocations = [],
  targetedDevice,
  setProgressBarPercent,
) =>
  axios
    .post(
      process.env.API_BASE_URL + 'api/v1/GAM/EditLineItemById',
      {
        id,
        rate,
        quantity,
        startTime,
        endTime,
        expectedCreative: [
          {
            width,
            height,
          },
        ],
        adUnitIds,
        geoLocations,
        excludedGeoLocations,
        targetedDevice,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        onUploadProgress: (p) =>
          setProgressBarPercent((p?.loaded / p?.total) * 100),
      },
    )
    .then((response) => {
      const { success, errors } = response.data;

      if (success) {
        return response.data;
      }

      throw new Error(errors[0]);
    });
export const CreativesForLineItem = (token, name) =>
  axios
    .get(process.env.API_BASE_URL + 'api/v1/GAM/CreativesForLineItem', {
      params: {
        name,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      throw new Error(errors[0]);
    });
export const GetPrivacyIssues = (token, host) =>
  axios
    .get(process.env.API_BASE_URL + 'api/PrivacyCenter/GetPrivacyIssues', {
      params: {
        host,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      throw new Error(errors[0]);
    });
export const UpdateCreativeById = (token, formData) =>
  axios
    .put(process.env.API_BASE_URL + 'api/v1/GAM/UpdateCreativeById', formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      throw new Error(errors[0]);
    });
export const LineItemReport = (
  token,
  lineItemId,
  orderName,
  from,
  to,
  reportType,
  adUnitIds,
  country,
  deviceIds,
  creativeSizes,
  setProgressBarPercent,
) =>
  axios
    .post(
      process.env.API_BASE_URL + 'api/v1/GAMReports/LineItemReport',
      {
        lineItemId,
        orderName,
        from,
        to,
        reportType,
        adUnitIds,
        country,
        deviceIds,
        creativeSizes,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        onUploadProgress: (p) =>
          setProgressBarPercent((p?.loaded / p?.total) * 100),
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      throw new Error(errors[0]);
    });
export const DeleteCreateiveForLineItem = (token, lineItemId, creativeId) =>
  axios
    .delete(
      process.env.API_BASE_URL +
        `api/v1/GAM/DeleteCreateiveForLineItem/${lineItemId}/${creativeId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success === false) {
        throw new Error(errors[0]);
      }

      return success;
    });
export const PerformLineItemAction = (token, id, action) =>
  axios
    .put(
      process.env.API_BASE_URL + 'api/v1/GAM/PerformLineItemAction',
      {
        id,
        action,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      throw new Error(errors[0]);
    });
export const CopyLineItem = (
  token,
  lineItemId,
  numberOfCopies = 1,
  copyWithCreatives,
) =>
  axios
    .post(
      process.env.API_BASE_URL + 'api/v1/GAM/CopyLineItem',
      {
        lineItemId,
        numberOfCopies,
        copyWithCreatives,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      throw new Error(errors[0]);
    });
export const EditLineItemNameById = (token, id, name) =>
  axios
    .put(
      process.env.API_BASE_URL + 'api/v1/GAM/EditLineItemNameById',
      {
        id,
        name,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      throw new Error(errors[0]);
    });
export const GetTargetedLocationsForLineItems = (token, lineItemId) =>
  axios
    .get(
      process.env.API_BASE_URL + 'api/v1/GAM/GetTargetedLocationsForLineItems',
      {
        params: {
          lineItemId,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data, success, errors } = response.data;

      if (success) {
        return data;
      }

      throw new Error(errors[0]);
    });
